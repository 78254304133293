<template>
  <div class="payment">
    <zv-nav-bar title="已支付" :sourceType="sourceType"></zv-nav-bar>
    <van-list v-model="loading" :finished="finished" @load="onLoad">
      <div class="item" v-for="item in list" :key="item.orderID" @click="gotoPage(item)">
        <div class="logo">
          <img :src="item.logoFile" />
        </div>
        <div class="other">
          <div class="orderNo">订单号：{{item.orderNO}}</div>
          <div class="title">{{item.goodsName}}</div>
          <div class="detail">
            <div class="time">{{item.orderTime}}</div>
            <div class="price">￥<span style="font-size: 0.42rem;">{{item.orderAmount | formatValue}}</span></div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  name: 'Payment',
  data() {
    return {
      loading: false,
      finished: false,
      pageIndex: 0,
      list: [],
      isBack: '',
      sourceType: ''
    }
  },
  filters: {
    formatValue(value) {
      if (!value) {
        return '0.00'
      }
      return Number(value).toFixed(2)
    }
  },
  created() {
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      this.sourceType = 'payment'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    onLoad() {
      this.$axios.get(`/course/front/member/order/list?status=00&pageSize=10&pageIndex=${this.pageIndex}`).then(res => {
        if (res.data.status === 1) {
          this.pageIndex = this.pageIndex + 1
          this.list = this.list.concat(res.data.data.data)
          this.loading = false
          if (this.list.length >= res.data.data.total) {
            this.finished = true
          }
        } else {
          this.$Toast.fail(res.data.message)
          this.loading = false
          this.finished = true
        }
      }).catch(e => {
        this.$Toast.fail('服务器开小差了，稍后重试')
        return e
      })
    },
    gotoPage(item) {
      let path = `/allCourses/details/${item.ID}/curriculum`
      if (item.attribute === 'Course') {
        if (item.type !== 0) {
          path = `/allCourses/courseHandout/${item.ID}`
        } else {
          path = path + "?backUrl=" + this.$router.history.current.fullPath
        }
      } else if (item.attribute === 'CoursePackage') {
        path = `/allCourses/packDetails/${item.ID}` + "?backUrl=" + this.$router.history.current.fullPath
      } else {
        path = `/allCourses/listDetails/${item.ID}`
      }
      this.$router.push({
        path: path
      })
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  }
}
</script>
<style scoped>
  .payment .item:first-child {
    padding-top: 0.65rem;
  }
  .item {
    padding: 0 0 0.65rem 0.52rem;
    display: flex;
  }
  .logo {
    width: 1.2rem;
    height: 1.2rem;
    background-color: #e7f2ff;
    border-radius: 50%;
  }
  .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .title {
    font-size: 0.4rem;
    color: #222222;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    width: 8rem;
  }
  .time {
    font-size: 0.35rem;
    color: #b1b1b1;
  }
  .price {
    font-size: 0.32rem;
    font-weight: bold;
    color: #f87601;
  }
  .other {
    margin-left: 0.24rem;
    width: 8rem;
    border-bottom: 1px solid #eee;
  }
  .detail {
    display: flex;
    justify-content: space-between;
    padding: 0.31rem 0.45rem 0.61rem 0;
  }
  .orderNo {
    font-size: 0.4rem;
    color: #222222;
  }
</style>
