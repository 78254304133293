<template>
  <div class="register">
    <!-- <van-nav-bar left-arrow @click-left="onClickLeft" /> -->
    <van-row>
      <van-col span="24" align="center">
        <p class="tips">你还不是会员，请完善个人信息</p>
        <van-form @submit="onSubmit" class="login-form" :style="{'margin-top': idCardShow ? '-6.13rem': '0.93rem'}">
          <span class="form-item-label">姓名</span>
          <van-field
            v-model="form.username"
            name="username"
            placeholder="请填写您的姓名"
            :rules="[{ required: true, message: '请填写您的姓名' }]"
          />
          <span class="form-item-label">性别</span>
          <van-dropdown-menu>
            <van-dropdown-item v-model="form.sex" :options="sexs" />
          </van-dropdown-menu>
          <span class="form-item-label">手机号</span>
          <van-field
            v-model="form.phonenumber"
            name="phonenumber"
            placeholder="请输入正确的手机号"
            :readonly="true"
            @click="show = true"
            :rules="[{ validator: validator, required: true, message: '请输入正确的手机号' }]"
          />
          <span class="form-item-label">验证码</span>
          <van-field
            v-model="form.verificationCode"
            name="verificationCode"
            placeholder="请输入验证码"
            :readonly="true"
            @click="verifCodeShow = true"
            :rules="[{ required: true, message: '请输入验证码' }]"
          >
            <template #button>
              <span @click.stop="getVerificationCode">{{codeText}}</span>
            </template>
          </van-field>
          <span class="form-item-label">学历</span>
          <van-dropdown-menu direction="up">
            <van-dropdown-item v-model="form.education" :options="educations" />
          </van-dropdown-menu>
          <span class="form-item-label">身份证号</span>
          <van-field
            v-model="form.idcard"
            name="idcard"
            placeholder="请输入正确的身份证号"
            :readonly="true"
            @click="idCardShow = true"
            :rules="[{ validator: idCardValidator, required: true, message: '请输入正确的身份证号' }]"
          />
          <span class="form-item-label">企业名称</span>
          <van-field
            v-model="form.enterpriseName"
            name="enterpriseName"
            placeholder="请输入您的企业名称"
          />
          <div style="margin: 1.1rem 0.67rem 1.2rem 0.67rem;">
            <van-button round block type="info" native-type="submit">完成</van-button>
          </div>
        </van-form>
      </van-col>
    </van-row>
    <van-number-keyboard :show="show" theme="custom" close-button-text="完成" @blur="show = false" @input="onInput" @delete="onDelete" />
    <van-number-keyboard :show="verifCodeShow" theme="custom" close-button-text="完成" @blur="verifCodeShow = false" @input="onInputVerifCode" @delete="onDeleteVerifCode" />
    <van-number-keyboard :show="idCardShow" extra-key="X" close-button-text="完成" @blur="idCardShow = false" @input="onIdCardInput" @delete="onIdCardDelete"/>
  </div>
</template>

<script>
export default {
  name: 'register',
  data () {
    return {
      show: false,
      verifCodeShow: false,
      idCardShow: false,
      timer: null,
      codeText: '发送验证码',
      form: {
        username: '',
        sex: '1',
        phonenumber: '',
        verificationCode: '',
        education: '4',
        idcard: '',
        enterpriseName: ''
      },
      sexs: [
        { text: '男', value: '1' },
        { text: '女', value: '0' }
      ],
      educations: [
        { text: '小学', value: '0' },
        { text: '初中', value: '1' },
        { text: '高中', value: '2' },
        { text: '大专', value: '3' },
        { text: '大学', value: '4' },
        { text: '研究生', value: '5' },
        { text: '博士', value: '6' }
      ]
    }
  },
  methods: {
    onSubmit() {
      this.$router.push({ path: '/' })
    },
    getVerificationCode() {
      if (this.timer) {
        return false
      }
      // TODO 请求接口获取验证码
      let i = 60
      this.timer = setInterval(() => {
        i = Number(i) - 1
        this.codeText = i + 's'
        if (i <= 0) {
          clearInterval(this.timer)
          this.timer = null
          this.codeText = '发送验证码'
        }
      }, 1000)
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onInput(number) {
      this.form.phonenumber += String(number)
    },
    onInputVerifCode(number) {
      this.form.verificationCode += String(number)
    },
    onIdCardInput(number) {
      this.form.idcard += String(number)
    },
    onDelete() {
      this.form.phonenumber = this.form.phonenumber.substring(0, this.form.phonenumber.length - 1)
    },
    onDeleteVerifCode() {
      this.form.verificationCode = this.form.verificationCode.substring(0, this.form.verificationCode.length - 1)
    },
    onIdCardDelete() {
      this.form.idcard = this.form.idcard.substring(0, this.form.idcard.length - 1)
    },
    validator(val) {
      let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      return reg.test(val)
    },
    idCardValidator(val) {
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      return reg.test(val)
    }
  }
}
</script>

<style scoped>
.register >>> .van-nav-bar__content {
  height: 1.22rem;
}
.register >>> .van-nav-bar__title {
  font-size: 0.42rem;
}
.register >>> .van-nav-bar__arrow {
  font-size: 0.42rem;
  color: #333;
}
.tips {
  font-size: 0.53rem;
  font-weight: bold;
  color: #333333;
  margin: 0.87rem 1.68rem 0 0.68rem;
}
.login-form {
  margin-top: 0.93rem;
}
.form-item-label {
  text-align: left;
  display: block;
  text-indent: 0.87rem;
  font-size: 0.37rem;
  color: #b9b9b9;
}
.register div.van-cell.van-field {
  margin: 0.33rem 0;
  padding: 0 0.67rem;
}
.register >>> .van-cell__value.van-cell__value--alone.van-field__value {
  background-color: #F8F8F8;
  border-radius: 0.57rem;
  height: 1.13rem;
  line-height: 1.13rem;
}
.register >>> .van-cell {
  font-size: 0.37rem;
}
.register >>> .van-cell::after {
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  border-bottom: none;
}
.register >>> .van-field__body input.van-field__control {
  text-indent: 0.62rem;
}
.register >>> .van-dropdown-menu {
  margin: 0.33rem 0;
  padding: 0 0.67rem;
}
.register >>> .van-dropdown-menu__bar {
  background-color: #F8F8F8;
  border-radius: 0.57rem;
  height: 1.13rem;
  line-height: 1.13rem;
  box-shadow: unset;
}
.register >>> .van-dropdown-menu__title {
  font-size: 0.37rem;
  line-height: 0.48rem;
}
.register >>> .van-field__button {
  position: absolute;
  right: 0.625rem;
  border-left: 1px solid #e3e3e3;
  height: 0.6875rem;
  line-height: 0.6875rem;
  color: #1578f2;
  font-size: 0.37rem;
}
.register >>> .van-button {
  height: 1.27rem;
  font-size: 0.37rem;
}
</style>
