<template>
  <div>
    <zv-nav-bar :title="pagename + '(目录)'" :sourceType="sourceType" v-if="!isShowSearch" :clickLeft="goBack">
      <span class="fa fa-search" style="font-size: 0.42rem;" @click="isShowSearch = true"></span>
    </zv-nav-bar>
    <div v-else class="van-nav-bar__content">
      <div style="padding: 0 0.42rem;" @click="goBack">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
      </div>
      <div class="head">
        <div class="search-input">
          <i class="van-icon van-icon-search"></i>
          <input v-model="textValue" placeholder="搜索课程" />
        </div>
      </div>
      <van-button :loading="searchLoading" type="info" loading-type="spinner" @click="searchClick">搜索</van-button>
      <van-button @click="cancel">取消</van-button>
    </div>
    <div class="course-list-box">
      <div class="catalog">
        <span>{{ catalogPath }}</span>
      </div>
      <van-list v-model="listLoading" @load="onLoad" :finished="finished">
        <template v-if="courseList.length > 0">
          <div class="course-list-item" v-for="item in courseList" :key="item.ID" @click="gotoPage(item)">
            <div class="course-item">
              <div style="display: flex; align-items: center;">
                <div class="logo">
                  <img :src="item.logoFile" />
                </div>
                <div class="item">
                  <div style="display: flex;">
                    <span class="tag" :style="{ 'background-color': item.enterpriseExclusive === 1 ? '#70dd96' : '#fbc047' }">{{ item.type !== 0 ? '讲义' : '视频' }}</span>
                    <span class="title">{{ item.name }}</span>
                  </div>
                  <div style="display: flex; justify-content: space-between; align-items: center; width: 7.2rem;">
                    <div>
                      <span class="star fa fa-star" :style="{ color: item.hasPermission === 'Y' ? '#29b8f9' : '#cecfd2' }"></span>
                      <span class="star fa fa-star" :style="{ color: item.hasComplete === 'Y' ? '#29b8f9' : '#cecfd2' }"></span>
                      <span class="time">{{ item.totalLength === '不足一分钟' ? `＜1分钟` : `${item.totalLength}分钟` }}</span>
                    </div>
                    <template v-if="item.isForbid === 0">
                      <div style="display: flex; font-size: 0.43rem; justify-content: space-between; margin-top: 0.15rem;" v-if="item.isFree == '0'">
                        <div class="current-price">￥{{ isVip ? item.memberPrice : item.sellPrice }}</div>
                        <div class="old-price" v-if="isVip">￥{{ item.sellPrice }}</div>
                      </div>
                      <div v-else style="display: flex; font-size: 0.43rem; justify-content: space-between; margin-top: 0.15rem;">
                        <div class="current-price">免费</div>
                      </div>
                    </template>
                    <template v-else-if="item.hasPermission === 'Y'">
                      <div v-if="item.isFree === '1'" style="display: flex; font-size: 0.43rem; justify-content: space-between; margin-top: 0.15rem;">
                        <div class="current-price">免费</div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="display: flex; font-size: 0.43rem; justify-content: space-between; margin-top: 0.15rem;" v-if="item.isFree == '0'">
                        <div class="current-price">会员专享</div>
                      </div>
                      <div v-else style="display: flex; font-size: 0.43rem; justify-content: space-between; margin-top: 0.15rem;">
                        <div class="current-price">免费</div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else-if="finished" class="not-data">暂无数据</div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: 'courseList',
  data() {
    return {
      pagename: '',
      catalogPath: '',
      listLoading: false,
      finished: false,
      pageIndex: 0,
      courseList: [],
      searchLoading: false,
      isShowSearch: false,
      textValue: '',
      isVip: false,
      sourceType: '',
      isBack: '',
    }
  },
  async created() {
    window.document.title = '麓辰教育'
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = Number(info.isVip) && (info.enterpriseName || info.enterpriseShorthand) ? true : false
    }
    await this.getCatalog()
    this.setShare()
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      this.sourceType = 'courseList'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.pagename} + '(目录)'`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    goBack() {
      if (this.sourceType && this.sourceType === 'courseList') {
        this.$router.push({ path: `/allCourses/index?id=${this.$route.params.id}&innerCode=${this.$route.query.catalogInnercode}` })
      } else if (window.history.length >= 2 && window.location.href.indexOf('isLoged') === -1) {
        if (window.location.href.indexOf('isShare') === -1) {
          if (this.$route.query.catalogInnercode.length / 6 > 6) {
            this.$router.go(-1)
          } else {
            this.$router.push({ path: `/allCourses/index?id=${this.$route.params.id}&innerCode=${this.$route.query.catalogInnercode}` })
          }
        } else {
          this.$router.push({
            path: '/',
          })
        }
      } else {
        this.$router.push({
          path: '/',
        })
      }
    },
    async getCatalog() {
      let id = this.$route.params.id
      let catalogInnercode = this.$route.query.catalogInnercode
      await this.$axios
        .get(`/course/front/permit/catalog/more/${id}/content?catalogInnercode=${catalogInnercode}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.catalogPath = res.data.data.title
            this.pagename = res.data.data.currentTitle
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.loading = false
          this.finished = true
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    async onLoad() {
      let id = this.$route.params.id
      let catalogInnercode = this.$route.query.catalogInnercode
      await this.$axios
        .get(`/course/front/permit/catalog/more/${id}/courses?catalogInnercode=${catalogInnercode}&pageSize=15&pageIndex=${this.pageIndex}&name=${this.textValue}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.pageIndex = this.pageIndex + 1
            this.courseList = this.courseList.concat(res.data.data.data)
            this.listLoading = false
            if (this.courseList.length >= res.data.data.total) {
              this.finished = true
            }
          } else {
            this.$Toast.fail(res.data.message)
            this.loading = false
            this.finished = true
          }
        })
        .catch((e) => {
          this.loading = false
          this.finished = true
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    gotoPage(item) {
      if (item.packageID) {
        this.$router.push({
          path: `/allCourses/packDetails/${item.packageID}`,
        })
      } else if (item.type === 1) {
        this.$router.push({
          path: `/allCourses/courseHandout/${item.ID}`,
        })
      } else {
        // let temp = this.$router.history.current.fullPath
        // localStorage.back_url = temp
        this.$router.push({
          path: `/allCourses/details/${item.ID}/curriculum?backUrl=${this.$router.history.current.fullPath}`,
        })
      }
    },
    async searchClick() {
      this.pageIndex = 0
      this.courseList = []
      this.searchLoading = true
      await this.onLoad()
      this.searchLoading = false
    },
    cancel() {
      this.textValue = ''
      this.isShowSearch = false
      this.onLoad()
    },
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>
<style scoped>
.course-list-box {
  padding: 0 0.5rem;
}
.catalog {
  margin-top: 0.23rem;
  font-size: 0.29rem;
  font-weight: bold;
  color: #444444;
  padding-bottom: 0.32rem;
  border-bottom: 1px solid #eee;
}
.catalog span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.course-list-item {
  margin-top: 0.3rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #eee;
}
.item {
  margin-left: 0.32rem;
}
.logo {
  width: 1.04rem;
  height: 1.04rem;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.11rem;
}
.tag {
  padding: 0 0.1rem;
  background-color: #fbc047;
  border-radius: 0.08rem;
  font-size: 0.27rem;
  color: #fff;
  display: flex;
  align-items: center;
}
.title {
  font-size: 0.4rem;
  font-weight: bold;
  color: #444444;
  margin-left: 0.15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 6.4rem;
  display: inline-block;
}
.star {
  font-size: 0.45rem;
}
.time {
  width: 1.3rem;
  background-color: #f6f7f9;
  border-radius: 0.05rem;
  font-size: 0.27rem;
  color: #b1b1b1;
  padding: 0.1rem;
  margin-top: 0.1rem;
  text-align: center;
  margin-left: 0.2rem;
  display: inline-block;
}
.current-price {
  color: #1b1b1b;
}
.old-price {
  background-color: #f3f3f3;
  border-radius: 0.05rem;
  margin-left: 0.27rem;
  padding: 0.1rem 0.2rem;
  font-size: 0.32rem;
  color: #999;
  text-decoration: line-through;
}
.head {
  display: flex;
}
.search-input {
  width: 5.6rem;
  height: 0.95rem;
  background-color: #f7f7f7;
  border-radius: 0.4rem;
  line-height: 0.8rem;
  display: flex;
  align-items: center;
}
.head .van-icon-search {
  font-size: 0.4rem;
  margin: 0 0.44rem;
  color: #bcbcbc;
}
.head .search-input input {
  font-size: 0.32rem;
  color: #bcbcbc;
  border: none;
  background-color: #f7f7f7;
  height: 0.7rem;
  line-height: 0.7rem;
}
.van-nav-bar__content >>> .van-button--normal {
  padding: 0 0.13rem;
  font-size: 0.37rem;
  height: 0.8rem;
}
.van-nav-bar__content {
  height: 1.22rem;
  padding-right: 12px;
  justify-content: space-between;
}
</style>
