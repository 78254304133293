<template>
  <div>
    <template v-if="data.length > 0">
      <div class="catalog-item-box" v-for="item in data" :key="item.ID" :style="{'margin-left': item.treeLevel > 3 ? '0.47rem' : ''}">
        <div class="catalog-item" @click.stop="operationItem(item)">
          <div class="catalog-name">
            <span>{{item.name}}</span>
            <span v-if="item.isLeaf" class="fa fa-play" style="margin-left: 0.1rem; color: #3fa1fd"></span>
          </div>
          <div class="catalog-do" :style="{'justify-content': item.coursePackageID ? 'space-between' : 'flex-end'}">
            <span v-if="(!scope[item.innerCode] && openTag.indexOf(item.innerCode) === -1) || item.isLeaf" class="fa fa-angle-right" style="font-size: 0.53rem;" @click.stop="loadMore(item)"></span>
            <span v-else class="fa fa-angle-up" style="font-size: 0.53rem; color: #3fa1fd" @click.stop="closeByLevel(item)"></span>
            <template v-if="item.hasPermission === 'Y'">
              <span style="margin-left: 0.2rem; color: #3fa1fd" @click.stop="gotoCoursePackPage(`/allCourses/packDetails/${item.coursePackageID}`)">进入学习</span>
            </template>
            <template v-else>
              <span v-if="item.coursePackageID" style="margin-left: 0.4rem; color: #3fa1fd" @click.stop="gotoPage(`/allCourses/order/${item.coursePackageID}?type=CoursePackage`)">购买</span>
              <span v-if="item.coursePackageID" class="van-icon van-icon-gold-coin-o" style="color: #3fa1fd"></span>
            </template>
          </div>
        </div>
        <tree-menu :data="item.children" :openTag="openTag" :isEnterprisecenter="isEnterprisecenter" v-if="(scope[item.innerCode] || openTag.indexOf(item.innerCode) !== -1) && !item.isLeaf" ref="test"></tree-menu>
      </div>
    </template>
    <div v-else class="not-data">暂无数据</div>
  </div>
</template>
<script>
export default {
  name: 'treeMenu',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    openTag: {
      type: Array,
      default() {
        return []
      }
    },
    isEnterprisecenter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scope: {}
    }
  },
  watch: {
    '$route'() {
      if (this.$route.query.innerCode) {
        for (let k in this.scope) {
          if (this.openTag.indexOf(k) === -1) {
            this.$set(this.scope, k, false)
          } else {
            if (k.length === this.$route.query.innerCode.length) {
              this.$set(this.scope, this.$route.query.innerCode, k === this.$route.query.innerCode)
            }
          }
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$eventHub.$on('setStatus', this.setStatus)
    })
  },
  methods: {
    operationItem(item) {
      if ((!this.scope[item.innerCode] && this.openTag.indexOf(item.innerCode) === -1) || item.isLeaf) {
        this.loadMore(item)
      } else {
        this.closeByLevel(item)
      }
    },
    doTask (item) {
      this.$set(this.scope, item.innerCode, true)
      this.$eventHub.$emit('setStatus', item.innerCode)
    },
    loadMore(item) {
      if (item.isLeaf) {
        let path = `/allCourses/CourseList/${item.ID}?catalogInnercode=${item.innerCode}`
        if (this.isEnterprisecenter) {
          let enterpriseID = this.$route.params.enterpriseID
          let enterpriseInnercode = this.$route.params.enterpriseInnercode
          path = `/nzzone/allCourses/CourseList/${enterpriseID}/${enterpriseInnercode}/${item.ID}?catalogInnercode=${item.innerCode}`
        }
        // 列表页
        this.$router.push({
          path: path
        })
      } else if (item.children && item.children.length) {
        this.doTask(item)
      } else {
        let path = `/allCourses/CatalogList/${item.ID}?catalogInnercode=${item.innerCode}`
        if (this.isEnterprisecenter) {
          let enterpriseID = this.$route.params.enterpriseID
          let enterpriseInnercode = this.$route.params.enterpriseInnercode
          path = `/nzzone/allCourses/CatalogList/${enterpriseID}/${enterpriseInnercode}/${item.ID}?catalogInnercode=${item.innerCode}`
        }
        this.$router.push({
          path: path
        })
      }
    },
    closeByLevel(item) {
      let index = this.openTag.findIndex(i => (i === item.innerCode))
      if (index > -1) {
        this.$eventHub.$emit('spliceOpenTag', index)
      } else {
        this.$set(this.scope, item.innerCode, false)
      }
    },
    setStatus(innerCode) {
      if (this.scope[innerCode]) {
        this.$set(this.scope, innerCode, true)
        if (this.data.length > 1) {
          for (let i = 0; i < this.data.length; i++) {
            this.$set(this.scope, this.data[i].innerCode, this.data[i].innerCode === innerCode)
            if (this.data[i].innerCode !== innerCode) {
              this.closeByLevel(this.data[i])
            }
          }
        }
      } else {
        let flag = false
        let count = innerCode.length / 6
        for (let i = 0; i < count; i++) {
          let code = innerCode.substring(0, innerCode.length - 6 * i)
          if (this.scope[code]) {
            flag = true
            this.$set(this.scope, code, true)
          }
        }
        if (!flag) {
          this.$set(this.scope, innerCode, false)
        }
      }
    },
    gotoCoursePackPage(path) {
      let temp = this.$router.history.current.fullPath
      localStorage.course_pack_source = temp
      this.gotoPage(path)
    },
    gotoPage(path) {
      this.$router.push({path: path})
    }
  },
  destroyed() {
    this.$eventHub.$off('setStatus', this.setStatus)
  }
}
</script>
<style scoped>
.catalog-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  height: 1.07rem;
}
.catalog-name {
  font-size: 0.4rem;
	color: #333333;
  font-weight: bold;
  max-width: 5.4rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.catalog-do {
  display: flex;
  align-items: center;
  /* width: 1.87rem; */
  justify-content: space-between;
}
</style>
