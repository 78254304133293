import AllCourses from '@/page/allCourses/Index'
import CurriculumList from '@/page/allCourses/CurriculumList'
import CourseList from '@/page/allCourses/CourseList'
import CatalogList from '@/page/allCourses/CatalogList'
import CurriculumDetails from '@/page/allCourses/CurriculumDetails'
import CourseDetails from '@/page/allCourses/CourseDetails'
import CoursePackDetails from '@/page/allCourses/CoursePackDetails'
import ListDetails from '@/page/allCourses/ListDetails'
import CourseHandout from '@/page/allCourses/CourseHandout'
import CoursesList from '@/page/allCourses/CoursesList'
import ClassifyCourseslist from '@/page/allCourses/ClassifyCourseslist'
import ExaminationPaper from '@/page/allCourses/ExaminationPaper'
import WrongTopic from '@/page/allCourses/WrongTopic'
import Order from '@/page/allCourses/Order'
import memberInfo from '@/page/allCourses/MemberInfo.vue'
import ClassByCourse from '@/page/allCourses/ClassByCourse'
import AllErrorQuestion from '@/page/allCourses/AllErrorQuestion'
import WrongClassExe from '@/page/allCourses/WrongClassExe'
import WrongExeRecord from '@/page/allCourses/WrongExeRecord'
import ErrorAgain from '@/page/allCourses/ErrorAgain'
export default [
  {
    path: '/allCourses/index',
    name: 'allCourses',
    component: AllCourses,
    meta: {
      title: '全部课程',
      keepAlive: true,
    },
  },
  {
    path: '/allCourses/curriculumList/:id',
    name: 'curriculumList',
    component: CurriculumList,
  },
  {
    path: '/allCourses/CourseList/:id',
    name: 'courseList',
    component: CourseList,
  },
  {
    path: '/allCourses/CatalogList/:id',
    name: 'catalogList',
    component: CatalogList,
  },
  {
    path: '/allCourses/details/:id/:type',
    name: 'curriculumDetails',
    component: CurriculumDetails,
  },
  {
    path: '/allCourses/courseDetails/:id/:type',
    name: 'courseDetails',
    component: CourseDetails,
  },
  {
    path: '/allCourses/packDetails/:id',
    name: 'coursePackDetails',
    component: CoursePackDetails,
  },
  {
    path: '/allCourses/listDetails/:id',
    name: 'listDetails',
    component: ListDetails,
  },
  {
    path: '/allCourses/courseHandout/:id',
    name: 'courseHandout',
    component: CourseHandout,
  },
  {
    path: '/allCourses/courseslist',
    name: 'courseslist',
    component: CoursesList,
    meta: {
      title: '课程列表',
      // keepAlive: true
    },
  },
  {
    path: '/allCourses/classifyCourseslist',
    name: 'classifyCourseslist',
    component: ClassifyCourseslist,
    meta: {
      title: '课程分类列表',
    },
  },
  {
    path: '/allCourses/examinationpaper',
    name: 'examinationpaper',
    component: ExaminationPaper,
  },
  {
    path: '/allCourses/wrongtopic/:id',
    name: 'wrongtopic',
    component: WrongTopic,
  },
  {
    path: '/allCourses/order/:id',
    name: 'order',
    component: Order,
  },
  {
    path: '/allCourses/memberInfo/:url',
    name: 'memberInfo',
    component: memberInfo,
  },
  {
    path: '/allCourses/classByCourse/:id',
    name: 'classByCourse',
    component: ClassByCourse,
  },
  {
    path: '/allCourses/allErrorQuestion',
    name: 'allErrorQuestion',
    component: AllErrorQuestion,
  },
  {
    path: '/allCourses/WrongClassExe',
    name: 'wrongClassExe',
    component: WrongClassExe,
  },
  {
    path: '/allCourses/WrongExeRecord',
    name: 'wrongExeRecord',
    component: WrongExeRecord,
  },
  {
    path: '/allCourses/errorAgain/:paperID',
    name: 'errorAgain',
    component: ErrorAgain,
  },
]
