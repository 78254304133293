<template>
  <div class="couponsmodule">
    <zv-nav-bar :title="pagename"></zv-nav-bar>
    <van-tabs v-model="active" @change="change">
      <van-tab title="未使用">
        <div class="coupon_list">
          <van-cell-group>
            <template v-if="listData.length > 0">
              <van-cell clickable v-for="item in listData" :key="item.ID" @click="gotoPage(item)">
                <template #title>
                  <div :class="{listbg: true, kecheng: item.type !== 'All', tongyong: item.type === 'All'}">
                    <div class="cell_l">
                      <div class="cell_number">
                        <span class="num">{{item.faceValue}}</span>
                        <span class="num_text">元</span>
                      </div>
                      <div class="cell_time">有效期至{{item.validitiyEnd.substring(0, 10)}}</div>
                    </div>
                    <div class="cell_c">
                      <span v-if="item.type !== 'All'">部分课程使用</span>
                      <span v-else>所有商品通用</span>
                    </div>
                    <div class="cell_r">
                      <span v-if="item.type !== 'All'">去使用</span>
                      <span v-else>通用券</span>
                    </div>
                  </div>
                </template>
              </van-cell>
            </template>
            <div v-else class="not-data">暂无数据</div>
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="已过期">
        <div class="coupon_list">
          <van-cell-group>
            <template v-if="expireListData.length > 0">
              <van-cell clickable v-for="item in expireListData" :key="item.ID">
                <template #title>
                  <div class="listbg guoqi">
                    <div class="cell_l">
                      <div class="cell_number">
                        <span class="num">{{item.faceValue}}</span>
                        <span class="num_text">元</span>
                      </div>
                      <div class="cell_time">有效期至{{item.validitiyEnd.substring(0, 10)}}</div>
                    </div>
                    <div class="cell_c">
                      <span v-if="item.type !== 'All'">部分课程使用</span>
                      <span v-else>所有商品通用</span>
                    </div>
                    <div class="cell_r">
                      <span v-if="item.type !== 'All'">去使用</span>
                      <span v-else>通用券</span>
                    </div>
                  </div>
                </template>
              </van-cell>
            </template>
            <div v-else class="not-data">暂无数据</div>
          </van-cell-group>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "couponsmodule",
  data() {
    return {
      pagename: "优惠券",
      active: 0,
      listData: [],
      expireListData: []
    };
  },
  created() {
    this.init()
  },
  methods: {
    gotoPage(item) {
      let path = `/user/couponList/${item.batchID}?couponId=${item.couponID}`
      if (item.type === 'All') {
        path = '/allCourses/index'
      }
      this.$router.push({
        path: path
      })
    },
    init(status) {
      this.$axios.get(`/course/priv/member/coupon?status=${status ? status : 0}`).then(res => {
        if (res.data.status === 1) {
          !status && (this.listData = res.data.data.data)
          status && (this.expireListData = res.data.data.data)
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        this.$Toast.fail('服务器开小差了，稍后重试')
        return e
      })
    },
    change(status) {
      this.init(status)
    }
  }
}
</script>

<style lang="less" scoped>
.couponsmodule {
  min-height: 100vh;
  background-color: #f8f8f8;
  .coupon_list {
    padding: 0.4rem;
    .van-cell-group {
      background-color: initial;
    }
    [class*="van-hairline"]::after {
      border: 0;
    }
    .van-cell {
      background-color: initial;
      margin-bottom: 0.4rem;
      padding: 0;
      .van-cell__title {
        // flex: 0 0 90%;
      }
      .listbg {
        min-height: 3.1rem;
        // display: flex;
        align-items: center;
        // padding: 0 0.5rem;
        // padding-right: 0.8rem;
        position: relative;
        .cell_l {
          position: absolute;
          top: 56%;
          left: 4%;
          transform: translate(0, -50%);
          .cell_number {
            margin-bottom: 0.2rem;
            .num {
              font-family: PingFang-SC-Heavy;
              font-size: 1.2rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #fffefe;
              text-shadow: 0rem 0.11rem 0rem rgba(27, 90, 167, 0.56);
              opacity: 0.97;
            }
            .num_text {
              font-family: SourceHanSansCN-Medium;
              font-size: 0.46rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #fffefe;
            }
          }
          .cell_time {
            font-family: AdobeHeitiStd-Regular;
            font-size: 0.34rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #fffefe;
          }
        }
        .cell_c {
          width: 2rem;
          position: absolute;
          top: 53%;
          left: 42%;
          transform: translate(0, -50%);
          text-align: center;
          font-size: 0.3rem;
          line-height: 0.56rem;
          span {
            font-family: PingFang-SC-Bold;
            // font-size: 0.17rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #1d72da;
            display: block;
            position: relative;
            z-index: 1;
          }
        }
        .cell_c::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 0;
          background: #fff;
          border-radius: 0.13rem;
          transform: skew(-25deg);
        }

        .cell_r {
          position: absolute;
          top: 50%;
          right: 11%;
          transform: translate(0, -50%);

          font-size: 0.37rem;
          line-height: 0.37rem;
          span {
            font-family: PingFang-SC-Bold;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #ffffff;
          }
        }
      }
      .kecheng {
        background: url("../../../public/images/user/kecheng@2x.png") no-repeat;
        background-size: cover;
      }
      .tongyong {
        background: url("../../../public/images/user/tongyong@2x.png") no-repeat;
        background-size: cover;
        .cell_c {
          span {
            color: #ff564e;
          }
        }
      }
      .guoqi {
        background: url("../../../public/images/user/guoqi.png") no-repeat;
        background-size: cover;
        & > .cell_l {
          & > .cell_number {
            & > .num {
              text-shadow: 0rem 0.11rem 0rem rgba(132, 132, 132, 0.56);
            }
          }
        }
        .cell_c {
          span {
            color: #919191;
          }
        }
      }
    }

    .van-cell::after {
      border: 0;
    }
  }
  .coupon_btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    text-align: center;
    padding: 0.27rem;
    .van-button {
      width: 90%;
    }
  }
}
.not-data {
  font-size: 0.32rem;
  text-align: center;
}
</style>
