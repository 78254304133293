<template>
  <div>
    <zv-nav-bar :title="itemData.courseName">
      <span>提问</span>
    </zv-nav-bar>
    <div class="question-box">
      <div class="addTime-box">
        <span>添加时间：</span>
        <span>{{itemData.addTime}}</span>
        <van-field  v-model="itemData.iContent" type="textarea" readonly />
      </div>
      <div class="answer-name">老师回复内容：</div>
      <div class="van-cell van-field van-field--min-height">
        <div class="van-field__body">
          <div class="van-field__control" v-html="itemData.rContent"></div>
        </div>
      </div>
      <!-- <van-field  v-model="answer" type="textarea"/> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'answer',
  data() {
    return {
      pagename: '',
      itemData: {}
    }
  },
  created() {
    this.setShare()
    this.getAnswer()
  },
  methods: {
    setShare() {
      const shareInfo = {
        disabled: true
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    getAnswer() {
      this.$axios.get(`/course/front/member/memberquestion/${this.$route.params.id}`).then(res => {
        if (res.data.status === 1) {
          this.itemData = res.data.data
        } else {
          return this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        return this.$Toast.fail('服务器开小差了，稍后重试' + e)
      })
    }
  }
}
</script>
<style scoped>
.question-box {
  background-color: #eee;
  height: calc(100vh - 2.02rem);
  padding: 0.4rem;
}
.question-box >>> .van-cell {
  font-size: 0.37rem;
  line-height: 0.64rem;
  padding: 0.26rem 0.42rem;
  margin-top: 0.21rem;
  overflow: auto;
}
.question-box >>> .van-field__control {
  height: 5.33rem;
  font-size: 0.4rem;
  color: #222;
  font-weight: bold;
  background-color: #fff;
}
.question-box >>> .van-field__control p {
  margin: 0;
  padding: 0;
}
.addTime-box {
  font-size: 0.32rem;
	color: #333333;
  margin-bottom: 0.63rem;
}
.answer-name {
  font-size: 0.32rem;
	color: #333333;
}
</style>