<template>
  <div class="work-details-box">
    <zv-nav-bar :title="info.name">
      <van-button class="header-btn" @click="saveWork" :loading="loading" type="info" v-if="isShow" :disabled="isUploading">保存</van-button>
    </zv-nav-bar>
    <div class="content-box">
      <div class="title-box">
        <span class="title">作业内容</span>
        <div v-if="info.progressStatus === 1 || info.progressStatus === 2" :class="{ unstart: info.progressStatus === 1, overdue: info.progressStatus === 2 }">
          {{ info.progressStatus === 1 ? '未开始' : '已过期' }}
        </div>
      </div>
      <div class="time" v-show="!($route.query.isHiddenTime === 'true')">{{ info.publishTime }}布置 | {{ info.endTime }}截止</div>
      <div class="info">
        <div class="info-title">{{ info.name }}</div>
        <div v-html="info.workContent" class="details-box"></div>
      </div>
      <div class="publish-work">
        <span class="title">提交作业</span>
        <span :class="{ 'work-status': true, shenhe: info.status === 1, dahui: info.status === 3, yiwanc: info.status === 2 }">{{ info.statusName }}</span>
      </div>
      <textarea v-model="info.answerContent" rows="3" placeholder="请填写作业内容" class="textarea-text" :disabled="isDisabled"></textarea>
      <div class="file-upload-box">
        <van-uploader v-model="imgList" :after-read="afterImgRead" :max-count="imgMaxCount" multiple @delete="imgListChange = true" :disabled="isDisabled" :deletable="!isDisabled" />
        <van-uploader
          accept=""
          class="file-upload"
          v-model="fileList"
          :before-read="beforeFileRead"
          :after-read="afterFileRead"
          :max-count="fileMaxCount"
          @delete="fileListChange = true"
          :disabled="isDisabled"
          :deletable="!isDisabled"
        />
      </div>
      <div class="comment" v-if="info.status >= 2">
        <div class="title">老师点评</div>
        <div class="teacher">
          <div class="teacher-info">
            <div class="logo">
              <img :src="info.avatar" />
            </div>
            <div class="teacher-comment-time-box">
              <div class="teacher-name">{{ info.teacherName || info.teacher }}</div>
              <div class="comment-time">{{ info.evaluateTime }}</div>
            </div>
          </div>
          <div :class="{ 'comment-status': true, unqualified: info.grade === 0, medium: info.grade === 2, good: info.grade === 3 }">{{ info.gradeName }}</div>
        </div>
        <div v-html="info.evaluation" class="teacher-comment details-box"></div>
      </div>
    </div>
    <div class="bottom-btn">
      <!-- <div class="back">返回课程</div> -->
      <van-button
        class="submit"
        @click="submitWork"
        :loading="submitLoading"
        :disabled="isUploading"
        type="info"
        v-if="info.status !== 1 && info.status !== 2 && info.progressStatus !== 1 && info.progressStatus !== 2"
        >提交作业</van-button
      >
      <van-button class="submit" @click="$router.go(-1)" type="info" v-else>返回</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'workDetails',
  data() {
    return {
      imgList: [],
      fileList: [],
      info: {},
      oldInfo: {},
      loading: false,
      submitLoading: false,
      imgListChange: false,
      fileListChange: false,
      imgMaxCount: 9,
      fileMaxCount: 1,
      isUploading: false,
    }
  },
  computed: {
    isShow() {
      return this.info.status !== 1 && this.info.status !== 2 && this.info.progressStatus !== 1 && this.info.progressStatus !== 2
    },
    isDisabled() {
      return this.info.status === 1 || this.info.status === 2 || this.info.progressStatus === 1 || this.info.progressStatus === 2
    },
  },
  created() {
    this.$Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    })
    this.getWork()
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.info.name || '作业'}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    getWork() {
      let id = this.$route.params.workId
      let path = `/course/front/work/${id}`
      if (this.$route.query.classId) {
        path = `/course/front/work/${id}?classId=${this.$route.query.classId}`
      }
      this.$axios
        .get(path)
        .then((res) => {
          this.$nextTick(() => {
            this.$Toast.clear()
          })
          if (res.data.status === 1) {
            if (res.data.data.hasPermission === 'N') {
              this.$Toast('没有作业权限，请联系管理员！')
              setTimeout(() => {
                this.$router.replace('/')
              }, 2 * 1000)
              return
            }
            this.info = res.data.data
            if (this.$route.query.time) {
              let time = this.$route.query.time
              let arr = time.split('-')
              this.info.publishTime = arr[0]
                .substring()
                .replace('年', '.')
                .replace('月', '.')
                .replace('日', '')
              this.info.endTime = arr[1]
                .substring()
                .replace('年', '.')
                .replace('月', '.')
                .replace('日', '')
            }
            this.oldInfo = JSON.parse(JSON.stringify(this.info))
            let imgList = res.data.data.picturePath ? res.data.data.picturePath.split(',') : []
            if (this.info.status === 1 || this.info.status === 2) {
              this.imgMaxCount = imgList.length
            }
            let fileList = res.data.data.path ? res.data.data.path.split(',') : []
            if (this.info.status === 1 || this.info.status === 2) {
              this.fileMaxCount = fileList.length
            }
            for (let i = 0; i < imgList.length; i++) {
              let obj = {}
              obj.url = imgList[i]
              obj.zurl = imgList[i]
              this.imgList.push(obj)
            }
            for (let i = 0; i < fileList.length; i++) {
              let obj = {}
              // obj.url = fileList[i]
              obj.url = res.data.data.oldName || '附件'
              obj.zurl = fileList[i]
              this.fileList.push(obj)
            }
            this.setShare()
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.clear()
          this.$Toast.fail('服务器开小差了，稍后再试，' + e)
          return e
        })
    },
    // 批次上传
    uploadImgFiles(files) {
      let count = 0
      this.isUploading = true
      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        file.status = 'uploading'
        file.message = '上传中...'
        let params = new FormData()
        params.append('file', file.file)
        this.$axios
          .post('/course/front/work/answer/upload', params)
          .then((res) => {
            count += 1
            let arr = []
            if (res.data.status === 1) {
              this.imgListChange = true
              file.status = 'success'
              files[i].zurl = res.data.data.path
              arr.push(files[i])
            }
            this.imgList.concat(arr)
            if (count === files.length) {
              this.isUploading = false
            }
          })
          .catch((e) => {
            count += 1
            file.status = 'failed'
            file.message = '上传失败'
            if (count === files.length) {
              this.isUploading = false
            }
            return e
          })
      }
    },
    // 图片开始上传时的回调
    afterImgRead(file, detail) {
      this.isUploading = true
      if (Array.isArray(file)) {
        this.uploadImgFiles(file)
      } else {
        file.status = 'uploading'
        file.message = '上传中...'
        let params = new FormData()
        params.append('file', file.file)
        this.$axios
          .post('/course/front/work/answer/upload', params)
          .then((res) => {
            this.isUploading = false
            if (res.data.status === 1) {
              this.imgListChange = true
              file.status = 'success'
              this.imgList[detail.index].zurl = res.data.data.path
            }
          })
          .catch((e) => {
            this.isUploading = false
            file.status = 'failed'
            file.message = '上传失败'
            return e
          })
      }
    },
    beforeFileRead(file) {
      const fileAccetsType = ['application/zip', 'application/rar', 'application/7z']
      const fileType = file.type
      if (!fileAccetsType.includes(fileType)) {
        this.$Toast.fail('上传失败，请上传zip、rar、7z文件')
        return false
      }
      return true
    },
    // 附件开始上传时的回调
    afterFileRead(file, detail) {
      this.isUploading = true
      file.status = 'uploading'
      file.message = '上传中...'
      let params = new FormData()
      params.append('file', file.file)
      this.$axios
        .post('/course/front/work/answer/upload', params)
        .then((res) => {
          this.isUploading = false
          if (res.data.status === 1) {
            this.fileListChange = true
            file.status = 'success'
            this.fileList[detail.index].zurl = res.data.data.path
            this.fileList[detail.index].oldName = res.data.data.oldName
          }
        })
        .catch((e) => {
          this.isUploading = false
          file.status = 'failed'
          file.message = '上传失败'
          return e
        })
    },
    // 作业保存
    saveWork() {
      if (!this.needSave()) {
        return this.$Toast('没有修改')
      }
      if (!this.info.answerContent && this.fileList.length === 0 && this.imgList.length === 0) {
        return this.$Toast.fail('请上传资源或填写内容')
      }
      this.loading = true
      let params = new FormData()
      let picturePath = []
      for (let i = 0; i < this.imgList.length; i++) {
        picturePath.push(this.imgList[i].zurl)
      }
      params.append('workID', this.info.workID)
      params.append('path', this.fileList.length > 0 ? this.fileList[0].zurl : '')
      params.append('oldName', this.fileList.length > 0 ? this.fileList[0].oldName : '')
      params.append('picturePath', picturePath.join(','))
      params.append('content', this.info.answerContent)
      this.$axios
        .post('/course/front/work/answer', params)
        .then((res) => {
          this.loading = false
          if (res.data.status === 1) {
            this.oldInfo = JSON.parse(JSON.stringify(this.info))
            this.$Toast.success(res.data.message)
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$Toast.fail('服务器开小差了，稍后再试，' + e)
          return e
        })
    },
    // 提交作业
    submitWork() {
      let params = new FormData()
      let picturePath = []
      for (let i = 0; i < this.imgList.length; i++) {
        picturePath.push(this.imgList[i].zurl)
      }
      params.append('workID', this.info.workID)
      params.append('path', this.fileList.length > 0 ? this.fileList[0].zurl : '')
      params.append('oldName', this.fileList.length > 0 ? this.fileList[0].oldName : '')
      params.append('picturePath', picturePath.join(','))
      params.append('content', this.info.answerContent)
      params.append('status', 1)
      this.submitLoading = true
      this.$axios
        .post('/course/front/work/answer', params)
        .then((res) => {
          if (res.data.status === 1) {
            this.$Toast.success(res.data.message)
            setTimeout(() => {
              this.$router.go(-1)
            }, 1500)
          } else {
            this.submitLoading = false
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.submitLoading = false
          this.$Toast.fail('服务器开小差了，稍后再试，' + e)
          return e
        })
    },
    // 是否需要调用保存接口
    needSave() {
      if (JSON.stringify(this.info) === JSON.stringify(this.oldInfo) && !this.imgListChange && !this.fileListChange) {
        return false
      }
      return true
    },
  },
}
</script>
<style scoped>
.work-details-box >>> .van-button--normal {
  padding: unset !important;
}
.work-details-box >>> .van-uploader__preview-delete {
  width: 0.53rem;
  height: 0.53rem;
  border-radius: unset;
}
.work-details-box >>> .van-uploader__preview-delete-icon {
  top: 0px;
  right: 0px;
  font-size: 0.53rem;
  transform: scale(0.7);
}
.work-details-box >>> .van-nav-bar__title {
  max-width: 50%;
}
.header-btn {
  width: 1.86rem;
  height: 0.8rem;
  background-color: #1578f2;
  border-radius: 0.53rem;
  color: #fff;
  font-size: 0.37rem;
}
.content-box {
  padding: 0.28rem 0.48rem 1.6rem 0.48rem;
  /* height: calc(100vh - 3.01rem); */
  overflow-y: auto;
}
.content-box >>> .van-uploader__upload {
  width: 2.13rem;
  height: 2.13rem;
  margin: 0 0.21rem 0.21rem 0;
}
.content-box >>> .van-uploader__file {
  width: 2.13rem;
  height: 2.13rem;
  margin: 0 0.21rem 0.21rem 0;
}
.content-box >>> .van-uploader__file-icon {
  font-size: 1.12rem;
}
.content-box >>> .van-uploader__file-name {
  font-size: 0.32rem;
}
.content-box >>> .file-upload .van-icon-photograph::before {
  content: '\F0B0';
}
.content-box >>> .van-uploader__upload {
  border-radius: 0.13rem;
  border: dashed 0.04rem #d7d7d7;
}
.content-box >>> .van-uploader__upload-icon {
  font-size: 1.12rem;
}
.content-box >>> .van-uploader__preview {
  margin: 0 0.21rem 0.21rem 0;
}
.content-box >>> .van-uploader__preview-image {
  width: 2.8rem;
  height: 2.8rem;
}
.title-box {
  display: flex;
  justify-content: space-between;
}
.title-box div {
  font-size: 0.32rem;
  font-weight: bold;
  line-height: 0.48rem;
  border-radius: 0.13rem 0rem 0.13rem;
  width: 1.21rem;
  height: 0.48rem;
  text-align: center;
}
.title-box div.unstart {
  background-image: linear-gradient(-41deg, #1578f2 0%, #4c9cff 100%);
  color: #fff;
}
.title-box div.overdue {
  background-image: linear-gradient(-41deg, #999 0%, #666 100%);
  color: #fff;
}
.title {
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 0.48rem;
  color: #222222;
}
.title::before {
  content: '';
  width: 0.11rem;
  height: 0.4rem;
  background-color: #1578f2;
  border-radius: 0.05rem;
  display: inline-block;
  margin-right: 0.19rem;
  vertical-align: text-bottom;
}
.time {
  margin-top: 0.35rem;
  font-size: 0.35rem;
  font-weight: bold;
  color: #666666;
}
.info {
  margin-top: 0.63rem;
}
.info-title {
  font-size: 0.4rem;
  font-weight: bold;
  color: #333333;
}
.details-box {
  font-size: 0.35rem;
  font-weight: bold;
  color: #333333;
  margin-top: 0.4rem;
}
.details-box >>> * {
  font-size: 0.35rem;
}
.details-box >>> img {
  max-width: 100%;
}
.details-box >>> ol {
  padding-left: 0.4rem;
}
.details-box >>> ul {
  padding-left: 0.4rem;
}
.details-box >>> p {
  margin: 0;
}
.publish-work {
  display: flex;
  justify-content: space-between;
  margin-top: 0.68rem;
}
.work-status {
  font-size: 0.35rem;
  font-weight: bold;
  color: #888888;
}
.textarea-text {
  margin-top: 0.6rem;
  margin-bottom: 0.4rem;
  background-color: #f8f8f8;
  border-radius: 0.13rem;
  font-size: 0.35rem;
  border: none;
  width: calc(100% - 0.52rem);
  padding: 0.26rem;
  min-height: 2.95rem;
  resize: none;
}
.file-upload-box {
  display: flex;
  flex-wrap: wrap;
}
.shenhe {
  color: #fbc047;
}
.dahui {
  color: #e4393c;
}
.yiwanc {
  color: #1578f2;
}
.comment {
  margin-top: 0.69rem;
}
.teacher {
  display: flex;
  justify-content: space-between;
  margin-top: 0.55rem;
  margin-bottom: 0.65rem;
}
.teacher-info {
  display: flex;
}
.logo {
  width: 1.33rem;
  height: 1.33rem;
  border-radius: 50%;
  margin-right: 0.39rem;
}
.logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.teacher-comment-time-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.teacher-name {
  font-size: 0.45rem;
  line-height: 0.48rem;
  color: #222222;
}
.teacher-comment.details-box >>> * {
  word-wrap: break-word;
}
.teacher-comment.details-box >>> p {
  margin: 0;
}
.comment-time {
  font-size: 0.37rem;
  line-height: 0.48rem;
  color: #888888;
}
.comment-status {
  width: 1.44rem;
  height: 0.69rem;
  background-color: #ffffff;
  border-radius: 0.08rem;
  border: solid 0.03rem #70dd96;
  font-size: 0.37rem;
  color: #70dd96;
  text-align: center;
  line-height: 0.69rem;
}
.unqualified {
  color: #e4393c;
  border: solid 0.03rem #e4393c;
}
.medium {
  color: #1578f2;
  border: solid 0.03rem #1578f2;
}
.good {
  color: #fc8126;
  border: solid 0.03rem #fc8126;
}
.bottom-btn {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  height: 1.51rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  justify-content: space-evenly;
  align-items: center;
}
.back {
  width: 2.4rem;
  height: 1.07rem;
  background-color: #ffffff;
  border-radius: 0.53rem;
  border: solid 0.01rem #1578f2;
  color: #1578f2;
  font-size: 0.4rem;
  text-align: center;
  line-height: 1.07rem;
}
.submit {
  /* width: 5.33rem; */
  width: 8rem;
  height: 1.07rem;
  background-color: #1578f2;
  border-radius: 0.53rem;
  color: #fff;
  font-size: 0.4rem;
  text-align: center;
  line-height: 1.07rem;
}
</style>
