<template>
  <div class="order">
    <template v-if="!isShow">
      <zv-nav-bar :title="pagename"></zv-nav-bar>
      <van-row class="pabox">
        <van-col class="orderbox">
          <div class="imgbox">
            <img :src="goodsdata.logofile" />
          </div>
          <div class="titlebox">
            <div class="title">
              <span class="tags">{{ tagName }}</span>
              <span class="title-column">{{ goodsdata.goodsName }}</span>
            </div>
            <div class="titlemsg">
              <span class="msgtext">主讲老师：{{ speakers || '暂无数据' }}</span>
            </div>
            <div class="rest-days">
              <span class="msgtext">学习天数：{{ validDay || '暂无数据' }}天</span>
            </div>
          </div>
          <div class="price">
            <div class="newprice">￥{{ isVipMember ? goodsdata.vipPrice : goodsdata.goodsPrice }}</div>
            <s class="oldprice" v-if="isVipMember">￥{{ goodsdata.goodsPrice }}</s>
          </div>
        </van-col>
        <template v-if="relaCoursePackages.length > 0">
          <span class="advice">建议购买套组课程更实惠</span>
          <van-col class="orderbox" v-for="(item, index) in relaCoursePackages" :key="item.ID">
            <div class="imgbox">
              <img :src="item.logoFile" />
            </div>
            <div class="titlebox">
              <div class="title">
                <span class="tags">系列课</span>
                <span class="title-column">{{ item.name }}</span>
              </div>
              <div class="titlemsg">
                <span class="more">内含{{ item.courseNumber }}门课程</span>
              </div>
            </div>
            <div class="price">
              <div class="newprice">￥{{ isVipMember ? item.memberPrice : item.sellPrice }}</div>
              <s class="oldprice" v-if="isVipMember">￥{{ item.sellPrice }}</s>
            </div>
            <div class="checkbox">
              <van-checkbox v-model="item.checked" @click="selectCoursePackages(item, index)"></van-checkbox>
            </div>
          </van-col>
        </template>
        <van-col class="orderbox coupons" @click="isShow = true" v-if="coupons.length > 0">
          <span>优惠券</span>
          <span class="couponsnumber">{{ coupons.length }}张可用 <van-icon name="arrow"/></span>
        </van-col>
        <van-col class="orderbox invoicing">
          <van-col>
            <span>{{ tagName }}原价</span>
            <span>￥{{ goodsInfo.goodsPrice }}</span>
          </van-col>
          <van-col v-if="isVipMember">
            <span>会员价</span>
            <span>￥{{ goodsInfo.vipPrice }}</span>
          </van-col>
          <van-col v-if="discount">
            <span>优惠券抵扣</span>
            <span>-￥{{ discount | formatValue }}</span>
          </van-col>
          <van-col v-if="activityDiscount !== '0.00'">
            <span>{{ activity.activityname }}（活动）</span>
            <span>-￥{{ activityDiscount }}</span>
          </van-col>
          <van-col class="results">
            <span>合计</span>
            <span>￥{{ payamount - discount > 0 ? (payamount - discount).toFixed(2) : '0.00' }}</span>
          </van-col>
        </van-col>
      </van-row>
      <van-row class="btnstyle">
        <van-button round type="info" :loading="payLoading" @click="pay">立即支付</van-button>
      </van-row>
    </template>
    <coupons v-if="isShow" @clickLeft="clickLeft" :coupons="coupons" :checkedRadio="couponID"></coupons>
  </div>
</template>

<script>
const wx = require('weixin-js-sdk')
import Coupons from '@/page/user/Coupons.vue'
export default {
  name: 'order',
  components: {
    coupons: Coupons,
  },
  data() {
    return {
      pagename: '确认订单',
      goodid: '',
      relaCoursePackages: [],
      goodsdata: {},
      payamount: 0,
      isVipMember: true,
      isShow: false,
      type: 'Course',
      coupons: [],
      couponID: '0',
      speakers: 0,
      goodsInfo: {},
      isPayCoursePackage: false,
      activity: {},
      activityDiscount: '',
      payLoading: false,
    }
  },
  computed: {
    discount() {
      const coupon = this.coupons.find((item) => item.ID === this.couponID)
      if (coupon) {
        return coupon.faceValue
      }
      return 0
    },
    tagName() {
      let name = '视频'
      if (this.type === 'Course') {
        if (this.goodsdata.type === 1) {
          name = '讲义'
        }
      } else if (this.type === 'LIVE') {
        name = '直播'
      } else if (this.type === 'CoursePackage') {
        name = '系列课'
      }
      return name
    },
  },
  filters: {
    formatValue(value) {
      if (!value) {
        return '0.00'
      }
      return Number(value).toFixed(2)
    },
  },
  created() {
    this.type = this.$route.query.type || 'Course'
    console.log('this.$route.query.type: ', this.$route.query.type)
    this.goodid = this.$route.params.id
    this.getPrevieworder()
    this.setShare()
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    clickLeft(couponID, flag) {
      this.isShow = flag
      if (couponID !== null) {
        this.couponID = couponID
      }
    },
    selectCoursePackages(item) {
      let type = this.type
      if (item.checked) {
        this.isPayCoursePackage = true
        this.goodid = item.ID
        type = 'CoursePackage'
        this.relaCoursePackages = this.relaCoursePackages.map((i) => {
          if (i.ID !== item.ID) {
            i.checked = false
          }
          return i
        })
      } else {
        this.isPayCoursePackage = false
        this.goodid = this.$route.params.id
        type = 'Course'
      }
      this.$Toast.loading({
        message: '正在计算，请稍后...',
        forbidClick: true,
      })
      this.getPrevieworder(true, type)
    },
    getPrevieworder(isNotSetCoursePackages, type) {
      console.log('type: ', type)
      console.log('this.type: ', this.type)
      this.$axios.get(`/course/front/goodsbuy/previeworder/${this.goodid}?goodsType=${type || this.type}`).then((res) => {
        if (res.data.status === 1) {
          this.$Toast.clear()
          if (!isNotSetCoursePackages) {
            let relaCoursePackages = res.data.data.relaCoursePackages || []
            this.isVipMember = res.data.data.isVipMember
            this.speakers = res.data.data.speakers
            this.goodsdata = res.data.data.goodsdata[0]
            this.goodsInfo = res.data.data.goodsdata[0]
            this.coupons = res.data.data.coupons
            this.validDay = res.data.data.validDay
            this.relaCoursePackages = relaCoursePackages.map((item) => {
              item.checked = false
              return item
            })
          } else {
            this.isVipMember = res.data.data.isVipMember
            this.goodsInfo = res.data.data.goodsdata[0]
            this.coupons = res.data.data.coupons
          }
          this.activityDiscount = res.data.data.activityDiscount
          this.activity = res.data.data.goodsdata[0].activity ? res.data.data.goodsdata[0].activity : {}
          this.payamount = res.data.data.payamount
          if (this.$route.query.couponId) {
            let coupon = res.data.data.coupons.find((item) => item.ID == this.$route.query.couponId)
            this.clickLeft(coupon.ID, false)
          }
        } else {
          this.$Toast.fail(res.data.message)
        }
      })
    },
    pay() {
      this.payLoading = true
      let type = this.isPayCoursePackage ? 'CoursePackage' : this.$route.query.type || 'Course'
      const that = this
      this.$axios.get(`/course/front/goodsbuy/submitorder/${this.goodid}?goodsType=${type}&paymentType=WeChatPay&couponID=${this.couponID == 0 ? '' : this.couponID}&tradeType=JSAPI`).then((res) => {
        if (res.data.status === 1) {
          let data = res.data.data.paymentURL
          if (res.data.data.needPayStatus === 0) {
            that.$Toast.success('支付成功')
            setTimeout(() => {
              // 前往已支付列表页
              that.$router.replace({
                path: '/user/payment',
              })
            }, 1000)
            that.payLoading = false
          } else {
            wx.config({
              debug: false,
              appId: data.appId,
              timestamp: data.timeStamp,
              nonceStr: data.nonceStr,
              signature: data.paySign,
              jsApiList: ['chooseWXPay'],
            })
            wx.chooseWXPay({
              timestamp: data.timeStamp,
              nonceStr: data.nonceStr,
              package: data.package,
              signType: 'MD5',
              paySign: data.paySign,
              success: function(res) {
                if (res.errMsg === 'chooseWXPay:ok') {
                  that.$Toast.success('支付成功')
                  setTimeout(() => {
                    // 前往已支付列表页
                    that.$router.replace({
                      path: '/user/payment',
                    })
                  }, 1000)
                  that.payLoading = false
                } else {
                  that.payLoading = false
                  that.$Toast.fail(res.errMsg)
                }
              },
              cancel: function() {
                that.$Toast.fail('取消支付, 即将离开页面，进入未支付订单列表')
                setTimeout(() => {
                  that.payLoading = false
                  that.$router.replace({
                    path: '/user/notPayment',
                  })
                }, 1000)
              },
            })
          }
        } else {
          this.payLoading = false
          this.$Toast.fail(res.data.message)
        }
      })
    },
  },
  destroyed() {
    this.$Toast.clear()
  },
}
</script>

<style lang="less" scoped>
.order {
  background-color: #f8f8f8;
  min-height: 100vh;
  .pabox {
    padding: 0.33rem;
    margin-bottom: 1.6rem;
  }
  .advice {
    font-family: PingFang-SC-Medium;
    font-size: 0.34rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #444444;
    margin-bottom: 0.21rem;
    display: inline-block;
  }
  .orderbox {
    font-family: PingFang-SC-Medium;
    font-size: 0.32rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #484a49;
    background-color: #fff;
    padding: 0.41rem 0.33rem;
    border-radius: 0.13rem;
    display: flex;
    width: 100%;
    .imgbox {
      width: 1.04rem;
      height: 1.04rem;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .titlebox {
      overflow: hidden;
      margin-left: 0.28rem;
      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .tags {
          display: inline-block;
          font-family: PingFang-SC-Medium;
          font-size: 0.26rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #fdfdfd;
          padding: 0.1rem 0.2rem;
          background-color: #fbc047;
          border-radius: 0.08rem;
        }
        .title-column {
          margin-left: 0.18rem;
          font-family: PingFang-SC-Medium;
          font-size: 0.39rem;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #444444;
          // vertical-align: middle;
        }
      }
      .titlemsg {
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #484a49;
      }
      .msgtext {
        display: inline-block;
        margin-top: 0.09rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 5.6rem;
      }
      .more {
        color: #3fa1fd;
        border-radius: 0.2rem;
        border: solid 0.01rem #3fa1fd;
        padding: 0 0.16rem;
        margin-top: 0.09rem;
        display: inline-block;
      }
    }
    .price {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .newprice {
        font-family: PingFang-SC-Heavy;
        font-size: 0.47rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #111111;
      }
      .oldprice {
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #999999;
        text-align: right;
      }
    }
  }
  .checkbox {
    display: flex;
    margin-left: 0.26rem;
  }
  .coupons {
    margin-top: 0.33rem;
    font-family: PingFang-SC-Medium;
    font-size: 0.39rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #000000;
    .couponsnumber {
      margin-left: auto;
      font-family: PingFang-SC-Medium;
      font-size: 0.37rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: -0.02rem;
      color: #333333;
      .van-icon {
        vertical-align: middle;
        color: #5f5f5f;
      }
    }
  }
  .invoicing {
    flex-direction: column;
    margin-top: 0.33rem;
    .van-col {
      display: flex;
      justify-content: space-between;
      font-family: PingFang-SC-Medium;
      font-size: 0.32rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #666666;
      margin-bottom: 0.38rem;
    }
    .results {
      font-family: PingFang-SC-Bold;
      font-size: 0.37rem;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #111111;
      margin-bottom: 0;
    }
  }
  .btnstyle {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-evenly;
    width: 104%;
    bottom: 0;
    background-color: #fff;
    height: 1.6rem;
    .van-button {
      width: 90%;
      height: 1.17rem;
      font-size: 0.37rem;
      padding: 0 0.4rem;
    }
  }
}
</style>
