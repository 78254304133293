<template>
  <div class="examinationpaper wrongtopic clearfix">
    <zv-nav-bar :title="pagename" :clickLeft="clickLeft"></zv-nav-bar>
    <div class="paperbox">
      <div class="testbox">
        <template v-for="(q, q_index) in questionlist">
          <template v-if="q_index === activeIndex">
            <div class="title" :style="{'margin-bottom': (q.type === 'GroupOption' || q.type === 'GroupStem' || q.type === 'CaseAnalysis') ? '0.44rem' : '0.88rem'}" :key="q.ID">
              <span class="title_type">{{q.typeName}}</span>
              <span class="title_text" v-html="q.title"></span>
            </div>
            <div class="options" :key="q.ID + 'options'">
              <template v-if="q.type === 'MultipleChoice'">
                <van-checkbox-group v-model="q.q_res">
                  <van-checkbox shape="square" :class="{operror: q.resMap[item.split('.')[0]]}" :name="item.split('.')[0]" v-for="(item, index) in q.options.split('_ZVING_')" :key="index"><span v-html="item"></span></van-checkbox>
                </van-checkbox-group>
              </template>
              <template v-if="q.type === 'SingleChoice'">
                <van-radio-group v-model="q.q_res">
                  <van-radio :name="item.split('.')[0]" :class="{operror: q.answer !== q.q_res}" v-for="(item, index) in q.options.split('_ZVING_')" :key="index"><span v-html="item"></span></van-radio>
                </van-radio-group>
              </template>
              <template v-if="q.type === 'GroupOption'">
                <div class="title_p_title" v-html="q.PTitle"></div>
                <van-radio-group v-model="q.q_res" class="groupOption">
                  <van-radio :name="item.split('.')[0]" :class="{operror: q.answer !== q.q_res}" v-for="(item, index) in q.options.split('_ZVING_')" :key="index"><span v-html="item"></span></van-radio>
                </van-radio-group>
              </template>
              <template v-if="q.type === 'GroupStem'">
                <div class="title_p_title" v-html="q.PTitle"></div>
                <van-radio-group v-model="q.q_res">
                  <van-radio :name="item.split('.')[0]" :class="{operror: q.answer !== q.q_res}" v-for="(item, index) in q.options.split('_ZVING_')" :key="index"><span v-html="item"></span></van-radio>
                </van-radio-group>
              </template>
              <template v-if="q.type === 'CaseAnalysis'">
                <div class="title_p_title" v-html="q.PTitle"></div>
                <van-checkbox-group v-model="q.q_res">
                  <van-checkbox shape="square" :class="{operror: q.resMap[item.split('.')[0]]}" :name="item.split('.')[0]" v-for="(item, index) in q.options.split('_ZVING_')" :key="index"><span v-html="item"></span></van-checkbox>
                </van-checkbox-group>
              </template>
              <template v-if="q.type === 'Checking'">
                <van-radio-group v-model="q.q_res">
                  <van-radio :name="item.split('.')[1]" :class="{operror: q.answer !== q.q_res}" v-for="(item, index) in q.options.split('_ZVING_')" :key="index">{{item.split('.')[2]}}</van-radio>
                </van-radio-group>
              </template>
              <template v-if="q.type.indexOf('Completion') > -1">
                <van-field class="tkt" v-for="(item, index) in q.inputOptions" :key="index" v-model="item.value" :placeholder="`请输入第${formatValue(index)}项答案`" />
              </template>
              <template v-if="q.type.indexOf('Answer') > -1">
                <van-field class="jdt" v-for="(item, index) in q.inputOptions" type="textarea" rows="6" :key="index" v-model="item.value" :placeholder="`请输入第${formatValue(index)}项答案`" />
              </template>
            </div>
          </template>
        </template>
        <div class="correct sup">
          <span class="line linesup"></span>
          <span class="correcttext">正确答案: {{ questionlist[activeIndex] | formatValue }}</span>
        </div>
      </div>
      <div class="analysis">
        <van-row class="analysisbtom">
          <div class="correct">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div>
                <span class="line"></span>
                <span class="correcttext">答案分析:</span>
              </div>
              <div>
                <span class="correcttext">答错次数：</span>
                <span class="correcttext" style="color: #fb0202">{{count}}</span>
              </div>
            </div>
          </div>
          <div class="btomtext" v-if="questionlist[activeIndex] && questionlist[activeIndex].explainInfo" v-html="questionlist[activeIndex] && questionlist[activeIndex].explainInfo">
          </div>
          <div class="btomtext" v-else style="text-align: center">暂无分析</div>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "singleWrongtopic",
  data() {
    return {
      activeIndex: 0,
      pagename: "错题解析",
      questionlist: [],
      isiOS: false
    };
  },
  props: {
    question: {
      required: true,
      type: Array
    },
    count: {
      type: [String, Number]
    }
  },
  filters: {
    formatValue (item) {
      if(!item) {
        return ''
      }
      if (item.type === 'Checking') {
        let arr = item.options.split('_ZVING_')
        for (let i =0 ; i< arr.length; i++) {
          if (arr[i].indexOf(item.answer) > -1) {
            return arr[i].split('.')[2]
          }
        }
      }
      if (item.type === 'Completion') {
        return item.answer.split('_ZVING_').join(',')
      }
      return item.answer
    }
  },
  created() {
    this.initPage()
    this.isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  methods: {
    clickLeft() {
      this.$emit('back')
    },
    formatValue(value) {
      let num = ['一', '二', '三', '四', '五', '六', '七', '八', '九']
      return num[value] || '一'
    },
    initPage() {
      let data = this.question
      data = data.map(item => {
        if (item.type.indexOf('Completion') > -1 || item.type.indexOf('Answer') > -1) {
          let arr = []
          for (let i = 0; i < item.options; i++) {
            let obj = {
              value: item.respond ? item.respond.split('_ZVING_')[i] : ''
            }
            arr.push(obj)
          }
          item.inputOptions = arr
        } else if (item.type === 'MultipleChoice' || item.type === 'CaseAnalysis') {
          let answer = item.answer ? item.answer.split('') : []
          let respond = item.respond ? item.respond.split('') : []
          let resMap = {}
          for (let k = 0; k < respond.length; k++) {
            resMap[respond[k]] = answer.indexOf(respond[k]) === -1
          }
          item.q_res = respond
          item.resMap = resMap
        } else if (item.type === 'Checking') {
          item.options = 'A.Y.正确_ZVING_B.N.错误'
          item.q_res = item.respond ? item.respond : ''
        } else {
          item.q_res = item.respond ? item.respond : ''
        }
        return item
      })
      this.questionlist = data
    }
  }
};
</script>
<style scoped>
  .examinationpaper >>>  .groupOption.van-radio-group {
    display: flex;
    justify-content: space-around;
  }
  .testbox .options >>> .tkt.van-cell::after {
    right: 0;
    left: 0;
  }
  .testbox .options >>> .jdt.van-cell::after {
    right: 0;
    left: 0;
  }
</style>
<style lang="less" scoped>
.wrongtopic {
  min-height: 100vh;
  background-color: #fff;
  .paperbox {
    display: flex;
    flex-direction: column;
  }
  .correct {
    .line {
      display: inline-block;
      width: 0.18rem;
      height: 0.43rem;
      background-color: #1578f2;
      border-radius: 0.08rem;
      vertical-align: middle;
      margin-right: 0.29rem;
    }
    .correcttext {
      font-family: PingFang-SC-Medium;
      font-size: 0.4rem;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #222222;
    }
  }
  .sup {
    display: flex;
    align-items: center;
    .linesup {
      flex: 0 0 0.18rem;
    }
  }

  .testbox {
    background-color: #fff;
    padding: 0.43rem 0.33rem;
    /deep/img {
      max-width: 100%;
      height: auto;
    }
    .title {
      margin-bottom: 0.88rem;
      .title_type {
        font-size: 0.28rem;
        padding: 0.11rem 0.2rem;
        color: #fff;
        background-image: linear-gradient(90deg, #53a0ff 0%, #1578f2 100%);
        border-radius: 0.27rem 0rem 0.27rem 0rem;
        margin-right: 0.2rem;
      }
      .title_text {
        font-family: PingFang-SC-Medium;
        font-size: 0.4rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.65rem;
        letter-spacing: 0rem;
        color: #333333;
        /deep/ img {
          max-width: 100%;
          max-height: 100%;
        }
        /deep/ p {
          display: inline;
          margin: 0;
        }
      }
    }
    .options {
      .van-radio,
      .van-checkbox {
        margin-bottom: 0.66rem;
        font-family: PingFang-SC-Regular;
        font-size: 0.4rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #333333;
        pointer-events: none; // css禁止点击事件
      }
      /deep/ .van-cell {
        padding-left: 0;
        padding-right: 0;
      }
      .van-cell:last-child {
        margin-bottom: 0.66rem;
      }
      .van-cell--borderless::after,
      .van-cell:last-child::after {
        display: block;
      }
      /deep/ input {
        pointer-events: none;
      }
      .operror {
        /deep/ .van-checkbox__icon--checked {
          .van-icon {
            background-color: red;
            border-color: red;
            line-height: 1.24;
          }
          .van-icon-success::before {
            content: "\F042";
          }
        }
        /deep/ .van-radio__icon--checked {
          .van-icon {
            background-color: red;
            border-color: red;
            line-height: 1.24;
          }
          .van-icon-success::before {
            content: "\F042";
          }
        }
      }
    }
    .title_p_title {
      font-family: PingFang-SC-Medium;
      font-size: 0.4rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.65rem;
      letter-spacing: 0rem;
      color: #333333;
      margin-bottom: 0.88rem;
      /deep/ p {
        display: inline-block;
      }
    }
  }
  .analysis {
    border-top: 0.29rem solid #f8f8f8;
    padding: 0rem 0.33rem;
    background-color: #fff;
    padding-bottom: 0.5rem;

    .analysisbox {
      text-align: center;
      padding: 0.54rem 0;
      .van-col {
        position: relative;
      }
      .van-col::after {
        content: " ";
        position: absolute;
        top: 25%;
        right: 0;
        width: 1px;
        height: 0.8rem;
        background-color: #e5e5e5;
      }
      .van-col:last-child::after {
        content: "";
        width: 0;
      }
      .analysisnum {
        font-size: 0.27rem;
        font-family: PingFang-SC-Heavy;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #111111;
        margin-bottom: 0.3rem;
        span {
          font-size: 0.45rem;
        }
      }
      .analysistext {
        font-family: PingFang-SC-Regular;
        font-size: 0.27rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #333333;
      }
    }
    .analysisbtom {
      .btomtext {
        padding-top: 0.33rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.3rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #444444;
        /deep/ img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .changebtn {
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    font-family: PingFang-SC-Medium;
    font-size: 0.31rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #1578f2;
    .info-btn {
      //   width: 30%;
      height: 1rem;
      padding: 0 0.33rem;
      margin-right: 0.33rem;
      width: 2.2rem;
      .van-button__text {
        font-size: 0.31rem;
      }
    }
  }
  .boxbotm {
    background-color: #fff;
    border-top: 1px solid #f2f2f2;
    position: fixed;
    bottom: 0;
    height: 1.33rem;
    line-height: 1.33rem;
    width: 100%;
    font-family: PingFang-SC-Medium;
    font-size: 0.4rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #666666;
    display: flex;
    justify-content: space-between;
    .iconbox {
      margin-left: 0.33rem;
    }
    .botmicon {
      font-size: 0.5rem;
      vertical-align: sub;
      margin-right: 0.25rem;
    }
  }
}
</style>
