<template>
  <div class="curriculum-list">
    <zv-nav-bar :title="title"></zv-nav-bar>
    <van-list v-model="loading" :finished="finished" finished-text="">
      <div class="curriculum-list-content">
        <template v-if="catalogData.length > 0">
          <div v-for="(item, index) in catalogData" :key="index" :class="{ container: item.alias, curriculum: !item.alias }">
            <template v-if="item.alias">
              <div class="item" @click="showChildrenList(item, index)">
                <span class="classification" :style="{ width: item.hasPermission !== 'Y' && item.coursePackageID !== 0 ? '5.49rem' : '6.66rem' }">{{ item.name }}</span>
                <span class="count">
                  <!-- {{item.courseNum}}节课 -->
                  <!-- <span class="separator">|</span> -->
                  <span class="separator"></span>
                </span>
                <span class="buy" v-if="item.hasPermission !== 'Y' && item.coursePackageID !== 0" @click.stop="gotoPage(`/allCourses/order/${item.coursePackageID}?type=CoursePackage`)"
                  >购买<i class="van-icon van-icon-gold-coin-o"></i
                ></span>
                <span class="buy">
                  <!-- <i class="van-icon van-icon-gold-coin-o"></i> -->
                </span>
                <span class="arrow-btn">
                  <i :class="{ 'van-icon': true, 'van-icon-arrow-up': childrenData.length > 0 && item.isShow, 'van-icon-arrow-down': childrenData.length === 0 || !item.isShow }"></i>
                </span>
              </div>
              <template v-if="childrenData.length > 0 && item.isShow">
                <div
                  class="item-list"
                  v-for="(_item, _index) in childrenData"
                  :key="_index"
                  @click.stop="_item.alias ? gotoPage(`/allCourses/curriculumList/${_item.ID}?title=${_item.name}`) : hrefPage(_item)"
                >
                  <div class="logo">
                    <img :src="_item.logoFile" />
                  </div>
                  <div class="details" v-if="_item.alias">
                    <div class="title">{{ _item.name }}</div>
                    <div class="details-item">
                      <span>
                        <!-- {{_item.courseNum}}节课 -->
                        <!-- <span class="separator">|</span> -->
                      </span>
                      <span class="buy" v-if="_item.hasPermission !== 'Y' && _item.coursePackageID !== 0" @click.stop="gotoPage(`/allCourses/order/${_item.coursePackageID}`)"
                        >购买<i class="van-icon van-icon-gold-coin-o details-item-icon"></i
                      ></span>
                    </div>
                  </div>
                  <div class="details reset" v-else @click.stop="hrefPage(_item)">
                    <div class="title">
                      <span class="tag">课程</span>
                      <span class="title-column">{{ _item.name }}</span>
                      <span class="current-price" v-if="_item.hasPermission === 'N'">￥{{ isVip ? _item.memberPrice : _item.sellPrice }}</span>
                      <span class="current-price" v-else style="color: #1989fa;" @click.stop="hrefPage(_item)">进入学习</span>
                    </div>
                    <div class="details-item">
                      <span class="duration">时长{{ _item.totalLength }}</span>
                      <span class="count">{{ _item.viewCount }}</span>
                      <span class="old-price" v-if="isVip && _item.hasPermission === 'N'">
                        <span class="bg-content">￥{{ _item.sellPrice }}</span>
                      </span>
                    </div>
                  </div>
                  <div class="item-list-icon" v-if="_item.alias">
                    <i class="van-icon van-icon-arrow"></i>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="logo" @click="hrefPage(item)">
                <img :src="item.logoFile" />
              </div>
              <div class="details reset" @click="hrefPage(item)">
                <div class="title">
                  <span class="tag">课程</span>
                  <span class="title-column">{{ item.name }}</span>
                  <span class="current-price" v-if="item.hasPermission === 'N'">￥{{ isVip ? item.memberPrice : item.sellPrice }}</span>
                  <span class="current-price" v-else style="color: #1989fa;" @click.stop="hrefPage(item)">进入学习</span>
                </div>
                <div class="details-item">
                  <span class="duration">时长{{ item.totalLength }}</span>
                  <span class="count">{{ item.viewCount }}</span>
                  <span class="old-price" v-if="isVip && item.hasPermission === 'N'">
                    <span class="bg-content">￥{{ item.sellPrice }}</span>
                  </span>
                </div>
              </div>
            </template>
          </div>
        </template>
        <div v-else class="not-data">
          <span>暂无数据</span>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  name: 'curriculumList',
  props: {},
  data() {
    return {
      loading: false,
      finished: true,
      nextLevelData: [],
      ID: this.$route.params.id,
      title: this.$route.query.title,
      catalogData: [],
      isVip: true,
    }
  },
  watch: {
    $route(to, from) {
      if (to.query.title !== from.query.title) {
        this.ID = to.params.id
        this.title = to.query.title
        this.getData()
        this.setShare()
      }
    },
  },
  computed: {
    childrenData() {
      return this.nextLevelData
    },
  },
  created() {
    this.getData()
    this.setShare()
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: `课程列表`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    hrefPage(item) {
      if (!item.alias) {
        this.gotoPage(`/allCourses/details/${item.ID}/curriculum`)
      }
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    getData() {
      if (localStorage.userInfo) {
        let info = JSON.parse(localStorage.userInfo)
        this.isVip = info.enterpriseName || info.enterpriseShorthand ? true : false
      }
      this.$Toast.loading({
        duration: 0, // 持续展示 toast
        message: '加载中...',
        forbidClick: true,
      })
      this.$axios
        .get(`/course/front/permit/catalog/more/${this.ID}`)
        .then((res) => {
          if (res.data.status === 1) {
            let data = res.data.data
            if (data.courses.length && data.children) {
              this.catalogData = data.children.concat(data.courses)
            } else if (data.children) {
              this.catalogData = data.children
            } else if (data.courses) {
              this.catalogData = data.courses
            }
          } else {
            this.$Toast.fail(res.data.message)
          }
          this.$Toast.clear()
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，请稍后再试')
          this.$Toast.clear()
          return e
        })
    },
    showChildrenList(item, index) {
      let d = this.catalogData
      if (d[index].isShow) {
        d[index].isShow = false
      } else {
        this.$Toast.loading({
          duration: 0, // 持续展示 toast
          message: '加载中...',
          forbidClick: true,
        })
        this.$axios
          .get(`/course/front/permit/catalog/more/${item.ID}`)
          .then((res) => {
            if (res.data.status === 1) {
              let data = res.data.data
              if (data.courses.length && data.children) {
                this.nextLevelData = data.children.concat(data.courses)
              } else if (data.children) {
                this.nextLevelData = data.children
              } else if (data.courses) {
                this.nextLevelData = data.courses
              }
              for (let i = 0; i < d.length; i++) {
                if (i === index && this.nextLevelData.length > 0) {
                  d[i].isShow = !d[i].isShow
                } else {
                  d[i].isShow = false
                }
              }
            } else {
              this.$Toast.fail(res.data.message)
              this.catalogData[index].isShow = false
            }
            this.$Toast.clear()
          })
          .catch((e) => {
            this.$Toast.fail('服务器开小差了，请稍后再试')
            this.catalogData[index].isShow = false
            this.$Toast.clear()
            return e
          })
      }
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped>
.curriculum-list >>> .van-icon.van-icon-search {
  font-size: 0.37rem;
}
.curriculum-list-content {
  padding: 0.33rem;
}
.container {
  line-height: 0.96rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.08rem 0.13rem 0rem rgb(207 207 207 / 20%);
  box-sizing: border-box;
  margin-bottom: 0.03rem;
}
.container .item {
  border-radius: 0.13rem;
  border: solid 0.01rem #f5f5f5;
  padding-left: 0.29rem;
}
.classification {
  font-size: 0.4rem;
  color: #333333;
  font-weight: 600;
  display: inline-block;
  width: 5.49rem;
}
.count {
  color: #bebebe;
  font-size: 0.32rem;
}
.separator {
  margin: 0 0.16rem;
}
.buy {
  color: #3fa1fd;
  font-size: 0.32rem;
  position: relative;
  width: 1.2rem;
  display: inline-block;
}
.buy i {
  font-size: 0.34rem;
  position: absolute;
  top: 0.32rem;
}
.arrow-btn {
  position: relative;
  width: 0.53rem;
  display: inline-block;
  height: 0.32rem;
}
.arrow-btn i {
  position: absolute;
  font-size: 0.42rem !important;
  top: 0;
  color: #bebebe;
}
.arrow-btn i.van-icon-arrow-up {
  position: absolute;
  font-size: 0.42rem !important;
  top: 0;
  color: #3fa1fd;
}
.item-list {
  height: 1.68rem;
  background-color: #ffffff;
  box-shadow: 0 0.08rem 0.13rem 0 rgb(207 207 207 / 20%);
  border-radius: 0.13rem;
  border: solid 0.01rem #f5f5f5;
  line-height: 1.68rem;
  padding: 0.32rem 0.25rem;
  box-sizing: border-box;
  display: flex;
  margin: 0.17rem 0 0.27rem 0;
}
.logo {
  width: 1.04rem;
  height: 1.04rem;
  margin-left: 0.32rem;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.curriculum .logo {
  margin-left: 0;
}
.details {
  width: 6.56rem;
  margin-left: 0.19rem;
}
.details .title {
  height: 0.36rem;
  line-height: 0.48rem;
  font-weight: bold;
  color: #444;
  font-size: 0.37rem;
}
.details .details-item {
  height: 0.53rem;
  line-height: 0.53rem;
  color: #bebebe;
  font-size: 0.32rem;
  display: flex;
  margin-top: 0.26rem;
  align-items: center;
  justify-content: space-between;
  width: 7.2rem;
}
.details .details-item .details-item-icon {
  top: 0.4rem;
}
.item-list-icon i {
  font-size: 0.42rem;
  color: #bebebe;
  vertical-align: super;
}
.curriculum {
  height: 1.68rem;
  background-color: #ffffff;
  box-shadow: 0 0.08rem 0.13rem 0 rgb(207 207 207 / 20%);
  border-radius: 0.13rem;
  border: solid 0.01rem #f5f5f5;
  line-height: 1.68rem;
  padding: 0.32rem 0.25rem;
  box-sizing: border-box;
  display: flex;
  margin: 0.17rem 0 0.27rem 0;
  padding-right: 0;
}
.tag {
  font-size: 0.32rem;
  display: inline-block;
  border: 1px solid #fbc047;
  border-radius: 0.11rem;
  padding: 0 1px;
  color: #fff;
  background-color: #fbc047;
  margin-right: 0.13rem;
}
.details.reset {
  width: 7.73rem;
}
.title-column {
  display: inline-block;
  width: 4.26rem;
  /* width: 6.66rem; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.current-price {
  text-align: right;
  width: 2.08rem;
  display: inline-block;
  padding-right: 0.05rem;
}
.duration {
  /* width: 1.6rem; */
  /* margin: 0 1.86rem 0 0; */
  background-color: #f6f7f9;
  border-radius: 2px;
  padding: 2px;
}
.old-price {
  /* text-align: right;
  width: 2.08rem;
  display: inline-block;
  padding-right: 0.05rem; */
}
.bg-content {
  background-color: #f6f7f9;
  border-radius: 2px;
  text-decoration: line-through;
}
.not-data {
  display: flex;
  justify-content: center;
  height: calc(100vw);
  line-height: calc(100vw);
  color: #999;
}
</style>
