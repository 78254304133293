export class Logger {
  log(...args) {
    window.console.log(...args)
  }
  info(...args) {
    window.console.info(...args)
  }
  warn(...args) {
    window.console.warn(...args)
  }
  error(...args) {
    window.console.error(...args)
  }
}
