<template>
  <div>
    <zv-nav-bar title="通知详情"></zv-nav-bar>
    <div class="news-detail">
      <div class="news-title">{{ title }}</div>
      <div class="news-time">{{ time }}</div>
      <div class="news-content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: '',
      title: '',
      time: '',
      id: '',
    }
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.newsTitle}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    getDetail() {
      this.$axios
        .get(`/course/front/messagehandle/${this.id}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.content = res.data.data.content
            this.time = res.data.data.sendTime
            this.title = res.data.data.title
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，请稍后再试')
          return e
        })
    },
  },
  created() {
    this.id = this.$route.params.id
    this.getDetail()
  },
}
</script>
<style lang="less" scoped>
.news-detail {
  padding: 0.13rem 0.53rem 0.5rem 0.53rem;
  /deep/ .news-title {
    color: #333333;
    font-size: 0.48rem;
    margin-bottom: 0.44rem;
    font-weight: bold;
  }
  /deep/ .news-time {
    font-size: 0.32rem;
    color: #999;
  }

  .news-content {
    font-size: 0.35rem;
    /deep/ p {
      font-size: 0.37rem !important;
      margin-bottom: 0.69rem;
    }
    /deep/ img {
      max-width: 100%;
    }
    /deep/ video {
      max-width: 100%;
    }
  }
}
</style>
