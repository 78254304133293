import NzZone from '@/page/nzzone/Index'
import ClassCourses from '@/page/nzzone/ClassCourses'
import SearchResult from '@/page/nzzone/SearchResult'
import ClassInfo from '@/page/nzzone/ClassInfo/Index'
import ClassList from '@/page/nzzone/ClassInfo/ClassList'
import LiveList from '@/page/nzzone/LiveList'
import EnterpriseList from '@/page/nzzone/EnterpriseList'
import EnterpriseClassList from '@/page/nzzone/EnterpriseClassList'
import AllCourses from '@/page/nzzone/AllCourses'
import CourseList from '@/page/nzzone/CourseList'
import CatalogList from '@/page/nzzone/CatalogList'
import CurriculumList from '@/page/nzzone/CurriculumList'
import WorkDetails from '@/page/nzzone/WorkDetails'

export default [
  //诺舟主页
  {
    path: '/nzzone',
    name: 'nzzone',
    component: NzZone,
    meta: {
      title: '企业首页',
      keepAlive: true
    }
  },
  //机构课程
  {
    path: '/nzzone/allCourses/:enterpriseID/:enterpriseInnercode',
    name: 'nzzone',
    component: AllCourses,
    meta: {
      title: '企业课程',
      keepAlive: true
    }
  },
  {
    path: '/nzzone/allCourses/CourseList/:enterpriseID/:enterpriseInnercode/:id',
    name: 'courseList',
    component: CourseList
  },
  {
    path: '/nzzone/allCourses/CatalogList/:enterpriseID/:enterpriseInnercode/:id',
    name: 'catalogList',
    component: CatalogList
  },
  //机构课程列表页
  {
    path: '/nzzone/allCourses/curriculumList/:id/:enterpriseID/:enterpriseInnercode',
    name: 'curriculumList',
    component: CurriculumList
  },
  //搜索结果页
  {
    path: '/nzzone/SearchResult',
    name: 'searchResult',
    component: SearchResult
  },
  //诺舟班级全部课程
  {
    path: '/nzzone/classcourses',
    name: 'classcourses',
    component: ClassCourses,
    meta: {
      title: '企业班级课程列表'
    }
  },
  {
    path: '/nzzone/liveList',
    name: 'liveList',
    component: LiveList,
    meta: {
      title: '班级直播列表'
    }
  },
  //班级详情
  {
    path: '/nzzone/classinfo',
    name: 'classinfo',
    component: ClassInfo
  },
  //班级列表
  {
    path: '/nzzone/classinfo/classlist',
    name: 'classlist',
    component: ClassList,
    meta: {
      title: '班级列表'
    }
  },
  //企业课程列表
  {
    path: '/nzzone/classinfo/enterpriseList',
    name: 'enterpriseList',
    component: EnterpriseList
  },
  //企业班级列表
  {
    path: '/nzzone/classinfo/enterpriseClassList',
    name: 'enterpriseClassList',
    component: EnterpriseClassList,
    meta: {
      title: '企业班级列表',
      keepAlive: true
    }
  },
  // 作业详情
  {
    path: '/nzzone/workDetails/:workId',
    name: 'workDetails',
    component: WorkDetails
  }
]