<template>
  <div class="member-info">
    <zv-nav-bar :title="pageName"></zv-nav-bar>
    <div class="main__wrap">
      <div class="title" v-html="content.title"></div>
      <div class="publish-date" v-html="content.publishDate"></div>
      <div class="content" v-html="content.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberInfo',
  data() {
    this.pageName = '企业会员'
    return {
      content: {},
    }
  },
  methods: {
    getUrl() {
      return this.$route.params.url
    },
    getPageDetails(url) {
      this.$axios({
        url,
        methods: 'GET',
        params: {},
      }).then(({ data }) => {
        if (Number(data.status) !== 1) return
        this.content = data.data
      })
    },
  },
  mounted() {
    const artUrl = this.getUrl()
    if (artUrl) this.getPageDetails(artUrl)
  },
}
</script>

<style lang="less" scoped>
.main__wrap {
  padding: 0.13rem 0.53rem 0.5rem 0.53rem;
  .title {
    color: #333333;
    font-size: 0.48rem;
    margin-bottom: 0.44rem;
    font-weight: bold;
  }
  .publish-date {
    font-size: 0.32rem;
    color: #999;
  }
  .content {
    font-size: 0.35rem;
  }
}
</style>
