<template>
  <div class="ali-player">
    <div id="J_prismPlayer"></div>
  </div>
</template>

<script>
export default {
  props: {
    videoSrc: String,
    videoCover: String,
    playVideoCallback: Function,
  },
  data() {
    return {
      aliPlayer: '',
    }
  },
  methods: {
    initAliPlayer(videoSrc) {
      if (!window.Aliplayer) {
        return
      }
      this.aliPlayer = new window.Aliplayer(
        {
          id: 'J_prismPlayer',
          // vid: 'wxd4d63590475cb39b', // 播放id
          // playauth: '7ce7b8c6d0cd2aa57b3a693f6a6a3737d81d7b51', // 播放凭证
          source: videoSrc,
          width: '100%',
          height: '5.65rem',
          // 以下可选设置
          cover: this.videoCover,
          qualitySort: 'asc', // 清晰度排序
          mediaType: 'video', // 返回音频还是视频
          autoplay: false, // 自动播放
          isLive: false, // 直播
          rePlay: false, // 循环播放
          preload: true,
          controlBarVisibility: 'hover', // 控制条的显示方式：鼠标悬停
          useH5Prism: true, // 播放器类型：html5
          components: [
            {
              name: 'RateComponent',
              type: window.AliPlayerComponent.RateComponent,
            },
          ],
        },
        function(player) {
          player.on('play', () => {
            console.log('play')
            this.playVideoCallback()
          })
          console.log('播放器创建成功')
        }.bind(this)
      )
    },
    async loadJS(url, data) {
      return new Promise(function(resolve, reject) {
        let s = document.createElement('script')
        s.setAttribute('src', url)
        if (data) {
          s.setAttribute('data', data)
        }
        document.head.appendChild(s)
        s.onload = resolve
        s.onerror = reject
      })
    },
    async loadSrc() {
      await this.loadJS(location.protocol + '//' + location.host + '/js/aliplayer-min.js')
      await this.loadJS(location.protocol + '//' + location.host + '/js/CommentCoreLibrary.js')
      await this.loadJS(location.protocol + '//' + location.host + '/js/aliplayercomponents-1.0.6.min.js')
      await this.loadJS(location.protocol + '//' + location.host + '/js/aliplayer-hls-min.js')
      this.initAliPlayer(this.videoSrc)
    },
  },
  mounted() {},
  watch: {
    videoSrc: {
      handler(newVal) {
        if (newVal) {
          this.loadSrc()
        }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
#J_prismPlayer /deep/ .prism-big-play-btn {
  left: 50% !important;
  top: 50%;
  transform: translate(-50%, -50%);
}
#J_prismPlayer /deep/ .prism-info-display {
  box-sizing: border-box;
}
</style>
