<template>
  <div class="curriculum-details" :style="{ 'margin-bottom': marginBottom }">
    <zv-nav-bar :title="'直播详情'">
      <div class="poster-sharer" @click="posterSharer">
        <span style="font-size: 0.32rem; color: #1989fa;">海报分享</span>
        <span class="share fa fa-share-square-o"></span>
      </div>
    </zv-nav-bar>
    <van-row>
      <van-col span="24">
        <div class="video-js">
          <img class="logo" :src="itemData.logoFile" />
        </div>
        <div class="details" :style="{ 'padding-bottom': itemData.isPhoto === 'Y' ? '0.16rem' : '0.26rem' }">
          <div class="details-item">
            <div class="title">
              <van-tag type="warning">直播</van-tag>
              <span>{{ itemData.name }}</span>
            </div>
          </div>
          <div v-if="itemData.speaker" class="teacher">
            <span>讲师：</span>
            <span class="teacher-arr">{{ itemData.speaker }}</span>
            <span class="right-data">
              {{ itemData.viewCount }}
            </span>
          </div>
          <div class="price-item" v-if="isFree">
            <span class="price">免费学习</span>
          </div>
          <div class="price-item" v-else-if="!isPay">
            <span class="price">￥{{ hasEnterprise ? itemData.memberPrice : itemData.sellPrice }}</span>
            <span class="separator"></span>
            <span class="old-price" v-if="hasEnterprise">￥{{ itemData.sellPrice }}</span>
            <span class="separator"></span>
            <span class="pay" v-if="hasEnterprise">企业已经为你支付{{ (itemData.sellPrice - itemData.memberPrice).toFixed(2) }}元</span>
          </div>
        </div>
        <van-tabs v-model="activeName">
          <van-tab title="直播介绍" name="1">
            <div>【直播介绍】</div>
            <template v-if="itemData.description">
              <div class="desc" v-html="itemData.description"></div>
            </template>
            <div v-else class="not-data">暂无数据</div>
            <div>【主讲老师】</div>
            <template v-if="itemData.speakers && itemData.speakers.length > 0">
              <div class="teacher-item" v-for="(item, index) in itemData.speakers" :key="index" @click="toTeacherDetail(item)">
                <div class="teacher-logo">
                  <img :src="item.logoFile" />
                </div>
                <div class="teacher-content">
                  <div class="teacher-name">{{ item.name }}</div>
                  <div class="teacher-desc" v-html="item.description || '暂无描述'"></div>
                </div>
              </div>
            </template>
            <div class="not-data" v-else>暂无数据</div>
          </van-tab>
          <van-tab title="" name="2">
            <div></div>
          </van-tab>
          <van-tab title="" name="3">
            <div></div>
          </van-tab>
        </van-tabs>
        <div class="practise" v-if="isPay || isFree">
          <van-button round block type="info" @click="gotoStudy">{{ isFree ? '免费学习' : '立即学习' }}</van-button>
          <div class="share van-icon van-icon-apps-o" @click="showShare = true"></div>
        </div>
        <!-- <div class="buynow" v-else-if="isFree">
          <van-button round block type="info" @click="gotoStudy">免费学习</van-button>
        </div> -->
        <div class="buynow" v-else-if="!isPay">
          <div class="buy-btn-group" v-if="!isMember">
            <van-button class="buy-btn--highlighted" round @click="getMemberPage('buy')">企业会员价&nbsp;{{ formatAmountToFixed2(itemData.memberPrice) }}元</van-button>
            <van-button class="buy-btn" round @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}&type=LIVE`)"
              >立即购买&nbsp;{{ formatAmountToFixed2(itemData.sellPrice) }}元</van-button
            >
          </div>
          <div class="buy-btn-group" v-else>
            <van-button class="buy-btn" style="background: #4087ef" round @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}&type=LIVE`)">立即购买</van-button>
          </div>
        </div>
        <div class="buynow" v-else>
          <van-button round block type="info" @click="gotoStudy">立即学习</van-button>
        </div>
      </van-col>
    </van-row>
    <van-share-sheet v-model="showShare" :options="options" @select="onSelect" />
    <van-dialog v-model="shareDialog" title="长按图片分享" confirmButtonText="关闭" @confirm="shareDialog = false" width="100%">
      <img :src="photoSrc + '?' + new Date().getTime()" style="max-width: 100%; height: auto" />
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: 'listDetails',
  computed: {
    marginBottom() {
      if (!this.isPay) {
        return '1.61rem'
      }
      return 0
    },
  },
  data() {
    return {
      shareDialog: false,
      photoSrc: '',
      id: '',
      activeName: '1',
      isPay: true,
      itemData: {},
      hasEnterprise: false,
      isFree: false,
      showShare: false,
      isMember: false,
      options: [
        {
          name: '班级',
          typeName: 'class',
          icon: 'https://img01.yzcdn.cn/vant/custom-icon-fire.png',
        },
      ],
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getListData()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      if (Number(info.isVip) && (info.enterpriseName || info.enterpriseShorthand)) {
        this.hasEnterprise = true
      }
    }
  },
  mounted() {
    this.isMember = this.$eventHub.userInfo.isVip
  },
  methods: {
    getMemberPage(type) {
      // 跳转企业会员相关文章页
      this.$axios({
        url: '/json/ktqyhy/index.json',
        methods: 'GET',
        params: {},
      }).then(({ data }) => {
        if (Number(data.status) !== 1) return
        if (type === 'buy') this.handleGotoMemberOrder(data.data[0].artUrl)
        else if (type === 'permission') this.handleGotoMemberOrder(data.data[1].artUrl)
      })
    },
    handleGotoMemberOrder(url) {
      const artUrl = url.substring(url.search(/json/))
      this.$router.push({
        name: 'memberInfo',
        params: {
          url: artUrl,
        },
      })
    },
    posterSharer() {
      if (this.photoSrc) {
        return (this.shareDialog = true)
      }
      this.$Toast.loading({
        message: '正在生成海报中...',
        forbidClick: true,
        duration: 0,
      })
      this.$axios
        .get(`/course/front/permit/poster/${this.$route.params.id}?type=LIVE`)
        .then((res) => {
          this.$Toast.clear()
          if (res.data.status === 1) {
            this.shareDialog = true
            this.photoSrc = res.data.data.url
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    gotoStudy() {
      let token = localStorage.token ? localStorage.token.replace('Bearer+', 'Bearer ') : ''
      window.location.href = `${process.env.VUE_APP_URL}/course/front/live/${this.itemData.ID}/log/coupons?token=${token}`
    },
    onSelect() {
      this.$router.push({
        path: `/allCourses/classByCourse/${this.$route.params.id}`,
      })
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.itemData.name || '课程详情'}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    // 课程详情
    getListData() {
      this.$axios
        .get(`/course/front/permit/live/${this.id}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.itemData = res.data.data
            this.isPay = res.data.data.hasPermission === 'Y'
            this.isFree = res.data.data.isFree === '1'
            this.setShare()
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    toTeacherDetail(item) {
      this.$router.push({
        name: 'teacherDetail',
        params: {
          id: item.ID,
        },
      })
    },
    formatAmountToFixed2(amount) {
      return Number(amount).toFixed(2)
    },
  },
}
</script>

<style scoped>
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.curriculum-details >>> .van-dialog {
  margin-top: 0.93rem;
}
.curriculum-details >>> .van-dialog__content {
  max-height: 80vh;
  overflow-y: auto;
}
.poster-sharer {
  display: flex;
  align-items: center;
}
.poster-sharer span.share {
  font-size: 0.58rem;
  color: #1989fa;
  margin-left: 0.26rem;
}
.curriculum-details {
  background-color: #eee;
  margin-bottom: 1.61rem;
}
.curriculum-details >>> #videoPreview {
  width: 100%;
  height: 100%;
}
.curriculum-details >>> .van-tabs__nav {
  pointer-events: none;
}
.curriculum-details >>> .van-tag--warning {
  background-color: #fbc047;
}
.curriculum-details >>> .van-nav-bar .van-icon {
  font-size: 0.48rem;
  color: #333;
}
.curriculum-details >>> .van-tabs.van-tabs--line {
  margin-top: 0.13rem;
  margin-bottom: 1.6rem;
}
.curriculum-details >>> .van-tab {
  line-height: 0.53rem;
}
.curriculum-details >>> .van-tabs__line {
  position: absolute;
  bottom: 0.4rem;
  left: 0;
  z-index: 1;
  width: 1.06rem;
  height: 0.08rem;
  background-color: #1578f2;
  border-radius: 0.08rem;
}
.curriculum-details >>> .van-tag {
  font-size: 0.32rem;
  line-height: 0.58rem;
  height: 0.58rem;
  border-radius: 2px;
  white-space: nowrap;
  margin-right: 0.13rem;
  vertical-align: top;
}
.curriculum-details >>> .van-tabs--line .van-tabs__wrap {
  height: 1.17rem;
}
.curriculum-details >>> .van-tab__text.van-tab__text--ellipsis {
  font-size: 0.37rem;
  /* color: #ccc; */
}
.curriculum-details >>> .van-tab--active .van-tab__text.van-tab__text--ellipsis {
  color: #222;
  font-weight: bold;
  /* text-shadow: 1px 0px 0px #000; */
}
.curriculum-details >>> .van-tabs__content {
  background-color: #fff;
  /* padding: 0.47rem 0.53rem; */
  padding: 0.26rem 0.53rem;
  font-size: 0.37rem;
}
.logo {
  height: 5.65rem;
  width: 100%;
}
.details {
  padding: 0.57rem 0.51rem 0.16rem 0.57rem;
  background-color: #fff;
  margin-top: -0.13rem;
}
.details-item {
  height: 0.44rem;
  display: flex;
}
.tag {
  font-size: 0.32rem;
  display: inline-block;
  border: 1px solid #fc7d55;
  border-radius: 0.11rem;
  padding: 0 1px;
  color: #fc7d55;
}
.title {
  font-size: 0.45rem;
  color: #111111;
  height: 0.58rem;
  line-height: 0.58rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8.74rem;
}
.teacher {
  font-size: 0.35rem;
  margin-top: 0.33rem;
  color: #111111;
  position: relative;
}
.teacher-arr {
  display: inline-block;
  width: 5.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: text-bottom;
}
.separator {
  margin: 0 0.13rem;
}
.right-data {
  position: absolute;
  right: 0;
}
.count {
  color: #777777;
}
.contain {
  color: #1578f2;
  border: 0.01rem solid #1578f2;
  font-size: 0.32rem;
  margin-left: 0.32rem;
}
.price-item {
  font-size: 0.32rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1rem;
  padding-right: 0.13rem;
}
.price {
  font-size: 0.53rem;
  color: #111;
}
.old-price {
  font-size: 0.32rem;
  color: #999999;
  text-decoration: line-through;
}
.pay {
  color: #777777;
}
.player-count {
  top: 0.21rem;
  width: 1.2rem;
  /* height: 0.45rem; */
  background-color: #ffffff;
  border-radius: 0.05rem;
  border: solid 0.01rem #1578f2;
  text-align: center;
  /* line-height: 0.45rem; */
  /* position: absolute; */
  right: 0;
  color: #1578f2;
}
.desc {
  font-size: 0.35rem;
  color: #474747;
  line-height: 0.56rem;
  margin-top: 0.27rem;
  margin-bottom: 0.4rem;
  padding-left: 0.13rem;
  text-align: justify;
}
.desc >>> img {
  max-width: 100%;
}
.teacher-item {
  display: flex;
  margin-top: 0.4rem;
}
.teacher-logo {
  width: 1.33rem;
  height: 1.33rem;
  margin-left: 0.21rem;
}
.teacher-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.teacher-content {
  width: 7.09rem;
  padding-left: 0.31rem;
}
.teacher-name {
  font-size: 0.37rem;
}
.teacher-desc {
  font-size: 0.32rem;
  /* text-align: justify; */
  padding-top: 0.29rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  width: 7.06rem;
}
.teacher-desc >>> p {
  margin: 0;
}
.buynow {
  height: 1.61rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.curriculum-details >>> .van-button--round {
  margin: 0.19rem 0.28rem;
  width: calc(100% - 0.56rem);
}
.curriculum-details >>> .van-button--normal {
  padding: 0 0.4rem;
  font-size: 0.37rem;
}
.curriculum-details >>> .van-button {
  height: 1.17rem;
}
.curriculum-details .practise >>> .van-button {
  width: 8.29rem;
  /* width: 100%; */
}
.course-list {
  height: 2.13rem;
  display: flex;
  padding: 0.32rem 0;
  border-bottom: 1px solid #eee;
}
.course-list:last-child {
  border-bottom: none;
}
.course-list .logo {
  width: 2.93rem;
  height: 1.95rem;
}
.course-list .logo img {
  width: 100%;
  height: 100%;
}
.course-list-item {
  margin-left: 0.21rem;
  width: 5.81rem;
}
.course-list-item-title {
  font-size: 0.4rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.course-list-item-tacher-name {
  font-size: 0.35rem;
  color: #777777;
  margin-top: 0.33rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.video-content {
  margin-left: 0.26rem;
}
.video-count {
  font-size: 0.32rem;
  color: #777;
}
.video-list-content {
  width: 8.26rem;
}
.video-list-item {
  display: flex;
  justify-content: space-between;
  font-size: 0.37rem;
  color: #777;
  height: 1.26rem;
  line-height: 1.26rem;
  border-bottom: 1px solid #eee;
}
.video-list-item.active {
  color: #1578f2;
}
.video-list-item-title {
  width: 4.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.video-list-item-time {
  font-size: 0.27rem;
  line-height: 1.26rem;
}
.video-list-item-icon {
  line-height: 1.26rem;
  font-size: 0.58rem;
}
.html-content {
  margin: 0 -0.53rem;
}
.html-content >>> img {
  max-width: 100%;
}
.practise {
  height: 1.61rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
}
.share {
  line-height: 1.55rem;
  font-size: 0.69rem;
}
.handouts-content {
  color: #999;
  width: 100vw;
  margin-left: -0.53rem;
  background-color: #eee;
  line-height: 0.93rem;
  margin-bottom: 0.13rem;
}
.handouts-content.active {
  color: #1578f2;
}
.handouts {
  width: 8.93rem;
  display: flex;
  padding: 0 0.53rem;
}
.handouts .handouts-title {
  display: inline-block;
  width: 7.78rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.handouts .van-icon.van-icon-paid {
  font-size: 0.53rem;
  margin-right: 0.26rem;
  line-height: 0.93rem;
}
.handouts-content .btn {
  line-height: 0.93rem;
}
.handouts-content .handouts-details {
  display: block;
  padding-top: 0.13rem;
  background-color: rgb(255, 255, 255);
  line-height: normal;
  text-align: center;
}
.handouts-content img {
  max-width: 100%;
  height: auto;
}
.curriculum-details >>> .van-image-preview__image img {
  pointer-events: none;
}
.buy-btn-group {
  display: flex;
}
.buy-btn {
  background: linear-gradient(to right, #63a1ed, #97d0f5);
  color: #fff;
}
.buy-btn--highlighted {
  background: linear-gradient(to right, #532c10, #7f583b);
  color: #ffebb6;
}
</style>
<style>
.video-js {
  width: 100%;
  height: 5.65rem;
}
.my-video-dimensions {
  width: 100%;
  height: 5.65rem;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-js .vjs-tech {
  position: relative !important;
}
.video-js .vjs-time-control {
  display: block;
}
.video-js .vjs-remaining-time {
  display: none;
}
</style>
