<template>
  <div class="content">
    <zv-nav-bar :title="pagename"></zv-nav-bar>
    <div class="container">
      <van-tabs v-model="active" @change="tabsChange">
        <van-tab>
          <template #title>
            <div class="title-item">
              <i class="fa fa-comment" style="font-size: 0.83rem; padding-bottom: 0.16rem; color: #FEB955"></i>
              <span class="title">答疑</span>
            </div>
          </template>
          <van-list v-model="qsLoading" :finished="qsFinished" finished-text="" @load="getQuestion">
            <div class="question-item" v-for="item in questionList" :key="item.ID" @click="gotoPage(item)">
              <div class="qs-item">
                <div class="logo-item">
                  <div class="us-other">
                    <span class="us-name">
                      <span class="qs-title">{{item.courseName}}</span>
                      <span style="margin-left: 0.13rem;">提问</span>
                      <span class="qs-red" v-if="item.isShowRed !== 0"></span>
                    </span>
                    <span class="qs-desc">{{item.rContent || '等待回复'}}</span>
                  </div>
                </div>
                <div class="time-item">
                  <span class="qs-date">{{item.addTime.substring(0, 10)}}</span>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab>
          <template #title>
            <div class="title-item">
              <i class="fa fa-bell" style="font-size: 0.83rem; padding-bottom: 0.16rem; color: #84BBFC"></i>
              <span class="title">通知</span>
            </div>
          </template>
          <van-list v-model="loading" :finished="finished" finished-text="" @load="getNotice">
            <div class="item" v-for="item in messageList" :key="item.ID" @click="getDetails(item)">
              <div class="top">
                <span class="title">{{item.title}}  <span class="date">{{item.sendTime}}</span></span>
                <span :class="{'tips': item.isRead === '0'}"></span>
              </div>
              <div class="desc">
                {{item.content}}
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: 'notice',
  data() {
    return {
      loading: false,
      qsLoading: false,
      finished: false,
      qsFinished: false,
      pagename: '通知',
      pageIndex: 0,
      qsPageIndex: 0,
      questionList: [],
      messageList: [],
      active: 1
    }
  },
  created() {
    this.setShare()
    this.active = this.$route.query.activeIndex == 0 ? 0 : 1
  },
  methods: {
    setShare() {
      const shareInfo = {
        disabled: true
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    tabsChange(index) {
      if (index === 1) {
        this.pagename = '通知'
      } else {
        this.pagename = '答疑'
      }
      this.$router.replace({
        path: `/user/notice?activeIndex=${index}` 
      })
    },
    getQuestion() {
      this.$axios.get(`/course/front/member/memberquestion?beginTime=&endTime=&keyWord=&pageSize=10&pageIndex=${this.qsPageIndex}`).then(res => {
        if (res.data.status === 1) {
          this.qsPageIndex = this.qsPageIndex + 1
          this.questionList = this.questionList.concat(res.data.data.data)
          this.qsLoading = false
          if (this.questionList.length >= res.data.data.total) {
            this.qsFinished = true
          }
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        return this.$Toast.fail('服务器开小差了，稍后重试' + e)
      })
    },
    getNotice() {
      this.$axios.get(`/course/front/messagehandle?pageSize=10&pageIndex=${this.pageIndex}`).then(res => {
        if (res.data.status === 1) {
          this.pageIndex = this.pageIndex + 1
          this.messageList = this.messageList.concat(res.data.data.data)
          this.loading = false
          if (this.messageList.length >= res.data.data.total) {
            this.finished = true
          }
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        return this.$Toast.fail('服务器开小差了，稍后重试' + e)
      })
    },
    getDetails(item) {
      this.$router.push({
        path: `/user/NoticeDetail/${item.ID}`
      })
    },
    gotoPage(item) {
      if (item.rStatus != 1) {
        return
      }
      this.$router.push({
        path: `/user/answer/${item.ID}`
      })
    }
  }
}
</script>
<style scoped>
.title-item {
  display: flex;
  flex-direction: column;
}
.content >>> .van-tabs--line .van-tabs__wrap {
  height: 2.13rem;
  margin-bottom: 0.27rem;
}
.content >>> .van-tabs__nav--line {
  padding-bottom: 0.4rem;
}
.content >>> .van-tabs__line {
  width: 0.39rem;
  background-color: #1578f2;
  bottom: 0.53rem;
  height: 0.08rem;
  border-radius: 0.08rem;
}
.content >>> .van-tabs__content {
  padding: 0.27rem 0.12rem 0.27rem 0.33rem;
  background-color: #fff;
  min-height: calc(100vh - 3.89rem);
}
.container {
  background-color: #eee;
  min-height: calc(100vh - 1.76rem);
}
.question-item {
  display: flex;
  padding: 0.5rem 0.28rem 0.17rem 0.2rem;
}
.logo-item {
  display: flex;
}
.logo {
  width: 1.07rem;
  height: 1.07rem;
  padding-right: 0.28rem;
}
.logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.qs-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  height: 1.5rem;
}
.us-other {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.07rem;
}
.us-name {
	font-size: 0.4rem;
	line-height: 0.48rem;
	color: #0a0a0a;
  font-weight: bold;
  display: flex;
  position: relative;
}
.qs-title {
  max-width: 5.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qs-desc {
	font-size: 0.35rem;
	line-height: 0.48rem;
	color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 6.93rem;
}
.qs-date {
	font-size: 0.29rem;
	line-height: 0.48rem;
	color: #999999;
}
.qs-red {
  width: 0.29rem;
  height: 0.29rem;
	background-color: #f21549;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: -0.53rem;
  top: -0.13rem;
}
.time-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 1.07rem;
}
.top {
  position: relative;
}
.item {
  padding: 0.2rem 0.6rem 0.41rem 0.45rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.04rem 0.2rem 0rem rgb(0 0 0 / 6%);
  border-radius: 0.13rem;
  margin-bottom: 0.27rem;
}
.title {
  color: #444444;
  font-size: 0.4rem;
  font-weight: bold;
  display: inline-block;
  /* margin-right: 0.23rem; */
  line-height: 0.4rem;
}
.date {
  font-size: 0.32rem;
  color: #888888;
  margin-left: 0.23rem;
}
.tips {
  width: 0.29rem;
	height: 0.29rem;
  background-color: #f21549;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  right: -0.26rem;
  top: 0.2rem;
}
.desc {
  color: #555555;
  font-size: 0.35rem;
  margin-top: 0.2rem;
  line-height: 0.56rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>