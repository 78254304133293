<template>
  <div class="home-page">
    <!-- 搜索栏 -->
    <div class="part-1">
      <!-- <van-icon name="question-o" size="0.8rem"/> -->
      <div class="search-box">
        <van-search v-model="searchValue" shape="round" placeholder="搜索课程" @focus.prevent @click="toSearchPage" />
        <van-button
          class="search-button"
          style="top: 50%; transform: translateY(-50%)"
          round
          color="linear-gradient(90deg, #5abffe 0%, #389cf8 61%, #1578f2 100%)"
          @click="toSearchPage"
          >搜索</van-button
        >
      </div>
      <van-icon
        name="chat-o"
        :dot="mesCount.messageCount > 0 || mesCount.questionCount > 0"
        size="0.7rem"
        @click="gotoNotice"
      />
    </div>
    <!-- 轮播图 -->
    <div class="part-2">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item
          v-for="(item, index) in swipelist"
          :id="item.id"
          :key="item.id + index + index"
          @click="clickswipe(item)"
        >
          <van-image width="100%" height="100%" :src="item.logo || imgExample" :alt="item.title" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 通知公告 -->
    <div class="part-3" v-if="noticeName">
      <div class="part-3-box">
        <div @click="goNotice('notice')">{{ noticeName }}</div>
        <a @click="toNewsDetail(notice, '资讯')"> {{ notice.title }}</a>
      </div>
    </div>
    <!-- 菜单 -->
    <div class="part-4">
      <ul v-if="catalogList.length > 0">
        <li v-for="(item, index) in catalogList" :key="index" :style="{ 'margin-bottom': index > 3 ? '0' : '0.52rem' }">
          <a @click="gotoCatalog(item)">
            <img :src="item.icon ? item.icon : `/images/index/menu-icon0${index + 1}.png`" />
            <span>{{ item.name }}</span>
          </a>
        </li>
      </ul>
      <div v-else class="not-data">暂无数据</div>
    </div>
    <div class="parts-box">
      <div class="part-title" v-if="liveList.length > 0 || liveHot.ID > 0">
        <span>热门直播</span>
        <!--        <a href="https://www.baidu.com">测试入口</a>-->
        <router-link to="/broadcast">更多<van-icon name="arrow"/></router-link>
      </div>
      <!-- 热门直播 -->
      <div class="part-5 part-card" v-if="liveList.length > 0 || liveHot.ID > 0">
        <template>
          <div
            class="live-card"
            :style="{ 'background-image': 'url(' + liveHot.logoFile + ')' }"
            @click="hrefPage(liveHot)"
          >
            <div class="live-status">{{ liveHot.liveStatusName }}</div>
            <!-- <div class="live-title">
              <h5>{{liveHot.name}}</h5>
              <span>{{liveHot.speaker}}</span>
              <span>{{liveHot.startTime}}</span>
            </div> -->
          </div>
          <div class="live-list">
            <ul v-for="item in liveList" :key="item.ID" @click="hrefPage(item)">
              <li>
                <div class="img-box">
                  <img :src="item.logoFile" />
                </div>
              </li>
              <li>
                <h5 class="line-ellipsis">{{ item.name }}</h5>
                <span>{{ item.speaker }}</span>
                <span>{{ item.startTime }}</span>
              </li>
              <li>
                <p style="margin: 0.66rem 0 0 0; font-size: 0.42rem; font-weight: bold;" v-if="item.isFree === '1'">
                  免费
                </p>
                <template v-else-if="item.hasPermission !== 'Y'">
                  <p style="margin: 0.66rem 0 0 0; font-size: 0.42rem; font-weight: bold;">
                    ￥{{ isVip ? item.memberPrice : item.sellPrice }}
                  </p>
                  <p style="margin: 0; font-size: 0.42rem; text-decoration:line-through; color: #999" v-if="isVip">
                    ￥{{ item.sellPrice }}
                  </p>
                </template>
                <p v-else style="margin: 0.66rem 0 0 0; font-size: 0.42rem; font-weight: bold; color: #1989fa">
                  进入学习
                </p>
              </li>
            </ul>
          </div>
        </template>
      </div>
      <!-- 热门课程推荐 -->
      <div class="part-6 part-card">
        <div class="part-title">
          <span>热门课程推荐</span>
          <router-link to="/allCourses/classifyCourseslist?type=1">更多<van-icon name="arrow"/></router-link>
        </div>
        <div class="course-list">
          <template v-if="recommendList.length > 0">
            <ul v-for="item in recommendList" :key="item.ID" @click="gotoCoursePage(item)">
              <li>
                <div class="img-box">
                  <img :src="item.logoFile" />
                </div>
              </li>
              <li>
                <h5 class="line-ellipsis">{{ item.name }}</h5>
                <h6 class="line-ellipsis" v-html="item.description || '暂无描述'"></h6>
                <span>{{ item.speaker }}</span>
                <div class="price" style="display: flex; align-items: center; justify-content: space-between;">
                  <div v-if="item.isForbid === 0">
                    <span v-if="item.isFree === '1'" style="font-size: 0.37rem;">免费</span>
                    <span v-else-if="item.hasPermission !== 'Y'"
                      >￥{{ isVip ? item.memberPrice : item.sellPrice }}</span
                    >
                    <span v-else style="color: #1989fa; font-size: 0.37rem;">进入学习</span>
                    <span v-if="item.hasPermission !== 'Y' && isVip" style="text-decoration: line-through;"
                      >￥{{ item.sellPrice }}</span
                    >
                    <span v-else></span>
                  </div>
                  <div v-else>
                    <span v-if="item.isFree === '1'" style="font-size: 0.37rem;">免费</span>
                    <span v-else-if="item.hasPermission !== 'Y'">会员专享</span>
                    <span v-else style="color: #1989fa; font-size: 0.37rem;">进入学习</span>
                  </div>
                  <span class="count">{{ item.viewCount }}</span>
                </div>
              </li>
            </ul>
          </template>
          <div v-else class="not-data">暂无数据</div>
        </div>
      </div>
      <!-- 精品系列课程 -->
      <div class="part-7 part-card">
        <div class="part-title">
          <span>精品系列课程</span>
          <router-link to="/allCourses/classifyCourseslist?type=2">更多<van-icon name="arrow"/></router-link>
        </div>
        <div class="boutique-list">
          <template v-if="excellentList.length > 0">
            <ul
              v-for="item in excellentList"
              :key="item.ID"
              @click="gotoCoursePackDetail(`/allCourses/packDetails/${item.ID}`)"
            >
              <li>
                <div class="img-box">
                  <img :src="item.logoFile" />
                </div>
              </li>
              <li>
                <div class="title">
                  <h5>{{ item.name }}</h5>
                  <span>{{ item.viewCount }}</span>
                </div>
                <h6 class="line-ellipsis" v-html="item.description || '暂无描述'"></h6>
                <div class="price">
                  <span v-if="item.hasPermission !== 'Y'">￥{{ isVip ? item.memberPrice : item.sellPrice }}</span>
                  <span v-else style="color: #1989fa">进入学习</span>
                  <span v-if="item.hasPermission !== 'Y' && isVip">￥{{ item.sellPrice }}</span>
                  <span v-else></span>
                </div>
              </li>
            </ul>
          </template>
          <div v-else class="not-data">暂无数据</div>
        </div>
      </div>
      <!-- 名师风采 -->
      <div class="part-8 part-card">
        <div class="part-title">
          <span>名师风采</span>
          <router-link :to="{ name: 'teacher' }">更多<van-icon name="arrow"/></router-link>
        </div>
        <ul class="teacher-list">
          <li v-for="item in teacherList" :key="item.ID" @click="toTeacherDetail(item)">
            <img :src="item.logoFile" />
            <div>
              <div class="teacher-title">{{ item.name }}</div>
              <span class="line-ellipsis">{{ item.school }}</span>
            </div>
          </li>
        </ul>
      </div>
      <!-- 资讯链接 -->
      <div class="part-9 part-card">
        <div class="part-title">
          <span>{{ newName }}</span>
          <router-link :to="{ name: 'news' }">更多<van-icon name="arrow"/></router-link>
        </div>
        <ul class="teacher-list">
          <li v-for="item in tablist" :title="item.title" :key="item.articleId" @click="toNewsDetail(item)">
            <img :src="item.img || '/images/index/information.png'" :alt="item.title" />
            <div>
              <span class="line-ellipsis">{{ item.title }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 客服 -->
    <div class="customer-service" @click="toCustomerService">
      <img src="images/index/customer-service.png" alt="客服" />
      <span>客服</span>
    </div>
    <van-dialog v-model="show" title="提示" show-cancel-button @cancel="show = false" @confirm="gotoPay">
      <p style="text-align: center;">此直播未购买，是否购买</p>
    </van-dialog>
  </div>
</template>

<script>
import './index.less'
export default {
  name: 'login',
  data() {
    return {
      item: {},
      show: false,
      mesCount: {},
      searchValue: '', // 搜索关键字
      imgExample: '/images/index/examples/examples02.png', // 示例图片
      tablist: [],
      newName: '资讯',
      notice: {},
      noticeName: '通知公告',
      catalogList: [], // 分类
      swipelist: [], //轮播图
      liveHot: {}, //直播中
      liveList: [], //直播
      teacherList: [], // 名师风采列表
      recommendList: [], // 热门课程推荐
      excellentList: [], // 精品系列课程
      isVip: true,
      isBack: '',
    }
  },
  created() {
    // this.isBack = this.$route.query.isBack
    // if (this.isBack && this.isBack === 'Y') {
    //   history.pushState(null, null, document.URL)
    //   window.addEventListener('popstate', this.pushHistory, false)
    // }
  },
  methods: {
    // pushHistory() {
    //   history.pushState(null, null, document.URL)
    //   this.$router.push({path: '/'})
    // },
    gotoCatalog(item) {
      if (item.type === 1) {
        window.location.href = item.customUrl
        return
      }
      let path = `/allCourses/index?id=${item.ID}&innerCode=${item.innerCode}`
      if (item.isLeaf) {
        if (item.treeLevel > 2) {
          path = `/allCourses/CourseList/${item.ID}?catalogInnercode=${item.innerCode}`
        }
      } else {
        if (item.treeLevel > 5) {
          path = `/allCourses/CatalogList/${item.ID}?catalogInnercode=${item.innerCode}`
        }
      }
      this.$router.push({ path: path })
    },
    gotoCoursePage(item) {
      let path = `/allCourses/details/${item.ID}/${'curriculum'}`
      if (item.type !== 0) {
        path = `/allCourses/courseHandout/${item.ID}`
      } else {
        path = path + '?backUrl=' + this.$router.history.current.fullPath
      }
      // let temp = this.$router.history.current.fullPath
      // localStorage.back_url = temp
      this.gotoPage(path)
    },
    gotoNotice() {
      let path = '/user/notice'
      if (this.mesCount.questionCount >= this.mesCount.messageCount) {
        path = '/user/notice?activeIndex=0'
      } else if (this.mesCount.questionCount < this.mesCount.messageCount) {
        path = '/user/notice?activeIndex=1'
      }
      this.$router.push({
        path: path,
      })
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `云上麓辰`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    // 获取轮播图
    getSwipe() {
      let _this = this
      this.$axios({
        url: '/json/index.json',
        methods: 'GET',
        params: {},
      }).then((res) => {
        if (res.status == 200) {
          _this.swipelist = res.data.data.lbt
        }
      })
    },
    //轮播图点击
    clickswipe(item) {
      let _this = this
      let swipeobj = Object.assign({}, item)
      if (item.url.indexOf('json') != -1) {
        if (item.url.indexOf('.json') != -1) {
          // 直接跳转详情
          swipeobj.artUrl = item.url
          _this.toNewsDetail(swipeobj)
        } else {
          // 跳转列表
          _this.$router.push({
            name: 'news',
          })
        }
      } else {
        window.location.href = item.url
      }
    },
    // 获取通知数据
    getNoticeData() {
      let _this = this
      this.$axios({
        url: '/json/tzgg/index.json',
        methods: 'GET',
        params: {},
      })
        .then((res) => {
          if (res.data.status === 1) {
            if (res.data.data && res.data.data.length > 0) {
              _this.noticeName = res.data.name
              _this.notice = res.data.data[0]
            } else {
              _this.noticeName = ''
            }
          } else {
            this.$Toast.fail(res.data.message || '服务器异常')
          }
        })
        .catch((e) => {
          _this.noticeName = ''
          return e
        })
    },
    // 获取资讯数据
    getNewlistData() {
      let _this = this
      this.$axios({
        url: '/json/xwzx/index.json',
        methods: 'GET',
        params: {},
      }).then((res) => {
        if (res.status == 200) {
          _this.newName = res.data.name
          _this.tablist = res.data.data.slice(0, 3)
        }
      })
    },
    // 跳到通知页面
    goNotice(urlname) {
      // notice
      this.$router.push({
        name: 'news',
        query: {
          name: urlname,
        },
      })
    },
    // 获取分类数据
    getCatalogLis() {
      this.$axios
        .get('/course/front/permit/catalog')
        .then((res) => {
          if (res.data.status === 1) {
            this.catalogList = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 直播数据
    getLiveList() {
      this.$axios
        .get('/course/front/permit/live?pageSize=3&pageIndex=0')
        .then((res) => {
          if (res.data.status === 1) {
            this.liveList = res.data.data.data.length > 2 && res.data.data.data.splice(1, res.data.data.data.length)
            this.liveHot = res.data.data.data.length > 0 && res.data.data.data[0]
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 获取热门课程推荐数据
    getRecommendList() {
      this.$axios
        .get('/course/front/permit/course/recommend')
        .then((res) => {
          if (res.data.status === 1) {
            this.recommendList = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 获取精品系列课程数据
    getExcellentList() {
      this.$axios
        .get('/course/front/permit/coursepackage/excellent')
        .then((res) => {
          if (res.data.status === 1) {
            this.excellentList = res.data.data ? res.data.data.splice(0, 3) : []
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 用户未读消息总数
    getMessagehandle() {
      this.$axios
        .get('/course/front/messagehandle/unread')
        .then((res) => {
          if (res.data.status === 1) {
            this.mesCount = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    gotoPay() {
      this.show = false
      this.gotoPage(`/allCourses/order/${this.item.ID}?type=LIVE`)
    },
    gotoCoursePackDetail(path) {
      let temp = this.$router.history.current.fullPath
      localStorage.course_pack_source = temp
      this.gotoPage(path)
    },
    // 跳转至直播外链
    hrefPage(item) {
      this.$router.push({
        path: `/allCourses/listDetails/${item.ID}`,
      })
      // if (item.hasPermission === 'N') {
      //   this.item = item
      //   return this.show = true
      // } else {
      //   let token = localStorage.token ? localStorage.token.replace('Bearer+', 'Bearer ') : ''
      //   window.location.href = `${process.env.VUE_APP_URL}/course/front/live/${item.ID}/log/coupons?token=${token}`
      // }
    },
    // 跳转至搜索页
    toSearchPage() {
      localStorage.search_page_source = this.$router.history.current.fullPath
      this.$router.push({
        name: 'search',
      })
    },
    // 跳转至客服
    toCustomerService() {
      this.$router.push({
        name: 'customerService',
      })
    },
    // 跳转至资讯详情
    toNewsDetail(item, itemtype) {
      if (item.artUrl.indexOf('.json') >= 0) {
        let artUrl = item.artUrl.substring(item.artUrl.search(/json/))
        this.$router.push({
          name: 'newsDetail',
          params: {
            url: artUrl,
          },
          query: {
            itemtype: itemtype,
          },
        })
      } else {
        window.location.href = item.artUrl
      }
    },
    // 获取名师风采列表数据
    getTeachList() {
      this.$axios
        .get('/course/front/permit/speaker/famous')
        .then((res) => {
          if (res.data.status === 1) {
            this.teacherList = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 跳转至名师详情页
    toTeacherDetail(item) {
      this.$router.push({
        name: 'teacherDetail',
        params: {
          id: item.ID,
        },
      })
    },
    // 页面跳转
    gotoPage(path) {
      this.$router.push({ path: path })
    },
  },
  destroyed() {
    // window.removeEventListener('popstate', this.pushHistory, false)
  },
  activated() {
    this.show = false
    this.getNewlistData()
    this.getNoticeData()
    this.getSwipe()
    this.getLiveList()
    this.getCatalogLis()
    this.getRecommendList()
    this.getExcellentList()
    this.getTeachList()
    this.getMessagehandle()
    this.setShare()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = Number(info.isVip) && (info.enterpriseName || info.enterpriseShorthand)
    }
  },
}
</script>
<style scoped>
.home-page >>> .van-search__content--round {
  background-color: #f1f2f7;
}
</style>
