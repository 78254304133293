<template>
  <div>
    <zv-nav-bar :title="pagename">
      <span>提问</span>
    </zv-nav-bar>
    <div class="question-box">
      <van-field v-model="question" type="textarea" placeholder="请输入问题" />
    </div>
    <div class="bottom-btn-box" @click="submitQs">
      <van-button round block type="info" :loading="loading">提交问题</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'question',
  data() {
    return {
      pagename: 'W6-泌尿系统疾病和糖尿病',
      question: '',
      loading: false,
    }
  },
  created() {
    this.setShare()
    this.pagename = this.$route.query.title
  },
  methods: {
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    submitQs() {
      if (this.loading) {
        return
      }
      let courseId = this.$route.params.id
      if (!this.question) {
        return this.$Toast.fail('请输入问题')
      }
      this.loading = true
      let data = new FormData()
      data.append('videoID', '')
      data.append('content', this.question)
      this.$axios
        .post(`/course/front/course/${courseId}/askquestion`, data)
        .then((res) => {
          if (res.data.status === 1) {
            this.$Toast.success(res.data.message)
            setTimeout(() => {
              this.loading = false
              this.$Toast.clear()
              this.$router.replace({
                path: '/user/notice?activeIndex=' + 0,
              })
            }, 500)
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.loading = false
          return this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
  },
}
</script>
<style scoped>
.question-box {
  background-color: #eee;
  height: calc(100vh - 3.6rem);
  padding: 0.4rem;
}
.question-box >>> .van-cell {
  font-size: 0.37rem;
  line-height: 0.64rem;
  padding: 0.26rem 0.42rem;
}
.question-box >>> .van-field__control {
  height: 5.33rem;
  padding: 0.2133rem;
  background-color: #fff;
  font-size: 0.4rem;
  color: #222;
  font-weight: bold;
}
.bottom-btn-box {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 1.56rem;
  border: 1px solid #eee;
  background-color: #fff;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.58rem;
}
</style>
