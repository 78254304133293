<template>
  <div class="user-lrecord">
    <zv-nav-bar :title="pagename" :sourceType="sourceType"></zv-nav-bar>
    <van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <van-col span="24" v-for="item in buylist" :id="item.ID" :key="item.ID">
            <van-row class="buy-box" @click="goto(item)">
              <van-row class="box-left">
                <div class="left-img">
                  <van-image width="100%" height="100%" :src="item.logoFile" />
                </div>
              </van-row>
              <van-row class="box-centre">
                <van-row class="centre-title">
                  <div class="title-top">
                    <div :class="{'title-tag': item.enterpriseExclusive === 0, 'enterprise-tag': item.enterpriseExclusive !== 0}">{{item.resourceTypeName || '课程'}}</div>
                    <div class="title-name">
                      <span>{{item.name}}</span>
                    </div>
                  </div>
                  <div class="title-center">{{item.lastStudyTime}}</div>
                </van-row>
              </van-row>
              <van-row class="box-right">
                <div class="r-content">
                  <span>学习至</span>:<span class="r-schedule">{{item.studyPercent}}</span>
                </div>
              </van-row>
            </van-row>
          </van-col>
        </van-list>
      </van-pull-refresh>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "userlrecord",
  data() {
    return {
      refreshing: false,
      pagename: "学习记录",
      buylist: [],
      loading: false,
      finished: false,
      pageIndex: 0,
      isBack: '',
      sourceType: ''
    };
  },
  created() {
    this.setShare()
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      this.sourceType = 'lrecord'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    onRefresh() {
      this.buylist = []
      this.pageIndex = 0
      this.$nextTick(() => {
        this.finished = false
        this.loading = true
        this.onLoad()
      })
    },
    onLoad() {
      this.$axios.get(`/course/front/member/personalcenter/studyrecords?pageIndex=${this.pageIndex}`).then(res => {
        if (res.data.status === 1) {
          this.pageIndex = this.pageIndex + 1
          this.buylist = this.buylist.concat(res.data.data.data)
          this.loading = false
          if (this.buylist.length >= res.data.data.total) {
            this.finished = true
          }
          this.refreshing = false
        } else {
          this.loading = false
          this.finished = true
          this.refreshing = false
          this.$Toast.fail(res.data.message)
        }
      })
    },
    goto(item) {
      let path = `/allCourses/details/${item.ID}/curriculum`
      if (item.type === 1) {
        path = `/allCourses/courseHandout/${item.ID}`
      } else {
        path = path + "?backUrl=" + this.$router.history.current.fullPath
      }
      // let temp = this.$router.history.current.fullPath
      // localStorage.back_url = temp
      this.gotoPage(path)
    },
    gotoPage(path) {
      this.$router.push({path: path})
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  }
};
</script>

<style lang="less" scoped>
.user-lrecord {
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-bottom: 0.27rem;
  .buy-box {
    font-size: 0.32rem;
    height: 1.8rem;
    margin: 0.27rem 0.33rem 0 0.33rem;
    background-color: #ffffff;
    border-radius: 0.13rem;
    display: flex;
    img {
      width: 100%;
    }
    .box-left {
      width: 1.52rem;
      display: flex;
      justify-content: center;
      margin-top: 0.35rem;
      .left-img {
        width: 1.04rem;
        height: 1.04rem;
        background-color: #d2d2d2;
        border-radius: 0.11rem;
        /deep/ img {
          object-fit: cover;
        }
      }
    }
    .box-centre {
      flex: 1;
      .centre-title {
        margin-top: 0.39rem;
        .title-top {
          display: flex;
          .title-name {
            font-family: PingFang-SC-Medium;
            font-size: 0.4rem;
            font-weight: bold;
            font-stretch: normal;
            margin-left: 0.13rem;
            letter-spacing: 0rem;
            color: #444444;
            width: 4.26rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .title-tag {
            width: 0.7rem;
            height: 0.45rem;
            background-color: #ffffff;
            border-radius: 0.08rem;
            border: solid 0.01rem #fbc047;
            background-color: #fbc047;
            font-family: PingFang-SC-Medium;
            font-size: 0.27rem;
            font-weight: normal;
            font-stretch: normal;
            line-height: 0.45rem;
            letter-spacing: 0rem;
            color: #fff;
            margin-top: 0.06rem;
            text-align: center;
          }
          .enterprise-tag {
            width: 0.7rem;
            height: 0.45rem;
            background-color: #ffffff;
            border-radius: 0.08rem;
            border: solid 0.01rem #70dd96;
            background-color: #70dd96;
            font-family: PingFang-SC-Medium;
            font-size: 0.27rem;
            font-weight: normal;
            font-stretch: normal;
            line-height: 0.45rem;
            letter-spacing: 0rem;
            color: #fff;
            margin-top: 0.06rem;
            text-align: center;
          }
        }
        .title-center {
          font-family: PingFang-SC-Regular;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #999999;
          margin-top: 0.04rem;
        }
        .title-bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 0.24rem;
          .bottom-l {
            width: 1.6rem;
            height: 0.48rem;
            background-color: #f6f7f9;
            border-radius: 0.05rem;
            font-family: PingFang-SC-Regular;
            font-size: 0.27rem;
            line-height: 0.48rem;
            color: #b1b1b1;
            text-align: center;
          }
          .bottom-r {
            width: 4rem;
            height: 0.27rem;
            background-color: #eeeeee;
            border-radius: 0.13rem;
            margin-top: 0.1rem;
            .progress {
              height: 0.27rem;
              background-color: #d5af82;
              border-radius: 0.1rem;
            }
          }
        }
      }
    }
    .box-right {
      width: 2.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .r-content {
        font-family: PingFang-SC-Regular;
        font-size: 0.35rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #999999;
        .r-schedule {
          color: #3fa1fd;
        }
      }
    }
  }
}
</style>
