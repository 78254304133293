<template>
  <div class="classify-courses-list">
    <zv-nav-bar :title="title" :sourceType="sourceType"></zv-nav-bar>
    <van-list v-model="loading" :finished="finished" finished-text="" v-if="type === '1'">
      <div class="classify-courses-list-content">
        <template v-if="listData.length > 0">
          <div v-for="(item, index) in listData" :key="index" :class="{ container: item.alias, curriculum: !item.alias }">
            <div class="logo" @click="hrefPage(item)">
              <img :src="item.logoFile" />
            </div>
            <div class="details reset" @click="hrefPage(item)">
              <div class="title">
                <span class="tag">{{ type === '1' ? (item.type === 0 ? '视频' : '讲义') : '系列课' }}</span>
                <span class="title-column" :style="{ width: type === '1' ? '4.7rem' : '4.2rem' }">{{ item.name }}</span>
                <template v-if="item.isForbid === 0">
                  <span class="current-price" v-if="item.isFree === '1'">免费</span>
                  <span v-else class="current-price" :style="{ color: item.hasPermission === 'Y' ? '#1989fa' : '' }">{{
                    item.hasPermission === 'Y' ? '进入学习' : '￥' + (isVip ? item.memberPrice : item.sellPrice)
                  }}</span>
                </template>
                <template v-else>
                  <span class="current-price" v-if="item.isFree === '1'">免费</span>
                  <span v-else class="current-price" :style="{ color: item.hasPermission === 'Y' ? '#1989fa' : '' }">{{ item.hasPermission === 'Y' ? '进入学习' : '会员专享' }}</span>
                </template>
              </div>
              <div class="details-item">
                <span class="duration" v-if="type === '1'">时长{{ item.totalLength }}</span>
                <span class="count" v-if="type === '1'">{{ item.viewCount }}</span>
                <span class="old-price" :style="{ width: type === '1' ? '2.93rem' : '7.46rem' }">
                  <template v-if="item.isForbid === 0">
                    <span class="bg-content" v-if="item.hasPermission !== 'Y' && isVip">￥{{ item.sellPrice }}</span>
                  </template>
                </span>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="not-data">
          <span>暂无数据</span>
        </div>
      </div>
    </van-list>
    <van-list v-model="loading" :finished="finished" finished-text="" @load="getCoursePackData" v-else>
      <div class="classify-courses-list-content">
        <template v-if="listData.length > 0">
          <div v-for="(item, index) in listData" :key="index" :class="{ container: item.alias, curriculum: !item.alias }">
            <div class="logo" @click="hrefPage(item)">
              <img :src="item.logoFile" />
            </div>
            <div class="details reset" @click="hrefPage(item)">
              <div class="title">
                <span class="tag">{{ type === '1' ? (item.type === 0 ? '视频' : '讲义') : '系列课' }}</span>
                <span class="title-column" :style="{ width: type === '1' ? '4.7rem' : '4.2rem' }">{{ item.name }}</span>
                <span class="current-price" v-if="item.isFree === '1'">免费</span>
                <span v-else class="current-price" :style="{ color: item.hasPermission === 'Y' ? '#1989fa' : '' }">{{
                  item.hasPermission === 'Y' ? '进入学习' : '￥' + (isVip ? item.memberPrice : item.sellPrice)
                }}</span>
              </div>
              <div class="details-item">
                <span class="duration" v-if="type === '1'">时长{{ item.totalLength }}</span>
                <span class="count" v-if="type === '1'">{{ item.viewCount }}</span>
                <span class="old-price" :style="{ width: type === '1' ? '2.93rem' : '7.46rem' }">
                  <span class="bg-content" v-if="item.hasPermission !== 'Y' && isVip">￥{{ item.sellPrice }}</span>
                </span>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="not-data">
          <span>暂无数据</span>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  name: 'classifyCourseslist',
  props: {},
  data() {
    return {
      loading: false,
      finished: false,
      type: this.$route.query.type,
      listData: [],
      pageIndex: 0,
      isVip: true,
      isBack: '',
      sourceType: '',
      bool: true,
    }
  },
  computed: {
    title() {
      let title = this.type === '1' ? '热门课程' : '精品课程'
      return title
    },
  },
  created() {
    if (this.type === '1') {
      this.getCourseData()
    }
    this.setShare()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = info.isVip && (info.enterpriseName || info.enterpriseShorthand) ? true : false
    }
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      this.sourceType = 'classifyCoursesList'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  mounted() {
    setTimeout(() => {
      this.bool = false
    }, 1000)
    // window.addEventListener('popstate', this.routerPush, true)
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    routerPush() {
      if (this.bool) {
        return
      }
      if (this.isBack === 'Y') {
        window.removeEventListener('popstate', this.routerPush, true)
        this.$router.push({
          path: '/',
        })
      } else {
        if (window.history.length >= 2 && window.location.href.indexOf('isLoged') === -1) {
          if (window.location.href.indexOf('isShare') === -1) {
            this.$router.go(-1)
          } else {
            this.$router.push({
              path: '/',
            })
          }
        } else {
          this.$router.push({
            path: '/',
          })
        }
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.title}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    hrefPage(item) {
      if (this.type === '1') {
        let path = `/allCourses/details/${item.ID}/curriculum`
        if (item.type !== 0) {
          path = `/allCourses/courseHandout/${item.ID}`
        } else {
          path = path + '?backUrl=' + this.$router.history.current.fullPath
        }
        // let temp = this.$router.history.current.fullPath
        // localStorage.back_url = temp
        this.gotoPage(path)
      } else {
        let temp = this.$router.history.current.fullPath
        localStorage.course_pack_source = temp
        this.gotoPage(`/allCourses/packDetails/${item.ID}`)
      }
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    getCourseData() {
      this.$axios
        .get(`/course/front/permit/course/more?pageSize=9999&pageIndex=${this.pageIndex}&type=${this.type}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.listData = res.data.data.data
            this.finished = true
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    getCoursePackData() {
      this.$axios
        .get(`/course/front/permit/coursepackage/more?pageSize=10&pageIndex=${this.pageIndex}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.pageIndex = this.pageIndex + 1
            this.listData = this.listData.concat(res.data.data.data)
            this.loading = false
            if (this.listData.length >= res.data.data.total) {
              this.finished = true
            }
          } else {
            this.$Toast.fail(res.data.message)
            this.finished = true
            this.loading = false
          }
        })
        .catch((e) => {
          this.loading = false
          this.finished = true
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
  },
  beforeDestroy() {
    // window.removeEventListener('popstate', this.routerPush, true)
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>

<style scoped>
.classify-courses-list >>> .van-icon.van-icon-search {
  font-size: 0.37rem;
}
.classify-courses-list-content {
  padding: 0.33rem;
  min-height: calc(100vh - 1.86rem);
  background-color: #eee;
}
.container {
  line-height: 0.96rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.08rem 0.13rem 0rem rgb(207 207 207 / 20%);
  box-sizing: border-box;
  margin-bottom: 0.03rem;
}
.container .item {
  border-radius: 0.13rem;
  border: solid 0.01rem #f5f5f5;
  padding-left: 0.29rem;
}
.classification {
  font-size: 0.4rem;
  color: #333333;
  font-weight: 600;
  display: inline-block;
  width: 5.49rem;
}
.count {
  color: #bebebe;
  font-size: 0.32rem;
}
.separator {
  margin: 0 0.16rem;
}
.buy {
  color: #3fa1fd;
  font-size: 0.32rem;
  position: relative;
  width: 1.2rem;
  display: inline-block;
}
.buy i {
  font-size: 0.34rem;
  position: absolute;
  top: 0.32rem;
}
.arrow-btn {
  position: relative;
  width: 0.53rem;
  display: inline-block;
  height: 0.32rem;
}
.arrow-btn i {
  position: absolute;
  font-size: 0.42rem !important;
  top: 0;
  color: #bebebe;
}
.arrow-btn i.van-icon-arrow-up {
  position: absolute;
  font-size: 0.42rem !important;
  top: 0;
  color: #3fa1fd;
}
.item-list {
  height: 1.68rem;
  background-color: #ffffff;
  box-shadow: 0 0.08rem 0.13rem 0 rgb(207 207 207 / 20%);
  border-radius: 0.13rem;
  border: solid 0.01rem #f5f5f5;
  line-height: 1.68rem;
  padding: 0.32rem 0.25rem;
  box-sizing: border-box;
  display: flex;
  margin: 0.17rem 0 0.27rem 0;
}
.logo {
  width: 1.04rem;
  height: 1.04rem;
  margin-left: 0.32rem;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.curriculum .logo {
  margin-left: 0;
}
.details {
  width: 6.56rem;
  margin-left: 0.19rem;
}
.details .title {
  height: 0.36rem;
  line-height: 0.48rem;
  font-weight: bold;
  color: #444;
  font-size: 0.37rem;
}
.details .details-item {
  height: 0.69rem;
  line-height: 1.12rem;
  color: #bebebe;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
}
.details .details-item .details-item-icon {
  top: 0.4rem;
}
.item-list-icon i {
  font-size: 0.42rem;
  color: #bebebe;
  vertical-align: super;
}
.curriculum {
  height: 1.68rem;
  background-color: #ffffff;
  box-shadow: 0 0.08rem 0.13rem 0 rgb(207 207 207 / 20%);
  border-radius: 0.13rem;
  border: solid 0.01rem #f5f5f5;
  line-height: 1.68rem;
  padding: 0.32rem 0.25rem;
  box-sizing: border-box;
  display: flex;
  margin: 0.17rem 0 0.27rem 0;
  padding-right: 0;
}
.tag {
  font-size: 0.32rem;
  display: inline-block;
  border: 1px solid #fbc047;
  background-color: #fbc047;
  border-radius: 0.11rem;
  padding: 0 1px;
  color: #fff;
  margin-right: 0.13rem;
}
.details.reset {
  width: 7.73rem;
}
.title-column {
  display: inline-block;
  width: 4.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.current-price {
  text-align: right;
  width: 2.08rem;
  display: inline-block;
  padding-right: 0.05rem;
}
.duration {
  background-color: #f6f7f9;
  border-radius: 2px;
  padding: 2px;
  /* line-height: 0.53rem; */
  line-height: 0.32rem;
  text-align: center;
  margin-top: 0.26rem;
}
.old-price {
  text-align: right;
  width: 2.93rem;
  display: inline-block;
  padding-right: 0.05rem;
}
.bg-content {
  background-color: #f6f7f9;
  border-radius: 2px;
  text-decoration: line-through;
}
.not-data {
  display: flex;
  justify-content: center;
  height: calc(100vw);
  line-height: calc(100vw);
  color: #999;
}
</style>
