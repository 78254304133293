<template>
  <div>
    <div class="classinfo" v-if="!classListIsShow">
      <zv-nav-bar :title="classteacher.name" :sourceType="sourceType" :left-arrow="true" :clickLeft="clickLeft" />
      <van-row>
        <!--  <van-col span="24" class="user-box user-info">
          <div class="info-img">
            <van-image round width="100%" height="100%" :src="classteacher.logoSrc" />
          </div>
          <div class="info-conter">
            <div class="info-name">
              <span>班主任:{{ classteacher.header }}</span>
              <span class="change-class" @click="classListIsShow = true">
                <span class="change-ring">
                  <van-icon class="r-icon" name="exchange" />
                </span>
                <span>切换班级</span>
              </span>
            </div>
            <div class="info-enterprise" v-html="classteacher.description"></div>
          </div>
        </van-col> -->
        <div class="classinfo-baseInfo" v-if="classteacher.logoSrc">
          <van-image width="100%" :src="classteacher.logoSrc" class="classinfo-baseInfo__logo" />
        </div>

        <van-col span="24" class="list-infobox">
          <template v-if="classteacher.description">
            <van-col span="24" class="list-title">
              <div class="title-l">
                <img
                  :src="require('/public/images/classDetails/classDetails-icon1.png')"
                  class="list-item__icon"
                  style="width:0.49rem;height:0.49rem"
                />
                <span class="list-item__label">介绍</span>
              </div>
            </van-col>
            <van-col class="list-card" span="24">
              <div class="list-card-content" v-html="classteacher.description"></div>
            </van-col>
          </template>

          <template v-if="courseList.length">
            <van-col span="24" class="list-infobox">
              <van-col span="24" class="list-title">
                <div class="title-l">
                  <img
                    :src="require('/public/images/classDetails/classDetails-icon2.png')"
                    class="list-item__icon"
                    style="width:0.44rem;height:0.4rem"
                  />
                  <span class="list-item__label">课程</span>
                </div>
                <div
                  class="title-r"
                  @click="
                    gotoPage(
                      `/nzzone/classcourses?id=${classId || $route.query.id}&title=${handleName(
                        classteacher.name
                      )}&showLive=${classteacher.enterpriseID === 0}`
                    )
                  "
                >
                  <span class="r-more">
                    <span class="r-more__text">全部课程</span>
                    <van-icon name="arrow" />
                  </span>
                </div>
              </van-col>
              <van-col span="24" class="list-info">
                <template v-if="courseList.length > 0">
                  <van-col span="24" v-for="item in courseList" :id="item.ID" :key="item.ID" style="margin: 0.13rem 0">
                    <div class="course-box" @click="goto(item)">
                      <van-image :src="item.logoFile" class="courseItem__logo" />

                      <div class="course-rightFrame">
                        <!-- <span :class="{ 'title-tag': true, 'enterprise-tag': item.enterpriseExclusive === 1 }">{{ item.resourceTypeName }}</span> -->
                        <span class="title-name van-multi-ellipsis--l2">{{ item.name }}</span>
                        <span class="course-speaker">{{ item.speaker }}</span>
                        <span class="course-speaker">{{ item.viewCount }}</span>
                        <!-- <span class="title-type" v-if="item.isCompulsory !== '选修'">{{ item.isCompulsory }}</span> -->
                      </div>
                      <!--                 <div class="course-bottom" v-if="item.deadline">{{ item.isFree === 1 ? '截止至' + item.deadline : item.deadline }}</div>
                    <div class="course-bottom" v-else>暂无时间</div> -->

                      <!-- <div class="course-tips " :class="resolvetype(item.studyStatus)">{{ item.studyStatusName }}</div> -->
                    </div>
                  </van-col>
                  <div class="course-add" @click="getCourseList" v-if="!courseDatafinished && !loadCourseLoading">
                    加载更多课程
                  </div>
                  <div class="course-add" @click="getCourseList" v-else-if="!courseDatafinished && loadCourseLoading">
                    正在加载...
                  </div>
                  <div class="course-add" v-else-if="courseDatafinished && courseList.length">
                    没有更多了
                  </div>
                </template>
                <div v-else class="not-data">暂无数据</div>
              </van-col>
            </van-col>
          </template>

          <template v-if="examList.length">
            <van-col span="24" class="list-title">
              <div class="title-l">
                <img
                  :src="require('/public/images/classDetails/classDetails-icon3.png')"
                  class="list-item__icon"
                  style="width:0.43rem;height:0.48rem"
                />
                <span class="list-item__label">考试</span>
              </div>
              <div
                class="title-r"
                @click="
                  gotoPage(
                    `/nzzone/classcourses?id=${classId || $route.query.id}&type=3&title=${handleName(
                      classteacher.name
                    )}&showLive=${classteacher.enterpriseID === 0}`
                  )
                "
              >
                <span class="r-more">
                  <span class="r-more__text">更多</span>
                  <van-icon name="arrow" />
                </span>
              </div>
            </van-col>
            <van-col span="24" class="list-info">
              <template>
                <div class="info-box" v-for="item in examList" :key="item.ID" @click="goAnswer(item)">
                  <div
                    :class="{
                      'info-type': true,
                      shenhe: handleExamCompleteStatus(item.isExamined, item.isOverExam, item.status).value === 3,
                      wancheng: handleExamCompleteStatus(item.isExamined, item.isOverExam, item.status).value === 2,
                    }"
                  >
                    {{ handleExamCompleteStatus(item.isExamined, item.isOverExam, item.status).label }}
                  </div>
                  <div class="type-name">{{ item.name }}</div>
                  <div class="type-time">
                    <template v-if="item.isExamined === 'Y' && item.status === 'Y'">
                      <span>时间：</span>
                      <span>{{ item.answerEndTime || '--' }}</span>
                    </template>

                    <template v-else>
                      <span>时间：</span>
                      <span>{{ item.timeRange }}</span>
                    </template>
                  </div>
                  <div class="type-time" style="margin-top:0">
                    <span v-if="handleExamCompleteStatus(item.isExamined, item.isOverExam, item.status).value === 2"
                      >用时: {{ item.elapsedTime }}</span
                    >
                    <span v-else>时长: {{ item.time }}</span>
                    分钟
                  </div>
                  <div
                    v-if="handleExamCompleteStatus(item.isExamined, item.isOverExam, item.status).value === 1"
                    class="btn-todo"
                  >
                    <span>立即</span>
                    <span>考试</span>
                  </div>
                  <div
                    v-else-if="handleExamCompleteStatus(item.isExamined, item.isOverExam, item.status).value === 3"
                    class="btn-todo btn-todo--notScore"
                  >
                    <span>暂无</span>
                    <span>分数</span>
                  </div>
                  <div
                    class="btn-score"
                    v-else-if="handleExamCompleteStatus(item.isExamined, item.isOverExam, item.status).value === 2"
                  >
                    <span class="btn-score__text">分数</span>
                    <span class="btn-score__score" style="margin-right: 0.12rem;">{{ item.mark || 0 }}</span>
                  </div>
                </div>
              </template>
            </van-col>
          </template>

          <template v-if="liveList.length">
            <van-col span="24" class="list-infobox" v-if="classteacher.enterpriseID === 0">
              <van-col span="24" class="list-title">
                <div class="title-l">
                  <img
                    :src="require('/public/images/classDetails/classDetails-icon4.png')"
                    class="list-item__icon"
                    style="width:0.44rem;height:0.43rem"
                  />
                  <span class="list-item__label">直播</span>
                </div>
                <div
                  class="title-r"
                  @click="
                    gotoPage(
                      `/nzzone/classcourses?id=${classId || $route.query.id}&type=2&title=${handleName(
                        classteacher.name
                      )}&showLive=${classteacher.enterpriseID === 0}`
                    )
                  "
                >
                  <span class="r-more">
                    <span class="r-more__text">更多</span>
                    <van-icon name="arrow" />
                  </span>
                </div>
              </van-col>
              <van-col span="24" class="list-info">
                <template v-if="liveList.length > 0">
                  <van-col span="24" v-for="item in liveList" :id="item.ID" :key="item.ID" style="margin: 0.13rem 0">
                    <van-row class="course-box" @click="gotoPage(`/allCourses/listDetails/${item.ID}`)">
                      <img
                        :src="require('/public/images/classDetails/live--active.png')"
                        class="courseItem__icon"
                        v-if="item.liveStatus === liveStatusEnum.Living.value"
                      />
                      <img
                        :src="require('/public/images/classDetails/live.png')"
                        class="courseItem__icon"
                        v-else-if="item.liveStatus === liveStatusEnum.NoStart.value"
                      />

                      <van-image :src="item.logoFile" class="courseItem__logo" />

                      <div class="course-rightFrame">
                        <span class="title-name van-multi-ellipsis--l2">{{ item.name }}</span>

                        <span
                          class="course-speaker course-speaker--living"
                          v-if="item.liveStatus === liveStatusEnum.Living.value"
                          >{{ item.speaker || '老师' }} 正在直播</span
                        >

                        <span class="course-speaker" v-else>{{ item.speaker }}</span>

                        <div class="course-openTime">
                          <div class="course-openTime__tip" v-if="item.liveStatus === liveStatusEnum.NoStart.value">
                            开播
                          </div>
                          <div
                            class="course-openTime__tip"
                            v-else-if="item.liveStatus === liveStatusEnum.PlayBack.value"
                          >
                            回放
                          </div>
                          <div
                            class="course-openTime__tip course-openTime__tip--over"
                            v-else-if="item.liveStatus === liveStatusEnum.Over.value"
                          >
                            已结束
                          </div>
                          <span class="course-openTime__time">{{ item.liveTimeRange || item.liveStartTime }}</span>
                        </div>
                      </div>
                      <!-- <div class="course-tips " :class="resolvetype(item.studyStatus)">{{ item.studyStatusName }}</div> -->
                    </van-row>
                  </van-col>
                </template>
                <div v-else class="not-data">暂无数据</div>
              </van-col>
            </van-col>
          </template>

          <template v-if="worklist.length">
            <van-col span="24" class="list-title">
              <div class="title-l">
                <img
                  :src="require('/public/images/classDetails/classDetails-icon5.png')"
                  class="list-item__icon"
                  style="width:0.41rem;height:0.47rem"
                />
                <span class="list-item__label">作业</span>
              </div>
              <div
                class="title-r"
                @click="
                  gotoPage(
                    `/nzzone/classcourses?id=${classId || $route.query.id}&type=1&title=${handleName(
                      classteacher.name
                    )}&showLive=${classteacher.enterpriseID === 0}`
                  )
                "
              >
                <span class="r-more">
                  <span class="r-more__text">更多</span>
                  <van-icon name="arrow" />
                </span>
              </div>
            </van-col>
            <van-col span="24" class="list-info">
              <div
                class="info-box"
                v-for="item in worklist"
                :key="item.workID"
                @click="gotoPage(`/nzzone/workDetails/${item.workID}?classId=${classId}`)"
              >
                <div
                  :class="{
                    'info-type': true,
                    shenhe: item.status === 1,
                    wancheng: item.status === 2,
                    back: item.status === 3,
                  }"
                >
                  {{ item.statusName }}
                </div>
                <div class="type-name">{{ item.name }}</div>

                <div class="type-time" v-if="item.status === 0">
                  <span>提交时间：</span>
                  <span>{{ formatWorkDate(item.publishTime) }}</span
                  >-
                  <span>{{ formatWorkDate(item.endTime) }}</span>
                </div>
                <div class="type-time" v-else>
                  <span>提交时间：</span>
                  <span>{{ item.commitTime || '--' }}</span>
                </div>

                <div v-if="item.status === 0" class="btn-todo">做作业</div>
                <div v-if="item.status >= 3" class="btn-review">查看点评</div>
              </div>
            </van-col>
          </template>
        </van-col>
      </van-row>
    </div>
    <class-list v-if="classListIsShow" @classChange="onClassChange"></class-list>
  </div>
</template>

<script>
import { Dialog } from 'vant'

import ClassList from '@/page/nzzone/ClassInfo/ClassList.vue'
export default {
  name: 'classinfo',
  components: {
    'class-list': ClassList,
  },
  data() {
    this.liveStatusEnum = {
      NoStart: {
        label: '未开始',
        value: 'NoStart',
      },
      Living: {
        label: '直播中',
        value: 'Living',
      },
      PlayBack: {
        label: '可回放',
        value: 'PlayBack',
      },
      Over: {
        label: '已结束',
        value: 'Over',
      },
    }
    return {
      worklist: [],
      classteacher: {},
      courseList: [],
      liveList: [],
      examList: [],
      classListIsShow: false,
      classId: '',
      isBack: '',
      sourceType: 'classInfo',
      coursePageIndex: 0,
      loadCourseLoading: false,
    }
  },
  created() {
    this.classId = this.$route.query.id
    this.getdata()
    this.getLiveData()
    this.getWork()
    this.getExams()
    this.setShare()
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    clickLeft() {
      if (this.$route.query.qrCode || window.history.length === 1 || window.location.href.indexOf('isShare') !== -1) {
        this.$router.push('/study')
      } else {
        this.$router.back()
      }
    },
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    onClassChange(id) {
      if (id === -1) {
        this.classListIsShow = false
        return
      }
      this.classListIsShow = false
      this.getdata(id)
      this.getLiveData(id)
      this.getWork(id)
      this.classId = id
      this.$router.replace(`/nzzone/classinfo?id=${id}`)
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.classteacher.name}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    handleName(name) {
      return encodeURIComponent(name)
    },
    resolve(type) {
      return type == 0 ? '' : type == 1 ? 'shenhe' : 'wancheng'
    },
    resolvetype(studyStatus) {
      return studyStatus == 0 ? '' : studyStatus == 2 ? 'dianping' : studyStatus == 1 ? 'kaibo' : 'lianxi'
    },
    getdata(classId) {
      let id = classId || this.$route.query.id
      this.$axios
        .get(`/course/front/class/${id}`, {
          params: {
            qrCode: this.$route.query.qrCode,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            this.classteacher = res.data.data
            if (res.data.data.hasPermission !== 'Y') {
              this.$Toast.fail(res.data.message || '没有该班级权限，稍后将自动跳转回首页')
              const timer = setTimeout(() => {
                clearTimeout(timer)
                this.$router.replace({
                  path: '/',
                })
              }, 2000)
            } else {
              this.setShare()
            }
          } else {
            this.$Toast.fail(res.data.message)
            setTimeout(() => {
              this.$router.replace({
                path: '/',
              })
            }, 2000)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
      this.getCourseList()
    },
    getCourseList() {
      if (this.loadCourseLoading) return
      this.loadCourseLoading = true
      this.$axios
        .get(`/course/front/class/${this.$route.query.id}/course?pageIndex=${this.coursePageIndex}&pageSize=3`)
        .then((res) => {
          this.loadCourseLoading = false
          if (res.data.status === 1) {
            this.courseList = this.courseList.concat(res.data.data || [])
            if (!res.data.data || res.data.data.length === 0) {
              this.courseDatafinished = true
            }
            this.coursePageIndex++
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.loadCourseLoading = false
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    getLiveData(classId) {
      let id = classId || this.$route.query.id
      this.$axios
        .get(`/course/front/class/${id}/live?pageIndex=0&pageSize=3`)
        .then((res) => {
          if (res.data.status === 1) {
            this.liveList = res.data.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    getWork(classId) {
      let id = classId || this.$route.query.id
      this.$axios
        .get(`/course/front/class/${id}/work?pageIndex=0&pageSize=3`)
        .then((res) => {
          if (res.data.status === 1) {
            this.worklist = res.data.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },

    getExams() {
      let classID = this.$route.query.id
      this.$axios
        .get(`/tk/front/class/exam`, {
          params: {
            pageIndex: 0,
            pageSize: 3,
            classID,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            this.examList = res.data.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    goto(item) {
      let path = `/allCourses/details/${item.ID}/curriculum`
      if (item.type === 1 || item.type === '1') {
        path = `/allCourses/courseHandout/${item.ID}`
      } else {
        path = path + '?backUrl=' + this.$router.history.current.fullPath + '&fromStudyCenter=1'
        // let temp = this.$router.history.current.fullPath
        // localStorage.back_url = temp
      }
      this.gotoPage(path)
    },
    gotoPage(path) {
      if (path.indexOf('/nzzone/classcourses') > 0 && window.location.href.indexOf('#') > 0) {
        let url = window.location.href.split('#')[1] + '&isBack=Y'
        if (path.indexOf('?') > 0) {
          path = path + '&backUrl=' + encodeURIComponent(url)
        } else {
          path = path + '?backUrl=' + encodeURIComponent(url)
        }
      }
      this.$router.push({ path: path })
    },
    gotoHomePage() {
      this.$router.push('/')
    },
    handleExamCompleteStatus(isExamined, isOverExam, status) {
      if (isExamined === 'N' && isOverExam === 1) {
        return {
          label: '缺考',
          value: 3,
        }
      } else if (isExamined === 'N') {
        return {
          label: '待考',
          value: 1,
        }
      } else if (status === 'Y') {
        return {
          label: '完成',
          value: 2,
        }
      } else if (isExamined === 'Y' && status === 'N') {
        return {
          label: '待考',
          value: 1,
        }
      }
    },
    formatWorkDate(date) {
      const reg = /\d+/g
      let str = ''
      date.match(reg).forEach((item, index) => {
        if (index === 0) {
          str += item
        } else {
          str += '.' + item
        }
      })
      return str
    },
    goAnswerRequest(item) {
      this.$axios
        .get(`/tk/front/paper/${item.productID}/checkandgeneratekey?examID=${item.ID}`)
        .then((res) => {
          if (res.data.status === 1) {
            // 前往答题页面
            this.$router.push({
              path: `/allCourses/examinationpaper?id=${item.ID}&isPhoto=${item.isPhoto}&key_success=${res.data.data.key_success}`,
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    goAnswer(item) {
      if (item.isStartExam === 0) {
        return this.$Toast.fail('考试未开始！')
      }
      if (item.isOverExam === 1 && item.isExamined === 'N') {
        return this.$Toast.fail('考试已过期！')
      }

      if (item.status === 'Y') {
        // 前往考试完成页面
        this.$router.push({
          path: `/study/testresults/${item.productID}/${item.ID}`,
        })
        return
      }
      if (item.isIdCardAuth === 1 && item.isFaceAuth === 0) {
        let info = JSON.parse(localStorage.userInfo)
        if (info.isChecked !== 1) {
          Dialog.confirm({
            title: '身份认证提示',
            message: '未进行身份认证，请先去身份认证',
          })
            .then(() => {
              this.$router.push({ path: `/user/userinfo/info` })
            })
            .catch(() => {
              return
            })
        } else {
          this.goAnswerRequest(item)
        }
      } else if (item.isIdCardAuth === 1 && item.isFaceAuth === 1) {
        this.faceAuthRequest(item)
      } else {
        this.goAnswerRequest(item)
      }
    },
    // 人脸认证
    async faceAuthRequest(item) {
      let info = JSON.parse(localStorage.userInfo)
      if (info.isChecked !== 1) {
        Dialog.confirm({
          title: '身份认证提示',
          message: '未进行身份认证，请先去身份认证',
        })
          .then(() => {
            this.$router.push({ path: `/user/userinfo/info` })
          })
          .catch(() => {
            return
          })
      } else {
        this.$axios
          .get(`/course/front/member/face/auth/issuccess?type=ClassDetailExam&id=${item.ID}`)
          .then((res) => {
            if (res.data.status === 1) {
              if (res.data.data.isSuccess !== 1) {
                Dialog.confirm({
                  title: '温馨提示',
                  message: '即将进入人脸认证，是否继续？',
                })
                  .then(() => {
                    this.getFaceAuthUrl(item, false)
                  })
                  .catch(() => {
                    return
                  })
              } else {
                this.goAnswerRequest(item)
              }
            } else {
              this.$Toast.fail(res.data.message)
              return
            }
          })
          .catch(() => {
            this.$Toast.fail('服务器开小差了，稍后重试')
            return
          })
      }
    },
    getFaceAuthUrl(item) {
      let url = ''
      if (window.location.href.indexOf('#') > 0) {
        url = window.location.href.split('#')[1]
      }
      // 获取用户结果
      this.$axios
        .get(`/course/front/member/face/auth?type=ClassExam&id=${item.ID}&examRedirectUrl=${encodeURIComponent(url)}`)
        .then((res) => {
          if (res.data.status === 1) {
            if (!res.data.data.bizToken && res.data.data.isSuccess === 1) {
              this.goAnswerRequest(item)
            } else {
              window.location.href = res.data.data.url
            }
          } else {
            this.$Toast.fail(res.data.message)
            return
          }
        })
        .catch(() => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return
        })
    },
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
}
.classinfo {
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-bottom: 0.5rem;
  &-baseInfo {
    &__logo {
      width: 100%;
      max-height: 5.57rem;
      &::v-deep {
        .van-image__img {
          max-height: 5.57rem;
        }
      }
    }
  }
  .user-box {
    background-color: #fff;
    display: flex;
    align-items: center;
    .info-img {
      width: 1.87rem;
      height: 1.87rem;
    }
    .info-conter {
      display: flex;
      flex-direction: column;
      margin-left: 0.36rem;
      flex: 1;
      .info-name {
        font-family: PingFang-SC-Medium;
        font-size: 0.37rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #222222;
        position: relative;
        padding: 0.13rem 0;
        .change-class {
          position: absolute;
          right: -0.33rem;
          top: -0.1rem;
          background-color: #1578f2;
          border-radius: 0.36rem 0rem 0rem 0.36rem;
          color: #fff;
          font-family: PingFang-SC-Medium;
          font-size: 0.35rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #ffffff;
          padding: 0.1rem;
          .change-ring {
            width: 0.48rem;
            height: 0.48rem;
            background-color: #ffffff;
            color: #1578f2;
            display: inline-block;
            border-radius: 50%;
            text-align: center;
            margin-right: 0.1rem;
            vertical-align: text-bottom;
            .r-icon {
              line-height: 0.48rem;
            }
          }
        }
      }

      .info-enterprise {
        align-self: baseline;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.53rem;
        letter-spacing: 0rem;
        color: #888888;
        p {
          width: 68vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin: 0;
          margin-top: 0.3rem;
        }
      }
      .members-icon {
        font-size: 0.37rem;
        vertical-align: middle;
        margin-top: -0.04rem;
      }
    }
  }
  .user-info {
    padding: 0.33rem;
  }
  .list-infobox {
    .list-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.32rem;
      padding: 0.44rem 0.2rem;
      padding-bottom: 0.2rem;

      .title-l {
        display: flex;
        align-items: center;
        font-family: PingFang-SC-Bold;
        font-size: 0.4rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #222222;
        .l-icon {
          font-size: 0.5rem;
          vertical-align: middle;
          color: #1578f2;
        }
      }
      .list-item__label {
        margin-left: 0.3rem;
        font-size: 0.43rem;
        font-weight: bold;
        color: #222222;
      }
      .list-item__icon {
      }
      .title-r {
        color: #5f5f5f;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        .r-more {
          display: flex;
          align-items: center;
          justify-content: center;
          .van-icon {
            margin-top: 0.06rem;
          }
          &__text {
            margin-right: 0.16px;
            font-size: 0.32rem;
            color: #5f5f5f;
          }
        }
      }
    }
    .list-card {
      padding: 0 0.32rem;
      box-sizing: border-box;
      &-content {
        padding: 0.32rem 0.7rem;
        box-sizing: border-box;
        min-height: 3.52rem;
        border-radius: 0.13rem;
        font-size: 0.37rem;
        color: #474747;
        line-height: 0.56rem;
        background: #ffffff;
        &::v-deep {
          p {
            max-width: 100% !important;
          }
          img {
            max-width: 100% !important;
          }
          span {
            white-space: unset !important;
            max-width: 100% !important;
          }
        }
      }
    }
    .info-box:first-child {
      margin-top: 0;
    }
    .info-box {
      background-color: #ffffff;
      border-radius: 0.13rem;
      margin: 0.25rem 0.33rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .info-type {
        font-family: PingFang-SC-Regular;
        font-weight: bold;
        background-image: linear-gradient(-41deg, #1578f2 0%, #4c9cff 100%);
        border-radius: 0.13rem 0rem 0.13rem 0rem;
        font-size: 0.32rem;
        color: #fff;
        width: 1.21rem;
        height: 0.48rem;
        text-align: center;
      }
      .type-name {
        font-family: PingFang-SC-Bold;
        font-size: 0.43rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #333333;
        padding: 0.37rem 0 0.29rem 0.41rem;
      }
      .type-time {
        font-family: PingFang-SC-Regular;
        font-size: 0.35rem;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #666666;
        padding: 0 0 0.25rem 0.41rem;
      }
      .btn-review {
        width: 1.87rem;
        height: 0.67rem;
        background-color: #f3f8ff;
        border-radius: 0.33rem;
        line-height: 0.67rem;
        text-align: center;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #1578f2;
        position: absolute;
        right: 0.47rem;
        bottom: 0.45rem;
      }

      .btn-todo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0.47rem;
        bottom: 0.45rem;
        width: 1.33rem;
        height: 1.33rem;
        box-shadow: 0rem 0rem 0rem 0rem rgba(21, 120, 242, 0.35);
        border-radius: 50%;
        font-size: 0.35rem;
        text-align: center;
        white-space: nowrap;
        color: #ffffff;
        background: linear-gradient(0deg, #1578f2, #bde0f6);
        &--notScore {
          background: #bebebe;
        }
      }
      .btn-score {
        position: absolute;
        right: 0.47rem;
        bottom: 0.45rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1.33rem;
        height: 1.33rem;
        &__score {
          font-size: 0.64rem;
          font-family: PingFang;
          font-weight: 800;
          font-style: italic;
          color: #1578f2;
          line-height: 0.64rem;
          white-space: nowrap;
        }
        &__text {
          font-size: 0.32rem;
          font-family: PingFang;
          font-weight: 400;
          color: #b2bece;
        }
      }
      .shenhe {
        background-image: linear-gradient(-41deg, #fe9231 1%, #ffae0d 100%);
      }
      .wancheng {
        background-image: linear-gradient(-41deg, #04c761 0%, #50d590 100%);
      }
      .back {
        background-image: linear-gradient(-90deg, #999 0%, #fff 100%);
      }
      .examing {
        background-image: linear-gradient(-41deg, #04c761 0%, #50d590 100%);
      }
    }
    .course-box:first-child {
      margin-top: 0;
    }
    .course-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.33rem;
      margin: 0.33rem 0.33rem 0 0.33rem;
      background-color: #fff;
      border-radius: 0.13rem;
      position: relative;
      overflow: hidden;
      height: 2.8rem;
      .courseItem__icon {
        position: absolute;
        top: 0.8rem;
        left: 0.5rem;
        z-index: 2;
      }
      .courseItem__logo {
        width: 3.23rem;
        height: 2.16rem;
        border-radius: 0.08rem;
      }
      .course-rightFrame {
        display: flex;
        flex-direction: column;
        margin-left: 0.39rem;
        flex: 1;
        .course-speaker {
          margin-top: 0.32rem;
          font-size: 0.32rem;
          color: #999999;
          &--living {
            color: red;
          }
        }
        .course-openTime {
          display: flex;
          align-items: center;
          margin-top: 0.32rem;
          &__tip {
            margin-right: 0.21rem;
            width: 1rem;
            height: 0.41rem;
            white-space: nowrap;
            border-radius: 0.05rem;
            font-size: 0.32rem;
            font-family: PingFang;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 0.41rem;
            background: #1578f2;
            &--over {
              color: #fff;
              background: #979797;
            }
          }
          &__time {
            flex: 1;
            font-size: 0.32rem;
            color: #999999;
          }
        }
        .title-tag {
          height: 0.53rem;
          line-height: 0.53rem;
          border-radius: 0.08rem;
          border: solid 0.01rem #fbc047;
          background-color: #fbc047;
          font-family: PingFang-SC-Medium;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #fff;
          text-align: center;
          padding: 0 0.05rem;
        }
        .enterprise-tag {
          height: 0.53rem;
          line-height: 0.53rem;
          border-radius: 0.08rem;
          border: solid 0.01rem #70dd96;
          background-color: #70dd96;
          font-family: PingFang-SC-Medium;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #fff;
          text-align: center;
          padding: 0 0.05rem;
        }
        .title-name {
          font-family: PingFang-SC-Heavy;
          font-size: 0.43rem;
          font-weight: bold;
          color: #111111;
        }
        .title-type {
          height: 0.4rem;
          line-height: 0.4rem;
          background-color: #ff9250;
          border-radius: 0.05rem;
          font-family: PingFang-SC-Medium;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #ffffff;
          padding: 0 0.05rem;
        }
      }
      .course-bottom {
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #999999;
        margin-top: 0.32rem;
      }
      .course-tips {
        z-index: 1;
        width: 4rem;
        height: 0.71rem;
        font-family: PingFang-SC-Regular;
        font-size: 0.32rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #ffffff;
        transform: rotate(40deg);
        position: absolute;
        background-image: linear-gradient(-90deg, #999 0%, #fff 100%);
        right: -1.39rem;
        top: 0.16rem;
        text-align: center;
        line-height: 0.79rem;
      }

      .dianping {
        background-image: linear-gradient(-90deg, #cca779 0%, #e2be91 100%);
      }
      .kaibo {
        background-image: linear-gradient(-90deg, #ca2d2f 0%, #d8514a 100%);
      }
      .lianxi {
        background-image: linear-gradient(-90deg, #cba778 0%, #e2be91 100%);
      }
    }
  }
}

.course-add {
  margin: 20px 0;
  font-size: 16px;
  text-align: center;
  color: gray;
}
</style>
