<template>
  <div class="classlist clearfix">
    <zv-nav-bar :title="pagename"></zv-nav-bar>
    <van-col span="24">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="class-box" v-for="item in classlist" :id="item.ID" :key="item.ID" @click="gotopage(`/nzzone/classinfo?id=${item.ID}`)">
            <div class="class-top">
              <div class="class-l">
                <van-image round width="100%" height="100%" :src="item.logoSrc" />
              </div>
              <div class="class-r">
                <div class="title-box">
                  <span class="title-name">{{ item.name }}</span>
                  <span class="title-num">共{{ item.numberOfCourses }}节课</span>
                </div>
                <div class="title-time">有效期:{{ item.startDate }}-{{ item.endDate }}</div>
              </div>
            </div>
            <div class="info-enterprise" v-html="item.description"></div>
          </div>
        </van-list>
      </van-pull-refresh>
    </van-col>
  </div>
</template>

<script>
export default {
  name: 'classlist',
  data() {
    return {
      refreshing: false,
      activeID: -1,
      pagename: '班级列表',
      classlist: [],
      loading: false,
      finished: false,
      pageIndex: 0,
      courseid: '',
    }
  },
  created() {
    this.courseid = this.$route.params.id
    this.setShare()
  },
  methods: {
    setShare() {
      const shareInfo = {
        title: `班级列表`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    gotopage(path) {
      this.$router.push({
        path: path,
      })
    },
    onRefresh() {
      this.pageIndex = 0
      this.classlist = []
      this.activeID = -1
      this.$nextTick(() => {
        this.finished = false
        this.loading = true
        this.onLoad()
      })
    },
    onLoad() {
      this.$axios
        .get(`/course/front/course/${this.courseid}/class?pageIndex=${this.pageIndex}&pageSize=10`)
        .then((res) => {
          if (res.data.status === 1) {
            this.pageIndex = this.pageIndex + 1
            this.classlist = this.classlist.concat(res.data.data.data)
            this.loading = false
            if (this.classlist.length >= res.data.data.total) {
              this.finished = true
            }
            this.refreshing = false
          } else {
            this.$Toast.fail(res.data.message)
            this.loading = false
            this.finished = true
            this.refreshing = false
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
  },
}
</script>

<style lang="less" scoped>
.classlist {
  padding-bottom: 1.6rem;
  .class-box {
    border-radius: 0.13rem;
    margin: 0 0.36rem;
    padding: 0.19rem 0.53rem 0.44rem 0.28rem;
    .class-top {
      display: flex;
      .class-l {
        width: 1.45rem;
        height: 1.45rem;
        margin: 0.15rem;
      }
      .class-r {
        font-size: 0.35rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.17rem;
        flex: 1;
        .title-box {
          display: flex;
          justify-content: space-between;
          .title-name {
            font-family: PingFang-SC-Medium;
            font-size: 0.4rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #1b1b1b;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 5.22rem;
          }
          .title-num {
            font-family: PingFang-SC-Regular;
            font-size: 0.32rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #999999;
          }
        }
        .title-time {
          font-family: PingFang-SC-Regular;
          font-size: 0.35rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #999999;
        }
      }
    }
    .info-enterprise {
      align-self: baseline;
      font-family: PingFang-SC-Medium;
      font-size: 0.32rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.53rem;
      letter-spacing: 0rem;
      color: #888888;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      // overflow: hidden;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin: 0;
        margin-top: 0;
      }
    }
  }
  .active {
    background-color: #cae1fd;
  }
  .btn-box {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    .info-btn {
      width: 80%;
      height: 1.17rem;
      margin-bottom: 0.4rem;
      .van-button__text {
        font-size: 0.4rem;
      }
    }
  }
}
</style>
