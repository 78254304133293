<template>
  <div>
    <div class="tips">
      <span>请使用微信内置浏览器访问</span>
    </div>
    <div class="footer">湖南麓辰教育科技有限公司 版权所有 <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2021007849号-3</a></div>
  </div>
</template>

<script>
export default {
  name: 'notPrive',
  data() {
    return {}
  },
  mounted() {
    document.title = '麓辰教育'
  },
}
</script>
<style scoped>
.tips {
  position: relative;
  text-align: center;
  min-height: 100vh;
  line-height: 100vh;
  font-size: 0.64rem;
}

.footer {
  position: fixed;
  text-align: center;
  bottom: 50px;
  width: 100%;
  height: 50px;
  padding: 0;
  color: #717171;
  font-size: 0.07rem;
  z-index: 2;
}
.footer a {
  color: #2e3e4e;
  text-decoration: none;
}
</style>
