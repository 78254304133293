<template>
  <div class="examinationlist clearfix">
    <zv-nav-bar :title="pagename" :clickLeft="clickLeft"></zv-nav-bar>
    <div class="listbox" v-for="(item, index) in questionData" :key="index">
      <template v-if="item.length > 0">
        <van-row class="listtitle" type="flex" justify="space-between">
          <van-col>[{{ item[0].data.typeName ? item[0].data.typeName : (item[0].name | transformValue) }}]</van-col>
          <van-col>
            <van-col class="blockbox">
              <i class="block blue"></i>
              <span>{{ isAnswer ? '已作答' : '正确' }}</span> 
            </van-col>
            <van-col class="blockbox" v-if="!isAnswer">
              <i class="block red"></i>
              <span>错误</span>
            </van-col>
            <van-col class="blockbox">
              <i :class="{ block: true, orange: true }"></i>
              <span>未作答</span>
            </van-col>
          </van-col>
        </van-row>
        <van-row class="listcon" type="flex">
          <div v-for="q in item" :key="q.ID" :class="{ round: true, orange: isAnswer ? getOrange(q) : !q.data.respond, red: getRed(q) }" @click="goParentSubject(q.data.rowNo)">{{ q.data.rowNo }}</div>
        </van-row>
      </template>
      <div v-else>暂无数据</div>
    </div>
    <!-- <div class="listbtn" v-if="!isAnswer">
      <van-button class="info-btn" plain round hairline size="large" type="info" @click="gotoPage('/allCourses/wrongtopic')">题目解析</van-button>
      <van-button class="info-btn" round hairline size="large" type="info" @click="clickLeft">返回</van-button>
    </div> -->
    <div class="listbtn">
      <van-button class="info-btn" round hairline size="large" type="info" @click="clickLeft">返回</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'examinationlist',
  props: {
    questionlist: {
      type: Array,
      default() {
        return []
      },
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pagename: '答题情况',
      items: 10,
      isAnswer: true,
      questionData: [],
    }
  },
  filters: {
    transformValue: function(value) {
      if (!value) return ''
      switch (value) {
        case 'SingleChoice':
          return '单选题I'
        case 'SingleChoice2':
          return '单选题II'
        case 'SingleChoice3':
          return '单选题III'
        case 'GroupOption':
          return '选项组合题'
        case 'GroupStem':
          return '题干组合题'
        case 'MultipleChoice':
          return '多选题I'
        case 'MultipleChoice2':
          return '多选题II'
        case 'MultipleChoice3':
          return '多选题III'
        case 'Checking':
          return '判断题'
        case 'Completion':
          return '填空题I'
        case 'Completion2':
          return '填空题II'
        case 'Completion3':
          return '填空题II'
        case 'CaseAnalysis':
          return '案例分析题'
        case 'Answer':
          return '简答题I'
        case 'Answer2':
          return '简答题II'
        case 'Answer3':
          return '简答题III'
        case 'Answer4':
          return '简答题IV'
        case 'Answer5':
          return '简答题V'
      }
    },
  },
  created() {
    this.isAnswer = this.type ? true : false
    this.setList()
  },
  methods: {
    clickLeft() {
      this.$emit('back')
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    getOrange(item) {
      if (item.name.indexOf('SingleChoice') > -1 || item.name.indexOf('Checking') > -1 || item.name.indexOf('GroupOption') > -1 || item.name.indexOf('GroupStem') > -1) {
        if (item.data.q_res) {
          return false
        }
      }
      if (item.name.indexOf('MultipleChoice') > -1 || item.name.indexOf('CaseAnalysis') > -1) {
        if (item.data.q_res.length > 0) {
          return false
        }
      }
      if (item.name.indexOf('Completion') > -1) {
        for (let i = 0; i < item.data.inputOptions.length; i++) {
          if (!item.data.inputOptions[i].value) {
            return true
          }
        }
        return false
      }
      if (item.name.indexOf('Answer') > -1) {
        for (let i = 0; i < item.data.inputOptions.length; i++) {
          if (!item.data.respond) {
            return true
          }
          }
        return false
      }
      if (item.name.indexOf('Tongue') > -1) {
        if (item.data.q_res !== 0 && !item.data.q_res) {
            return true
          }
          return false
      }
      return true
    },
    getRed(item) {
      if(item.data.type.indexOf('Tongue') > -1) {
        return item.data.respond
      }
      if (!item.data.respond) {
        return false
      }
      return item.data.answerType === 'N'
    },
    setList() {
      let arr = []
      let res = []
      for (let i = 0; i < this.questionlist.length; i++) {
        arr.indexOf(this.questionlist[i].type) === -1 && arr.push(this.questionlist[i].type)
      }
      for (let i = 0; i < this.questionlist.length; i++) {
        let index = arr.indexOf(this.questionlist[i].type)
        let r = {
          data: this.questionlist[i],
          name: arr[index],
        }
        if (res[index]) {
          res[index].push(r)
        } else {
          res[index] = [r]
        }
      }
      this.questionData = res
    },
    goParentSubject(rowNo) {
      this.$emit('setActiveIndex', rowNo - 1)
    },
  },
}
</script>

<style lang="less" scoped>
.examinationlist {
  padding-bottom: 1rem;
  .listbox {
    .listtitle {
      background-color: #f8f8f8;
      font-family: PingFang-SC-Medium;
      font-size: 0.4rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #444444;
      padding: 0.25rem 0.44rem;
      .blockbox {
        display: flex;
        margin-right: 0.3rem;
      }
      .blockbox:last-child {
        margin-right: 0rem;
      }
      .block {
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.08rem;
        margin-right: 0.1rem;
      }
      .blue {
        background-color: #1578f2;
      }
      .orange {
        background-color: #fc9c16;
      }
      .red {
        background-color: #ff564e;
      }
    }
  }
  .listcon {
    font-family: PingFang-SC-Bold;
    font-size: 0.63rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #fefdfd;
    padding: 0.53rem 0.66rem;
    .round {
      width: 1.34rem;
      height: 1.34rem;
      border-radius: 50%;
      text-align: center;
      line-height: 1.34rem;
      margin-right: 0.4rem;
      margin-bottom: 0.73rem;
      background-color: #1578f2;
    }
    .round:nth-child(5n) {
      margin-right: 0rem;
    }
    .blue {
      background-color: #1578f2;
    }
    .orange {
      background-color: #fc9c16;
    }
    .red {
      background-color: #ff564e;
    }
  }
  .listbtn {
    display: flex;
    justify-content: space-around;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #f2f2f2;
    padding: 0.23rem 0;
    background: #fff;
    .info-btn {
      width: 30%;
      height: 1rem;
      .van-button__text {
        font-size: 0.4rem;
      }
    }
  }
}
</style>
