<template>
  <div class="usecoupon">
    <zv-nav-bar :title="pagename"></zv-nav-bar>
    <div class="get_bg"></div>
    <div class="imgbox">
      <div class="get_title">
        <div class="get_icon">
          <img src="../../../public/images/user/icon.png" alt="" />
        </div>
        <div class="icon_text">
          <span>麓辰教育</span><van-icon name="arrow" />
        </div>
      </div>
      <div class="get_box">
        <div class="box_img">
          <img src="../../../public/images/user/numcap.png" alt="" />
        </div>
        <div class="box_text">
          <div class="text_l">
            <div class="num_text">满901可用</div>
            <div>
              <span class="num">300</span><span class="num_text">元</span>
            </div>
          </div>
          <div class="text_r">
            <div class="r_text">平台通用优惠券</div>
            <div class="r_time">2021.04.30-2021.06.30</div>
          </div>
        </div>
      </div>
      <div class="get_btn">
        <span>立即使用</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "usecoupon",
  data() {
    return {
      pagename: "使用优惠券",
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.usecoupon {
  min-height: 100vh;
  background-color: #f8f8f8;
  img {
    width: 100%;
    height: 100%;
  }
  .get_bg {
    min-height: 6.9rem;
    background-image: linear-gradient(-17deg, #ed6e11 0%, #ffb307 100%);
    border-radius: 0 0 11% 11%;
  }
  .imgbox {
    min-height: 8.82rem;
    width: 9.73rem;
    margin: 0 auto;
    margin-top: -6rem;
    background: url("../../../public/images/user/bar.png") no-repeat;
    background-size: cover;
    .get_title {
      display: flex;
      align-items: center;
      width: 7.63rem;
      margin: 0 auto;
      border-bottom: 1px solid #ff771f;
      padding-top: 0.45rem;
      padding-bottom: 0.23rem;
      .get_icon {
        width: 0.52rem;
        height: 0.67rem;
      }
      .icon_text {
        margin-left: 0.16rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.4rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0rem;
        color: #604e44;
        span {
          display: inline-block;
        }
        .van-icon {
          top: 0.06rem;
        }
      }
    }
    .get_box {
      position: relative;
      line-height: normal;
      .box_img {
        width: 6.65rem;
        height: 2.95rem;
        margin: 0 auto;
        margin-top: 0.3rem;
      }
      .box_text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6.65rem;
        margin: 0 auto;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        .text_l {
          float: left;
          padding-left: 0.24rem;
          padding-right: 0.33rem;
          border-right: 1px dashed #fff;
          .num {
            font-family: PingFang-SC-Bold;
            font-size: 0.96rem;
            font-weight: normal;
            font-stretch: normal;
            color: #ffffff;
          }
          .num_text {
            font-family: PingFang-SC-Bold;
            font-size: 0.35rem;
            font-weight: normal;
            font-stretch: normal;
            color: #ffffff;
          }
        }

        .text_r {
          float: left;
          padding-left: 0.25rem;
          .r_text {
            font-family: PingFang-SC-Bold;
            font-size: 0.46rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #ffffff;
          }
          .r_time {
            font-family: PingFang-SC-Medium;
            font-size: 0.31rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #ffffff;
          }
        }
      }
    }
    .get_btn {
      padding: 0.2rem 0.5rem;
      background-image: linear-gradient(0deg, #f9a02e 0%, #fdd76c 100%),
        linear-gradient(#fcef9f, #fcef9f);
      background-blend-mode: normal, normal;
      border-radius: 0.71rem;
      width: 4.64rem;
      text-align: center;
      margin: 0 auto;
      margin-top: 1.3rem;
      span {
        font-family: PingFang-SC-Bold;
        font-size: 0.67rem;
        font-weight: normal;
        font-stretch: normal;
        color: #ad2011;
      }
    }
  }
  .use_class {
    padding: 0 0.3rem;
    .class_text {
      font-family: PingFang-SC-Bold;
      font-size: 0.46rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #040101;
      text-align: center;
      margin-bottom: 0.23rem;
    }
    .class_box {
      padding: 0.15rem;
      background-color: #ffffff;
      border-radius: 0.07rem;
      border: solid 0.01rem #bfbfbf;
      margin: 0 0.05rem;
      .class_box_img {
        width: 4.24rem;
        height: 2.39rem;
        margin: 0 auto;
        position: relative;
        .img_text {
          position: absolute;
          bottom: 0.37rem;
          right: 0.16rem;
          font-family: PingFang-SC-Medium;
          font-size: 0.27rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #020000;
          background-color: #fbc047;
          border-radius: 0.08rem;
          padding: 0.13rem 0.2rem;
        }
      }
      .class_box_text {
        font-family: PingFang-SC-Regular;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #040101;
        padding-left: 0.1rem;
      }
    }
  }
}
</style>
