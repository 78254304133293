<template>
  <div style="background-color: #f8f8f8; min-height: 100vh;">
    <zv-nav-bar :title="'错题练习记录'"></zv-nav-bar>
    <div class="content-box">
      <van-list v-model="listLoading" @load="onLoad" :finished="finished" finished-text="没有更多了">
        <div class="item-box" v-for="item in listData" :key="item.ID" @click="gotoExe(item)">
          <div class="flex-box">
            <div class="title">{{item.name}}</div>
            <span class="count-q">{{item.useTimes}}题</span>
          </div>
          <div class="flex-box">
            <span class="time">最近练习时间：{{item.latestDate}}</span>
            <span class="count-e">已练习{{item.counts}}次</span>
          </div>
        </div>
      </van-list>
    </div>
    <div class="bottom-btn-box">
      <div @click="$router.go(-1)">
        <span>返回</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'wrongExeRecord',
  data() {
    return {
      finished: false,
      listLoading: false,
      listData: [],
      pageIndex: 0
    }
  },
  methods: {
    onLoad() {
      this.$axios.get(`/tk/front/member/errorquestion/papers?pageSize=8&pageIndex=${this.pageIndex}`).then(res => {
        if (res.data.status === 1) {
          this.pageIndex = this.pageIndex + 1
          this.listData = this.listData.concat(res.data.data.data)
          this.listLoading = false
          if (this.listData.length >= res.data.data.total) {
            this.finished = true
          }
        } else {
          this.$Toast.fail(res.data.message)
          this.loading = false
          this.finished = true
        }
      }).catch(e => {
        this.loading = false
        this.finished = true
        this.$Toast.fail('服务器开小差了，稍后重试' + e)
      })
    },
    gotoExe(item) {
      this.$Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      this.$axios.get(`/tk/front/paper/${item.ID}/checkandgeneratekey`).then(res => {
        this.$Toast.clear()
        if (res.data.status === 1) {
          this.$router.push({
            path: `/allCourses/examinationpaper?errorAgain=true&pagename=错题练习&isPhoto=N&key_success=${res.data.data.key_success}`
          })
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        this.$Toast.clear()
        return this.$Toast.fail('服务器开小差了，稍后重试' + e)
      })
    }
  }
}
</script>
<style scoped>
.content-box {
  padding: 0.27rem 0.33rem;
  margin-bottom: 1.33rem;
}
.item-box {
  background-color: #fff;
  padding: 0.33rem;
  border-radius: 0.13rem;
}
.item-box:nth-of-type(n + 2) {
  margin-top: 0.21rem;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-size: 0.4rem;
	font-weight: bold;
	color: #444444;
  max-width: 7.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.count-q {
  font-size: 0.32rem;
	font-weight: bold;
	color: #076ce9;
}
.time {
  font-size: 0.32rem;
	color: #999999;
  margin-top: 0.33rem;
}
.count-e {
  font-size: 0.32rem;
	color: #999999;
  margin-top: 0.33rem;
}
.bottom-btn-box {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  height: 1.56rem;
  border: 1px solid #eee;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
}
.bottom-btn-box div {
  font-size: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  width: 8.29rem;
	height: 1.07rem;
	background-color: #1578f2;
	box-shadow: 0rem 0.07rem 0.11rem 0rem 
		rgba(119, 180, 255, 0.69);
	border-radius: 0.53rem;
}
</style>