<template>
  <div class="courseslist clearfix">
    <zv-nav-bar :title="pagename" :sourceType="sourceType"></zv-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-col span="24" v-for="item in courseList" :id="item.ID" :key="item.ID">
          <van-row class="course-box" @click="gotoPage(item.type !== 0 ? `/allCourses/courseHandout/${item.ID}` : `/allCourses/details/${item.ID}/curriculum`)">
            <div class="course-top">
              <span :class="{'title-tag': item.enterpriseID == '0', 'enterprise-tag': item.enterpriseID != '0'}">{{item.type !== 0 ? '讲义' : item.resourceTypeName}}</span>
              <span class="title-name">{{item.name}}</span>
              <span class="title-type" v-if="item.isCompulsory !== '选修'">{{item.isCompulsory}}</span>
            </div>
            <div class="course-bottom">时长:{{item.totalLength}}</div>
            <div class="course-tips" :class="resolvetype(item.studyStatus)">{{item.studyStatusName}}</div>
          </van-row>
        </van-col>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  name: "courseslist",
  data() {
    return {
      refreshing: false,
      pagename: "课程列表",
      loading: false,
      finished: false,
      courseList: [],
      isBack: '',
      sourceType: ''
    };
  },
  created() {
    this.setShare()
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      this.sourceType = 'coursesList'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    resolvetype(type) {
      return type == 0 ? "" : type == 2 ? "dianping" : type == 1 ? "kaibo" : "lianxi"
    },
    onRefresh() {
      this.courseList = []
      this.$nextTick(() => {
        this.finished = false
        this.loading = true
        this.onLoad()
      })
    },
    onLoad() {
      this.$axios.get('/course/front/member/personalcenter/freeresources').then(res => {
        if (res.data.status === 1) {
          this.courseList = res.data.data
          // 加载状态结束
          this.loading = false
          this.finished = true
          this.refreshing = false
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        // 加载状态结束
        this.loading = false
        this.finished = true
        this.refreshing = false
        this.$Toast.fail('服务器开小差了，稍后重试')
        return e
      })
      this.$forceUpdate()
    },
    gotoPage(path) {
      path = path + "?backUrl=" + this.$router.history.current.fullPath
      // let temp = this.$router.history.current.fullPath
      // localStorage.back_url = temp
      this.$router.push({path: path})
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  }
};
</script>

<style lang="less" scoped>
.courseslist {
  background-color: #f8f8f8;
  min-height: 100vh;
  .course-box {
    padding: 0.33rem;
    margin: 0.33rem 0.33rem 0 0.33rem;
    background-color: #fff;
    border-radius: 0.13rem;
    position: relative;
    overflow: hidden;
    .course-top {
      display: flex;
      align-items: center;
      .title-tag {
        height: 0.53rem;
        line-height: 0.53rem;
        border-radius: 0.08rem;
        border: solid 0.01rem #fbc047;
        background-color: #fbc047;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #fff;
        text-align: center;
        padding: 0 0.05rem;
      }
      .enterprise-tag {
        height: 0.53rem;
        line-height: 0.53rem;
        border-radius: 0.08rem;
        border: solid 0.01rem #70dd96;
        background-color: #70dd96;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #fff;
        text-align: center;
        padding: 0 0.05rem;
      }
      .title-name {
        font-family: PingFang-SC-Heavy;
        font-size: 0.43rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #111111;
        margin: 0 0.2rem;
        width: 5.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title-type {
        height: 0.4rem;
        line-height: 0.4rem;
        background-color: #ff9250;
        border-radius: 0.05rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #ffffff;
        padding: 0 0.05rem;
      }
    }
    .course-bottom {
      font-family: PingFang-SC-Medium;
      font-size: 0.32rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #999999;
      margin-top: 0.32rem;
    }
    .course-tips {
      width: 4rem;
      height: 0.71rem;
      font-family: PingFang-SC-Regular;
      font-size: 0.32rem;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #ffffff;
      transform: rotate(40deg);
      position: absolute;
      background-image: linear-gradient(-90deg, #999 0%, #fff 100%);
      right: -1.39rem;
      top: 0.16rem;
      text-align: center;
      line-height: 0.79rem;
    }

    .dianping {
      background-image: linear-gradient(-90deg, #cca779 0%, #e2be91 100%);
    }
    .kaibo {
      background-image: linear-gradient(-90deg, #ca2d2f 0%, #d8514a 100%);
    }
    .lianxi {
      background-image: linear-gradient(-90deg, #cba778 0%, #e2be91 100%);
    }
  }
}
</style>
