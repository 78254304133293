<template>
  <van-nav-bar :title="title" :left-arrow="leftArrow" @click-left="onClickLeft">
    <template #right>
      <slot></slot>
    </template>
  </van-nav-bar>
</template>
<script>
export default {
  name: 'nav-bar',
  props: {
    title: {
      type: String,
    },
    clickLeft: {
      type: Function,
    },
    sourceType: {
      type: String,
      default: '',
    },
    leftArrow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bool: true,
    }
  },
  methods: {
    routerPush() {
      if (!this.sourceType || this.sourceType === '') {
        this.commonClick()
        return
      }
      // alert('我点击了左上角的返回')
      // console.log('页面来源', this.sourceType)
      if (this.sourceType === 'curriculumDetails') {
        let path = localStorage.back_url
        // alert(localStorage.back_url)
        if (path) {
          localStorage.back_url = ''
          this.$router.push({ path: path })
        } else {
          this.commonClick()
        }
      } else if (this.sourceType === 'coursePackDetails') {
        let temp = localStorage.course_pack_source
        if (temp) {
          // localStorage.course_pack_source = ''
          this.$router.push({ path: temp })
        } else {
          this.commonClick()
        }
      } else if (this.sourceType === 'teacherDetail') {
        this.$router.push({ path: `/index/teacher?isBack=Y` })
      } else if (this.sourceType === 'classifyCoursesList' || this.sourceType === 'teacher') {
        this.$router.push({ path: '/' })
      } else if (
        this.sourceType === 'coursesList' ||
        this.sourceType === 'userBuy' ||
        this.sourceType === 'lrecord' ||
        this.sourceType === 'myExam' ||
        this.sourceType === 'payment' ||
        this.sourceType === 'notPayment'
      ) {
        // 我的课程、免费课程、学习记录、我的考试、已支付、未支付
        this.$router.push({ path: '/user' })
      } else if (this.sourceType === 'courseList') {
        // 目录课程
        this.$router.push({ path: '/allCourses/index' })
      } else if (this.sourceType === 'classInfo') {
        this.$router.push({ path: '/study?active=0&isBack=Y' })
      } else {
        this.commonClick()
      }
    },
    onClickLeft() {
      if (this.clickLeft) {
        this.clickLeft()
        return
      }
      this.routerPush()
    },
    commonClick() {
      if (window.history.length >= 2 && window.location.href.indexOf('isLoged') === -1) {
        if (window.location.href.indexOf('isShare') === -1) {
          this.$router.go(-1)
        } else {
          this.$router.push({
            path: '/',
          })
        }
      } else {
        this.$router.push({
          path: '/',
        })
      }
    },
  },
}
</script>
<style scoped></style>
