<template>
  <div>
    <zv-nav-bar :title="pagename + '(目录)'" v-if="!isShowSearch" :clickLeft="goBack">
      <span class="fa fa-search" style="font-size: 0.42rem;" @click="isShowSearch = true"></span>
    </zv-nav-bar>
    <div v-else class="van-nav-bar__content">
      <div style="padding: 0 0.42rem;" @click="goBack">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
      </div>
      <div class="head">
        <div class="search-input">
          <i class="van-icon van-icon-search"></i>
          <input v-model="textValue" placeholder="搜索目录" />
        </div>
      </div>
      <van-button :loading="searchLoading" type="info" loading-type="spinner" @click="searchClick">搜索</van-button>
      <van-button @click="cancel">取消</van-button>
    </div>
    <div class="course-list-box">
      <div class="catalog">
        <span>{{ catalogPath }}</span>
      </div>
      <template v-if="catalogList.length > 0">
        <div class="course-list-item" v-for="item in catalogList" :key="item.ID" @click.stop="gotoPath(item)">
          <div class="course-item">
            <div style="display: flex; align-items: center;">
              <div class="logo">
                <img :src="item.logoFile" />
              </div>
              <div class="item">
                <div style="display: flex; align-items: center;">
                  <span class="catalogName">{{ item.name }}</span>
                  <span v-if="item.isLeaf === 1" class="fa fa-play" style="margin-left: 0.1rem; color: #3fa1fd; font-size: 0.37rem;"></span>
                </div>
                <div style="display: flex; align-items: center;">
                  <span class="fa fa-angle-right" style="font-size: 0.53rem;"></span>
                  <template v-if="item.hasPermission === 'Y'">
                    <span style="margin-left: 0.2rem; color: #3fa1fd; font-size: 0.37rem;" @clic.stop="gotoPage(`/allCourses/packDetails/${item.ID}`)">进入学习</span>
                  </template>
                  <template v-else>
                    <span
                      v-if="item.coursePackageID"
                      style="margin-left: 0.4rem; color: #3fa1fd; font-size: 0.37rem;"
                      @click.stop="gotoPage(`/allCourses/order/${item.coursePackageID}?type=CoursePackage`)"
                      >购买</span
                    >
                    <span v-if="item.coursePackageID" class="van-icon van-icon-gold-coin-o" style="color: #3fa1fd; font-size: 0.37rem;"></span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else-if="isShow" class="not-data">暂无数据</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'catalogList',
  data() {
    return {
      pagename: '',
      catalogPath: '',
      catalogList: [],
      isShow: false,
      searchLoading: false,
      isShowSearch: false,
      textValue: '',
    }
  },
  watch: {
    $route(to) {
      if (to.params.id) {
        this.getCatalog(to.params.id, to.query.catalogInnercode)
        this.onLoad(to.params.id, to.query.catalogInnercode)
      }
    },
  },
  async created() {
    await this.getCatalog()
    await this.onLoad()
    this.setShare()
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.pagename} + '(目录)'`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    goBack() {
      console.log('1111')
      if (window.history.length >= 2 && window.location.href.indexOf('isLoged') === -1) {
        if (window.location.href.indexOf('isShare') === -1) {
          if (this.$route.query.catalogInnercode.length / 6 > 6) {
            this.$router.go(-1)
          } else {
            this.$router.push({ path: `/allCourses/index?id=${this.$route.params.id}&innerCode=${this.$route.query.catalogInnercode}` })
          }
        } else {
          this.$router.push({
            path: '/',
          })
        }
      } else {
        this.$router.push({
          path: '/',
        })
      }
    },
    async getCatalog(catalogId, innercode) {
      let id = catalogId || this.$route.params.id
      let catalogInnercode = innercode || this.$route.query.catalogInnercode
      await this.$axios
        .get(`/course/front/permit/catalog/more/${id}/content?catalogInnercode=${catalogInnercode}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.catalogPath = res.data.data.title
            this.pagename = res.data.data.currentTitle
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.loading = false
          this.finished = true
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    async onLoad(catalogId, innercode) {
      let id = catalogId || this.$route.params.id
      let catalogInnercode = innercode || this.$route.query.catalogInnercode
      await this.$axios
        .get(`/course/front/permit/catalog/more/${id}/catalogs?catalogInnercode=${catalogInnercode}&name=${this.textValue}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.catalogList = res.data.data
            this.$nextTick(() => {
              this.isShow = true
            })
          } else {
            this.$Toast.fail(res.data.message)
            this.$nextTick(() => {
              this.isShow = true
            })
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
          this.$nextTick(() => {
            this.isShow = true
          })
        })
    },
    gotoPath(item) {
      if (item.isLeaf === 1) {
        this.$router.push({
          path: `/allCourses/CourseList/${item.ID}?catalogInnercode=${item.innerCode}`,
        })
      } else {
        this.$router.push({
          path: `/allCourses/CatalogList/${item.ID}?catalogInnercode=${item.innerCode}`,
        })
      }
    },
    gotoPage(path) {
      this.$router.push({
        path: path,
      })
    },
    async searchClick() {
      this.searchLoading = true
      await this.onLoad()
      this.searchLoading = false
    },
    cancel() {
      this.textValue = ''
      this.isShowSearch = false
      this.onLoad()
    },
  },
}
</script>
<style scoped>
.course-list-box {
  padding: 0 0.5rem;
}
.catalog {
  margin-top: 0.23rem;
  font-size: 0.29rem;
  font-weight: bold;
  color: #444444;
  padding-bottom: 0.32rem;
  border-bottom: 1px solid #eee;
}
.catalog span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.course-list-item {
  margin-top: 0.3rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #eee;
}
.item {
  margin-left: 0.32rem;
  display: flex;
  align-items: center;
  width: 7.62rem;
  justify-content: space-between;
}
.logo {
  width: 1.04rem;
  height: 1.04rem;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.11rem;
}
.catalogName {
  font-size: 0.4rem;
  font-weight: bold;
  color: #444444;
  max-width: 5.06rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.head {
  display: flex;
}
.search-input {
  width: 5.6rem;
  height: 0.8rem;
  background-color: #f7f7f7;
  border-radius: 0.4rem;
  line-height: 0.8rem;
  display: flex;
  align-items: center;
}
.head .van-icon-search {
  font-size: 0.4rem;
  margin: 0 0.44rem;
  color: #bcbcbc;
}
.head .search-input input {
  font-size: 0.32rem;
  color: #bcbcbc;
  border: none;
  background-color: #f7f7f7;
  height: 0.53rem;
}
.van-nav-bar__content >>> .van-button--normal {
  padding: 0 0.13rem;
  font-size: 0.37rem;
  height: 0.8rem;
}
.van-nav-bar__content {
  height: 1.22rem;
  padding-right: 12px;
  justify-content: space-between;
}
</style>
