<template>
  <div class="course-handout">
    <zv-nav-bar :title="itemData.name"></zv-nav-bar>
    <van-tabs v-model="active" @disabled="onClickDisabled">
      <van-tab title="课程简介">
        <div class="desc-box">
          <div class="desc-box-content">
            <template v-if="itemData.enterpriseExclusive !== 1">
              <template v-if="itemData.hasPermission !== 'Y'">
                <div class="title">课程信息</div>
                <div
                  style="display: flex; margin-top: 0.37rem; font-size: 0.29rem; color: #777; justify-content: space-between;"
                  v-if="!Number(itemData.isForbid) && Number(itemData.editStatus === 2)"
                >
                  <div style="display: flex; width: 6.93rem; justify-content: space-between;">
                    <span class="current-price">￥{{ isVip ? itemData.memberPrice : itemData.sellPrice }}</span>
                    <span v-if="isVip" style="text-decoration:line-through;">￥{{ itemData.sellPrice }}</span>
                    <span v-if="isVip"
                      >企业已为你支付{{ Number(itemData.sellPrice - itemData.memberPrice).toFixed(2) }}元</span
                    >
                  </div>
                  <span>{{ itemData.viewCount }}次</span>
                </div>
              </template>
              <template v-else-if="itemData.isFree === 1">
                <div class="title">课程信息</div>
                <div
                  style="display: flex; margin-top: 0.37rem; font-size: 0.29rem; color: #777; justify-content: space-between;"
                >
                  <div style="display: flex; width: 6.93rem; justify-content: space-between;">
                    <span class="current-price">免费</span>
                  </div>
                  <span>{{ itemData.viewCount }}次</span>
                </div>
              </template>
            </template>
            <div
              class="course-desc"
              :style="{
                'margin-top':
                  itemData.enterpriseExclusive !== 1 && itemData.hasPermission === 'Y' && itemData.isFree === 0
                    ? '0'
                    : '0.6rem',
              }"
            >
              课程介绍
            </div>
            <div class="rich-text" v-if="itemData.description" v-html="itemData.description"></div>
            <div class="rich-text" v-else>
              <p style="text-align: center;">暂无介绍</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="课件" :disabled="!isPay">
        <div class="handout-box" v-if="handouts.length > 0">
          <div v-for="(src, index) in handouts" :key="index" @click.stop="preventBubbling($event, index)">
            <img :src="src" style="pointer-events:none;" />
          </div>
        </div>
        <div v-else class="handout-box">
          <p style="text-align: center">暂无数据</p>
        </div>
        <div class="circle" v-if="this.itemData.handoutID">
          <van-circle v-model="currentRate" :rate="rate" :color="color" layer-color="#eee">
            <span class="van-circle__text" :style="{ color: color }">{{ text }}</span>
          </van-circle>
        </div>
      </van-tab>
    </van-tabs>
    <div :class="itemData.practices == '0' ? 'practise disable' : 'practise'" v-if="isPay">
      <van-button round block type="info" :disabled="itemData.practices == '0' || !itemData.practices" @click="goAnswer"
        >课堂练习</van-button
      >
      <div class="share van-icon van-icon-apps-o" @click="showShare = true"></div>
    </div>
    <div class="buynow" v-else-if="itemData.enterpriseExclusive !== 1">
      <template v-if="!Number(itemData.editStatus === 2)">
        <van-button class="buy-btn--disabled" round @click="$Toast.fail('商品已下架')">已下架</van-button>
      </template>
      <template v-else>
        <div class="buy-btn-group__wrap" v-if="itemData.isForbid !== 1">
          <div class="buy-btn-group" v-if="!isVip">
            <van-button class="buy-btn--highlighted" round @click="getMemberPage('buy')"
              >企业会员价&nbsp;{{ formatAmountToFixed2(itemData.memberPrice) }}元</van-button
            >
            <van-button
              class="buy-btn"
              round
              @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}`)"
              >立即购买&nbsp;{{ formatAmountToFixed2(itemData.sellPrice) }}元</van-button
            >
          </div>
          <van-button
            style="background: #4087ef"
            v-else
            round
            block
            type="info"
            @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}`)"
            >￥{{ isVip ? itemData.memberPrice : itemData.sellPrice }} 立即购买</van-button
          >
        </div>
        <van-button v-else round block type="info" @click="getMemberPage('permission')">会员专享</van-button>
      </template>
    </div>
    <van-image-preview
      v-model="imgPreviewShow"
      :images="handouts"
      :startPosition="startPosition"
      @change="imgPrevieChange"
      :closeable="true"
    >
      <template v-slot:index>第{{ imgPrevieIndex }}张</template>
    </van-image-preview>
    <van-share-sheet v-model="showShare" :options="options" @select="onSelect" />
  </div>
</template>
<script>
import { Dialog } from 'vant'

export default {
  name: 'courseHandout',
  data() {
    return {
      showShare: false,
      active: 0,
      isPay: false,
      currentRate: 0,
      color: '#e4393c',
      time: 0,
      rate: 0,
      isVip: true,
      id: '',
      itemData: {
        enterpriseExclusive: 1,
      },
      options: [
        {
          name: '班级',
          typeName: 'class',
          icon: 'https://img01.yzcdn.cn/vant/custom-icon-fire.png',
        },
        {
          name: '查看练习',
          typeName: 'exerc',
          icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png',
        },
      ],
      handouts: [],
      imgPreviewShow: false,
      startPosition: 0,
      imgPrevieIndex: 1,
      timer: null,
      timer2: null,
      timer3: null,
      lastWatchVideoID: '',
      uploadMap: {},
      isLoad: false,
      isMember: false,
    }
  },
  watch: {
    isPay(val) {
      if (val) {
        this.$nextTick(() => {
          this.active = 1
        })
      }
    },
    active(val) {
      if (val === 1) {
        if (this.itemData.maxLearningTimes !== 0 && this.itemData.hasStudyTimes >= this.itemData.maxLearningTimes) {
          this.active = 0
          return this.$Toast.fail('超过学习次数，无法阅读讲义')
        }
        if (this.itemData.isFaceAuth === 1) {
          this.faceAuthRequest()
        }
        if (!this.isLoad) {
          this.getHandoutsData()
        } else {
          // 记录学习次数
          this.logStudyTimes()
        }
      }
    },
  },
  computed: {
    text() {
      if (this.itemData.lastPosition === this.itemData.totalLength) {
        return '完成'
      }
      return this.formatDuring(this.time)
    },
  },
  async created() {
    this.id = this.$route.params.id
    await this.getCourseData()
    this.setShare()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = Number(info.isVip) && (info.enterpriseName || info.enterpriseShorthand) ? true : false
    }
  },
  methods: {
    getMemberPage(type) {
      // 跳转企业会员相关文章页
      this.$axios({
        url: '/json/ktqyhy/index.json',
        methods: 'GET',
        params: {},
      }).then(({ data }) => {
        if (Number(data.status) !== 1) return
        if (type === 'buy') this.handleGotoMemberOrder(data.data[0].artUrl)
        else if (type === 'permission') this.handleGotoMemberOrder(data.data[1].artUrl)
      })
    },
    handleGotoMemberOrder(url) {
      const artUrl = url.substring(url.search(/json/))
      this.$router.push({
        name: 'memberInfo',
        params: {
          url: artUrl,
        },
      })
    },
    // 人脸认证
    async faceAuthRequest() {
      let info = JSON.parse(localStorage.userInfo)
      if (info.isChecked !== 1) {
        Dialog.confirm({
          title: '身份认证提示',
          message: '未进行身份认证，请先去身份认证',
        })
          .then(() => {
            this.$router.push({ path: `/user/userinfo/info` })
          })
          .catch(() => {
            return
          })
      } else {
        // 获取用户结果
        await this.$axios
          .get(`/course/front/member/face/auth?type=Course&id=${this.id}`)
          .then((res) => {
            if (res.data.status === 1) {
              if (!res.data.data.bizToken && res.data.data.isSuccess === 1) {
                return
              } else {
                window.location.href = res.data.data.url
              }
            } else {
              this.$Toast.fail(res.data.message)
              return
            }
          })
          .catch(() => {
            this.$Toast.fail('服务器开小差了，稍后重试')
            return
          })
      }
    },
    setShare() {
      const shareInfo = {
        title: `${this.itemData.name}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    onClickDisabled() {
      if (this.itemData.enterpriseExclusive === 1) {
        this.$Toast.fail('请授权后，再观看课件内容！')
      } else if (Number(this.itemData.editStatus) !== 2) {
        this.$Toast.fail('课件已下架！')
      } else if (this.itemData.isForbid !== 1) {
        this.$Toast.fail('请购买后，再观看课件内容！')
      } else {
        this.$Toast.fail('请联系管理员，成为会员才能观看课件内容！')
      }
    },
    formatDuring(time) {
      if (time >= this.itemData.totalLength * 1000) {
        return '完成'
      }
      // 换成倒计时
      let tempTime = this.itemData.totalLength * 1000 - time
      let seconds = parseInt(tempTime / 1000)
      return seconds
    },
    // 课程详情
    async getCourseData() {
      this.$Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      function backToIndex() {
        this.$Toast.fail('没有该课程权限，稍后将自动跳转回首页')
        const timer = setTimeout(() => {
          clearTimeout(timer)
          this.$router.replace({
            path: '/',
          })
        }, 2000)
        return
      }
      await this.$axios
        .get(`/course/front/permit/course/${this.id}`)
        .then((res) => {
          this.$Toast.clear()
          if (res.data.status === 1) {
            if (res.data.data.isHidden === 'Y') {
              backToIndex.bind(this)()
            }
            this.itemData = res.data.data
            this.isPay = res.data.data.hasPermission === 'Y'
            this.time = this.itemData.lastPosition * 1000
            this.rate = (this.itemData.lastPosition / this.itemData.totalLength) * 100
            if (this.rate >= 100) {
              this.color = '#1989fa'
            }
            this.paperID = res.data.data.practices
            if (res.data.data.isDoneExerc !== 'Y') {
              this.options.splice(1, 1)
            }
            if (res.data.data.workID !== 0) {
              this.options.push({
                name: '作业',
                typeName: 'work',
                icon: 'https://img01.yzcdn.cn/vant/custom-icon-water.png',
                ID: res.data.data.workID,
              })
            }
          } else {
            this.$Toast.fail(res.data.message)
            setTimeout(() => {
              this.$router.push('/')
            }, 2000)
          }
        })
        .catch((e) => {
          this.$Toast.clear()
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 课程讲义详情
    getHandoutsData() {
      this.$Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      if (!this.itemData.handoutID || this.itemData.handoutID == 0) {
        this.$Toast.clear()
        return
      }
      this.$axios
        .get(`/course/front/course/${this.id}/handout?handoutID=${this.itemData.handoutID}`)
        .then((res) => {
          this.$Toast.clear()
          if (res.data.status === 1) {
            // 记录学习次数
            this.logStudyTimes()
            this.isLoad = true
            this.handouts = res.data.data.url
            if (this.itemData.lastPosition === this.itemData.totalLength) {
              return
            }
            this.$nextTick(() => {
              this.initTime()
              this.uploadMap[this.lastWatchVideoID] = this.uploadMap[this.lastWatchVideoID]
                ? this.uploadMap[this.lastWatchVideoID]
                : {}
              this.uploadMap[this.lastWatchVideoID].prePostion = 0
              this.uploadMap[this.lastWatchVideoID].spaceTime = 1
              this.setUploadvideoschedule()
              this.timer2 = setInterval(() => {
                this.uploadMap[this.lastWatchVideoID] && (this.uploadMap[this.lastWatchVideoID].spaceTime += 1)
              }, 1000)
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.clear()
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    logStudyTimes() {
      const formData = new FormData()
      formData.append('courseID', this.id)
      this.$axios.post('/course/front/course/studytimes', formData).then((res) => {
        if (res.data.status === 1) {
          this.itemData.hasStudyTimes = res.data.data
        } else {
          this.isNotMaxCount = false
          this.$Toast.fail(res.data.message)
        }
      })
    },
    preventBubbling(e, index) {
      this.startPosition = index
      this.imgPreviewShow = true
      return false
    },
    imgPrevieChange(index) {
      this.imgPrevieIndex = index + 1
    },
    initTime() {
      this.lastWatchVideoID = this.itemData.lastWatchVideoID
      let count = 0
      this.timer3 = setInterval(() => {
        count += 100 / this.itemData.totalLength
        if (count >= 100) {
          this.color = '#1989fa'
        }
        if (count <= 100) {
          this.rate += 100 / this.itemData.totalLength
        }
        this.time = this.time + 1000
      }, 1000)
    },
    setUploadvideoschedule() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        this.postVideoschedule()
      }, 1000 * 30)
    },
    postVideoschedule() {
      let videoID = this.itemData.lastWatchVideoID
      if (!this.uploadMap[videoID]) {
        return
      }
      let params = new FormData()
      let position = Math.ceil(this.time / 1000) - 1
      let _spaceTime = this.uploadMap[videoID].spaceTime > 30 ? 30 : this.uploadMap[videoID].spaceTime
      params.append('videoID', videoID)
      params.append('position', position)
      params.append('videoType', 'hd')
      params.append('spaceTime', _spaceTime)
      params.append('speed', 1)
      params.append('platform', 'h5')
      this.uploadMap[videoID].prePostion = position
      this.uploadMap[videoID].spaceTime = 0
      this.$axios.post(`/course/front/course/${this.id}/uploadvideoschedule`, params).then((res) => {
        if (res.data.status === 1) {
          this.uploadMap[videoID].lastPosition = position
        } else {
          this.$$Toast.fail(res.data.message)
        }
      })
    },
    onSelect(item) {
      if (item.typeName === 'exerc') {
        // 练习详情页
        this.$router.push({
          path: `/study/studyreport/${this.paperID}/${this.$route.params.id}`,
        })
      } else if (item.typeName === 'work') {
        // 作业详情页
        this.$router.push({
          path: `/nzzone/workDetails/${item.ID}?isHiddenTime=${this.itemData.isFree === 1 &&
            this.itemData.enterpriseExclusive === 0}`,
        })
      } else {
        this.$router.push({
          path: `/allCourses/classByCourse/${this.$route.params.id}`,
        })
      }
    },
    gotoPage(path) {
      this.$router.push({
        path: path,
      })
    },
    goAnswer() {
      const item = this.itemData
      if (item.practices === '0' || !item.practices) {
        return this.$Toast.fail('暂无课程练习！')
      }
      this.$axios
        .get(`/tk/front/paper/${item.practices}/checkandgeneratekey?courseID=${this.id}`)
        .then((res) => {
          if (res.data.status === 1) {
            // 前往答题页面
            this.$router.push({
              path: `/allCourses/examinationpaper?id=${item.ID}&isPhoto=${item.isPhoto}&key_success=${res.data.data.key_success}&handoutID=${this.id}`,
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    formatAmountToFixed2(amount) {
      return Number(amount).toFixed(2)
    },
  },
  destroyed() {
    if (this.isPay) {
      this.postVideoschedule()
    }
    clearInterval(this.timer)
    clearInterval(this.timer2)
    clearInterval(this.timer3)
  },
}
</script>
<style scoped>
.visible-hidden {
  visibility: hidden;
}
.course-handout {
  background-color: #eee;
}
.buy-btn-group__wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.buy-btn-group {
  display: flex;
  width: 100%;
}
.buy-btn {
  background: linear-gradient(to right, #63a1ed, #97d0f5);
  color: #fff;
}
.buy-btn--highlighted {
  background: linear-gradient(to right, #532c10, #7f583b);
  color: #ffebb6;
}
.buy-btn--disabled {
  background: #acafb3;
  color: #fff;
}
.course-handout >>> .van-button--round {
  margin: 0.19rem 0.28rem;
  width: calc(100% - 0.56rem);
}
.course-handout >>> .van-button--normal {
  padding: 0 0.4rem;
  font-size: 0.37rem;
}
.practise.disable >>> .van-button.van-button--info {
  color: #fff;
  background-color: #585f67;
  border: 1px solid #585f67;
}
.course-handout >>> .van-image-preview__image img {
  pointer-events: none;
}
.course-handout >>> .van-tabs--line .van-tabs__wrap {
  height: 1.17rem;
}
.course-handout >>> .van-tabs__line {
  bottom: 0.4rem;
  width: 1.06rem;
  height: 0.08rem;
  border-radius: 0.08rem;
}
.course-handout >>> .van-tabs__nav--line {
  padding-bottom: 0.4rem;
}
.course-handout >>> .van-tab {
  padding: 0 0.1rem;
  font-size: 0.37rem;
  line-height: 0.53rem;
}
.course-handout >>> .van-tabs__content {
  background-color: #fff;
  margin-top: 0.13rem;
}
.course-handout >>> .van-tab span.van-tab__text {
  font-size: 0.37rem;
  font-weight: bold;
}
.course-handout >>> .van-tab.van-tab--active span.van-tab__text {
  color: #1578f2;
}
.course-handout >>> .van-tabs__line {
  background-color: #1578f2;
}
.desc-box {
  padding: 0.39rem 0.8rem 1.61rem 0.7rem;
}
.desc-box .title {
  font-size: 0.37rem;
  color: #333333;
}
.desc-box .current-price {
  font-size: 0.53rem;
  line-height: 0.45rem;
  color: #000000;
}
.desc-box .course-desc {
  font-size: 0.37rem;
  color: #333333;
  margin-top: 0.6rem;
}
.desc-box .rich-text >>> p {
  font-size: 0.35rem;
  line-height: 0.56rem;
  color: #666666;
  margin: 0;
}
.desc-box .rich-text >>> li {
  font-size: 0.64rem;
}
.desc-box .rich-text >>> li::marker {
  font-size: 0.37rem;
}
.desc-box .rich-text img {
  max-width: 100% !important;
}

.desc-box .rich-text >>> img {
  max-width: 100% !important;
}
.course-handout .practise >>> .van-button {
  width: 8.29rem;
}
.practise {
  height: 1.61rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.buynow {
  height: 1.61rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.buynow >>> .van-button {
  width: 8.88rem;
}
.handout-box {
  padding: 0.6rem 0.63rem 1.61rem 0.48rem;
}
.handout-box p {
  font-size: 0.35rem;
  line-height: 0.64rem;
  color: #333333;
  margin: 0;
}
.handout-box >>> img {
  max-width: 100%;
}
.circle {
  position: fixed;
  bottom: 1.65rem;
  right: 0.26rem;
}
.circle >>> .van-circle {
  width: 1.33rem;
  height: 1.33rem;
}
.circle >>> .van-circle__text {
  font-size: 0.37rem;
  line-height: 0.53rem;
  padding: 0 0.1rem;
  color: #e4393c;
}
</style>
