<template>
  <div class="user-phone register">
    <zv-nav-bar :title="pagename"></zv-nav-bar>
    <van-row>
      <van-col span="24" align="center">
        <van-form @submit="onSubmit" class="login-form">
          <span class="form-item-label">手机号</span>
          <van-field v-model="form.mobile" name="mobile" placeholder="请输入您的手机号" :maxlength="11" ::rules="[{ validator: validator, required: true, message: '请输入正确的手机号' }]" />
          <span class="form-item-label">验证码</span>
          <van-field v-model="form.smsCode" name="smsCode" placeholder="请输入验证码" :maxlength="4" :rules="[{ required: true, message: '请输入验证码' }]">
            <template #button>
              <van-loading v-if="loading">
                <span>{{ codeText }}</span>
              </van-loading>
              <span v-else @click="getVerificationCode">{{ codeText }}</span>
            </template>
          </van-field>
           <div style="margin: 1.1rem 0.67rem 1.2rem 0.67rem;">
            <van-button round block type="info" native-type="submit">完成</van-button>
          </div>
        </van-form>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
  name: "changephone",
  data() {
    return {
      loading: false,
      pagename: "修改手机号码",
      timer: null,
      codeText: "发送验证码",
      form: {
        mobile: "",
        smsCode: "",
      },
    };
  },
  created() {
    this.pagename = this.$route.query.title || '修改手机号码'
  },
  methods: {
    validator(val) {
      let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      return reg.test(val)
    },
    onSubmit(data) {
      let params = new FormData()
      params.append('mobile', data.mobile)
      params.append('smsCode', data.smsCode)
      this.$axios.post('/course/front/member/modifyPhone', params, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then(res => {
        if (res.data.status === 1) {
          let userInfo = JSON.parse(localStorage.userInfo)
          userInfo.mobile = data.mobile
          localStorage.userInfo = JSON.stringify(userInfo)
          this.$Toast.success(res.data.message)
          const timer = setTimeout(() => {
            this.$router.go(-1)
            clearTimeout(timer)
          }, 1000)
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        this.$Toast.fail('服务器开小差了，稍后重试')
        return e
      })
    },
    // 获取手机验证码
    getVerificationCode() {
      if (this.loading) {
        return false
      }
      if (this.timer) {
        return false
      }
      if (!this.form.mobile) {
        return false
      }
      this.loading = true
      let data = new FormData()
      data.append('mobile', this.form.mobile)
      this.$axios.post(`/course/front/member/checkPhone`, data).then(res => {
        if (res.data.status === 1) {
          // 请求接口获取验证码
          this.getCode()
        } else {
          this.loading = false
          if (res.data.data === '602') {
            Dialog.confirm({
              message: res.data.message,
              confirmButtonText: '确定使用',
              cancelButtonText: '取消使用'
            }).then(() => {
              this.getCode()
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        }
      }).catch(e => {
        this.loading = false
        this.$Toast.fail('服务器开小差了，稍后重试')
        return e
      })
    },
    getCode() {
      // 请求接口获取验证码
      this.$axios.get(`/course/front/member/login/smssend?mobile=${this.form.mobile}`).then(res => {
        this.loading = false
        if (res.data.status === 1) {
          let i = 60;
          this.timer = setInterval(() => {
            i = Number(i) - 1;
            this.codeText = i + "s";
            if (i <= 0) {
              clearInterval(this.timer);
              this.timer = null;
              this.codeText = "发送验证码";
            }
          }, 1000)
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        this.loading = false
        this.$Toast.fail('服务器开小差了，稍后重试')
        return e
      })
    }
  }
}
</script>

<style scoped>
.tips {
  font-size: 0.53rem;
  font-weight: bold;
  color: #333333;
  margin: 0.87rem 1.68rem 0 0.68rem;
}
.login-form {
  margin-top: 0.93rem;
}
.form-item-label {
  text-align: left;
  display: block;
  text-indent: 0.87rem;
  font-size: 0.37rem;
  color: #b9b9b9;
}
.register div.van-cell.van-field {
  margin: 0.33rem 0;
  padding: 0 0.67rem;
}
.register >>> .van-cell__value.van-cell__value--alone.van-field__value {
  background-color: #f8f8f8;
  border-radius: 0.57rem;
  height: 1.13rem;
  line-height: 1.13rem;
}
.register >>> .van-cell {
  font-size: 0.37rem;
}
.register >>> .van-cell::after {
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  border-bottom: none;
}
.register >>> .van-field__body input.van-field__control {
  text-indent: 0.62rem;
}
.register >>> .van-dropdown-menu {
  margin: 0.33rem 0;
  padding: 0 0.67rem;
}
.register >>> .van-dropdown-menu__bar {
  background-color: #f8f8f8;
  border-radius: 0.57rem;
  height: 1.13rem;
  line-height: 1.13rem;
  box-shadow: unset;
}
.register >>> .van-dropdown-menu__title {
  font-size: 0.37rem;
  line-height: 0.48rem;
}
.register >>> .van-field__button {
  position: absolute;
  right: 0.625rem;
  border-left: 1px solid #e3e3e3;
  height: 0.6875rem;
  line-height: 0.6875rem;
  color: #1578f2;
  font-size: 0.37rem;
}
.register >>> .van-button {
  height: 1.27rem;
  font-size: 0.37rem;
}
</style>
