export default [
  // 搜索页
  {
    path: '/index/search',
    name: 'search',
    component: () => import('@/page/index/search/Search')
  },
  // 搜索结果页
  {
    path: '/index/search/searchResult',
    name: 'searchResult',
    component: () => import('@/page/index/search/SearchResult')
  },
  // 联系客服
  {
    path: '/index/customerService',
    name: 'customerService',
    component: () => import('@/page/index/customerService/Index')
  },
  // 新闻资讯
  {
    path: '/index/news',
    name: 'news',
    component: () => import('@/page/index/news/News')
  },
  // 新闻资讯详情
  {
    path: '/index/news/newsDetail/:url',
    name: 'newsDetail',
    component: () => import('@/page/index/news/NewsDetail')
  },
  // 名师列表
  {
    path: '/index/teacher',
    name: 'teacher',
    component: () => import('@/page/index/teacher/Teacher')
  },
  // 名师详情
  {
    path: '/index/teacherDetail/:id',
    name: 'teacherDetail',
    component: () => import('@/page/index/teacher/TeacherDetail')
  },
  // 人脸核身结果跳转
  {
    path: '/index/faceAuthResult',
    name: 'faceAuthResult',
    component: () => import('@/page/index/FaceAuthResult')
  }
]
