<template>
  <div class="user-study clearfix">
    <zv-nav-bar title="我的考试" :sourceType="sourceType"></zv-nav-bar>
    <div class="study-tabs">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-row class="course-box class" v-for="item in examList" :id="item.ID" :key="item.ID" @click="goAnswer(item)">
          <div class="course-top">
            <span class="title-tag" :class="{ platform: item.enterpriseExclusive === 0 }">{{ item.isOralExam === 'Y' ? '口语' : '考试' }}</span>
            <span class="title-name">{{ item.name }} </span>
            <span class="tag--exam-type" :class="{ 'tag--exam-type--official': item.productType === 25 }">{{ item.productType === 25 ? '正式' : '模拟' }}</span>
          </div>
          <div class="course-bottom">
            {{ item.timeRange }}
            <span class="duration">（{{ item.time }}分钟）</span>
          </div>
          <div class="course-tips urgent" v-if="item.isExpedited === 1">加急</div>
          <div class="course-tips finish" v-else-if="item.status === 'Y'">已完成</div>
          <div class="course-tips" v-else-if="item.status === 'N' || !item.status">未完成</div>
        </van-row>
      </van-list>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'

export default {
  name: 'myExam',
  data() {
    return {
      loading: false,
      finished: false,
      pageIndex: 0,
      examList: [],
      toAnswerExamID: 0,
      sourceType: '',
      isBack: '',
    }
  },
  created() {
    this.setShare()
    this.toAnswerExamID = this.$route.query.toAnswerExamID ? parseInt(this.$route.query.toAnswerExamID) : 0
    this.isBack = this.$route.query.isBack
    if (this.isBack === 'Y') {
      this.sourceType = 'myExam'
      // 禁用物理返回键（勿删）
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    onLoad() {
      this.$axios
        .get(`/tk/front/member/personalcenter/exams?pageSize=10&pageIndex=${this.pageIndex}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.examList = this.examList.concat(res.data.data.data)
            if (this.examList.length >= res.data.data.total) {
              this.finished = true
            } else {
              this.pageIndex = this.pageIndex + 1
            }
            // if (this.toAnswerExamID > 0 && this.examList.length > 0) {
            //   // 如果接口重定向进来的,直接去指定的考试答题
            //   let list = this.examList.filter(item => { return this.toAnswerExamID === item.ID })
            //   if (list && list.length > 0) {
            //     this.toAnswerExamID = 0
            //     this.goAnswer(list[0], true)
            //   }
            // }
          } else {
            this.$Toast.fail(res.data.message)
            this.finished = true
          }
          this.loading = false
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          this.loading = false
          this.finished = true
          return e
        })
    },
    goAnswerRequest(item) {
      this.$axios
        .get(`/tk/front/paper/${item.productID}/checkandgeneratekey?examID=${item.ID}`)
        .then((res) => {
          if (res.data.status === 1) {
            // 前往答题页面
            this.$router.push({
              path: `/allCourses/examinationpaper?id=${item.ID}&isPhoto=${item.isPhoto}&key_success=${res.data.data.key_success}`,
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    goAnswer(item) {
      // if (item.isOralExam === 'Y') {
      //   console.log('跳转小程序...')
      //   return
      // }
      if (item.status === 'Y') {
        // 前往考试完成页面
        this.$router.push({
          path: `/study/testresults/${item.productID}/${item.ID}`,
        })
        return
      }
      if (new Date().getTime() - new Date(item.beginTime).getTime() < 0) {
        return this.$Toast.fail('考试未开始！')
      }
      if (new Date().getTime() - new Date(item.endTime).getTime() > 0) {
        return this.$Toast.fail('考试已过期！')
      }
      if (item.isIdCardAuth === 1 && item.isFaceAuth === 0) {
        let info = JSON.parse(localStorage.userInfo)
        if (info.isChecked !== 1) {
          Dialog.confirm({
            title: '身份认证提示',
            message: '未进行身份认证，请先去身份认证',
          })
            .then(() => {
              this.$router.push({ path: `/user/userinfo/info` })
            })
            .catch(() => {
              return
            })
        } else {
          this.goAnswerRequest(item)
        }
      } else if (item.isIdCardAuth === 1 && item.isFaceAuth === 1) {
        this.faceAuthRequest(item)
      } else {
        this.goAnswerRequest(item)
      }
    },
    // 人脸认证
    async faceAuthRequest(item) {
      let info = JSON.parse(localStorage.userInfo)
      if (info.isChecked !== 1) {
        Dialog.confirm({
          title: '身份认证提示',
          message: '未进行身份认证，请先去身份认证',
        })
          .then(() => {
            this.$router.push({ path: `/user/userinfo/info` })
          })
          .catch(() => {
            return
          })
      } else {
        this.$axios
          .get(`/course/front/member/face/auth/issuccess?type=MyExam&id=${item.ID}`)
          .then((res) => {
            if (res.data.status === 1) {
              if (res.data.data.isSuccess !== 1) {
                Dialog.confirm({
                  title: '温馨提示',
                  message: '即将进入人脸认证，是否继续？',
                })
                  .then(() => {
                    this.getFaceAuthUrl(item, false)
                  })
                  .catch(() => {
                    return
                  })
              } else {
                this.goAnswerRequest(item)
              }
            } else {
              this.$Toast.fail(res.data.message)
              return
            }
          })
          .catch(() => {
            this.$Toast.fail('服务器开小差了，稍后重试')
            return
          })
      }
    },
    getFaceAuthUrl(item) {
      // 获取用户结果
      this.$axios
        .get(`/course/front/member/face/auth?type=MyExam&id=${item.ID}`)
        .then((res) => {
          if (res.data.status === 1) {
            if (!res.data.data.bizToken && res.data.data.isSuccess === 1) {
              this.goAnswerRequest(item)
            } else {
              window.location.href = res.data.data.url
            }
          } else {
            this.$Toast.fail(res.data.message)
            return
          }
        })
        .catch(() => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return
        })
    },
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>
<style lang="less" scoped>
.user-study {
  background-color: #f8f8f8;
  padding-bottom: 1.3rem;
  min-height: 16.48rem;
  .study-tabs {
    /deep/ .van-tabs__nav {
      background-color: rgba(0, 0, 0, 0);
    }
    .course-box {
      padding: 0.33rem;
      margin: 0.33rem 0.33rem 0 0.33rem;
      background-color: #fff;
      border-radius: 0.13rem;
      position: relative;
      overflow: hidden;
      .course-top {
        display: flex;
        align-items: flex-start;
        width: 8.6667rem;
        padding-right: 1.6rem;
        box-sizing: border-box;
        .title-tag {
          display: inline-block;
          height: 0.53rem;
          line-height: 0.53rem;
          border-radius: 0.08rem;
          background-color: #70dd96;
          font-family: PingFang-SC-Medium;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #fff;
          text-align: center;
          padding: 0 0.15rem;
          white-space: nowrap;
        }
        .tag--exam-type {
          display: inline-block;
          height: 0.53rem;
          padding: 0 0.08rem;
          margin-left: 0.1333rem;
          background-color: #ff9250;
          border-radius: 0.0533rem;
          letter-spacing: 0.0267rem;
          font-size: 0.32rem;
          font-weight: 500;
          line-height: 0.53rem;
          color: #fff;
          white-space: nowrap;
        }
        .tag--exam-type--official {
          background-color: #fe714b;
        }
        .platform {
          background-color: #fbc047;
        }
        .title-name {
          font-family: PingFang-SC-Heavy;
          font-size: 0.43rem;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #111111;
          margin: 0 0.2rem;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      .course-bottom {
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #999999;
        margin-top: 0.32rem;
        display: flex;
        .duration {
          margin-left: 0.9733rem;
        }
      }
      .course-tips {
        width: 4rem;
        height: 0.71rem;
        font-family: PingFang-SC-Regular;
        font-size: 0.32rem;
        font-weight: 400;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #ffffff;
        transform: rotate(40deg);
        position: absolute;
        background-image: linear-gradient(-90deg, #999 0%, #fff 100%);
        right: -1.39rem;
        top: 0.16rem;
        text-align: center;
        line-height: 0.79rem;
      }
      .urgent {
        background-image: linear-gradient(-90deg, #ca2d2f 0%, #d8514a 100%);
      }
      .finish {
        background-image: linear-gradient(-90deg, #70dd96 0%, #22ac51 100%);
      }
    }
  }
}
</style>
