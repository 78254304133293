<template>
  <div class="nzzone-classcourses">
    <zv-nav-bar :title="pagename" :clickLeft="clickLeft"> </zv-nav-bar>
    <van-tabs line-width="0.28rem" title-active-color="#222" title-inactive-color="#999" color="#1578f2" animated @click="onClickTabs" v-model="active">
      <van-tab v-for="item in tablist" :key="item.id" :disabled="active !== item.id && loading" :name="item.id">
        <template #title>
          <div :class="hasUnfinishedExam && item.id === 3 && 'examTab--unfinished'">{{ item.label }}</div>
        </template>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="active !== 0">
          <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
            <template v-if="active === 0">
              <van-col span="24" v-for="item in listData" :id="item.ID" :key="item.ID">
                <van-row class="course-box" @click="goto(item)">
                  <div class="course-top">
                    <span :class="{ 'title-tag': true, 'enterprise-tag': item.enterpriseExclusive === 1 }">{{ item.resourceTypeName }}</span>
                    <span class="title-name">{{ item.name }}</span>
                    <span class="title-type" v-if="item.isCompulsory !== '选修'">{{ item.isCompulsory }}</span>
                  </div>
                  <div class="course-bottom" v-if="item.timeRange">{{ item.timeRange }}</div>
                  <div class="course-bottom" v-else>暂无时间</div>
                  <div class="course-tips " :class="resolvetype(item.studyStatus)">{{ item.studyStatusName }}</div>
                </van-row>
              </van-col>
            </template>
            <template v-if="active === 1">
              <van-col span="24" v-for="item in listData" :id="item.ID" :key="item.workID">
                <van-row class="course-box" @click="gotoPage(`/nzzone/workDetails/${item.workID}?classId=${$route.query.id}`)">
                  <div class="course-top">
                    <span :class="{ 'title-tag': true, 'enterprise-tag': !isShow }">作业</span>
                    <span class="title-name">{{ item.name }}</span>
                  </div>
                  <div class="course-bottom">{{ item.publishTime }}-{{ item.endTime }}</div>
                  <div class="course-tips " :class="resolvetypeWork(item.status)">{{ item.statusName }}</div>
                </van-row>
              </van-col>
            </template>
            <template v-if="active === 2 && isShow">
              <van-col span="24" v-for="item in listData" :id="item.ID" :key="item.ID">
                <van-row class="course-box" @click="gotoPage(`/allCourses/listDetails/${item.ID}`)">
                  <div class="course-top">
                    <span :class="{ 'title-tag': true, 'enterprise-tag': item.enterpriseExclusive === 1 }">直播</span>
                    <span class="title-name">{{ item.name }}</span>
                  </div>
                  <div class="course-bottom">
                    <span style="margin-right: 0.4rem;">{{ item.listLiveStartTime }}</span>
                    <span>({{ item.liveLength }}分钟)</span>
                  </div>
                  <div class="course-tips " :class="resolvetype(item.studyStatus)">{{ item.studyStatusName }}</div>
                </van-row>
              </van-col>
            </template>

            <template v-if="active === 3">
              <div class="course-box" v-for="item in listData" :key="item.ID" @click="goAnswer(item)">
                <div class="course-top">
                  <span class="title-tag" :class="[item.enterpriseExclusive === 0 ? 'platform' : 'enterprise']">{{ item.isOralExam === 'Y' ? '口语' : '考试' }}</span>
                  <span class="title-name">{{ item.name }} </span>
                  <span class="tag--exam-type" :class="{ 'tag--exam-type--official': item.productType === 25 }">{{ item.productType === 25 ? '正式' : '模拟' }}</span>
                </div>
                <div class="course-bottom">
                  <span style="margin-right: 0.4rem;">{{ item.listTimeRange }}</span>
                  <span>({{ item.time }}分钟)</span>
                </div>
                <div class="course-tips kaibo" v-if="item.isExpedited === 1">加急</div>
                <div class="course-tips wancheng" v-else-if="item.status === 'Y'">已完成</div>
                <div class="course-tips" v-else-if="item.status === 'N' || !item.status">未完成</div>
              </div>
            </template>
          </van-list>
        </van-pull-refresh>
        <div class="course-tree" v-else>
          <!-- <van-list @load="onLoad" :finished="finished"> -->
          <!-- <van-cell v-for="item in courseTreeData.catalogs" :key="item.ID"> -->
          <leaf v-for="item in courseTreeData.catalogs" :key="item.ID" :data="item" @clickItem="handleGotoCourse" />
          <!-- </van-cell> -->
          <!-- <van-cell v-for="item in courseTreeData.courses" :key="item.ID"> -->
          <leaf v-for="item in courseTreeData.courses" :key="item.ID" :data="item" @clickItem="handleGotoCourse" />
          <!-- </van-cell> -->
          <!-- </van-list> -->
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Leaf from './components/Leaf.vue'
import { Dialog } from 'vant'
export default {
  name: 'classcourses',
  components: {
    Leaf,
  },
  data() {
    return {
      pagename: '',
      tablist: [
        {
          id: 0,
          label: '课程',
        },
        {
          id: 1,
          label: '作业',
        },
        {
          id: 2,
          label: '直播',
        },
        {
          id: 3,
          label: '考试',
        },
      ],
      active: 0,
      refreshing: false,
      loading: false,
      finished: false,
      listData: [],
      pageIndex: 0,
      finishedText: '',
      isShow: true,
      isBack: '',
      courseTreeData: [],
      backUrl: '',
      hasUnfinishedExam: 0,
    }
  },
  created() {
    this.pagename = decodeURIComponent(decodeURIComponent(this.$route.query.title)) || '班级全部课程'
    this.active = this.$route.query.type ? Number(this.$route.query.type) : 0
    this.isShow = this.$route.query.showLive === 'true'
    let id = this.$route.query.id

    if (!this.isShow) {
      this.tablist = this.tablist.filter(({ id }) => id !== 2)
      if (this.active === 2) this.active = 0
    }
    this.setShare()
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
    this.getUnfinishedExam(id)

    this.onLoad()
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    clickLeft() {
      this.backUrl = this.$route.query.backUrl
      if (this.backUrl) {
        this.$router.replace({ path: this.backUrl })
      } else if (this.isBack === 'Y') {
        this.$router.replace({
          path: `/nzzone/classinfo?id=${this.$route.query.id}&isBack=Y`,
        })
      } else {
        this.$router.replace({
          path: `/nzzone/classinfo?id=${this.$route.query.id}`,
        })
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.pagename}课程列表`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    resolvetype(studyStatus) {
      return studyStatus == 0 ? '' : studyStatus == 2 ? 'dianping' : studyStatus == 1 ? 'kaibo' : 'lianxi'
    },
    resolvetypeWork(studyStatus) {
      return studyStatus == 0 ? 'unfinish' : studyStatus == 1 ? 'shenhe' : studyStatus == 2 ? 'wancheng' : 'back'
    },
    onRefresh() {
      this.pageIndex = 0
      this.listData = []
      this.$nextTick(() => {
        this.finished = false
        this.loading = true
        this.onLoad()
      })
    },
    async onLoad() {
      let id = this.$route.query.id
      this.$axios.get(`/course/front/class/${id}`).then(({ data }) => {
        if (Number(data.status !== 1)) return this.$Toast.fail('网络不好，请刷新')
        if (data.data.hasPermission !== 'Y') {
          this.$Toast.fail('没有该班级权限，稍后将自动跳转回首页')
          const timer = setTimeout(() => {
            clearTimeout(timer)
            this.$router.replace({
              path: '/',
            })
          }, 2000)
          return
        }
      })
      let path = `/course/front/class/${id}/more/course?pageIndex=${this.pageIndex}&pageSize=10`
      if (this.active === 1) {
        path = `/course/front/class/${id}/work?pageIndex=${this.pageIndex}&pageSize=10`
      } else if (this.active === 2) {
        path = `/course/front/class/${id}/live?pageIndex=${this.pageIndex}&pageSize=10`
      } else if (this.active === 3) {
        path = `/tk/front/class/exam?classID=${id}&pageIndex=${this.pageIndex}&pageSize=10`
      }
      this.loading = true
      try {
        const res = await this.$axios.get(path)
        if (res.data.status === 1) {
          if (this.active === 0) {
            this.courseTreeData = res.data.data
          }
          if (this.pageIndex === 0) {
            this.listData = res.data.data.data || []
          } else {
            this.listData = this.listData.concat(res.data.data.data)
          }
          this.pageIndex = this.pageIndex + 1

          if (this.listData.length >= res.data.data.total) {
            this.finished = true
            this.finishedText = '没有更多了'
          }
          this.loading = false
        } else {
          this.$Toast.fail(res.data.message)
        }
      } catch (error) {
        this.$Toast.fail('服务器开小差了，稍后重试1')

        this.finished = true
        return error
      } finally {
        this.loading = false
        this.refreshing = false
      }
    },
    onClickTabs() {
      this.finishedText = '加载中...'
      this.pageIndex = 0
      this.listData = []
      this.onLoad()
    },
    handleGotoCourse(item) {
      this.goto(item)
    },
    goto(item) {
      let path = `/allCourses/details/${item.ID}/curriculum`
      if (item.type === 1 || item.type === '1') {
        path = `/allCourses/courseHandout/${item.ID}`
      } else {
        path = path + '?backUrl=' + encodeURIComponent(this.$router.history.current.fullPath) + '&fromStudyCenter=1'
        // let temp = this.$router.history.current.fullPath
        // localStorage.back_url = temp
      }
      this.gotoPage(path)
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    goAnswerRequest(item) {
      this.$axios
        .get(`/tk/front/paper/${item.productID}/checkandgeneratekey?examID=${item.ID}`)
        .then((res) => {
          if (res.data.status === 1) {
            // 前往答题页面
            this.$router.push({
              path: `/allCourses/examinationpaper?id=${item.ID}&isPhoto=${item.isPhoto}&key_success=${res.data.data.key_success}`,
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试2')
          return e
        })
    },
    goAnswer(item) {
      if (new Date().getTime() - new Date(item.beginTime).getTime() < 0) {
        return this.$Toast.fail('考试未开始！')
      }
      if (new Date().getTime() - new Date(item.endTime).getTime() > 0) {
        return this.$Toast.fail('考试已过期！')
      }
      if (item.status === 'Y') {
        // 前往考试完成页面
        this.$router.push({
          path: `/study/testresults/${item.productID}/${item.ID}`,
        })
        return
      }
      if (item.isIdCardAuth === 1 && item.isFaceAuth === 0) {
        let info = JSON.parse(localStorage.userInfo)
        if (info.isChecked !== 1) {
          Dialog.confirm({
            title: '身份认证提示',
            message: '未进行身份认证，请先去身份认证',
          })
            .then(() => {
              this.$router.push({ path: `/user/userinfo/info` })
            })
            .catch(() => {
              return
            })
        } else {
          this.goAnswerRequest(item)
        }
      } else if (item.isIdCardAuth === 1 && item.isFaceAuth === 1) {
        this.faceAuthRequest(item)
      } else {
        this.goAnswerRequest(item)
      }
    },
    // 人脸认证
    async faceAuthRequest(item) {
      let info = JSON.parse(localStorage.userInfo)
      if (info.isChecked !== 1) {
        Dialog.confirm({
          title: '身份认证提示',
          message: '未进行身份认证，请先去身份认证',
        })
          .then(() => {
            this.$router.push({ path: `/user/userinfo/info` })
          })
          .catch(() => {
            return
          })
      } else {
        this.$axios
          .get(`/course/front/member/face/auth/issuccess?type=ClassExam&id=${item.ID}`)
          .then((res) => {
            if (res.data.status === 1) {
              if (res.data.data.isSuccess !== 1) {
                Dialog.confirm({
                  title: '温馨提示',
                  message: '即将进入人脸认证，是否继续？',
                })
                  .then(() => {
                    this.getFaceAuthUrl(item, false)
                  })
                  .catch(() => {
                    return
                  })
              } else {
                this.goAnswerRequest(item)
              }
            } else {
              this.$Toast.fail(res.data.message)
              return
            }
          })
          .catch(() => {
            this.$Toast.fail('服务器开小差了，稍后重试3')
            return
          })
      }
    },
    getFaceAuthUrl(item) {
      let url = ''
      if (window.location.href.indexOf('#') > 0) {
        url = window.location.href.split('#')[1]
      }
      // 获取用户结果
      this.$axios
        .get(`/course/front/member/face/auth?type=ClassExam&id=${item.ID}&examRedirectUrl=${encodeURIComponent(encodeURIComponent(url))}`)
        .then((res) => {
          if (res.data.status === 1) {
            if (!res.data.data.bizToken && res.data.data.isSuccess === 1) {
              this.goAnswerRequest(item)
            } else {
              window.location.href = res.data.data.url
            }
          } else {
            this.$Toast.fail(res.data.message)
            return
          }
        })
        .catch(() => {
          this.$Toast.fail('服务器开小差了，稍后重试4')
          return
        })
    },
    async getUnfinishedExam(ID) {
      try {
        const res = await this.$axios.get(`/tk/front/class/exam/unfinished?classID=${ID}`)
        if (res.data.status === 1) {
          this.hasUnfinishedExam = res.data.data
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>
<style lang="less" scoped>
.nzzone-classcourses {
  background-color: #f8f8f8;
  min-height: 100vh;
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 0.88rem;
  }
  /deep/ .van-tab {
    font-size: 0.37rem;
  }
  /deep/ .van-tab__text--ellipsis {
    overflow: initial;
  }
  .share::before {
    color: #515151;
  }
  .course-tree {
    padding: 0.3333rem;
  }
  .course-box {
    padding: 0.33rem;
    margin: 0.33rem 0.33rem 0 0.33rem;
    background-color: #fff;
    border-radius: 0.13rem;
    position: relative;
    overflow: hidden;
    .course-top {
      display: flex;
      align-items: center;
      .title-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.53rem;
        border-radius: 0.08rem;
        background-color: #fbc047;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #fff;
        text-align: center;
        padding: 0 0.05rem;
      }
      .enterprise-tag {
        height: 0.53rem;
        line-height: 0.53rem;
        border-radius: 0.08rem;
        border: solid 0.01rem #70dd96;
        background-color: #70dd96;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #fff;
        text-align: center;
        padding: 0 0.05rem;
      }
      .title-name {
        font-family: PingFang-SC-Heavy;
        font-size: 0.43rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #111111;
        margin: 0 0.2rem;
        max-width: 5.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title-type {
        height: 0.4rem;
        line-height: 0.4rem;
        background-color: #ff9250;
        border-radius: 0.05rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #ffffff;
        padding: 0 0.05rem;
      }
    }
    .course-bottom {
      font-family: PingFang-SC-Medium;
      font-size: 0.32rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #999999;
      margin-top: 0.32rem;
    }
    .course-tips {
      width: 4rem;
      height: 0.71rem;
      font-family: PingFang-SC-Regular;
      font-size: 0.32rem;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #ffffff;
      transform: rotate(40deg);
      position: absolute;
      background-image: linear-gradient(-90deg, #999 0%, #fff 100%);
      right: -1.39rem;
      top: 0.16rem;
      text-align: center;
      line-height: 0.79rem;
    }

    .dianping {
      background-image: linear-gradient(-90deg, #cca779 0%, #e2be91 100%);
    }
    .kaibo {
      background-image: linear-gradient(-90deg, #ca2d2f 0%, #d8514a 100%);
    }
    .lianxi {
      background-image: linear-gradient(-90deg, #cba778 0%, #e2be91 100%);
    }
    .unfinish {
      background-image: linear-gradient(-90deg, #4c9cff 0%, #1578f2 100%);
    }
    .shenhe {
      background-image: linear-gradient(-41deg, #fe9231 1%, #ffae0d 100%);
    }
    .wancheng {
      background-image: linear-gradient(-41deg, #04c761 0%, #50d590 100%);
    }
    .unpush {
      background-image: linear-gradient(-90deg, #ff5134 0%, #ff8380 100%);
    }
    .back {
      background-image: linear-gradient(-90deg, #999 0%, #fff 100%);
    }
  }
  .tag--exam-type {
    display: inline-block;
    height: 0.53rem;
    line-height: 0.53rem;
    padding: 0 0.08rem;
    margin-left: 0.1333rem;
    background-color: #ff9250;
    border-radius: 0.0533rem;
    letter-spacing: 0.0267rem;
    font-size: 0.32rem;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
  }
  .tag--exam-type--official {
    background-color: #fe714b;
  }

  .examTab--unfinished {
    position: relative;
  }

  .examTab--unfinished::before {
    content: '';
    position: absolute;
    right: -5px;
    top: -3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
  }
}
.platform {
  background-color: #fbc047 !important;
}
.enterprise {
  background-color: #70dd96 !important;
}
</style>
