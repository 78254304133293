<template>
  <div>
    <zv-nav-bar :title="'错题分类练习'">
      <span class="count">共{{allCount}}题</span>
    </zv-nav-bar>
    <div class="content-box">
      <span class="label">选择题目分类：</span>
      <div class="select-box">
        <div class="select-box-item">
          <div class="label">二级分类：</div>
          <van-field readonly clickable :value="twoClassValue" placeholder="选择二级分类" @click="showClasTwo = true"/>
          <div class="count">{{secondLevelCount || 0}}题</div>
        </div>
        <div class="select-box-item">
          <div class="label">三级分类：</div>
          <van-field readonly clickable :value="threeClassValue" placeholder="选择三级分类" @click="threeSelectClick"/>
          <div class="count">{{thirdLevelCount || 0}}题</div>
        </div>
        <div class="select-box-item">
          <div class="label">四级分类：</div>
          <van-field readonly clickable :value="fourClassValue" placeholder="选择四级分类" @click="fourSelectClick"/>
          <div class="count">{{forthLevelCount || 0}}题</div>
        </div>
      </div>
      <div class="select-count-box">
        <div class="count-label">选择题目数量：</div>
        <van-radio-group v-model="radio" direction="horizontal">
          <van-radio name="10">10</van-radio>
          <van-radio name="20">20</van-radio>
          <van-radio name="30">30</van-radio>
          <van-radio name="50">50</van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="bottom-btn-box">
      <div @click="getErrorquestion">
        <span>开始练习</span>
      </div>
    </div>
    <van-popup v-model="showClasTwo" round position="bottom">
      <van-picker show-toolbar :columns="columnsTwo" @cancel="onCancelTwo" cancel-button-text="清除" @confirm="onConfirmTwo"/>
    </van-popup>
    <van-popup v-model="showClasThree" round position="bottom">
      <van-picker show-toolbar :columns="columnsThree" @cancel="onCancelThree" cancel-button-text="清除" @confirm="onConfirmThree"/>
    </van-popup>
    <van-popup v-model="showClasFour" round position="bottom">
      <van-picker show-toolbar :columns="columnsFour" @cancel="onCancelFour" cancel-button-text="清除" @confirm="onConfirmFour"/>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: 'wrongClassExe',
  data() {
    return {
      showClasTwo: false,
      showClasThree: false,
      showClasFour: false,
      twoClassValue: '',
      twoClassID: '',
      threeClassValue: '',
      threeClassID: '',
      fourClassValue: '',
      fourClassID: '',
      columnsTwo: [],
      columnsThree: [],
      columnsFour: [],
      secondLevelInnerCode: '',
      secondLevelCount: '',
      thirdLevelInnerCode: '',
      thirdLevelCount: '',
      forthLevelInnerCode: '',
      forthLevelCount: '',
      radio: '10',
      allCount: 0
    }
  },
  created() {
    this.getSelectData('columnsTwo')
    this.getAllErrorCount()
  },
  methods: {
    getAllErrorCount() {
      this.allCount = this.$route.query.allCount || 0
    },
    getSelectData(columnName, catalogID) {
      this.$axios.get(`/tk/front/catalogtree/catalogselector?catalogID=${catalogID ? catalogID : ''}`).then(res => {
        if (res.data.status === 1) {
          this[columnName] = res.data.data.map(item => {
            item.text = item.name
            return item
          })
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        return this.$Toast.fail('服务器开小差了，稍后重试' + e)
      })
    },
    getQuestionCount(levelCountName) {
      this.$axios.get(`/tk/front/member/errorquestion/choosenumber?forthLevelInnerCode=${this.forthLevelInnerCode}&secondLevelInnerCode=${this.secondLevelInnerCode}&thirdLevelInnerCode=${this.thirdLevelInnerCode}`).then(res => {
        if (res.data.status === 1) {
          this[levelCountName] = res.data.data
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        return this.$Toast.fail('服务器开小差了，稍后重试' + e)
      })
    },
    onConfirmTwo(item) {
      if (!item) {
        this.showClasTwo = false
        return
      }
      this.twoClassValue = item.text
      this.twoClassID = item.ID
      this.threeClassID = ''
      this.threeClassValue = ''
      this.thirdLevelCount = ''
      this.fourClassValue = ''
      this.fourClassID = ''
      this.forthLevelCount = ''
      this.thirdLevelInnerCode = ''
      this.forthLevelInnerCode = ''
      this.secondLevelInnerCode = item.innerCode
      this.showClasTwo = false
      this.getSelectData('columnsThree', this.twoClassID)
      this.getQuestionCount('secondLevelCount')
    },
    onConfirmThree(item) {
      if (!item) {
        this.showClasThree = false
        return
      }
      this.threeClassValue = item.text
      this.threeClassID = item.ID
      this.fourClassValue = ''
      this.fourClassID = ''
      this.forthLevelCount = ''
      this.forthLevelInnerCode = ''
      this.showClasThree = false
      this.thirdLevelInnerCode = item.innerCode
      this.getSelectData('columnsFour', this.threeClassID)
      this.getQuestionCount('thirdLevelCount')
    },
    onConfirmFour(item) {
      if (!item) {
        this.showClasFour = false
        return
      }
      this.fourClassValue = item.text
      this.fourClassID = item.ID
      this.forthLevelInnerCode = item.innerCode
      this.showClasFour = false
      this.getQuestionCount('forthLevelCount')
    },
    getErrorquestion() {
      this.$Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      let counts = 0
      if (this.forthLevelCount) {
        counts = this.forthLevelCount < this.radio ? this.forthLevelCount : this.radio
      } else if (this.thirdLevelCount) {
        counts = this.thirdLevelCount < this.radio ? this.thirdLevelCount : this.radio
      } else {
        counts = this.secondLevelCount < this.radio ? this.secondLevelCount : this.radio
      }
      if (!counts) {
        this.$Toast.clear()
        return this.$Toast.fail('当前分类下没有题目')
      }
      this.$axios.post(`/tk/front/member/errorquestion?forthLevelInnerCode=${this.forthLevelInnerCode}&secondLevelInnerCode=${this.secondLevelInnerCode || ''}&thirdLevelInnerCode=${this.thirdLevelInnerCode || ''}&counts=${counts}`).then(res => {
        if (res.data.status === 1) {
          this.getKey(res.data.data.ID)
        } else {
          this.$Toast.fail(res.data.message)
          this.$Toast.clear()
        }
      }).catch(e => {
        this.$Toast.clear()
        return this.$Toast.fail('服务器开小差了，稍后重试' + e)
      })
    },
    getKey(id) {
      this.$axios.get(`/tk/front/paper/${id}/checkandgeneratekey`).then(res => {
        this.$Toast.clear()
        if (res.data.status === 1) {
          this.$router.push({
            path: `/allCourses/examinationpaper?errorAgain=true&pagename=错题练习&isPhoto=N&key_success=${res.data.data.key_success}`
          })
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        this.$Toast.clear()
        return this.$Toast.fail('服务器开小差了，稍后重试' + e)
      })
    },
    onCancelTwo() {
      this.twoClassValue = ''
      this.twoClassID = ''
      this.secondLevelCount = ''
      this.threeClassID = ''
      this.threeClassValue = ''
      this.thirdLevelCount = ''
      this.fourClassValue = ''
      this.fourClassID = ''
      this.forthLevelCount = ''
      this.thirdLevelInnerCode = ''
      this.forthLevelInnerCode = ''
      this.secondLevelInnerCode = ''
      this.showClasTwo = false
    },
    onCancelThree() {
      this.threeClassID = ''
      this.threeClassValue = ''
      this.thirdLevelCount = ''
      this.fourClassValue = ''
      this.fourClassID = ''
      this.forthLevelCount = ''
      this.thirdLevelInnerCode = ''
      this.forthLevelInnerCode = ''
      this.showClasThree = false
    },
    onCancelFour() {
      this.fourClassValue = ''
      this.fourClassID = ''
      this.forthLevelCount = ''
      this.forthLevelInnerCode = ''
      this.showClasFour = false
    },
    threeSelectClick() {
      if (!this.twoClassValue) {
        return this.$Toast.fail('请先选择二级分类')
      }
      this.showClasThree = true
    },
    fourSelectClick() {
      if (!this.threeClassValue) {
        return this.$Toast.fail('请先选择三级分类')
      }
      this.showClasFour = true
    },
  }
}
</script>
<style scoped>
.content-box {
  padding: 0.49rem 0.43rem 0 0.43rem;
  background-color: #f8f8f8;
  height: calc(100vh - 3.33rem);
}
.content-box >>> .van-cell--clickable {
  width: 6.15rem;
  padding: 0 0.35rem;
  font-size: 0.32rem;
  height: 0.85rem;
  line-height: 0.85rem;
  background-color: #f1f2f7;
	border-radius: 0.07rem;
	border: 1px solid  #b8b8ba;
}
.content-box >>> .van-field__control {
  color: #999;
}
.select-box-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.39rem;
}
.label {
  font-size: 0.4rem;
	font-weight: bold;
	color: #444444;
}
.count {
  font-size: 0.32rem;
	color: #1578f2;
  min-width: 0.58rem;
  text-align: center;
}
.select-count-box {
  margin-top: 1.04rem;
}
.select-count-box >>> .van-radio-group--horizontal {
  justify-content: space-around;
  margin-top: 0.33rem;
}
.select-count-box >>> .van-radio__label {
  font-size: 0.4rem;
	line-height: 0.48rem;
	color: #333333;
  margin-left: 0.16rem;
}
.select-count-box >>> .van-radio__icon {
  font-size: 0.53rem;
}
.select-count-box >>> .van-radio--horizontal {
  margin-right: 0.32rem;
}
.count-label {
  font-size: 0.4rem;
	font-weight: bold;
	color: #444444;
}
.bottom-btn-box {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  height: 1.56rem;
  border: 1px solid #eee;
  justify-content: space-around;
  align-items: center;
}
.bottom-btn-box div {
  font-size: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  width: 8.29rem;
	height: 1.07rem;
	background-color: #1578f2;
	box-shadow: 0rem 0.07rem 0.11rem 0rem  rgba(119, 180, 255, 0.69);
	border-radius: 0.53rem;
}
</style>