<template>
  <div class="special-zone">
    <!-- 搜索栏 -->
    <div class="part-1">
      <div class="search-box">
        <van-search v-model="searchValue" shape="round" readonly="" placeholder="搜索课程" @click="toSearchPage" />
        <van-button class="search-button" round color="linear-gradient(90deg, #5abffe 0%, #389cf8 61%, #1578f2 100%)" @click="toSearchPage">搜索</van-button>
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="part-2" v-if="IsShowBanner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in swipelist" :id="item.id" :key="item.id + index + index" @click="clickswipe(item)">
          <van-image width="100%" height="100%" :src="item.logo || imgExample" :alt="item.title" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 通知公告 跟随banner图一起显示隐藏 -->
    <div class="part-3" v-if="IsShowBanner">
      <div class="part-3-box" v-if="notice">
        <div @click="goNotice('notice')"></div>
        <a class="line-ellipsis" @click="toNewsDetail(notice, '通知')"> {{ notice.title }}</a>
      </div>
    </div>
    <!-- 菜单 -->
    <div class="part-4">
      <ul>
        <li v-for="(item, index) in categoriesData" :key="index">
          <a @click="gotoCatalog(item, index)">
            <img :src="item.icon ? item.icon : `/images/index/menu-icon0${index + 1}.png`" />
            <span>{{ item.name }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="part-box">
      <!-- 上级企业入口 -->
      <div class="part-5" v-if="isShow" @click="resetPage(superior.parentID, superior.ID, superior.innercode)">
        <!-- <div class="part-title">
          <span>{{superior.shorthand}}</span>
        </div> -->
        <img :src="superior.banner" />
      </div>
      <!-- 外部链接入口 -->
      <div class="part-5" v-if="isExternalLink===1">
        <div v-for="(info, index) in externalLinks" :key="index" @click="linkBannerClick(info.externalLink)" class="link-banner">
          <img :src="info.linkBanner"/>
        </div>
      </div>
      <!-- 首页热门课程推荐 -->
      <div class="part-6" v-if="!coursesData.length">
        <div class="part-title">
          <span>热门课程</span>
          <router-link to="/allCourses/classifyCourseslist?type=1">更多<van-icon name="arrow"/></router-link>
        </div>
        <div class="course-list">
          <template v-if="recommendList && recommendList.length > 0">
            <ul v-for="item in recommendList" :key="item.ID" @click="gotoCoursePage(item)">
              <li>
                <div class="img-box">
                  <img :src="item.logoFile" />
                </div>
              </li>
              <li>
                <h5 class="line-ellipsis">{{ item.name }}</h5>
                <h6 class="line-ellipsis-2" v-html="item.description || '暂无描述'"></h6>
                <span>{{ item.speaker }}</span>
                <div class="price" style="display: flex; align-items: center; justify-content: space-between;">
                  <div v-if="item.isForbid === 0">
                    <span v-if="item.isFree === '1'" style="font-size: 0.37rem;">免费</span>
                    <span v-else-if="item.hasPermission !== 'Y'">￥{{ isVip ? item.memberPrice : item.sellPrice }}</span>
                    <span v-else style="color: #1989fa; font-size: 0.37rem;">进入学习</span>
                    <span v-if="item.hasPermission !== 'Y' && isVip" style="text-decoration: line-through;">￥{{ item.sellPrice }}</span>
                    <span v-else></span>
                  </div>
                  <div v-else>
                    <span v-if="item.isFree === '1'" style="font-size: 0.37rem;">免费</span>
                    <span v-else-if="item.hasPermission !== 'Y'">会员专享</span>
                    <span v-else style="color: #1989fa; font-size: 0.37rem;">进入学习</span>
                  </div>
                  <span class="count">{{ item.viewCount }}</span>
                </div>
              </li>
            </ul>
          </template>
          <div v-else class="not-data">暂无数据</div>
        </div>
      </div>
      <!-- 热门课程 -->
      <div class="part-6" v-else>
        <div class="part-title">
          <span>热门课程</span>
          <router-link :to="`/nzzone/classinfo/enterpriseList?id=${currentID}&code=${currentCode}`">更多<van-icon name="arrow"/></router-link>
        </div>
        <div class="course-list">
          <template>
            <ul v-for="item in coursesData" :key="item.ID" @click="gotoCoursePage(item)">
              <li>
                <div class="img-box">
                  <img :src="item.logoFile" />
                </div>
              </li>
              <li>
                <h5 class="line-ellipsis">{{ item.name }}</h5>
                <h6 class="line-ellipsis-2" v-html="item.description || '暂无描述'"></h6>
                <span>{{ item.speaker }}</span>
              </li>
            </ul>
          </template>
        </div>
      </div>
      <!-- 企业班级 -->
      <div class="part-7" v-if="classData.length > 0">
        <div class="part-title">
          <span>我的班级</span>
          <router-link :to="`/study?active=0`">更多<van-icon name="arrow"/></router-link>
        </div>
        <div class="class-list">
          <template>
            <div class="class-item" v-for="item in classData" :key="item.ID" @click="gotoPage(`/nzzone/classinfo?id=${item.ID}`)">
              <div class="img-box">
                <img :src="item.logoSrc" />
              </div>
              <div class="class-name">
                <div>
                  <h5 class="line-ellipsis-2">
                    <span :class="{ 'title-tag': item.enterpriseExclusive === 1, 'platform-tag': item.enterpriseExclusive !== 1 }">{{ item.enterpriseExclusive !== 1 ? '麓辰' : '企业' }}</span>
                    {{ item.name }}
                  </h5>
                  <span>({{ item.finishedCourseCount }}/{{ item.totalCourseCount }})</span>
                </div>
                <span>{{ item.timeRange }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- 热门直播 -->
      <div class="part-9" :style="{ 'margin-bottom': userEnterpriseID !== currentID ? (isIOS() ? '0.8rem' : '') : isIOS() ? '1.33rem' : '0.4rem' }">
        <div class="part-title" v-if="isShowLive == 1 && (liveList.length > 0 || liveHot.ID > 0)">
          <span>热门直播</span>
          <router-link to="/broadcast">更多<van-icon name="arrow"/></router-link>
        </div>
        <div class="part-5 part-card" v-if="isShowLive == 1 && (liveList.length > 0 || liveHot.ID > 0)">
          <template>
            <div class="live-card" :style="{ 'background-image': 'url(' + liveHot.logoFile + ')' }" @click="hrefPage(liveHot)">
              <div class="live-status">{{ liveHot.liveStatusName }}</div>
              <!-- <div class="live-title">
                <h5>{{liveHot.name}}</h5>
                <span>{{liveHot.speaker}}</span>
                <span>{{liveHot.startTime}}</span>
              </div> -->
            </div>
            <div class="live-list">
              <ul v-for="item in liveList" :key="item.ID" @click="hrefPage(item)">
                <li>
                  <div class="img-box">
                    <img :src="item.logoFile" />
                  </div>
                </li>
                <li>
                  <h5 class="line-ellipsis">{{ item.name }}</h5>
                  <span>{{ item.speaker }}</span>
                  <span>{{ item.startTime }}</span>
                </li>
                <li>
                  <p style="margin: 0.66rem 0 0 0; font-size: 0.42rem; font-weight: bold;" v-if="item.isFree === '1'">免费</p>
                  <template v-else-if="item.hasPermission !== 'Y'">
                    <p style="margin: 0.66rem 0 0 0; font-size: 0.42rem; font-weight: bold;">￥{{ isVip ? item.memberPrice : item.sellPrice }}</p>
                    <p style="margin: 0; font-size: 0.42rem; text-decoration:line-through; color: #999" v-if="isVip">￥{{ item.sellPrice }}</p>
                  </template>
                  <p v-else style="margin: 0.66rem 0 0 0; font-size: 0.42rem; font-weight: bold; color: #1989fa">进入学习</p>
                </li>
              </ul>
            </div>
          </template>
        </div>
        <div class="btn-content" v-if="userEnterpriseID !== currentID">
          <van-button round type="info" @click="goBack">返回本企业</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './index.less'
export default {
  name: 'nzzone',
  data() {
    return {
      IsShowBanner: true,
      liveList: [],
      liveHot: {},
      searchValue: '',
      swipelist: [],
      imgExample: '/images/index/examples/examples02.png', // 示例图片
      notice: {},
      noticeName: '公告',
      superior: {},
      categoriesData: [],
      coursesData: [],
      recommendList: [],
      classData: [],
      currentID: '',
      currentCode: '',
      parentEnterpriseID: '',
      userEnterpriseID: '',
      isShowLive: 1,
      isParent: false,
      isVip: true,
      isExternalLink: 0,
      externalLinks: []
    }
  },
  activated() {
    this.isParent = false
    if (localStorage.userInfo) {
      window.document.title = JSON.parse(localStorage.userInfo).enterpriseName
    }
    const userInfo = JSON.parse(localStorage.userInfo)
    this.currentID = userInfo.enterpriseID
    this.currentCode = userInfo.enterpriseInnercode
    this.userEnterpriseID = userInfo.enterpriseID
    this.parentEnterpriseID = userInfo.parentEnterpriseID
    this.getSwipe()
    this.getNoticeData()
    this.getCategories()
    this.getRecommendList()
    this.getCourses()
    this.getClass()
    this.getEnterpriseShowInfo()
    this.getSuperior()
    this.getLiveList()
    this.setShare()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = info.enterpriseName || info.enterpriseShorthand ? true : false
    }
  },
  computed: {
    isShow() {
      let flag = true
      if (!this.isParent) {
        flag = this.parentEnterpriseID !== 0
      } else {
        flag = this.superior.parentID !== 0
      }
      return flag
    },
  },
  created() {},
  methods: {
    // 跳转至直播外链
    hrefPage(item) {
      this.$router.push({
        path: `/allCourses/listDetails/${item.ID}`,
      })
    },
    gotoCoursePage(item) {
      let path = `/allCourses/details/${item.ID}/${'curriculum'}`
      if (item.type !== 0) {
        path = `/allCourses/courseHandout/${item.ID}`
      } else {
        path = path + '?backUrl=' + this.$router.history.current.fullPath
      }
      // let temp = this.$router.history.current.fullPath
      // localStorage.back_url = temp
      this.gotoPage(path)
    },
    // 获取热门课程推荐数据
    getRecommendList() {
      this.$axios
        .get('/course/front/permit/course/recommend')
        .then((res) => {
          if (res.data.status === 1) {
            this.recommendList = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    gotoCatalog(item, index) {
      if (item.type === 1) {
        window.location.href = item.customUrl
        return
      }
      if (index === 0) {
        // let path = `/allCourses/index?id=${item.ID}&innerCode=${item.innerCode}`
        // if (JSON.parse(localStorage.userInfo).isFrontPage === 0) {
        //   path = `/allCourses/index?id=${item.ID}&innerCode=${item.innerCode}&isContinue=1`
        // }
        // return this.$router.push({ path: path })
      }
      let path = `/nzzone/allCourses/${this.currentID}/${this.currentCode}?id=${item.ID}&innerCode=${item.innerCode}`
      if (item.isLeaf) {
        if (item.treeLevel > 2) {
          path = `/nzzone/allCourses/CourseList/${this.currentID}/${this.currentCode}/${item.ID}?catalogInnercode=${item.innerCode}`
        }
      } else {
        if (item.treeLevel > 5) {
          path = `/nzzone/allCourses/CatalogList/${this.currentID}/${this.currentCode}/${item.ID}?catalogInnercode=${item.innerCode}`
        }
      }
      this.$router.push({ path: path })
    },
    isIOS() {
      let u = navigator.userAgent
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      return isiOS
    },
    // 直播数据
    getLiveList() {
      this.$axios
        .get('/course/front/permit/live?pageSize=3&pageIndex=0')
        .then((res) => {
          if (res.data.status === 1) {
            this.liveList = res.data.data.data.length > 2 && res.data.data.data.splice(1, res.data.data.data.length)
            this.liveHot = res.data.data.data.length > 0 && res.data.data.data[0]
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: window.document.title + ``,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    // 获取轮播图
    getSwipe() {
      let _this = this
      this.$axios({
        url: '/json/index.json',
        methods: 'GET',
        params: {},
      }).then((res) => {
        if (res.status == 200) {
          _this.swipelist = res.data.data.lbt
        }
      })
    },
    //轮播图点击
    clickswipe(item) {
      let _this = this
      let swipeobj = Object.assign({}, item)
      if (item.url.indexOf('json') != -1) {
        if (item.url.indexOf('.json') != -1) {
          // 直接跳转详情
          swipeobj.artUrl = item.url
          _this.toNewsDetail(swipeobj)
        } else {
          // 跳转列表
          _this.$router.push({
            name: 'news',
          })
        }
      } else {
        window.location.href = item.url
      }
    },
    // 跳转至资讯详情
    toNewsDetail(item, itemtype) {
      let artUrl = item.artUrl.substring(item.artUrl.search(/json/))
      this.$router.push({
        name: 'newsDetail',
        params: {
          url: artUrl,
        },
        query: {
          itemtype: itemtype,
        },
      })
    },
    // 获取通知数据
    getNoticeData() {
      let _this = this
      this.$axios({
        url: '/json/tzgg/index.json',
        methods: 'GET',
        params: {},
      })
        .then((res) => {
          if (res.data.status === 1) {
            if (res.data.data && res.data.data.length > 0) {
              _this.notice = res.data.data[0]
            } else {
              _this.notice = ''
            }
          } else {
            this.$Toast.fail(res.data.message || '服务器异常')
          }
        })
        .catch((e) => {
          _this.notice = ''
          return e
        })
    },
    // 跳到通知页面
    goNotice(urlname) {
      // notice
      this.$router.push({
        name: 'news',
        query: {
          name: urlname,
        },
      })
    },
    // 跳转至企业搜索结果页
    toSearchPage() {
      this.$router.push({ path: `/nzzone/SearchResult?enterpriseID=${this.currentID}&query=${this.searchValue.replace(/\s*/g, '')}` })
    },
    // 知识点
    getCategories(id, code) {
      const userInfo = JSON.parse(localStorage.userInfo)
      let enterpriseID = id || userInfo.enterpriseID
      let enterpriseInnercode = code || userInfo.enterpriseInnercode
      this.$axios
        .get(`/course/front/member/enterprisecenter/categories?enterpriseID=${enterpriseID}&enterpriseInnercode=${enterpriseInnercode}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.categoriesData = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 企业专属课程
    getCourses(id, code) {
      const userInfo = JSON.parse(localStorage.userInfo)
      let enterpriseID = id || userInfo.enterpriseID
      let enterpriseInnercode = code || userInfo.enterpriseInnercode
      this.$axios
        .get(`/course/front/member/enterprisecenter/recommend/courses?pageSize=3&pageIndex=0&enterpriseID=${enterpriseID}&enterpriseInnercode=${enterpriseInnercode}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.coursesData = res.data.data.data || []
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    getEnterpriseShowInfo(id) {
      const userInfo = JSON.parse(localStorage.userInfo)
      let enterpriseID = id !== undefined ? id : userInfo.enterpriseID
      this.$axios
        .get(`/course/front/member/enterprisecenter/show/content?enterpriseID=${enterpriseID}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.IsShowBanner = res.data.data.isShowBanner
            this.isShowLive = res.data.data.isShowLive
            this.isExternalLink = res.data.data.isExternalLink
            if (res.data.data.externalLinks) {
              this.externalLinks = res.data.data.externalLinks
            }
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 上一级企业信息
    getSuperior(id) {
      const userInfo = JSON.parse(localStorage.userInfo)
      let enterpriseID = id !== undefined ? id : userInfo.parentEnterpriseID
      this.$axios
        .get(`/course/front/member/enterprisecenter/superior?enterpriseID=${enterpriseID}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.superior = res.data.data
          } else {
            // 没有上级企业返回的状态是0，这里特殊处理下，不显示消息，后面根据需要再调整
            // this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 企业班级
    getClass() {
      this.$axios
        .get(`/course/front/member/enterprisecenter/my/classes`)
        .then((res) => {
          if (res.data.status === 1) {
            this.classData = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 页面跳转
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    // 请求上级企业
    resetPage(parentID, id, code) {
      this.isParent = true
      this.currentID = id
      this.currentCode = code
      if (parentID) {
        this.getEnterpriseShowInfo(parentID)
        this.getSuperior(parentID)
      }
      window.document.title = this.superior.name
      this.getCategories(id, code)
      this.getCourses(id, code)
      this.setShare()
    },
    // 外链跳转
    linkBannerClick(url) {
      window.location.href = url
    },
    goBack() {
      this.isParent = false
      const userInfo = JSON.parse(localStorage.userInfo)
      this.currentID = userInfo.enterpriseID
      this.currentCode = userInfo.enterpriseInnercode
      this.getEnterpriseShowInfo()
      this.getSuperior()
      this.getCategories()
      this.getCourses()
      window.document.title = userInfo.enterpriseName
    },
    format(str) {
      if (!str) {
        return ''
      }
      return str.substring(0, 10).replace(/-/g, '.')
    },
  },
}
</script>
<style scoped lang="less">
.btn-content {
  display: flex;
  justify-content: space-evenly;
  padding: 0.16rem 0;
}
/* .btn-content ::deep() .van-button.van-button--info {
  width: 3.48rem;
  height: 0.97rem;
  font-size: 0.37rem;
}
 */
.part-6 {
  .course-list {
    background: #fff;
    ul {
      display: flex;
      padding: 0 0.47rem;
      margin-bottom: 0.41rem;
      li {
        &:nth-child(1) {
          .img-box {
            height: 2.13rem;
            width: 2.13rem;
            margin-right: 0.52rem;
            border-radius: 0.13rem;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        &:nth-child(2) {
          flex: 1;
          h5 {
            color: #1b1b1b;
            font-size: 0.37rem;
            // width: 4.26rem;
            width: 5.6rem;
          }
          h6 {
            color: #878787;
            font-size: 0.32rem;
            font-weight: normal;
            margin: 0.1rem 0;
          }
          & > span {
            display: block;
            color: #484a49;
            font-size: 0.32rem;
          }
          .price {
            span {
              &:nth-child(1) {
                font-size: 0.43rem;
                color: #1b1b1b;
              }
              &:nth-child(2) {
                font-size: 0.32rem;
                color: #999999;
                margin-left: 0.25rem;
                // text-decoration: line-through;
              }
              &:nth-child(3) {
                font-size: 0.32rem;
                color: #999999;
                margin-left: 0.25rem;
                // text-decoration: line-through;
                // width: 3.2rem;
                display: inline-block;
                text-align: right;
              }
            }
          }
        }
        &:nth-child(3) {
          display: flex;
          align-items: flex-end;
          div {
            color: #999999;
            font-size: 0.32rem;
          }
        }
      }
    }
  }
}

.part-7 {
  .boutique-list {
    background: #fff;
    ul {
      display: flex;
      padding: 0 0.47rem;
      margin-bottom: 0.41rem;
      li {
        &:nth-child(1) {
          .img-box {
            height: 2.13rem;
            width: 2.13rem;
            margin-right: 0.52rem;
            border-radius: 0.13rem;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        &:nth-child(2) {
          flex: 1;
          .title {
            display: flex;
            position: relative;
            span {
              color: #999999;
              font-size: 0.32rem;
              white-space: nowrap;
              position: absolute;
              right: 0;
            }
            h5 {
              color: #1b1b1b;
              font-size: 0.37rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 4.42rem;
            }
          }
          h6 {
            color: #878787;
            font-size: 0.32rem;
            font-weight: normal;
            margin: 0.1rem 0;
            -webkit-line-clamp: 2;
          }
          .price {
            span {
              &:nth-child(1) {
                font-size: 0.43rem;
                color: #1b1b1b;
              }
              &:nth-child(2) {
                font-size: 0.32rem;
                color: #999999;
                margin-left: 0.25rem;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }
}

.part-6 .course-list ul {
  padding-left: 0;
}

.special-zone .part-7 .class-list .class-item .img-box {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 10px !important;
}

.title-tag {
  display: inline-block;
  height: 0.53rem;
  line-height: 0.53rem;
  border-radius: 0.08rem;
  background-color: #70dd96;
  font-family: PingFang-SC-Medium;
  font-size: 0.32rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0rem;
  color: #fff;
  text-align: center;
  padding: 0 0.15rem;
  white-space: nowrap;
}
.platform-tag {
  height: 0.53rem;
  line-height: 0.53rem;
  border-radius: 0.08rem;
  background-color: #fbc047;
  font-family: PingFang-SC-Medium;
  font-size: 0.32rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0rem;
  color: #fff;
  text-align: center;
  padding: 0 0.15rem;
}

.line-ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.part-5 .link-banner {
  height: 1.4rem;
}
.part-5 .link-banner img {
  height: 100%;
}
</style>
