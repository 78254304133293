<template>
  <div class="content">
    <zv-nav-bar :title="pagename"></zv-nav-bar>
    <div class="live-list">
      <van-list v-model="loading" :finished="finished" finished-text="">
        <ul v-for="item in liveList" :key="item.ID" @click="getLiveSomething(item)">
          <li>
            <div class="img-box">
              <img :src="item.logoFile" />
            </div>
          </li>
          <li>
            <h5 class="line-ellipsis">{{ item.name }}</h5>
            <span>{{ item.speaker }}</span>
            <span>{{ item.startTime }}</span>
          </li>
          <li>
            <p v-if="item.isFree === '1'" style="color: #1b1b1b; font-size: 0.43rem;">免费</p>
            <template v-else-if="item.hasPermission !== 'Y'">
              <p class="current-price m-t">￥{{ isVip ? item.memberPrice : item.sellPrice }}</p>
              <p class="old-price" v-if="isVip">￥{{ item.sellPrice }}</p>
            </template>
            <p v-else class="current-price t-c" style="color: #1989fa">进入学习</p>
          </li>
        </ul>
      </van-list>
    </div>
    <van-dialog v-model="show" title="提示" show-cancel-button @cancel="show = false" @confirm="gotoPay">
      <p style="text-align: center;">此直播未购买，是否购买</p>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: 'broadcast',
  data() {
    return {
      loading: false,
      finished: true,
      pagename: '全部直播',
      pageIndex: 0,
      liveList: [],
      show: false,
      item: {},
      isVip: true,
    }
  },
  created() {
    this.setShare()
    this.getLiveList()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = info.isVip && (info.enterpriseName || info.enterpriseShorthand)
    }
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: `全部直播`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    getLiveList() {
      this.$axios
        .get(`/course/front/permit/live?pageSize=10&pageIndex=${this.pageIndex}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.liveList = this.liveList.concat(res.data.data.data)
            if (this.liveList.length >= res.data.data.total) {
              this.finished = true
            } else {
              this.pageIndex = this.pageIndex + 1
            }
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    getLiveSomething(item) {
      this.$router.push({
        path: `/allCourses/listDetails/${item.ID}`,
      })
      // if (item.hasPermission !== 'Y') {
      //   this.item = item
      //   return this.show = true
      // }
      // let token = localStorage.token ? localStorage.token.replace('Bearer+', 'Bearer ') : ''
      // window.location.href = `${process.env.VUE_APP_URL}/course/front/live/${item.ID}/log/${type}?token=${token}`
    },
    gotoPay() {
      this.$router.push({
        path: `/allCourses/order/${this.item.ID}?type=LIVE`,
      })
    },
  },
}
</script>
<style scoped>
.content {
  background: #f8f8f8;
  min-height: 100vh;
}
.live-list {
  margin: 0.33rem;
}
.live-list ul {
  display: flex;
  padding: 0.37rem 0 0.43rem 0.33rem;
  background-color: #fff;
  margin-bottom: 0.27rem;
}
.live-list ul li:nth-child(1) .img-box {
  height: 1.7rem;
  width: 1.7rem;
  margin-right: 0.37rem;
  border-radius: 0.16rem;
  overflow: hidden;
}
.live-list ul li:nth-child(1) .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.live-list ul li:nth-child(2) {
  flex: 1;
}
.live-list ul li:nth-child(2) h5 {
  color: #1b1b1b;
  font-size: 0.4rem;
  margin: 0;
}
.live-list ul li:nth-child(2) span {
  display: block;
  color: #484a49;
  font-size: 0.32rem;
  margin-top: 0.23rem;
  width: 4.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.live-list ul li:nth-child(3) {
  display: flex;
  flex-direction: column;
  margin-right: 0.33rem;
}
.live-list ul li:nth-child(3) .coupon {
  margin: 0px;
  font-size: 0.32rem;
  color: #e4393c;
  font-weight: bold;
}
.live-list ul li:nth-child(3) .current-price {
  margin: 0.33rem 0px 0px;
  font-size: 0.43rem;
  color: #1b1b1b;
  font-weight: bold;
}
.live-list ul li:nth-child(3) .current-price.m-t {
  margin: 0.66rem 0px 0px;
}
.live-list ul li:nth-child(3) .current-price.t-c {
  text-align: center;
}
.live-list ul li:nth-child(3) .old-price {
  margin: 0px;
  font-size: 0.32rem;
  text-decoration: line-through;
  color: #999999;
  text-align: right;
}
</style>
