<template>
  <div class="getcoupon">
    <zv-nav-bar :title="itemData.statusName + '优惠券'"></zv-nav-bar>
    <div class="get_bg"></div>
    <div class="imgbox">
      <div class="get_title">
        <div class="get_icon">
          <img src="../../../public/images/user/icon.png" alt="" />
        </div>
        <div class="icon_text">
          <span>麓辰教育</span><van-icon name="arrow" />
        </div>
      </div>
      <div class="get_box">
        <div class="box_img">
          <img src="../../../public/images/user/numcap.png" alt="" />
        </div>
        <div class="box_text">
          <div class="text_l">
            <!-- <div class="num_text">满{{itemData.fullValue}}可用</div> -->
            <div v-if="itemData.faceValue">
              <span class="num">{{itemData.faceValue}}</span><span class="num_text">元</span>
            </div>
            <!-- <div v-if="itemData.discount">
              <span class="num">{{itemData.discount * 10}}</span><span class="num_text">折</span>
            </div> -->
          </div>
          <div class="text_r">
            <div class="r_text">{{itemData.name}}</div>
            <div class="r_time">{{itemData.validitiyStart&&itemData.validitiyStart.substring(0, 10)}}至{{itemData.validitiyEnd&&itemData.validitiyEnd.substring(0, 10)}}</div>
          </div>
        </div>
      </div>
      <div :class="{'get_btn': itemData.statusName === '可领取', 'get_w_btn': itemData.statusName !== '可领取'}" @click="checkcanreceive">
        <span>{{itemData.statusName}}</span>
      </div>
    </div>
    <van-row class="use_class" v-if="itemData.Course && itemData.Course.length > 0">
      <van-row class="class_text">以下课程可使用此优惠券</van-row>
      <van-row v-for="(item, n) in (itemData.Course.length % 2 === 0 ? itemData.Course.length / 2 : parseInt(itemData.Course.length / 2) + 1)" :key="n">
        <van-col span="12" v-if="itemData.Course[n*2]">
          <div class="class_box">
            <div class="class_box_img">
              <img :src="itemData.Course[n*2].logoFile" />
              <div class="img_text">{{itemData.Course[n*2].type == 1 ? '讲义' : '视频'}}</div>
            </div>
            <div class="class_box_text">{{itemData.Course[n*2].GoodsName}}</div>
          </div>
        </van-col>
        <van-col span="12" v-if="itemData.Course[n*2 + 1]">
          <div class="class_box">
            <div class="class_box_img">
              <img :src="itemData.Course[n*2 + 1].logoFile" />
              <div class="img_text">{{itemData.Course[n*2 + 1].type == 1 ? '讲义' : '视频'}}</div>
            </div>
            <div class="class_box_text">{{itemData.Course[n*2 + 1].GoodsName}}</div>
          </div>
        </van-col>
      </van-row>
    </van-row>
    <van-row class="use_class" v-if="itemData.CoursePackage && itemData.CoursePackage.length > 0">
      <van-row class="class_text">以下课程包可使用此优惠券</van-row>
      <van-row  v-for="(item, n) in (itemData.CoursePackage.length % 2 === 0 ? itemData.CoursePackage.length / 2 : parseInt(itemData.CoursePackage.length / 2) + 1)" :key="n">
        <van-col span="12" v-if="itemData.CoursePackage[n*2]">
          <div class="class_box">
            <div class="class_box_img">
              <img :src="itemData.CoursePackage[n*2].logoFile"/>
              <div class="img_text">系列课</div>
            </div>
            <div class="class_box_text">{{ itemData.CoursePackage[n*2].GoodsName }}</div>
          </div>
        </van-col>
        <van-col span="12" v-if="itemData.CoursePackage[n*2 + 1]">
          <div class="class_box">
            <div class="class_box_img">
              <img :src="itemData.CoursePackage[n*2 + 1].logoFile"/>
              <div class="img_text">系列课</div>
            </div>
            <div class="class_box_text">{{ itemData.CoursePackage[n*2 + 1].GoodsName }}</div>
          </div>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "getcoupon",
  data() {
    return {
      itemData: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      let id = this.$route.params.id
      this.$axios.get(`/course/priv/member/coupon/${id}/detail`).then(res => {
        if (res.data.status === 1) {
          this.itemData = res.data.data
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        this.$Toast.fail('服务器开小差了，稍后重试')
        return e
      })
    },
    checkcanreceive() {
      let id = this.$route.params.id
      if (this.itemData.statusName === '可领取') {
        this.$axios.get(`/course/priv/member/coupon/${id}/checkcanreceive`).then(res => {
          if (res.data.status === 1) {
            // 领取优惠券
            this.receive()
          } else {
            this.$Toast.fail(res.data.message)
          }
        }).catch(e => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
      } else if(this.itemData.statusName === '可使用') {
        if (this.itemData.type === 'All') {
          this.$router.replace({
            path: '/allCourses/index'
          })
        } else {
          // 跳转到使用优惠券列表
          this.$router.replace({
            path: `/user/couponList/${id}?couponId=${this.itemData.id}&from=coupon`
          })
        }
      }
    },
    receive() {
      let id = this.$route.params.id
      this.$axios.put(`/course/priv/member/coupon/${id}/receive`).then(res => {
        if (res.data.status === 1) {
          this.$Toast.success(res.data.message)
          setTimeout(() => {
            if (this.itemData.type === 'All') {
              this.$router.replace({
                path: '/allCourses/index'
              })
            } else {
              // 跳转到使用优惠券列表
              this.$router.replace({
                path: `/user/couponList/${id}?couponId=${res.data.data.couponID}&from=coupon`
              })
            }
          }, 800)
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        this.$Toast.fail('服务器开小差了，稍后重试')
        return e
      })
    }
  },
};
</script>

<style lang="less" scoped>
.getcoupon {
  min-height: 100vh;
  background-color: #f8f8f8;
  /deep/.van-col {
    padding: 0.13rem 0;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .get_bg {
    min-height: 6.9rem;
    background-image: linear-gradient(-17deg, #ed6e11 0%, #ffb307 100%);
    border-radius: 0 0 11% 11%;
  }
  .imgbox {
    min-height: 8.82rem;
    width: 9.73rem;
    margin: 0 auto;
    margin-top: -6rem;
    background: url("../../../public/images/user/bar.png") no-repeat;
    background-size: cover;
    .get_title {
      display: flex;
      align-items: center;
      width: 7.63rem;
      margin: 0 auto;
      border-bottom: 1px solid #ff771f;
      padding-top: 0.45rem;
      padding-bottom: 0.23rem;
      .get_icon {
        width: 0.52rem;
        height: 0.67rem;
      }
      .icon_text {
        margin-left: 0.16rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.4rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0rem;
        color: #604e44;
        span {
          display: inline-block;
        }
        .van-icon {
          top: 0.06rem;
        }
      }
    }
    .get_box {
      position: relative;
      line-height: normal;
      .box_img {
        width: 6.65rem;
        height: 2.95rem;
        margin: 0 auto;
        margin-top: 0.3rem;
      }
      .box_text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6.65rem;
        margin: 0 auto;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        .text_l {
          float: left;
          padding-left: 0.24rem;
          padding-right: 0.33rem;
          border-right: 1px dashed #fff;
          .num {
            font-family: PingFang-SC-Bold;
            font-size: 0.96rem;
            font-weight: normal;
            font-stretch: normal;
            color: #ffffff;
          }
          .num_text {
            font-family: PingFang-SC-Bold;
            font-size: 0.35rem;
            font-weight: normal;
            font-stretch: normal;
            color: #ffffff;
          }
        }

        .text_r {
          float: left;
          padding-left: 0.25rem;
          .r_text {
            font-family: PingFang-SC-Bold;
            font-size: 0.46rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #ffffff;
          }
          .r_time {
            font-family: PingFang-SC-Medium;
            font-size: 0.31rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #ffffff;
          }
        }
      }
    }
    .get_btn {
      padding: 0.2rem 0.5rem;
      background-image: linear-gradient(0deg, #f9a02e 0%, #fdd76c 100%),
        linear-gradient(#fcef9f, #fcef9f);
      background-blend-mode: normal, normal;
      border-radius: 0.71rem;
      width: 4.64rem;
      text-align: center;
      margin: 0 auto;
      margin-top: 1.3rem;
      span {
        font-family: PingFang-SC-Bold;
        font-size: 0.67rem;
        font-weight: normal;
        font-stretch: normal;
        color: #ad2011;
      }
    }
    .get_w_btn {
      padding: 0.2rem 0.5rem;
      background-image: linear-gradient(0deg, #b5b4b4 0%, #ececeb 100%), linear-gradient(#fcef9f, #fcef9f);
      background-blend-mode: normal, normal;
      border-radius: 0.71rem;
      width: 4.64rem;
      text-align: center;
      margin: 0 auto;
      margin-top: 1.3rem;
      span {
        font-family: PingFang-SC-Bold;
        font-size: 0.67rem;
        font-weight: normal;
        font-stretch: normal;
        color: #ad2011;
      }
    }
  }
  .use_class {
    padding: 0 0.3rem;
    .class_text {
      font-family: PingFang-SC-Bold;
      font-size: 0.46rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #040101;
      text-align: center;
      margin-bottom: 0.23rem;
    }
    .class_box {
      padding: 0.15rem;
      background-color: #ffffff;
      border-radius: 0.07rem;
      border: solid 0.01rem #bfbfbf;
      margin: 0 0.05rem;
      .class_box_img {
        width: 4.24rem;
        height: 2.39rem;
        margin: 0 auto;
        position: relative;
        .img_text {
          position: absolute;
          bottom: 0.37rem;
          right: 0.16rem;
          font-family: PingFang-SC-Medium;
          font-size: 0.27rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #020000;
          background-color: #fbc047;
          border-radius: 0.08rem;
          padding: 0.13rem 0.2rem;
        }
      }
      .class_box_text {
        font-family: PingFang-SC-Regular;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #040101;
        padding-left: 0.1rem;
        width: 4.26rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
