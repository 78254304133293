<template>
  <div class="leaf">
    <div class="original-leaf" v-if="!onlyCourse">
      <div class="root-leaf flex-box" :class="{ 'root-leaf--expanded': leafExpanded }" @click="handleExpandLeaf(1)" v-if="Number(data.treeLevel) === 3">
        <div class="leaf__prefix ellipsis">
          {{ data.name }}
        </div>
        <div class="leaf__suffix no-wrap">
          <span :class="{ highlighted: parentExpanded || leafExpanded }">{{ data.finishCourseNum }}</span>
          {{ `&nbsp;/&nbsp;${data.courseNum}` }}
        </div>
      </div>
      <div class="first-leaf flex-box" v-if="Number(data.treeLevel) === 4" @click="handleExpandLeaf">
        <div class="leaf__prefix ellipsis">
          <img class="icon" :src="require('/public/images/user/point_def.png')" v-show="!leafExpanded" />
          <img class="icon" :src="require('/public/images/user/point_pre.png')" v-show="leafExpanded" />
          <span class="name">{{ data.name }}</span>
        </div>
        <div class="leaf__suffix no-wrap">
          <span :class="{ highlighted: parentExpanded || leafExpanded }">{{ data.finishCourseNum }}</span>
          {{ `&nbsp;/&nbsp;${data.courseNum}` }}
        </div>
      </div>
      <div class="second-leaf flex-box" v-if="Number(data.treeLevel) === 5" @click="handleExpandLeaf">
        <div class="leaf__prefix flex-box">
          <div class="dot"></div>
          <div class="name ellipsis">{{ data.name }}</div>
        </div>
        <div class="leaf__suffix no-wrap">
          <span :class="{ highlighted: parentExpanded || leafExpanded }">{{ data.finishCourseNum }}</span>
          {{ `&nbsp;/&nbsp;${data.courseNum}` }}
        </div>
      </div>
      <div class="third-leaf flex-box" :class="{ 'third-leaf--expanded': leafExpanded }" @click="handleExpandLeaf" v-if="Number(data.treeLevel) === 6">
        <div class="leaf__prefix flex-box">
          <div class="dot"></div>
          <div class="name ellipsis">{{ data.name }}</div>
        </div>
        <div class="leaf__suffix no-wrap">
          <span :class="{ highlighted: parentExpanded || leafExpanded }">{{ data.finishCourseNum }}</span>
          {{ `&nbsp;/&nbsp;${data.courseNum}` }}
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="leafExpanded">
        <div class="course__list" v-if="data.courses && data.courses.length > 0">
          <div class="course-item flex-box" v-for="item in data.courses" :key="item.ID" @click.stop="$emit('clickItem', item)">
            <div class="leaf__prefix">
              <div class="top-row flex-box">
                <span class="tag" :class="{ platform: Number(item.enterpriseExclusive) === 0 }">{{ item.resourceTypeName }}</span>
                <span class="name ellipsis">{{ item.name }}</span>
              </div>
              <div class="bottom-row no-wrap">
                {{ item.timeRange }}
              </div>
            </div>
            <div class="leaf__suffix" :class="{ 'leaf__suffix--doing': Number(item.studyStatus) === 1, 'leaf__suffix--finished': Number(item.studyStatus) === 2 }">
              {{ item.studyStatusName }}
            </div>
          </div>
        </div>
        <div class="sec-leaf__list" v-if="!onlyCourse">
          <leaf v-for="item in data.children" :key="item.ID" :data="item" v-on="$listeners" :parentExpanded="leafExpanded" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Leaf from './Leaf.vue'
export default {
  name: 'Leaf',
  props: {
    data: {
      require: true,
      type: Object,
    },
    onlyCourse: {
      type: Boolean,
      default: false,
    },
    parentExpanded: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Leaf,
  },
  data() {
    return {
      leafExpanded: false,
    }
  },
  computed: {
    hasChildren() {
      return Number(this.data?.childCount) > 0 || (this.data.courses && this.data.courses.length > 0)
    },
  },
  methods: {
    handleExpandLeaf() {
      if (this.hasChildren) {
        this.leafExpanded = !this.leafExpanded
      }
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.leaf {
  .ellipsis {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }
  .no-wrap {
    white-space: nowrap;
  }
  .flex-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  .highlighted {
    color: #1578f2;
  }
  .leaf__suffix {
    margin-left: 0.1333rem;
  }
  .root-leaf {
    height: 1.3333rem;
    padding: 0.4533rem 0.3733rem;
    background-color: #fff;
    margin-bottom: 0.1333rem;
    border-radius: 0.1333rem;
    .leaf__prefix {
      color: #111111;
      font-weight: 500;
      font-size: 0.4267rem;
    }
    .leaf__suffix {
      color: #999999;
      font-weight: 400;
      font-size: 0.32rem;
    }
  }
  .root-leaf--expanded {
    background-color: transparent;
  }
  .first-leaf {
    height: 1.1733rem;
    padding: 0 0.4rem;
    .name {
      font-size: 0.4rem;
      line-height: 0.4rem;
      vertical-align: middle;
    }
    .icon {
      margin-right: 0.2133rem;
      vertical-align: middle;
    }
    .leaf__suffix {
      font-size: 0.32rem;
      line-height: 0.32rem;
      color: #999999;
    }
  }
  .second-leaf {
    height: 1.1733rem;
    padding: 0 0.4rem 0 0.72rem;
    .dot {
      width: 0.2133rem;
      height: 0.2133rem;
      margin-right: 0.2133rem;
      border-radius: 50%;
      background-color: #c9cdd6;
    }
    .leaf__prefix {
      justify-content: flex-start;
      color: #707787;
      font-size: 0.4rem;
      line-height: 0.4rem;
    }
    .leaf__suffix {
      font-size: 0.32rem;
      line-height: 0.32rem;
      color: #999999;
    }
  }
  .third-leaf {
    height: 1.1733rem;
    padding: 0 0.4rem 0 1.3333rem;
    .dot {
      width: 0.1067rem;
      height: 0.1067rem;
      margin-right: 0.2133rem;
      border-radius: 50%;
      background-color: #c9cdd6;
    }
    .leaf__prefix {
      justify-content: flex-start;
      color: #707787;
      font-size: 0.4rem;
      line-height: 0.4rem;
    }
    .leaf__suffix {
      font-size: 0.32rem;
      line-height: 0.32rem;
      color: #999999;
    }
  }
  .third-leaf--expanded {
    .leaf__prefix {
      color: #1578f2;
    }
    .dot {
      background-color: #1578f2;
    }
  }
  .course-item {
    position: relative;
    min-height: 1.7333rem;
    padding: 0.28rem 1.0667rem 0.28rem 0.3333rem;
    margin-bottom: 0.2667rem;
    background-color: #fff;
    border-radius: 0.1333rem;
    overflow: hidden;
    .tag {
      height: 0.5333rem;
      padding: 0 0.1467rem;
      margin-right: 0.2133rem;
      background-color: #70dd96;
      border-radius: 0.08rem;
      line-height: 0.5333rem;
      font-size: 0.32rem;
      color: #fff;
    }
    .name {
      flex: 1;
      font-size: 0.4267rem;
      font-weight: 700;
      color: #111;
    }
    .platform {
      background-color: #fbc047;
    }
    .top-row {
      justify-content: flex-start;
      align-items: flex-start;
    }
    .bottom-row {
      margin-top: 0.2667rem;
      font-size: 0.32rem;
      line-height: 0.32rem;
      color: #999;
    }
    .leaf__suffix {
      position: absolute;
      right: -1.39rem;
      top: 0.16rem;
      transform: rotate(40deg);
      width: 4rem;
      height: 0.71rem;
      background: linear-gradient(to right, #fff, #999);
      font-size: 0.32rem;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 0.79rem;
    }
    .leaf__suffix--doing {
      background: linear-gradient(to right, #d8514a, #ca2d2f);
    }
    .leaf__suffix--finished {
      background: linear-gradient(to right, #e2be91, #cca779);
    }
  }
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
}
</style>
<style>
.van-cell {
  padding: 0 !important;
  background-color: transparent !important;
}
</style>
