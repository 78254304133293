<template>
  <div>
    <video id="videoPreview" style="object-fit: fill;"></video>
    <canvas id="canvas" width="320" height="380" style="display:none;"></canvas>
    <span style="font-size: 12px; color: #444;">动画视频（200ms）</span>
    <img v-for="item in imgSrcList" :src="item" :key="item" />
  </div>
</template>
<script>
export default {
  name: 'testVideo',
  data() {
    return {
      imgSrc: '',
      imgSrcList: [],
      tiemr: null
    }
  },
  methods: {
    initPhoto() {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          })
        }
      }
      const constraints = {
        video: true,
        audio: false
      };
      const that = this
      let flag = false
      let promise = navigator.mediaDevices.getUserMedia(constraints)
      return new Promise(resolve => {
        promise.then(stream => {
          this.$nextTick(() => {
            let videoPreview = document.getElementById('videoPreview')
            try {
              videoPreview.srcObject = stream
            } catch (error) {
              that.$Toast.fail('错误：' + error)
              videoPreview.src = window.URL.createObjectURL(stream)
            }
            videoPreview.onloadedmetadata = function () {
              flag = true
              videoPreview.play()
              that.$Toast.success('调用成功')
              return resolve({
                status: 1,
                message: '成功'
              })
            }
            setTimeout(() => {
              if (flag) {
                return that.$Toast.success('正常调用无需走这里的逻辑')
              }
              try {
                videoPreview.play()
              } catch (error) {
                that.$Toast.fail('失败：' + error)
              }
            }, 5000)
          })
        }).catch(err => {
          if (String(err).indexOf('getUserMedia is not implemented') !== -1) {
            that.$Toast.fail('手机系统版本过低，无法正常使用拍照功能')
            return resolve({
              status: 0,
              message: '手机系统版本过低，无法正常使用拍照功能'
            })
          } else {
            that.$Toast.fail('未开启摄像头')
            return resolve({
              status: 0,
              message: '未开启摄像头'
            })
          }
        })
      })
      
    },
    photo() {
      if (this.imgSrcList >= 15) {
        this.imgSrcList = []
      }
      this.$nextTick(() => {
        let v = document.getElementById('videoPreview')
        let canvas = document.getElementById('canvas')
        canvas.getContext('2d').drawImage(v, 0, 0, 320, 380)
        this.imgSrc = canvas.toDataURL('image/png')
        this.imgSrc && this.imgSrcList.push(this.imgSrc)
      })
    }
  },
  mounted() {
    this.initPhoto()
    try {
      this.tiemr = setInterval(() => {
        this.photo()
      }, 1000)
    } catch (error) {
      alert('报错了：' + error)
    }
  },
  destroyed() {
    this.tiemr && clearInterval(this.tiemr)
  }
}
</script>
<style scoped>
video {
  height: 5.65rem;
  width: 100%;
}
img {
  height: 5.65rem;
  width: 100%;
}
p {
  margin: 0;
  font-size: 12px;
}
</style>