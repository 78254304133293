<template>
  <div class="user-info">
    <zv-nav-bar :title="pagename"></zv-nav-bar>
    <van-row class="info-mg">
      <van-col span="24" class="info-box">
        <div class="info-img">
          <van-image round width="100%" height="100%" :src="userinfo.avatar" />
        </div>
        <div class="info-name">{{ userinfo.realName || '暂无数据' }}</div>
      </van-col>
      <van-col span="24" class="info-box info-list">
        <van-col span="6" class="label">企业</van-col>
        <van-col span="18" class="value">{{ userinfo.enterpriseName || '暂无数据' }}</van-col>
      </van-col>
      <van-col span="24" class="info-box info-list">
        <van-col span="6" class="label">电话</van-col>
        <van-col span="18" class="value">{{ userinfo.mobile || '暂无数据' }}</van-col>
      </van-col>
      <van-col span="24" class="info-box info-list">
        <van-col span="6" class="label">身份证号</van-col>
        <van-col span="14" class="value">{{ userinfo.idCard || '暂无数据' }}</van-col>
        <van-col v-if="userinfo.isChecked === 1" span="4" class="label" style="font-size: 12px;" @click="authClick(1)"><van-button :loading="authClickLoading" type="primary" size="mini">已认证</van-button></van-col>
        <van-col v-else span="4" class="label" style="font-size: 12px;" @click="authClick(0)"><van-button type="primary" size="mini" color="#1989fa">未认证</van-button></van-col>
      </van-col>
      <div class="id-card-info" v-show="showIdCard">
        <div class="file-upload-box">
          <van-uploader @uploader-size="100" v-model="idCardFront" :after-read="afterImgReadFront" :max-count="1" multiple @delete="imgListChange(0)" :disabled="isDisabled" :deletable="userinfo.isChecked !== 1 && !isDisabled">
            <img src="../../../../public/images/user/front.png" alt="" width="100%" height="100%" style="opacity:0.2; filter:alpha(opacity=420);" />
            <div class="Userinfo_prompt">
              点击上传身份证人像面
            </div>
          </van-uploader>
        </div>
        <div class="file-upload-box">
          <van-uploader v-model="idCardBack" :after-read="afterImgReadBack" :max-count="1" multiple @delete="imgListChange(1)" :disabled="isDisabled" :deletable="userinfo.isChecked !== 1 && !isDisabled">
            <img src="../../../../public/images/user/back.png" alt="" width="100%" height="100%" style="opacity:0.2; filter:alpha(opacity=20);" />
            <div class="Userinfo_prompt">
              点击上传身份证国徽面
            </div>
          </van-uploader>
        </div>
      </div>
    </van-row>
    <van-sticky>
      <van-row :class="authClickFlag ? 'btn-box' : 'btn-box bottom-btn-info'">
        <van-button v-if="!uploadDisable" class="info-btn" plain round hairline size="large" type="info" :loading="uploadLoading" @click="uploadIdCard" :disabled="uploadDisable">上传认证</van-button>
        <van-button class="info-btn" round hairline size="large" type="info" @click="onClickLeft">返回</van-button>
        <van-button v-if="uploadDisable" class="info-btn" plain round hairline size="large" type="info" @click="changephone(userinfo)">修改手机号码</van-button>
      </van-row>
    </van-sticky>
  </div>
</template>

<script>
import {Dialog} from "vant";

export default {
  name: "userinfo",
  data() {
    return {
      pagename: "我的资料",
      userinfo: {
        realName: "",
        mobile: "",
        idCard: "",
        enterpriseName: "",
        idCardFront: "",
        idCardRear: "",
        isChecked: 0
      },
      showIdCard: false,
      authClickLoading: false,    // 已认证按钮点击加载效果
      idCardFront: [],
      idCardBack: [],
      isDisabled: false,
      uploadLoading: false,   // 身份证上传认证按钮加载效果
      uploadDisable: true,    // 身份证上传认证按钮显示和禁用（false显示，true不显示）
      authClickFlag: false    // 是否显示身份证图片
    };
  },
  created() {
    this.init();
    // history.pushState(null, null, document.URL)
    // window.addEventListener('popstate', function() {
    //   history.pushState(null, null, document.URL)
    // })
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    init() {
      this.userinfo = this.$eventHub.userInfo
      console.log(this.userinfo)
      this.userinfo.mobile = this.userinfo.mobile.replace('+86 ', '')
    },
    authClick(val) {
      if (val === 1) {
        this.authClickLoading = true
        this.uploadDisable = true
        // 调接口查身份证图片
        if (!this.userinfo.idCardFront || !this.userinfo.idCardRear) {
          this.$axios.get(`/course/front/member/idcard`).then(res => {
            if (res.data.status === 1) {
              this.authClickLoading = false
              this.userinfo.idCardFront = res.data.data.idCardFront
              this.userinfo.idCardRear = res.data.data.idCardRear
              this.idCardFront = [{ url: res.data.data.idCardFrontSrc }]
              this.idCardBack = [{ url: res.data.data.idCardRearSrc }]
              this.showIdCard = true
              this.authClickFlag = true
            } else {
              this.authClickLoading = false
              this.$Toast.fail(res.data.message)
            }
          })
        } else {
          this.authClickLoading = false
          this.showIdCard = true
          this.authClickFlag = true
        }
      } else {
        this.showIdCard = true
        this.uploadDisable = false
        this.authClickFlag = true
      }
    },
    imgListChange(val) {
      if (this.userinfo.isChecked === 1) {
        this.userinfo.idCardFront = ''
        this.userinfo.idCardRear = ''
        this.idCardFront = []
        this.idCardBack = []
        if (this.uploadDisable) {
          this.uploadDisable = false
        }
      } else {
        if (val === 0) {
          this.userinfo.idCardFront = ''
          this.idCardFront = []
        } else {
          this.userinfo.idCardRear = ''
          this.idCardBack = []
        }
        if (!this.userinfo.idCardFront && !this.userinfo.idCardRear && this.uploadDisable) {
          this.uploadDisable = false
        }
      }
    },
    changephone() {
      this.$router.push("/user/userinfo/changephone");
    },
    // 图片开始上传时的回调
    afterImgReadFront(file) {
      this.isUploading = true
      if (Array.isArray(file)) {
        this.$Toast.fail("一次只能上传一个图片")
        return
      } else {
        let srcSize = parseFloat(file.file.size)
        let index = Math.floor(Math.log(srcSize)/Math.log(1024))
        let size = srcSize/Math.pow(1024,index)
        size = size.toFixed(2)
        if (index > 1 && size >= 5) {
          Dialog.alert({message: '图片不允许超过5MB'}).then(() => {
            this.idCardFront = []
            return
          })
        } else {
          this.userinfo.idCardFront = file.content
        }
      }
    },
    afterImgReadBack(file) {
      this.isUploading = true
      if (Array.isArray(file)) {
        this.$Toast.fail("一次只能上传一个图片")
        return
      } else {
        let srcSize = parseFloat(file.file.size)
        let index = Math.floor(Math.log(srcSize)/Math.log(1024))
        let size = srcSize/Math.pow(1024,index)
        size = size.toFixed(2)
        if (index > 1 && size >= 5) {
          Dialog.alert({message: '图片不允许超过5MB'}).then(() => {
            this.idCardBack = []
            return
          })
        } else {
          this.userinfo.idCardRear = file.content
        }
      }
    },
    uploadIdCard() {
      this.uploadLoading = true
      if (!this.userinfo.idCardFront || !this.userinfo.idCardRear) {
        this.$Toast.fail("身份证人像面和国徽面必须同时上传")
        this.uploadLoading = false
        return
      }
      let params = new FormData()
      params.append("idCardFront", this.userinfo.idCardFront)
      params.append("idCardBack", this.userinfo.idCardRear)
      this.$axios.post('/course/front/member/upload/idcard', params).then(res => {
        if (res.data.status === 1) {
          if (res.data.data.isUsing === 'Y') {
            Dialog.confirm({
              title: '身份信息确认',
              message: res.data.data.promptContent,
            }).then(() => {
              this.updateMemberIdCardInfo(res)
            })
          } else {
            this.updateMemberIdCardInfo(res)
          }
          this.uploadLoading = false
        } else {
          Dialog.alert({message: res.data.message}).then(() => {
            // this.$Toast.fail(res.data.message)
            this.uploadLoading = false
          })
        }
      }).catch(() => {
        this.$Toast.fail("身份证识别异常")
        this.uploadLoading = false
      })
    },
    updateMemberIdCardInfo(params) {
      let formData = new FormData()
      formData.append("idCard", params.data.data.idCard)
      formData.append("realName", params.data.data.realName)
      formData.append("birthday", params.data.data.birthday)
      formData.append("gender", params.data.data.gender)
      formData.append("idCardFront", params.data.data.idCardFront)
      formData.append("idCardRear", params.data.data.idCardRear)
      this.$axios.post('/course/front/member/idcardinfo', formData).then(res => {
        if (res.data.status === 1) {
          this.userinfo.isChecked = 1
          this.userinfo.idCard = params.data.data.idCard
          this.userinfo.realName = params.data.data.realName
          this.userinfo.idCardFront = ''
          this.userinfo.idCardRear = ''
          // 更新缓存
          localStorage.userInfo = JSON.stringify(this.userinfo)
          this.uploadDisable = true
          this.$Toast.success(res.data.message)
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(() => {
        this.$Toast.fail("身份证信息入库异常")
      })
    }
  },
};
</script>

<style scoped>
.bottom-btn-info {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
}
.id-card-info >>> .van-uploader {
  margin-top: 0.2rem;
}
.id-card-info >>> .van-uploader__upload {
  width: 6rem;
  height: 4rem;
}
.id-card-info >>> .van-uploader__preview {
  width: 6rem;
  height: 4rem;
}
.id-card-info >>> .van-uploader__preview-image {
  width: 6rem;
  height: 4rem;
}
.file-upload-box {
  position: relative;
  margin: 0 2rem;
}
.Userinfo_prompt{
  width: 100%;
  position: absolute;
  top: 40%;
  left: 8%;
  font-size: 0.5rem;
  color: #666;
  /*margin: auto 0.5rem;*/
}
</style>

<style lang="less" scoped>
.user-info {
  background-color: #f8f8f8;
  min-height: 100vh;
  .info-mg {
    margin-top: 10px;
  }
  .info-box {
    height: 2.48rem;
    background-color: #ffffff;
    border-bottom: solid 0.01rem #eeeeee;
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
    .info-img {
      width: 1.73rem;
      height: 1.73rem;
      border-radius: 50%;
      background-color: #eeeeee;
      margin-right: 0.63rem;
    }
    .info-name {
      font-family: PingFang-SC-Medium;
      font-size: 0.4rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0.01rem;
      color: #050505;
    }
    .label {
      font-family: PingFang-SC-Regular;
      font-size: 0.37rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0.01rem;
      color: #666666;
      padding-left: 0.1rem;
    }
    .value {
      font-family: PingFang-SC-Medium;
      font-size: 0.37rem;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0.01rem;
      color: #333333;
    }
  }
  .info-list {
    height: 1.2rem;
  }
  .btn-box {
    text-align: center;
    margin-top: 0.6rem;

    .info-btn {
      width: 80%;
      height: 1.17rem;
      margin-bottom: 0.4rem;
      .van-button__text {
        font-size: 0.4rem;
      }
    }
  }
}
</style>
