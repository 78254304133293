import User from '@/page/user/Index'
import Notice from '@/page/user/Notice'
import NoticeDetail from '@/page/user/NoticeDetail'
import Userbuy from '@/page/user/UserBuy/Userbuy'
import UserLrecord from '@/page/user/UserBuy/Lrecord'
import UserInfo from '@/page/user/Userinfo/Userinfo'
import ChangePhone from '@/page/user/Userinfo/ChangePhone'
import Coupons from '@/page/user/Coupons'
import CouponsModule from '@/page/user/CouponsModule'
import GetCoupon from '@/page/user/GetCoupon'
import UseCoupon from '@/page/user/UseCoupon'
import MyExam from '@/page/user/MyExam'
import CouponList from '@/page/user/CouponList'
import Question from '@/page/user/Question'
import Answer from '@/page/user/Answer'
import Payment from '@/page/user/Order/Payment'
import NotPayment from '@/page/user/Order/NotPayment'
export default [
  //我的主页
  {
    path: '/user',
    name: 'user',
    component: User
  },
  // 通知
  {
    path: '/user/notice',
    name: 'notice',
    component: Notice
  },
  // 通知详情
  {
    path: '/user/NoticeDetail/:id',
    name: 'noticeDetail',
    component: NoticeDetail
  },
  // 我购买的-课程
  {
    path: '/user/userbuy/buy',
    name: 'userbuy',
    component: Userbuy,
    meta: {
      title: '我的课程',
      // keepAlive: true
    }
  },
  // 我的学习记录
  {
    path: '/user/userbuy/lrecord',
    name: 'userlrecord',
    component: UserLrecord,
    meta: {
      title: '学习记录',
      // keepAlive: true
    }
  },
  //我的资料
  {
    path: '/user/userinfo/info',
    name: 'userinfo',
    component: UserInfo
  },
  // 修改手机号
  {
    path: '/user/userinfo/changephone',
    name: 'changephone',
    component: ChangePhone
  },
  //选择优惠券
  {
    path: '/user/coupons',
    name: 'coupons',
    component: Coupons
  },
  // 领取优惠券
  {
    path: '/user/getcoupon/:id',
    name: 'getcoupon',
    component: GetCoupon
  },
  // 使用优惠券
  {
    path: '/user/usecoupon',
    name: 'usecoupon',
    component: UseCoupon
  },
  // 优惠券商品列表
  {
    path: '/user/couponList/:id',
    name: 'couponList',
    component: CouponList
  },
  //已支付
  {
    path: '/user/payment',
    name: 'payment',
    component: Payment
  },
  //未支付
  {
    path: '/user/NotPayment',
    name: 'notPayment',
    component: NotPayment
  },
  // 个人中心优惠券
  {
    path: '/user/couponsmodule',
    name: 'couponsmodule',
    component: CouponsModule
  },
  // 我的考试
  {
    path: '/user/myexam',
    name: 'myExam',
    component: MyExam
  },
  // 提问
  {
    path: '/user/question/:id',
    name: 'question',
    component: Question
  },
  // 回答
  {
    path: '/user/answer/:id',
    name: 'answer',
    component: Answer
  }
]