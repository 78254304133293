<template>
  <div>
    <zv-nav-bar :title="'班级列表'"></zv-nav-bar>
    <div class="class-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="class-item" v-for="item in classData" :key="item.ID" @click="gotoPage(`/nzzone/classinfo?id=${item.ID}`)">
            <div class="img-box">
              <img :src="item.logoSrc" />
            </div>
            <div class="class-name">
              <div>
                <h5 class="line-ellipsis">{{ item.name }}</h5>
                <span>共{{ item.numberOfCourses }}节课</span>
              </div>
              <span>有效期：{{ item.startDate }}-{{ item.endDate }}</span>
            </div>
            <p class="line-ellipsis" v-html="item.description"></p>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
export default {
  name: 'enterpriseClassList',
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      classData: [],
      pageIndex: 0,
    }
  },
  activated() {
    this.setShare()
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: `班级列表`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    onRefresh() {
      this.pageIndex = 0
      this.classData = []
      this.$nextTick(() => {
        this.finished = false
        this.loading = true
        this.onLoad()
      })
    },
    onLoad() {
      this.$axios
        .get(`/course/front/member/enterprisecenter/classes?pageSize=10&pageIndex=${this.pageIndex}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.pageIndex = this.pageIndex + 1
            this.classData = this.classData.concat(res.data.data.data)
            this.loading = false
            if (this.classData.length >= res.data.data.total) {
              this.finished = true
            }
            this.refreshing = false
          } else {
            this.loading = false
            this.finished = true
            this.refreshing = false
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          // 加载状态结束
          this.loading = false
          this.finished = true
          this.refreshing = false
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
      this.$forceUpdate()
    },
    // 页面跳转
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    format(str) {
      if (!str) {
        return ''
      }
      return str.substring(0, 10).replace(/-/g, '.')
    },
  },
}
</script>
<style scoped>
.class-list {
  background: #fff;
  border-radius: 0.13rem 0.13rem 0 0;
  padding: 0.4rem 0.4rem 0 0.4rem;
}
.class-list .class-item {
  display: flex;
  flex-wrap: wrap;
  color: #999;
  border-bottom: 0.01rem solid #eee;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.class-list .class-item .img-box {
  width: 1.45rem;
  height: 1.45rem;
  border-radius: 50%;
  overflow: hidden;
}
.class-list .class-item .img-box img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.class-list .class-item .class-name {
  margin: 0 0 0 0.35rem;
  flex: 1;
}
.class-list .class-item .class-name > div {
  display: flex;
  justify-content: space-between;
}
.class-list .class-item .class-name > div h5 {
  font-size: 0.4rem;
  color: #1b1b1b;
  /* font-size: 0.32rem; */
  margin: 0;
  width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.special-zone .line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.class-list .class-item .class-name > div span {
  font-size: 0.32rem;
  white-space: nowrap;
}
.class-list .class-item .class-name > span {
  font-size: 0.35rem;
}
.class-list .class-item > p {
  font-size: 0.33rem;
  width: 100%;
  -webkit-line-clamp: 2;
  margin: 0.3rem 0 0 0;
}
</style>
