<template>
  <div class="search-page">
    <!-- 搜索栏 -->
    <div class="part-1">
      <van-icon name="arrow-left" size="0.55rem" @click="toPrevPage" />
      <div class="search-box">
        <van-search v-model="searchValue" shape="round" left-icon="" placeholder="搜索课程" />
        <van-button class="search-button" round color="linear-gradient(90deg, #5abffe 0%, #389cf8 61%, #1578f2 100%)" @click="searchRes">搜索</van-button>
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="classify-courses-list-content">
        <template v-if="listData.length > 0">
          <div v-for="(item, index) in listData" :key="index" class="curriculum" @click="gotoPage(item)">
            <div class="logo">
              <img :src="item.logoFile" />
            </div>
            <div class="details reset">
              <div class="title">
                <span class="tag">{{ item.type !== 0 ? '讲义' : '视频' }}</span>
                <span class="title-column">{{ item.name }}</span>
              </div>
              <div class="teacher">{{ item.speaker }}</div>
              <div class="details-item">
                <div>
                  <span class="duration">时长{{ item.totalLength }}</span>
                  <span class="count">{{ item.viewCount }}</span>
                </div>
                <div :class="{ 'has-pive': item.hasPermission === 'Y' }">{{ item.hasPermission === 'Y' ? '有权限' : '无权限' }}</div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="not-data">
          <span>暂无数据</span>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  name: 'searchResult',
  data() {
    return {
      loading: false,
      finished: false,
      searchValue: '',
      listData: [],
      pageIndex: 0,
      prevValue: '',
    }
  },
  created() {
    this.searchValue = this.$route.query.query || ''
  },
  methods: {
    // 返回上一页
    toPrevPage() {
      this.$router.go(-1)
    },
    searchRes() {
      if (!this.searchValue) {
        return this.$Toast.fail('请输入关键字')
      }
      if (this.prevValue !== this.searchValue) {
        this.pageIndex = 0
        this.listData = []
        this.finished = false
        this.loading = true
      }
      this.onLoad()
    },
    onLoad() {
      if (!this.searchValue) {
        return
      }
      let enterpriseID = this.$route.query.enterpriseID
      this.prevValue = this.searchValue
      this.$axios
        .get(`/course/front/member/enterprisecenter/search?enterpriseID=${enterpriseID}&searchName=${this.searchValue}&pageSize=10&pageIndex=${this.pageIndex}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.pageIndex = this.pageIndex + 1
            this.listData = this.listData.concat(res.data.data.data)
            this.loading = false
            if (this.listData.length >= res.data.data.total) {
              this.finished = true
            }
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          this.loading = false
          this.finished = true
          return e
        })
    },
    gotoPage(item) {
      let path = `/allCourses/details/${item.ID}/curriculum`
      if (item.type !== 0) {
        path = `/allCourses/courseHandout/${item.ID}`
      }
      this.$router.push(path)
    },
  },
}
</script>
<style scoped>
.classify-courses-list-content {
  padding: 0.33rem;
  min-height: calc(100vh - 1.86rem);
  background-color: #eee;
}
.container {
  line-height: 0.96rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.08rem 0.13rem 0rem rgb(207 207 207 / 20%);
  box-sizing: border-box;
  margin-bottom: 0.03rem;
}
.count {
  color: #bebebe;
  font-size: 0.32rem;
}
.logo {
  width: 1.04rem;
  height: 1.04rem;
  margin-left: 0.32rem;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.curriculum .logo {
  margin-left: 0;
}
.details {
  width: 6.56rem;
  margin-left: 0.19rem;
}
.details .title {
  height: 0.36rem;
  line-height: 0.48rem;
  font-weight: bold;
  color: #444;
  font-size: 0.37rem;
}
.details .teacher {
  font-size: 0.32rem;
  height: 0.48rem;
  line-height: 0.96rem;
}
.details .details-item {
  height: 0.69rem;
  line-height: 1.12rem;
  color: #bebebe;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
  width: 7.2rem;
}
.details .details-item .has-pive {
  color: #1b1b1b;
}
.curriculum {
  height: 2.13rem;
  background-color: #ffffff;
  box-shadow: 0 0.08rem 0.13rem 0 rgb(207 207 207 / 20%);
  border-radius: 0.13rem;
  border: solid 0.01rem #f5f5f5;
  line-height: 1.68rem;
  padding: 0.32rem 0.25rem;
  box-sizing: border-box;
  display: flex;
  margin: 0.17rem 0 0.27rem 0;
  padding-right: 0;
}
.tag {
  font-size: 0.32rem;
  display: inline-block;
  border: 1px solid #70dd96;
  background-color: #70dd96;
  border-radius: 0.11rem;
  padding: 0 1px;
  color: #fff;
  margin-right: 0.13rem;
}
.details.reset {
  width: 7.73rem;
}
.title-column {
  display: inline-block;
  width: 6.66rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.duration {
  width: 1.6rem;
  margin: 0 0.53rem 0 0;
  background-color: #f6f7f9;
  border-radius: 2px;
  padding: 2px;
}
.not-data {
  display: flex;
  justify-content: center;
  height: calc(100vw);
  line-height: calc(100vw);
  color: #999;
}
</style>
<style lang="less">
.search-page {
  .part-1 {
    display: flex;
    align-items: center;
    padding: 0 0.3rem 0.2rem 0.3rem;
    .search-box {
      display: flex;
      align-items: center;
      flex: 1;
      position: relative;
      .van-search {
        width: 100%;
      }
      .van-button--normal {
        font-size: 0.35rem;
        padding: 0.4rem 0.2rem;
        height: 0.72rem;
        width: 1.47rem;
      }
      .search-button {
        position: absolute;
        right: 16px;
      }
      .van-field__body {
        height: 100%;
      }
      .van-field__control {
        font-size: 0.35rem;
        padding: 0.4rem 0.2rem;
        height: 0.72rem;
      }
      .van-field__left-icon {
        display: flex;
        align-items: center;
      }
      .van-icon-search {
        font-size: 0.45rem !important;
      }
    }
  }
}
</style>
