<script>
// import CryptoJS from 'crypto-js'

import { IseRecorder, Recorder_status } from '../../utils/ise-recorder/IseRecorder'

export default {
  name: 'IseRecorder',

  props: {
    appId: {
      type: String,
      required: true,
    },
    appSecret: {
      type: String,
      required: true,
    },
    appKey: {
      type: String,
      required: true,
    },
    text: String,
    ent: String,
    category: String,
    group: String,
    check_type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      recorder: null,
      recorderStatus: Recorder_status.NULL,
    }
  },

  computed: {
    scopedSlotProps() {
      return {
        status: this.recorderStatus,
        isRecorderInitFinish: this.isRecorderInitFinish,
        isRecording: this.isRecording,
      }
    },

    isRecorderInitFinish() {
      const { recorderStatus } = this
      return recorderStatus !== Recorder_status.NULL || recorderStatus !== Recorder_status.INIT
    },

    isRecording() {
      return this.recorderStatus === Recorder_status.RECORDING || this.recorderStatus === Recorder_status.RECORD_END
    },
  },

  async created() {
    const url = await this.getIseWebSocketUrl()

    this.recorder = new IseRecorder({
      url,
      appId: this.appId,
      appSecret: this.appSecret,
      appKey: this.appKey,
      onWillStatusChange: this.onWillStatusChange,
      onTextChange: this.onTextChange,
      getSID: this.getSID,
      logger: {
        log: (message, code) => {
          // if (code == '10114') {
          //   this.$emit('timeOut', message)
          // }
          this.$emit('getLog', {
            message,
            code,
          })
        },
        // info: (code) => {
        //   this.$emit('getInfo', code)
        // },
        error: (message, readyState, recorderState) => {
          this.$emit('getError', {
            message,
            readyState,
            recorderState,
          })
        },
        warn: (warn) => {
          this.$emit('getWarn', warn)
        },
      },
    })
    this.recorder.getWssUrl = this.getWssUrl
  },

  methods: {
    getRecorderBusinessParams() {
      return {
        ent: this.ent,
        category: this.category,
        text: this.text,
        group: this.group,
        check_type: this.check_type,
      }
    },

    handleClick() {
      this.toggleRecordStatus()
    },

    getIsRecording() {
      return this.isRecording
    },

    async startRecord() {
      if (this.isRecorderInitFinish && !this.isRecording) {
        this.recorder.closeEventCode += '1'
        const errorMsg = await this.recorder.startRecord(this.getRecorderBusinessParams()).catch((msg) => {
          msg && this.sendErrorLog(msg)
        })
        errorMsg && this.sendErrorLog(errorMsg)
      }
    },

    stopRecord() {
      if (this.isRecording) {
        this.recorder.closeEventCode += '2'
        this.recorder.stopRecord()
      }
      console.log('Recorder_status', this.recorderStatus)
    },

    async toggleRecordStatus() {
      if (this.isRecording) {
        this.recorder.closeEventCode += '6'
        this.recorder.stopRecord()
      } else if (this.isRecorderInitFinish) {
        this.recorder.closeEventCode += '7'
        const errorMsg = await this.recorder.startRecord(this.getRecorderBusinessParams()).catch((msg) => {
          msg && this.sendErrorLog(msg)
        })
        errorMsg && this.sendErrorLog(errorMsg)
      }
    },
    sendErrorLog(message) {
      this.$axios.get('course/front/permit/test/tongue/logs', {
        params: {
          message,
        },
      })
    },
    getWssUrl() {
      return new Promise((resolve) => {
        this.$axios
          .get('/tk/front/tongue/answer/wssurl', {
            params: {
              apiKey: this.appKey,
              apiSecret: this.appSecret,
            },
          })
          .then(({ data }) => {
            if (Number(data.status) !== 1 && !data.data) {
              this.$Toast.fail('获取口语websocket连接地址失败')
            }
            resolve(data.data)
          })
          .catch((err) => {
            this.$Toast.fail('获取口语websocket连接地址异常')
            console.log(err)
          })
      })
    },

    /**
     * 获取 WebSocket url
     */
    async getIseWebSocketUrl() {
      const getUrl = await this.getWssUrl()
      return new Promise((resolve) => {
        // const API_SECRET = this.appSecret
        // const API_KEY = this.appKey
        // const baseUrl = 'wss://ise-api.xfyun.cn/v2/open-ise'
        // const host = 'ise-api.xfyun.cn'
        // const date = new Date().toGMTString()
        // const algorithm = 'hmac-sha256'
        // const headers = 'host date request-line'
        // const signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v2/open-ise HTTP/1.1`
        // const signatureSha = CryptoJS.HmacSHA256(signatureOrigin, API_SECRET)
        // const signature = CryptoJS.enc.Base64.stringify(signatureSha)
        // const authorizationOrigin = `api_key="${API_KEY}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`
        // const authorization = btoa(authorizationOrigin)
        // const url = `${baseUrl}?authorization=${authorization}&date=${date}&host=${host}`
        const url = getUrl
        resolve(url)
      })
    },

    onWillStatusChange(oldStatus, status) {
      this.recorderStatus = status

      if (status === Recorder_status.RECORDING) {
        this.recorder.closeEventCode += '3'
        this.$emit('recording')
      } else if (status === Recorder_status.RECORD_END) {
        this.recorder.closeEventCode += '4'
        this.$emit('record-end', this.recorder.audioData)
      }
    },

    onTextChange(grade) {
      this.recorder.closeEventCode += '5'
      console.log('onTextChange')
      this.$emit('text-change', grade)
    },
    getSID(sid) {
      this.$emit('get-sid', sid)
    },
  },
}
</script>

<template>
  <div class="lc-recorder" @click="handleClick">
    <slot :isRecording="isRecording" :isRecorderInitFinish="isRecorderInitFinish"> </slot>
  </div>
</template>

<style lang="less" scoped></style>
