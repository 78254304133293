<template>
  <div class="all-courses">
    <van-row>
      <van-col span="24">
        <div class="head">
          <div class="search" @click="gotoPage('/index/search')">
            <div class="search-input">
              <i class="van-icon van-icon-search"></i>
              <span>搜索课程</span>
            </div>
          </div>
          <div class="search-btn">搜索</div>
        </div>
      </van-col>
    </van-row>
    <!-- 一级栏目 -->
    <div class="one-catalog">
      <div
        :class="{
          'one-catalog-title-box': true,
          active: activeCatalogID === item.ID,
        }"
        v-for="item in catalogData"
        :key="item.ID"
        @click="clickOneCatalog(item)"
        :id="item.ID"
        :code="item.innerCode"
      >
        <template v-if="item.name.length <= 3">
          <span class="one-catalog-title">{{ item.name }}</span>
        </template>
        <template v-else-if="item.name.length === 4">
          <span class="one-catalog-title">{{ item.name.substring(0, 2) }}</span>
          <span class="one-catalog-title">{{ item.name.substring(2) }}</span>
        </template>
        <template v-else-if="item.name.length === 5">
          <span class="one-catalog-title">{{ item.name.substring(0, 2) }}</span>
          <span class="one-catalog-title">{{ item.name.substring(2) }}</span>
        </template>
        <template v-else-if="item.name.length > 5">
          <span class="one-catalog-title">{{ item.name.substring(0, 3) }}</span>
          <span class="one-catalog-title">{{ item.name.substring(3, 6) }}</span>
        </template>
      </div>
    </div>
    <!-- 一级栏目 end-->

    <!-- 二级栏目 -->
    <van-row>
      <van-col span="24">
        <van-tree-select :items="items" :main-active-index.sync="active">
          <template #content>
            <van-pull-refresh v-model="loading" @refresh="onRefresh">
              <z-tree :data="rightCatalogData" :openTag="openTag"></z-tree>
            </van-pull-refresh>
          </template>
        </van-tree-select>
      </van-col>
    </van-row>
    <!-- 二级栏目 end-->
  </div>
</template>
<script>
import $ from 'jquery'
import ZTree from '@/components/ZTree.vue'
export default {
  name: 'allCourses',
  components: {
    'z-tree': ZTree,
  },
  data() {
    return {
      activeCatalogID: '',
      catalogData: [],
      loading: false,
      active: 0,
      leftCatalog: {},
      openTag: [],
    }
  },
  computed: {
    items() {
      if (this.leftCatalog[this.activeCatalogID]) {
        let res = this.leftCatalog[this.activeCatalogID]
        for (let i = 0; i < res.length; i++) {
          res[i].text = res[i].name.substring(0, 8)
        }
        return res
      }
      return []
    },
    rightCatalogData() {
      if (this.leftCatalog[this.activeCatalogID]) {
        let res = this.leftCatalog[this.activeCatalogID][this.active].children || []
        return res
      }
      return []
    },
  },
  created() {
    this.getData()
  },
  mounted() {
    this.$nextTick(() => {
      this.$eventHub.$on('spliceOpenTag', this.spliceOpenTag)
    })
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: '麓辰课程',
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    spliceOpenTag(index) {
      this.openTag.splice(index, 1)
    },
    async getData(notNeedChckedDefault) {
      await this.$axios
        .get(`/course/front/permit/catalog/more`)
        .then((res) => {
          if (res.data.status === 1) {
            this.catalogData = res.data.data.data
            this.$eventHub.catalogData = this.catalogData
            this.openTag = this.setOpenTag()
            if (this.catalogData.length > 0) {
              if (!notNeedChckedDefault) {
                this.activeCatalogID = this.catalogData[0].ID
              }
            }
            let data = res.data.data.data
            let obj = {}
            for (let i = 0; i < data.length; i++) {
              if (data[i].children) {
                obj[data[i].ID] = data[i].children
              }
            }
            this.leftCatalog = obj
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          return this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    setOpenTag() {
      let res = []
      let innerCode = this.$route.query.innerCode || ''
      if (!innerCode) {
        return this.openTag
      }
      if (innerCode.length / 6 > 5) {
        innerCode = innerCode.substring(0, innerCode.length - 6)
      }
      let count = innerCode.length / 6
      for (let i = 0; i < count; i++) {
        if (i < count - 2) {
          let code = innerCode.substring(0, innerCode.length - 6 * i)
          res.push(code)
        }
      }
      return res
    },
    gotoPage(path) {
      localStorage.search_page_source = this.$router.history.current.fullPath
      this.$router.push({ path: path })
    },
    clickOneCatalog(item) {
      this.active = 0
      this.activeCatalogID = item.ID
    },
    async onRefresh() {
      await this.getData(true)
      this.loading = false
    },
  },
  activated() {
    window.document.title = '麓辰教育'

    this.setShare()
    let innerCode = ''
    if (this.$route.query.innerCode) {
      innerCode = this.$route.query.innerCode
    } else if (document.getElementById(this.activeCatalogID)) {
      innerCode = this.items[this.active].innerCode
    }
    if (!innerCode) {
      return
    }
    if (this.catalogData.length === 0) {
      const timer = setInterval(() => {
        if (this.catalogData.length > 0) {
          this.$nextTick(() => {
            for (let i = 0; i < this.catalogData.length; i++) {
              if (this.catalogData[i].innerCode === innerCode.substring(0, 6)) {
                this.activeCatalogID = this.catalogData[i].ID
                let scrollLeft = document.getElementById(this.activeCatalogID).offsetLeft
                $('.one-catalog').animate({ scrollLeft: scrollLeft }, 500)
                break
              }
            }
            if (innerCode.length === 6) {
              this.active = 0
              clearInterval(timer)
              return
            }
            for (let i = 0; i < this.catalogData.length; i++) {
              if (this.catalogData[i].children) {
                let arr = this.catalogData[i].children
                for (let j = 0; j < arr.length; j++) {
                  if (arr[j].innerCode === innerCode.substring(0, 12)) {
                    this.active = j
                  }
                }
              }
            }
            this.openTag = this.setOpenTag()
            clearInterval(timer)
          })
        }
      }, 100)
    } else {
      this.$nextTick(() => {
        for (let i = 0; i < this.catalogData.length; i++) {
          if (this.catalogData[i].innerCode === innerCode.substring(0, 6)) {
            this.activeCatalogID = this.catalogData[i].ID
            let scrollLeft = document.getElementById(this.activeCatalogID).offsetLeft
            $('.one-catalog').animate({ scrollLeft: scrollLeft }, 500)
            break
          }
        }
        if (innerCode.length === 6) {
          this.active = 0
          this.openTag = this.setOpenTag()
          return
        }
        for (let i = 0; i < this.catalogData.length; i++) {
          if (this.catalogData[i].children) {
            let arr = this.catalogData[i].children
            for (let j = 0; j < arr.length; j++) {
              if (arr[j].innerCode === innerCode.substring(0, 12)) {
                this.active = j
              }
            }
          }
        }
        this.openTag = this.setOpenTag()
      })
    }
  },
  destroyed() {
    this.$eventHub.$off('spliceOpenTag', this.spliceOpenTag)
  },
}
</script>
<style scoped>
.all-courses .head {
  display: flex;
  margin: 0.26rem 0.33rem 0.39rem 0.33rem;
}
.all-courses .head .search-input {
  width: 7.93rem;
  height: 0.8rem;
  background-color: #f7f7f7;
  border-radius: 0.4rem;
  line-height: 0.8rem;
  display: flex;
  align-items: center;
}
.all-courses .head .van-icon-search {
  font-size: 0.4rem;
  margin: 0 0.44rem;
  color: #bcbcbc;
}
.all-courses .head .search-input span {
  font-size: 0.32rem;
  color: #bcbcbc;
}
.all-courses .head .search-btn {
  width: 1rem;
  height: 0.8rem;
  background-image: linear-gradient(-45deg, rgba(50, 141, 252, 0.99) 0%, rgba(114, 173, 255, 0.99) 100%), linear-gradient(#353535, #353535);
  border-radius: 0.33rem;
  color: #ffffff;
  font-size: 0.32rem;
  margin-left: 0.33rem;
  line-height: 0.8rem;
  text-align: center;
}
.one-catalog {
  padding-bottom: 0.2rem;
  display: flex;
  overflow-y: auto;
  background-color: #f7f8fa;
}
.one-catalog-title-box {
  /* width: 1.12rem;
  height: 0.96rem; */
  padding: 0.21rem 0.011rem;
  border-right: 1px solid #eee;
}
.one-catalog-title-box.active {
  background-color: #808080;
  color: #fff;
}
.one-catalog-title {
  font-size: 0.31rem;
  width: 1.2rem;
  text-align: center;
  display: block;
}
.all-courses >>> .van-tree-select {
  height: 100% !important;
  font-size: 0.37rem;
}
.all-courses >>> .van-sidebar {
  width: 2.13rem;
  background-color: #f8f8f8;
}
.all-courses >>> .van-sidebar {
  -webkit-box-flex: unset;
  -webkit-flex: unset;
  flex: unset;
  background-color: unset;
  height: calc(100vh - 4.26rem);
}
.all-courses >>> .van-tree-select__nav-item {
  padding: 0.26rem;
  font-size: 0.34rem;
  line-height: 0.58rem;
}
.all-courses >>> .van-sidebar-item--select {
  font-weight: bold;
}
.all-courses >>> .van-sidebar-item--select::before {
  background-color: #3fa1fd;
  border-radius: 0rem 0.09rem 0.09rem 0rem;
  width: 0.11rem;
  height: 0.42rem;
}
.all-courses >>> .van-tree-select__content {
  margin-left: 0.28rem;
  margin-right: 0.25rem;
  height: calc(100vh - 4.26rem);
  /* padding-bottom: 1.33rem; */
}
.all-courses >>> .van-pull-refresh {
  height: 100%;
  overflow-y: auto;
}
</style>
