<template>
  <div>
    <zv-nav-bar :title="title" :clickLeft="clickLeft"></zv-nav-bar>
    <div class="content">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
        <div class="item" v-for="item in listData" :key="item.ID" @click="goDetails(item)">
          <div class="logo">
            <img :src="item.logoFile" />
          </div>
          <div class="other">
            <div class="title-item">
              <div style="display: flex; height: 0.5rem; line-height: 0.5rem;">
                <span v-if="item.type !== 0 && item.goodsType === 'Course'" class="tag" :style="{ 'min-width': item.goodsType === 'CoursePackage' ? '1.22rem' : '0.85rem' }">讲义</span>
                <span v-else class="tag" :style="{ 'min-width': item.goodsType === 'CoursePackage' ? '1.22rem' : '0.85rem' }">{{ item.goodsType | formatValue }}</span>
                <span class="title">{{ item.name }}</span>
              </div>
              <span class="teacher">{{ item.speaker || '' }}</span>
              <div style="height: 0.48rem; line-height: 0.48rem;">
                <span class="time" v-if="item.goodsType !== 'CoursePackage'">时长{{ (item.totalLength || item.liveLength) | formatTime }}</span>
                <span class="count" v-if="item.viewCount">{{ item.viewCount | formatCount }}</span>
              </div>
            </div>
            <div class="price-item">
              <template v-if="!item.isForbid">
                <div class="current">￥{{ isVip ? item.memberPrice : item.sellPrice }}</div>
                <div class="old" v-if="isVip">￥{{ item.sellPrice }}</div>
              </template>
              <template v-else>
                <div style="font-size: 0.32rem; width: 1.28rem;">会员专享</div>
              </template>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: 'couponList',
  props: {},
  data() {
    return {
      loading: false,
      finished: true,
      listData: [],
      isVip: true,
    }
  },
  computed: {
    title() {
      return '商品列表'
    },
  },
  filters: {
    formatValue(value) {
      if (!value) {
        return '视频'
      }
      switch (value) {
        case 'Course':
          return '视频'
        case 'CoursePackage':
          return '系列课'
        default:
          return '直播'
      }
    },
    formatTime(value) {
      if (!value) {
        return 0
      }
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      let m = Math.floor((result / 60) % 60) < 10 ? '0' + Math.floor((result / 60) % 60) : Math.floor((result / 60) % 60)
      let s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60)

      let res = ''
      if (h !== '00') res += `${h}:`
      res += `${m}:`
      res += `${s}`
      return res || 0
    },
    formatCount(value) {
      let res = parseInt(value)
      if (res < 10000) {
        return res + '次'
      } else {
        if (res / 10000 < 1.1) {
          return (res % 10000) + 'W次'
        }
        return parseInt(res % 10000) + 'W次'
      }
    },
  },
  created() {
    this.getData()
    this.setShare()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = info.isVip && (info.enterpriseName || info.enterpriseShorthand)
    }
  },
  methods: {
    clickLeft() {
      if (this.$route.query.from === 'coupon') {
        this.gotoPage('/')
      } else {
        this.$router.go(-1)
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    hrefPage(item) {
      if (!item.alias) {
        this.gotoPage(`/allCourses/details/${item.ID}/curriculum`)
      }
    },
    goDetails(item) {
      if (item.goodsType === 'LIVE') {
        this.gotoPage(`/allCourses/listDetails/${item.ID}?couponId=${this.$route.query.couponId}`)
      } else if (item.goodsType === 'Course') {
        let path = `/allCourses/details/${item.ID}/curriculum?couponId=${this.$route.query.couponId}`
        if (item.type !== 0) {
          path = `/allCourses/courseHandout/${item.ID}?couponId=${this.$route.query.couponId}`
        }
        this.gotoPage(path)
      } else {
        this.gotoPage(`/allCourses/packDetails/${item.ID}?couponId=${this.$route.query.couponId}`)
      }
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    getData() {
      let id = this.$route.params.id
      this.$axios
        .get(`/course/priv/member/coupon/${id}/goodslist`)
        .then((res) => {
          if (res.data.status === 1) {
            this.listData = res.data.data
            this.finished = true
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
  },
}
</script>

<style scoped>
.content {
  background-color: #eee;
  padding: 0.29rem 0.21rem;
  min-height: 100vh;
}
.item {
  display: flex;
  background-color: #fff;
  height: 2rem;
  padding: 0.17rem 0.24rem;
  margin-top: 0.29rem;
}
.content .item:first-child {
  margin-top: 0;
}
.logo {
  width: 1.04rem;
  height: 1.04rem;
}
.logo img {
  width: 100%;
  height: 100%;
  border-radius: 0.11rem;
  object-fit: cover;
}
.other {
  display: flex;
  width: 8.05rem;
  justify-content: space-between;
}
.title-item {
  padding-left: 0.23rem;
  margin-top: -0.21rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.tag {
  color: #fc7d55;
  font-size: 0.32rem;
  display: inline-block;
  border: 1px solid #fc7d55;
  padding: 1px;
  border-radius: 0.08rem;
  text-align: center;
}
.title {
  color: #444444;
  font-size: 0.4rem;
  font-weight: bold;
  margin-left: 0.15rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.teacher {
  color: #484a49;
  font-size: 0.32rem;
}
.time {
  background-color: #f6f7f9;
  border-radius: 0.05rem;
  display: inline-block;
  background-color: #eee;
  font-size: 0.32rem;
  color: #999;
  width: 1.6rem;
  text-align: center;
}
.count {
  font-size: 0.32rem;
  color: #999999;
  margin-left: 0.37rem;
}
.price-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.current {
  font-size: 0.43rem;
  color: #1b1b1b;
  margin-top: -0.32rem;
}
.old {
  font-size: 0.32rem;
  color: #999;
  background-color: #f3f3f3;
  border-radius: 0.05rem;
  text-indent: 0.1rem;
  text-decoration: line-through;
}
</style>
