<template>
  <div class="coupons">
    <zv-nav-bar :title="pagename" :clickLeft="clickLeft"></zv-nav-bar>
    <div class="coupon_list">
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell clickable @click="radio = '0'">
            <template #title>
              <span class="custom-title">不使用优惠券</span>
            </template>
            <template #right-icon>
              <van-radio name="0" />
            </template>
          </van-cell>
          <van-cell clickable @click="radio = item.ID" v-for="item in coupons" :key="item.ID">
            <template #title>
              <div :class="{listbg: true, kecheng: item.type !== 'All', tongyong: item.type === 'All'}">
                <div class="cell_l">
                  <div class="cell_number">
                    <span class="num">{{item.faceValue}}</span><span class="num_text">元</span>
                  </div>
                  <div class="cell_time">有效期至{{item.validitiyEnd}}</div>
                </div>
                <div class="cell_c" v-if="item.type !== 'All'">
                  <span>部分课程使用</span>
                </div>
                <div class="cell_c" v-else>
                  <span>所有商品通用</span>
                </div>
                <div class="cell_r" v-if="item.type !== 'All'">
                  <span>去使用</span>
                </div>
                <div class="cell_r" v-else>
                  <span>通用券</span>
                </div>
              </div>
            </template>
            <template #right-icon>
              <van-radio :name="item.ID" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="coupon_btn">
      <van-button round type="info" @click="clickLeft(true)">确认使用</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "coupons",
  data() {
    return {
      pagename: "选择优惠券",
      radio: "0",
    };
  },
  props: {
    coupons: {
      type: Array,
      default() {
        return []
      }
    },
    checkedRadio: {
      type: [String, Number],
      default: '0'
    }
  },
  created() {
    this.radio = this.checkedRadio === 0 ? '0': this.checkedRadio
  },
  methods: {
    clickLeft(flag) {
      if (flag) {
        this.$emit('clickLeft', Number(this.radio), false)
      } else {
        this.$emit('clickLeft', null, false)
      }
    }
  },
};
</script>

<style lang="less" scoped>
.coupons {
  min-height: 100vh;
  background-color: #f8f8f8;
  .coupon_list {
    padding: 0.4rem;
    .van-cell-group {
      background-color: initial;
    }
    [class*="van-hairline"]::after {
      border: 0;
    }
    .van-cell {
      background-color: initial;
      margin-bottom: 0.4rem;
      padding: 0;
      padding-right: 0.4rem;
      line-height: ;
      .van-cell__title {
        // flex: 0 0 90%;
      }
      .listbg {
        min-height: 2.9rem;
        // display: flex;
        align-items: center;
        // padding: 0 0.5rem;
        // padding-right: 0.8rem;
        position: relative;
        .cell_l {
          position: absolute;
          top: 56%;
          left: 4%;
          transform: translate(0, -50%);
          .cell_number {
            margin-bottom: 0.2rem;
            .num {
              font-family: PingFang-SC-Heavy;
              font-size: 1.2rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #fffefe;
              text-shadow: 0rem 0.11rem 0rem rgba(27, 90, 167, 0.56);
              opacity: 0.97;
            }
            .num_text {
              font-family: SourceHanSansCN-Medium;
              font-size: 0.46rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #fffefe;
            }
          }
          .cell_time {
            font-family: AdobeHeitiStd-Regular;
            font-size: 0.34rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #fffefe;
          }
        }
        .cell_c {
          // background-color: #fffdfd;
          // border-radius: 0.13rem;
          // display: none;
          width: 1.99rem;
          // padding: 0 5px;
          position: absolute;
          top: 53%;
          left: 42%;
          transform: translate(0, -50%);
          text-align: center;
          font-size: 0.3rem;
          line-height: 0.4rem;
          span {
            font-family: PingFang-SC-Bold;
            // font-size: 0.17rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #1d72da;
            display: block;
            position: relative;
            z-index: 1;
          }
        }
        .cell_c::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 0;
          background: #fff;
          border-radius: 0.13rem;
          transform: skew(-25deg);
        }

        .cell_r {
          position: absolute;
          top: 50%;
          right: 11%;
          transform: translate(0, -50%);
          // margin-left: auto;
          // margin-right: 0.4rem;
          font-size: 0.37rem;
          line-height: 0.37rem;
          span {
            font-family: PingFang-SC-Bold;
            // font-size: 0.25rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #ffffff;
          }
        }
      }
      .kecheng {
        background: url("../../../public/images/user/kecheng@2x.png") no-repeat;
        background-size: cover;
      }
      .tongyong {
        background: url("../../../public/images/user/tongyong@2x.png") no-repeat;
        background-size: cover;
      }
    }

    .van-cell:first-child {
      background-color: #ffffff;
      border-radius: 0.16rem;
      padding: 0.4rem;
      justify-content: space-between;
      font-size: 0.4rem;
    }
    .van-cell::after {
      border: 0;
    }
  }
  .coupon_btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    text-align: center;
    padding: 0.27rem;
    .van-button {
      width: 90%;
    }
  }
}
</style>
