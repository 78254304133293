<template>
  <div class="user-study clearfix">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="study-bg"></div>
      <div class="study-title">{{ !!realName ? realName + '的' : '' }}学习中心</div>
      <van-row class="study-top-box clearfix">
        <van-col class="box-item" span="8" @click="activeStatus = 1">
          <div class="box-num">{{ task.finishTask }}</div>
          <div class="box-text" :style="{ color: activeStatus === 1 ? '#000' : '#999' }">已完成任务</div>
          <div class="bottom-line" v-if="activeStatus === 1"></div>
        </van-col>
        <van-col class="box-item" span="8" @click="activeStatus = 2">
          <div class="box-num two">{{ task.unFinishTask }}</div>
          <div class="box-text" :style="{ color: activeStatus === 2 ? '#000' : '#999' }">未完成任务</div>
          <div class="bottom-line" v-if="activeStatus === 2"></div>
        </van-col>
        <van-col class="box-item" span="8" @click="activeStatus = 0">
          <div class="box-num">{{ task.totalTask }}</div>
          <div class="box-text" :style="{ color: activeStatus === 0 ? '#000' : '#999' }">全部任务</div>
          <div class="bottom-line" v-if="activeStatus === 0"></div>
        </van-col>
      </van-row>
    </van-pull-refresh>
    <van-row class="clearfix">
      <van-tabs class="study-tabs" line-width="0.28rem" title-active-color="#222" title-inactive-color="#999" color="#1578f2" v-model="active">
        <van-tab title="班级" class="p-lr">
          <div class="course-box" v-for="item in classList" :key="item.ID" @click="gotoPage(`/nzzone/classinfo?id=${item.ID}`)">
            <div class="course-top">
              <span :class="{ 'title-tag': item.enterpriseExclusive === 1, 'platform-tag': item.enterpriseExclusive !== 1 }">{{ item.enterpriseExclusive !== 1 ? '麓辰' : '企业' }}</span>
              <span class="title-name flex-1" :style="{ width: item.isExpedited === 1 ? '6.2' : '7.2rem' }">{{ item.name }}</span>
            </div>
            <div class="course-bottom">
              <span style="margin-right: 0.4rem;">{{ item.timeRange }}</span>
              <span>({{ item.finishedCourseCount }}/{{ item.totalCourseCount }})</span>
            </div>
            <div class="course-tips kaibo" v-if="item.isExpedited === 1">加急</div>
          </div>
        </van-tab>
        <van-tab title="课程">
          <van-tree-select :items="items" :main-active-index.sync="activeNav" @click-nav="clickNav" style="padding-top: 0.24rem">
            <template #content>
              <template v-if="coursesArr.length > 0">
                <van-row class="course-box" v-for="(item, _index) in coursesArr" :id="item.ID" :key="_index" @click="goto(item)">
                  <div class="course-top">
                    <span :class="{ 'title-tag': item.enterpriseExclusive === 1, 'platform-tag': item.enterpriseExclusive !== 1 }">{{ item.resourceTypeName }}</span>
                    <span class="title-name flex-1" v-if="item.isCompulsory === 'Y' && item.isExpedited === 1" style="width: 3.6rem">{{ item.name }}</span>
                    <span class="title-name flex-1" v-else-if="item.isCompulsory === 'Y'" style="width: 4.5rem">{{ item.name }}</span>
                    <span class="title-name flex-1" v-else-if="item.isExpedited === 1" style="width: 4.8rem">{{ item.name }}</span>
                    <span class="title-name flex-1" v-else style="width: 5rem">{{ item.name }}</span>
                    <span v-if="item.isCompulsory === 'Y'" class="title-type">必修</span>
                  </div>
                  <div class="course-bottom">{{ item.timeRange }}</div>
                  <div class="course-bottom">
                    <div style="display: flex; width: 100%; justify-content: space-between;">
                      <div class="bottom-l">{{ item.totalLength }}</div>
                      <div class="bottom-r" :style="{ width: item.hasPractice !== 0 ? '2rem' : '3.46rem' }">
                        <div class="progress" :style="{ width: parseInt(item.coursePercent * 100) + '%' }"></div>
                      </div>
                      <div class="bottom-rr">{{ parseInt(item.coursePercent * 100) }}%</div>
                      <template v-if="item.hasPractice !== 0">
                        <div style="width: 70px; color: #1578f2; text-align: center; border-radius: 0.15rem; border: 1px solid #1578f2; font-size: 0.32rem;" v-if="item.paperPercent">完成练习</div>
                        <div style="width: 70px; color: #f21515; text-align: center; border-radius: 0.15rem; border: 1px solid #f21515; font-size: 0.32rem;" v-else>练习未做</div>
                      </template>
                    </div>
                  </div>
                  <div class="course-tips kaibo" v-if="item.isExpedited === 1">加急</div>
                </van-row>
              </template>
              <div v-else class="not-data">暂无数据</div>
            </template>
          </van-tree-select>
        </van-tab>
        <van-tab class="p-lr">
          <template #title>
            <van-badge :dot="liveDot">
              <span>直播</span>
            </van-badge>
          </template>
          <div class="course-box" v-for="item in liveList" :key="item.ID" @click="gotoPage(`/allCourses/listDetails/${item.ID}`)">
            <div class="course-top">
              <span :class="{ 'title-tag': item.enterpriseExclusive === 1, 'platform-tag': item.enterpriseExclusive !== 1 }">{{ item.enterpriseExclusive !== 1 ? '直播' : '直播' }}</span>
              <span class="title-name flex-1" :style="{ width: item.isExpedited === 1 ? '6.2rem' : '7.2rem' }">{{ item.name }}</span>
            </div>
            <div class="course-bottom">
              <span style="margin-right: 0.4rem;">{{ item.timeRange }}</span>
              <span>({{ item.liveLength }}分钟)</span>
            </div>
            <div class="course-tips kaibo" v-if="item.isExpedited === 1">加急</div>
          </div>
        </van-tab>
        <van-tab class="p-lr">
          <template #title>
            <van-badge :dot="workDot">
              <span>作业</span>
            </van-badge>
          </template>
          <div class="course-box" v-for="(item, index) in workList" :key="index" @click="gotoPage(`/nzzone/workDetails/${item.ID}?time=${item.timeRange}`)">
            <div class="course-top">
              <span :class="{ 'title-tag': item.enterpriseExclusive === 1, 'platform-tag': item.enterpriseExclusive !== 1 }">{{ item.enterpriseExclusive !== 1 ? '作业' : '作业' }}</span>
              <span class="title-name flex-1" :style="{ width: item.isExpedited === 1 ? '6.2rem' : '7.2rem' }">{{ item.name }}</span>
            </div>
            <div class="course-bottom" style="justify-content: space-between">
              <span style="margin-right: 0.4rem;">{{ item.timeRange }}</span>
              <span v-if="item.status === 0" style="border-radius: 0.15rem; border: 1px solid #d1d1d1; padding: 0 0.2rem; color: #eee; font-size: 0.32rem; color: #999">{{ item.statusName }}</span>
              <span v-else-if="item.status === 1" style="border-radius: 0.15rem; border: 1px solid #1578f2; padding: 0 0.2rem; color: #eee; font-size: 0.32rem; color: #1578f2">{{
                item.statusName
              }}</span>
              <span v-else-if="item.status === 2" style="border-radius: 0.15rem; border: 1px solid #05a03b; padding: 0 0.1rem; color: #02ac3d; font-size: 0.32rem;">{{ item.statusName }}</span>
              <span v-else-if="item.status === 3" style="border-radius: 0.15rem; border: 1px solid #e25e54; padding: 0 0.2rem; color: #eee; font-size: 0.32rem; color: #e25e54">{{
                item.statusName
              }}</span>
            </div>
            <div class="course-tips kaibo" v-if="item.isExpedited === 1">加急</div>
          </div>
        </van-tab>
        <van-tab class="p-lr exam-module">
          <template #title>
            <van-badge :dot="examDot">
              <span>考试</span>
            </van-badge>
          </template>
          <div class="course-box" v-for="(item, index) in examList" :key="index" @click="goAnswer(item)">
            <div class="course-top">
              <span class="title-tag" :class="{ platform: item.enterpriseExclusive === 0 }">{{ item.isOralExam === 'Y' ? '口语' : '考试' }}</span>
              <span class="title-name">{{ item.name }} </span>
              <span class="tag--exam-type" :class="{ 'tag--exam-type--official': item.productType === 25 }">{{ item.productType === 25 ? '正式' : '模拟' }}</span>
            </div>
            <!-- <div class="course-top">
              <span :class="{ 'title-tag': item.enterpriseExclusive === 1, 'platform-tag': item.enterpriseExclusive !== 1 }">考试{{ item.productType === 25 ? '(正式)' : '(模拟)' }}</span>
              <span class="title-name">{{ item.name }}</span>
            </div> -->
            <div class="course-bottom">
              <span style="margin-right: 0.4rem;">{{ item.timeRange }}</span>
              <span>({{ item.time }}分钟)</span>
            </div>
            <div class="course-tips kaibo" v-if="item.isExpedited === 1">加急</div>
          </div>
        </van-tab>
      </van-tabs>
    </van-row>
  </div>
</template>

<script>
import { Dialog } from 'vant'

export default {
  name: 'studyIndex',
  data() {
    return {
      realName: '',
      isLoading: false,
      active: 0,
      activeNav: -1,
      activeStatus: 2,
      courseList: [],
      classList: [],
      liveList: [],
      workList: [],
      examList: [],
      task: {
        finishTask: 0,
        totalTask: 0,
        unFinishTask: 0,
        unFinishExam: 0,
        unFinishLive: 0,
        unFinishWork: 0,
      },
      items: [],
      catalogInnercode: -1,
      toAnswerExamID: 0,
    }
  },
  computed: {
    coursesArr() {
      let res = []
      if (!this.catalogInnercode || this.catalogInnercode === -1) {
        return this.courseList
      }
      for (let i = 0; i < this.courseList.length; i++) {
        let item = this.courseList[i]
        if (item.catalogInnerCodes.split(',').indexOf(this.catalogInnercode) !== -1) {
          res.push(item)
        }
      }
      return res
    },
    liveDot() {
      return this.task.unFinishLive > 0
    },
    workDot() {
      return this.task.unFinishWork > 0
    },
    examDot() {
      return this.task.unFinishExam > 0
    },
  },
  watch: {
    activeStatus() {
      let val = this.active
      this.catalogInnercode = -1
      this.activeNav = -1
      switch (val) {
        case 0:
          this.onLoadClass()
          return
        case 1:
          this.onLoadCourse()
          return
        case 2:
          this.onLoadLive()
          return
        case 3:
          this.onLoadWork()
          return
        case 4:
          this.onLoadExam()
          return
      }
    },
    $route(to) {
      this.setShare()
      if (to.path === '/study' || to.name === 'study') {
        this.active = this.$route.query.active && this.$route.query.active !== 0 ? parseInt(this.$route.query.active) : this.active

        switch (this.active) {
          case 0:
            this.onLoadClass()
            return
          case 1:
            this.onLoadCourse()
            return
          case 2:
            this.onLoadLive()
            return
          case 3:
            this.onLoadWork()
            return
          case 4:
            this.onLoadExam()
            return
        }
      }
    },
    active: {
      handler(val) {
        this.catalogInnercode = -1
        this.activeNav = -1
        switch (val) {
          case 0:
            this.onLoadClass()
            return
          case 1:
            this.onLoadCourse()
            return
          case 2:
            this.onLoadLive()
            return
          case 3:
            this.onLoadWork()
            return
          case 4:
            this.onLoadExam()
            return
        }
      },
      immediate: true,
    },
  },
  created() {
    let info = JSON.parse(localStorage.userInfo)
    if (info) {
      this.realName = info.realName
    }
    this.active = this.$route.query.active && this.$route.query.active !== 0 ? parseInt(this.$route.query.active) : this.active
    this.toAnswerExamID = this.$route.query.toAnswerExamID ? parseInt(this.$route.query.toAnswerExamID) : 0
    this.getTask()
    // this.onLoadCourse()
    this.setShare()
    this.isBack = this.$route.query.isBack
    if (this.isBack === 'Y') {
      this.activeStatus = this.$route.query.activeStatus || this.$route.query.activeStatus == 0 ? parseInt(this.$route.query.activeStatus) : 2
      // 禁用物理返回键（勿删）
      // history.pushState(null, null, document.URL)
      // window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    // pushHistory() {
    //   console.log('禁用按钮的监听')
    //   if (this.isBack === 'Y') {
    //     history.pushState(null, null, document.URL)
    //     // this.$Toast.fail('已经没有上一页咯！')
    //   }
    // },
    async onRefresh() {
      this.catalogInnercode = -1
      this.activeNav = -1
      await this.getTask()
      // await this.onLoadCourse()
      await this.onLoadClass()
      this.isLoading = false
    },
    clickNav(index) {
      if (!this.items[index]) {
        return
      }
      this.catalogInnercode = this.items[index].innerCode
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    async getTask() {
      await this.$axios
        .get('/course/front/member/learningcenter/tasks')
        .then((res) => {
          if (res.data.status === 1) {
            this.task.finishTask = res.data.data.finishTask
            this.task.totalTask = res.data.data.totalTask
            this.task.unFinishTask = res.data.data.unFinishTask
            this.task.unFinishExam = res.data.data.unFinishExam
            this.task.unFinishLive = res.data.data.unFinishLive
            this.task.unFinishWork = res.data.data.unFinishWork
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 课程数据
    async onLoadCourse() {
      this.$Toast.loading({
        message: '加载中...',
        duration: 0,
      })
      await this.$axios
        .get(`/course/front/member/learningcenter/course?type=${this.activeStatus}&catalogInnercode=`)
        .then((res) => {
          if (res.data.status === 1) {
            let arr = res.data.data.categories || []
            for (let i = 0; i < arr.length; i++) {
              arr[i].text = arr[i].name.substring(0, 8)
            }
            this.items = arr.concat()
            this.courseList = res.data.data.courses || []
            this.$nextTick(() => {
              this.$Toast.clear()
            })
          } else {
            this.$Toast.clear()
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.clear()
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
          return e
        })
    },
    // 班级数据
    onLoadClass() {
      this.$Toast.loading({
        message: '加载中...',
        duration: 0,
      })
      this.$axios
        .get(`/course/front/member/learningcenter/class`)
        .then((res) => {
          if (res.data.status === 1) {
            this.classList = res.data.data
            this.$nextTick(() => {
              this.$Toast.clear()
            })
          } else {
            this.$Toast.clear()
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.clear()
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
          return e
        })
    },
    // 直播数据
    onLoadLive() {
      this.$Toast.loading({
        message: '加载中...',
        duration: 0,
      })
      this.$axios
        .get(`/course/front/member/learningcenter/live?type=${this.activeStatus}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.liveList = res.data.data
            this.$nextTick(() => {
              this.$Toast.clear()
            })
          } else {
            this.$Toast.clear()
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.clear()
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
          return e
        })
    },
    // 作业数据
    onLoadWork() {
      this.$Toast.loading({
        message: '加载中...',
        duration: 0,
      })
      this.$axios
        .get(`/course/front/member/learningcenter/work?type=${this.activeStatus}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.workList = res.data.data
            this.$nextTick(() => {
              this.$Toast.clear()
            })
          } else {
            this.$Toast.clear()
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.clear()
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
          return e
        })
    },
    // 考试数据
    onLoadExam() {
      this.$Toast.loading({
        message: '加载中...',
        duration: 0,
      })
      this.$axios
        .get(`/course/front/member/learningcenter/exam?type=${this.activeStatus}`)
        .then((res) => {
          if (res.data.status === 1) {
            console.log(res.data.data)
            this.examList = res.data.data
            this.$nextTick(() => {
              this.$Toast.clear()
            })
            // if (this.toAnswerExamID > 0 && this.examList.length > 0) {
            //   // 如果接口重定向进来的,直接去指定的考试答题
            //   let list = this.examList.filter(item => { return this.toAnswerExamID === item.ID })
            //   if (list && list.length > 0) {
            //     this.toAnswerExamID = 0
            //     this.goAnswer(list[0], true)
            //   }
            // }
          } else {
            this.$Toast.clear()
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.clear()
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
          return e
        })
    },
    goto(item) {
      // 从学习页进入
      let path = `/allCourses/details/${item.ID}/curriculum`
      if (item.type === 1 || item.type === '1') {
        path = `/allCourses/courseHandout/${item.ID}`
      } else {
        // 防止来回切换tab和页面多次重定向导致选中的tab页显示不正确
        let temp = this.$router.history.current.fullPath
        if (temp.indexOf('activeStatus=') < 0) {
          if (temp.indexOf('?') >= 0) {
            temp = temp + '&activeStatus=' + this.activeStatus
          } else {
            temp = temp + '?activeStatus=' + this.activeStatus
          }
        } else {
          if (temp.indexOf('activeStatus=0') >= 0) {
            temp = temp.replace('activeStatus=0', 'activeStatus=' + this.activeStatus)
          } else if (temp.indexOf('activeStatus=1') >= 0) {
            temp = temp.replace('activeStatus=1', 'activeStatus=' + this.activeStatus)
          } else if (temp.indexOf('activeStatus=2') >= 0) {
            temp = temp.replace('activeStatus=2', 'activeStatus=' + this.activeStatus)
          }
        }
        if (temp.indexOf('active=') < 0) {
          temp = temp + '&active=1'
        } else {
          if (temp.indexOf('active=0') >= 0) {
            temp = temp.replace('active=0', 'active=1')
          } else if (temp.indexOf('active=2') >= 0) {
            temp = temp.replace('active=2', 'active=1')
          } else if (temp.indexOf('active=3') >= 0) {
            temp = temp.replace('active=3', 'active=1')
          } else if (temp.indexOf('active=4') >= 0) {
            temp = temp.replace('active=4', 'active=1')
          }
        }
        path = path + '?backUrl=' + encodeURIComponent(temp) + '&fromStudyCenter=1'
      }
      // let temp = this.$router.history.current.fullPath + '?activeStatus=' + this.activeStatus
      // localStorage.back_url = temp
      this.gotoPage(path)
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    goAnswerRequest(item) {
      this.$axios
        .get(`/tk/front/paper/${item.productID}/checkandgeneratekey?examID=${item.ID}`)
        .then((res) => {
          if (res.data.status === 1) {
            // 前往答题页面
            this.$router.push({
              path: `/allCourses/examinationpaper?id=${item.ID}&isPhoto=${item.isPhoto}&key_success=${res.data.data.key_success}`,
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    goAnswer(item) {
      if (new Date().getTime() - new Date(item.beginTime).getTime() < 0) {
        return this.$Toast.fail('考试未开始！')
      }
      if (new Date().getTime() - new Date(item.endTime).getTime() > 0) {
        return this.$Toast.fail('考试已过期！')
      }
      if (item.status === 'Y') {
        // 前往考试完成页面
        this.$router.push({
          path: `/study/testresults/${item.productID}/${item.ID}`,
        })
        return
      }
      if (item.isIdCardAuth === 1 && item.isFaceAuth === 0) {
        let info = JSON.parse(localStorage.userInfo)
        if (info.isChecked !== 1) {
          Dialog.confirm({
            title: '身份认证提示',
            message: '未进行身份认证，请先去身份认证',
          })
            .then(() => {
              this.$router.push({ path: `/user/userinfo/info` })
            })
            .catch(() => {
              return
            })
        } else {
          this.goAnswerRequest(item)
        }
      } else if (item.isIdCardAuth === 1 && item.isFaceAuth === 1) {
        this.faceAuthRequest(item)
      } else {
        this.goAnswerRequest(item)
      }
    },
    // 人脸认证
    async faceAuthRequest(item) {
      let info = JSON.parse(localStorage.userInfo)
      if (info.isChecked !== 1) {
        Dialog.confirm({
          title: '身份认证提示',
          message: '未进行身份认证，请先去身份认证',
        })
          .then(() => {
            this.$router.push({ path: `/user/userinfo/info` })
          })
          .catch(() => {
            return
          })
      } else {
        this.$axios
          .get(`/course/front/member/face/auth/issuccess?type=Exam&id=${item.ID}`)
          .then((res) => {
            if (res.data.status === 1) {
              if (res.data.data.isSuccess !== 1) {
                Dialog.confirm({
                  title: '温馨提示',
                  message: '即将进入人脸认证，是否继续？',
                })
                  .then(() => {
                    this.getFaceAuthUrl(item, false)
                  })
                  .catch(() => {
                    return
                  })
              } else {
                this.goAnswerRequest(item)
              }
            } else {
              this.$Toast.fail(res.data.message)
              return
            }
          })
          .catch(() => {
            this.$Toast.fail('服务器开小差了，稍后重试')
            return
          })
      }
    },
    getFaceAuthUrl(item) {
      // 获取用户结果
      this.$axios
        .get(`/course/front/member/face/auth?type=Exam&id=${item.ID}&activeStatus=${this.activeStatus}`)
        .then((res) => {
          if (res.data.status === 1) {
            if (!res.data.data.bizToken && res.data.data.isSuccess === 1) {
              this.goAnswerRequest(item)
            } else {
              window.location.href = res.data.data.url
            }
          } else {
            this.$Toast.fail(res.data.message)
            return
          }
        })
        .catch(() => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return
        })
    },
  },
  destroyed() {
    // window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>
<style scoped>
.flex-1 {
  flex: 1;
}
.course-box.van-row:first-child {
  margin-top: 0 !important;
}
.user-study >>> .van-pull-refresh {
  height: 4.8rem;
}
.user-study >>> .van-tree-select {
  height: 100% !important;
  font-size: 0.37rem;
}
.user-study >>> .van-sidebar {
  width: 2.13rem;
  background-color: #f8f8f8;
}
.user-study >>> .van-sidebar {
  -webkit-box-flex: unset;
  -webkit-flex: unset;
  flex: unset;
  background-color: unset;
  height: calc(100vh - 6.93rem);
}
.user-study >>> .van-tree-select__nav-item {
  padding: 0.26rem;
  font-size: 0.34rem;
  line-height: 0.58rem;
}
.user-study >>> .van-sidebar-item--select {
  font-weight: bold;
}
.user-study >>> .van-sidebar-item--select::before {
  background-color: #3fa1fd;
  border-radius: 0rem 0.09rem 0.09rem 0rem;
  width: 0.11rem;
  height: 0.42rem;
}
.user-study >>> .van-tree-select__content {
  margin-left: 0.28rem;
  margin-right: 0.25rem;
  background-color: unset;
  height: calc(100vh - 6.93rem);
}
.user-study >>> .van-tabs--line .van-tabs__wrap {
  height: 1.17rem;
}
.user-study >>> .van-tabs__nav--line {
  padding-bottom: 0.4rem;
}
.user-study >>> .van-tab {
  font-size: 0.37rem;
  line-height: 0.53rem;
}
.user-study >>> .van-tabs__line {
  bottom: 0.4rem;
  height: 0.08rem;
  border-radius: 0.08rem;
}
.user-study >>> .van-tab__text.van-tab__text--ellipsis {
  overflow: unset;
}
.user-study >>> .van-badge--fixed {
  right: -0.13rem;
  width: 0.21rem;
  height: 0.21rem;
}
.p-lr {
  padding: 0px 0.35rem;
}
</style>
<style lang="less" scoped>
.user-study {
  background-color: #f8f8f8;
  // padding-bottom: 1.3rem;
  // height: 17.33rem;
  // overflow: hidden;
  margin-bottom: 1.3rem;
  min-height: 100vh;
  overflow-y: auto;
  .study-bg {
    height: 4.4rem;
    background-color: #3377ff;
    border-radius: 0 0 30% 30%;
  }
  .study-title {
    color: #fff;
    font-family: PingFang-SC-Medium;
    font-size: 0.45rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    position: absolute;
    top: 0.8rem;
    //left: 50%;
    //transform: translate(-50%, 0);
    text-align: center;
    width: 100%;
  }
  .study-top-box {
    width: 90%;
    position: absolute;
    top: 2.4rem;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #ffffff;
    box-shadow: 0rem 0.03rem 0.11rem 0rem #ececec;
    border-radius: 0.27rem;
    font-size: 0.29rem;
    text-align: center;
    .box-item {
      padding: 0.43rem 0;
      position: relative;
      .box-num {
        font-family: PingFang-SC-Bold;
        font-size: 0.64rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #3377ff;
      }
      .two {
        color: #ef2824;
      }
      .box-text {
        padding-top: 0.35rem;
        color: #999;
      }
      .bottom-line {
        height: 0.08rem;
        border-radius: 0.08rem;
        position: absolute;
        width: 1.51rem;
        background-color: #1578f2;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
  .study-tabs {
    /deep/ .van-tabs__nav {
      background-color: rgba(0, 0, 0, 0);
    }
    .course-box {
      padding: 0.33rem;
      margin-top: 0.33rem;
      background-color: #fff;
      border-radius: 0.13rem;
      position: relative;
      overflow: hidden;
      .course-top {
        display: flex;
        align-items: flex-start;
        width: calc(100% - 0.5rem);
        box-sizing: border-box;
        .title-tag {
          display: inline-block;
          height: 0.53rem;
          line-height: 0.53rem;
          border-radius: 0.08rem;
          background-color: #70dd96;
          font-family: PingFang-SC-Medium;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #fff;
          text-align: center;
          padding: 0 0.15rem;
          white-space: nowrap;
        }
        .platform {
          background-color: #fbc047;
        }
        .platform-tag {
          height: 0.53rem;
          line-height: 0.53rem;
          border-radius: 0.08rem;
          background-color: #fbc047;
          font-family: PingFang-SC-Medium;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #fff;
          text-align: center;
          padding: 0 0.15rem;
        }
        .title-name {
          flex: 1 !important;
          font-family: PingFang-SC-Heavy;
          font-size: 0.43rem;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #111111;
          margin: 0 0.2rem;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .title-type {
          height: 0.53rem;
          line-height: 0.53rem;
          background-color: #ff9250;
          border-radius: 0.05rem;
          font-family: PingFang-SC-Medium;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #ffffff;
          padding: 0 0.05rem;
        }
      }
      .tag--exam-type {
        display: inline-block;
        height: 0.53rem;
        line-height: 0.53rem;
        padding: 0 0.08rem;
        margin-left: 0.1333rem;
        background-color: #ff9250;
        border-radius: 0.0533rem;
        letter-spacing: 0.0267rem;
        font-size: 0.32rem;
        font-weight: 500;
        color: #fff;
        white-space: nowrap;
      }
      .tag--exam-type--official {
        background-color: #fe714b;
      }
      .course-bottom {
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #999999;
        margin-top: 0.32rem;
        display: flex;
        .bottom-l {
          width: 2rem;
          height: 0.48rem;
          background-color: #f6f7f9;
          border-radius: 0.05rem;
          font-family: PingFang-SC-Regular;
          font-size: 0.27rem;
          line-height: 0.48rem;
          color: #b1b1b1;
          text-align: center;
          margin-right: 0.1rem;
        }
        .bottom-r {
          width: 2rem;
          height: 0.27rem;
          background-color: #eeeeee;
          border-radius: 0.13rem;
          margin-top: 0.1rem;
          margin-right: 0.1rem;
          .progress {
            height: 0.27rem;
            background-color: #d5af82;
            border-radius: 0.1rem;
          }
        }
        .bottom-rr {
          font-family: PingFang-SC-Regular;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #999999;
          margin-right: 0.1rem;
        }
      }
      .course-tips {
        width: 4rem;
        height: 0.71rem;
        font-family: PingFang-SC-Regular;
        font-size: 0.32rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #ffffff;
        transform: rotate(40deg);
        position: absolute;
        background-image: linear-gradient(-90deg, #999 0%, #fff 100%);
        right: -1.39rem;
        top: 0.16rem;
        text-align: center;
        line-height: 0.79rem;
      }
      .kaibo {
        background-image: linear-gradient(-90deg, #ca2d2f 0%, #d8514a 100%);
      }
    }
  }
  .exam-module {
    .course-top {
      padding-right: 1.6rem;
    }
  }
}
</style>
