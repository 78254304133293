<template>
  <div class="payment">
    <zv-nav-bar title="未支付"></zv-nav-bar>
    <van-list v-model="loading" :finished="finished" @load="onLoad">
      <div class="item" v-for="(item, index) in list" :key="index" @click="gotoPage(item)">
        <div class="logo">
          <img :src="item.logoFile" />
        </div>
        <div class="other">
          <div class="orderNo">订单号：{{ item.orderNO }}</div>
          <div class="title">
            <span class="goods-name">{{ item.goodsName }}</span>
            <span class="old-price" v-if="isVip">￥{{ item.sellPrice }}</span>
          </div>
          <div class="detail">
            <div class="time">{{ item.orderTime }}</div>
            <div class="price">
              ￥<span style="font-size: 0.42rem;">{{ isVip ? item.orderAmount : item.sellPrice }}</span>
            </div>
          </div>
          <div class="btn">
            <div class="allCourse" v-if="item.attribute === 'CoursePackage'">共{{ item.courseNumber }}节课</div>
            <div class="allCourse" v-else style="width: 1.33rem;"></div>
            <div>
              <button class="cancelBtn" @click.stop="cancelOrder(item, index)">取消订单</button>
              <button class="payBtn" @click.stop="gotopay(item)" v-if="item.goodsStatus === '2'">
                <div style="display: flex; justify-content: space-around;">
                  <span>支</span>
                  <span>付</span>
                </div>
              </button>
            </div>
          </div>
          <div class="order-tips" v-if="item.goodsStatus !== '2'">已下架</div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
const wx = require('weixin-js-sdk')
export default {
  name: 'Payment',
  data() {
    return {
      loading: false,
      finished: false,
      pageIndex: 0,
      list: [],
      isVip: true,
      isBack: '',
      sourceType: '',
    }
  },
  created() {
    this.setShare()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = info.isVip && (info.enterpriseName || info.enterpriseShorthand)
    }
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      this.sourceType = 'notPayment'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    onLoad() {
      this.$axios
        .get(`/course/front/member/order/list?status=01&pageSize=10&pageIndex=${this.pageIndex}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.pageIndex = this.pageIndex + 1
            this.list = this.list.concat(res.data.data.data)
            this.loading = false
            if (this.list.length >= res.data.data.total) {
              this.finished = true
            }
          } else {
            this.$Toast.fail(res.data.message)
            this.loading = false
            this.finished = true
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    gotopay(item) {
      const that = this
      this.$axios.get(`/course/front/goodsbuy/payorder/${item.orderID}?paymentType=WechatPay&tradeType=JSAPI`).then((res) => {
        if (res.data.status === 1) {
          let data = res.data.data.paymentURL
          if (data.needPayStatus === 0) {
            that.$Toast.success('支付成功')
            setTimeout(() => {
              // 前往已支付列表页
              that.$router.replace({
                path: '/user/payment',
              })
            }, 2000)
          } else {
            wx.config({
              debug: false,
              appId: data.appId,
              timestamp: data.timeStamp,
              nonceStr: data.nonceStr,
              signature: data.paySign,
              jsApiList: ['chooseWXPay'],
            })
            wx.chooseWXPay({
              timestamp: data.timeStamp,
              nonceStr: data.nonceStr,
              package: data.package,
              signType: 'MD5',
              paySign: data.paySign,
              success: function(res) {
                if (res.errMsg === 'chooseWXPay:ok') {
                  that.$Toast.success('支付成功')
                  setTimeout(() => {
                    // 前往已支付列表页
                    that.$router.replace({
                      path: '/user/payment',
                    })
                  }, 2000)
                } else {
                  that.$Toast.fail(res.errMsg)
                }
              },
              cancel: function() {
                that.$Toast.fail('取消支付')
              },
            })
          }
        }
      })
    },
    gotoPage(item) {
      if (item.attribute === 'Course') {
        let path = `/allCourses/details/${item.ID}/curriculum`
        if (item.type !== 0) {
          path = `/allCourses/courseHandout/${item.ID}`
        } else {
          path = path + '?backUrl=' + this.$router.history.current.fullPath
        }
        // let temp = this.$router.history.current.fullPath
        // localStorage.back_url = temp
        this.$router.push({
          path: path,
        })
      }
    },
    cancelOrder(item, index) {
      this.$axios
        .post(`/course/front/member/order/cancel?ids=${item.orderID}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.list.splice(index, 1)
            this.$Toast.success(res.data.message)
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>
<style scoped>
.payment .item:first-child {
  padding-top: 0.65rem;
}
.item {
  padding: 0 0 0.65rem 0.52rem;
  display: flex;
}
.logo {
  width: 1.2rem;
  height: 1.2rem;
  background-color: #e7f2ff;
  border-radius: 50%;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.title {
  font-size: 0.4rem;
  color: #222222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.45rem;
}
.goods-name {
  width: 6.56rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.time {
  font-size: 0.35rem;
  color: #b1b1b1;
}
.old-price {
  text-decoration: line-through;
  color: #b1b1b1;
  font-size: 0.21rem;
}
.price {
  font-size: 0.32rem;
  font-weight: bold;
  color: #222;
}
.other {
  margin-left: 0.24rem;
  width: 8rem;
  border-bottom: 1px solid #eee;
  position: relative;
  overflow: hidden;
}
.detail {
  display: flex;
  justify-content: space-between;
  padding: 0.31rem 0.45rem 0.37rem 0;
}
.orderNo {
  font-size: 0.4rem;
  color: #222222;
}
.btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 0.39rem;
}
.allCourse {
  font-size: 0.29rem;
  font-weight: bold;
  color: #b1b1b1;
}
.cancelBtn {
  width: 2.13rem;
  height: 0.64rem;
  border-radius: 0.13rem;
  border: solid 0.03rem #222222;
  color: #222;
  font-size: 0.32rem;
  margin-right: 0.45rem;
}
.payBtn {
  width: 2.13rem;
  height: 0.64rem;
  border-radius: 0.13rem;
  border: solid 0.03rem #fa0303;
  color: #fb071e;
  font-size: 0.32rem;
}
.order-tips {
  width: 4rem;
  height: 0.71rem;
  font-family: PingFang-SC-Regular;
  font-size: 0.32rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0rem;
  color: #ffffff;
  transform: rotate(40deg);
  position: absolute;
  background-image: linear-gradient(-90deg, #ca2d2f 0%, #d8514a 100%);
  right: -1.38rem;
  top: 0.21rem;
  text-align: center;
  line-height: 0.71rem;
}
button {
  background-color: unset;
}
</style>
