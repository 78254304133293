<template>
  <div class="nzzone-classcourses">
    <zv-nav-bar :title="pagename" :clickLeft="clickLeft"></zv-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-col span="24" v-for="item in courseList" :id="item.ID" :key="item.ID">
          <van-row class="course-box" @click="gotoPage(`/allCourses/listDetails/${item.ID}`)">
            <div class="course-top">
              <span :class="{'title-tag': true, 'enterprise-tag': item.enterpriseExclusive === 1}">{{item.resourceTypeName}}</span>
              <span class="title-name">{{item.name}}</span>
            </div>
            <div class="course-bottom" v-if="item.timeRange">{{item.timeRange}}</div>
            <div class="course-bottom" v-else>暂无时间</div>
            <div class="course-tips " :class="resolvetype(item.studyStatus)">{{item.studyStatusName}}</div>
          </van-row>
        </van-col>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  name: "liveList",
  data() {
    return {
      pagename: "班级全部直播",
      active: 0,
      refreshing: false,
      loading: false,
      finished: false,
      courseList: [],
      pageIndex: 0
    };
  },
  created() {
    this.setShare()
  },
  methods: {
    clickLeft() {
      this.$router.replace({
        path: `/nzzone/classinfo?id=${this.$route.query.id}`
      })
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    resolvetype(studyStatus) {
      return studyStatus == 0
        ? ""
        : studyStatus == 2
        ? "dianping"
        : studyStatus == 1
        ? "kaibo"
        : "lianxi";
    },
    onRefresh() {
      this.pageIndex = 0
      this.courseList = []
      this.$nextTick(() => {
        this.finished = false
        this.loading = true
        this.onLoad()
      })
    },
    onLoad() {
      let id = this.$route.query.id
      this.$axios.get(`/course/front/class/${id}/live?pageIndex=${this.pageIndex}&pageSize=10`).then(res => {
        if (res.data.status === 1) {
          this.pageIndex = this.pageIndex + 1
          this.courseList = this.courseList.concat(res.data.data.data)
          this.loading = false
          if (this.courseList.length >= res.data.data.total) {
            this.finished = true
          }
          this.refreshing = false
        } else {
          this.$Toast.fail(res.data.message)
        }
      }).catch(e => {
        this.$Toast.fail('服务器开小差了，稍后重试')
        this.loading = false
        this.finished = true
        this.refreshing = false
        return e
      })
    },
    gotoPage(path) {
      this.$router.push({path: path})
    }
  },
};
</script>
<style lang="less" scoped>
.nzzone-classcourses {
  background-color: #f8f8f8;
  min-height: 100vh;
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 0.88rem;
  }
  /deep/ .van-tab {
    font-size: 0.37rem;
  }
  .course-box {
    padding: 0.33rem;
    margin: 0.33rem 0.33rem 0 0.33rem;
    background-color: #fff;
    border-radius: 0.13rem;
    position: relative;
    overflow: hidden;
    .course-top {
      display: flex;
      align-items: center;
      .title-tag {
        height: 0.53rem;
        line-height: 0.53rem;
        border-radius: 0.08rem;
        border: solid 0.01rem #fbc047;
        background-color: #fbc047;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #fff;
        text-align: center;
        padding: 0 0.05rem;
      }
      .enterprise-tag {
        height: 0.53rem;
        line-height: 0.53rem;
        border-radius: 0.08rem;
        border: solid 0.01rem #70dd96;
        background-color: #70dd96;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #fff;
        text-align: center;
        padding: 0 0.05rem;
      }
      .title-name {
        font-family: PingFang-SC-Heavy;
        font-size: 0.43rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #111111;
        margin: 0 0.2rem;
        max-width: 5.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title-type {
        height: 0.4rem;
        line-height: 0.4rem;
        background-color: #ff9250;
        border-radius: 0.05rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #ffffff;
        padding: 0 0.05rem;
      }
    }
    .course-bottom {
      font-family: PingFang-SC-Medium;
      font-size: 0.32rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #999999;
      margin-top: 0.32rem;
    }
    .course-tips {
      width: 4rem;
      height: 0.71rem;
      font-family: PingFang-SC-Regular;
      font-size: 0.32rem;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #ffffff;
      transform: rotate(40deg);
      position: absolute;
      background-image: linear-gradient(-90deg, #999 0%, #fff 100%);
      right: -1.39rem;
      top: 0.16rem;
      text-align: center;
      line-height: 0.79rem;
    }

    .dianping {
      background-image: linear-gradient(-90deg, #cca779 0%, #e2be91 100%);
    }
    .kaibo {
      background-image: linear-gradient(-90deg, #ca2d2f 0%, #d8514a 100%);
    }
    .lianxi {
      background-image: linear-gradient(-90deg, #cba778 0%, #e2be91 100%);
    }
  }
}
</style>
