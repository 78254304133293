<template>
  <div class="box van-circle">
    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="pr1" :style="{transform: `rotate(${-180 + parseInt(rate < 50 ? rate * 2 * 1.8 : 180)}deg)`}"></div>
    <div class="pr2" :style="{transform: `rotate(-${360 - (rate - 50) * 2 * 1.8 }deg)`, width: rate > 50 ? '1.33rem' : '0'}"></div>
    <div class="content">
      <slot></slot>
    </div>
	</div>
</template>
<script>
export default {
  name: 'zCircle',
  props: {
    currentRate: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    rate() {
      return parseInt(this.currentRate * 100)
    }
  }
}
</script>
<style scoped>
.box{
  position: relative;
}
.content {
  top: 0.26rem;
  left: 0.26rem;
  width: 2.13rem;
  height: 2.13rem;
  border-radius: 50%;
  position: absolute;
  background:#fff;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.bg1{
  position: absolute;
  width: 1.33rem;
  height: 2.66rem;
  border-radius: 2.66rem 0 0 2.66rem;
  z-index: 3;
  background: #51D6D1;
}
.bg2{
  left: 1.33rem;
  position: absolute;
  width: 1.33rem;
  height: 2.66rem;
  border-radius: 0px 2.66rem 2.66rem 0;
  z-index: 1;
  background: #51D6D1;
}
.pr1 {
  position: absolute;
  left: 1.33rem;
  width: 1.33rem;
  height: 2.66rem;
  border-radius: 0px 2.66rem 2.66rem 0px;
  z-index: 2;
  background: #1989fa;
  transform: rotate(-180deg);
  transform-origin: 0px 1.33rem;
}
.pr2 {
  width: 1.33rem;
  height: 2.66rem;
  position: absolute;
  left: 1.33rem;
  border-radius: 0px 2.66rem 2.66rem 0px;
  z-index: 4;
  background: #1989fa;
  transform: rotate(-180deg);
  transform-origin: 0px 1.33rem;
}
</style>