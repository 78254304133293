<template>
  <div class="curriculum-details curriculum-pack" :style="{ 'margin-bottom': marginBottom }">
    <zv-nav-bar :title="itemData.name" :sourceType="sourceType">
      <div class="poster-sharer" @click="posterSharer">
        <span style="font-size: 0.32rem; color: #1989fa;">海报分享</span>
        <span class="share fa fa-share-square-o"></span>
      </div>
    </zv-nav-bar>
    <van-row>
      <van-col span="24" class="zv_insert">
        <div class="video-js">
          <div v-html="videoHtml"></div>
          <img class="logo" :src="itemData.logoFile" v-show="!videoHtml" />
          <button class="vjs-big-play-button" @click="playBtnClick" v-if="isShowPlayBtn">
            <span class="vjs-icon-placeholder"></span>
          </button>
        </div>
        <div class="details">
          <div class="details-item" :style="{ 'justify-content': hasEnterprise ? 'flex-start' : 'space-between' }">
            <span class="tag">系列课</span>
            <div class="title" :style="{ width: hasEnterprise ? '7.68rem' : '5.18rem' }">{{ itemData.name }}</div>
            <span v-if="!hasEnterprise">
              <span class="contain">包含{{ courseListData.length }}门课程</span>
            </span>
          </div>
          <div v-if="hasEnterprise" class="has-enterprise">
            <span style="font-size: 0.32rem; color: #777;">企业已为你支付{{ Number(itemData.sellPrice - itemData.memberPrice).toFixed(2) }}元</span>
            <span class="contain">包含{{ courseListData.length }}门课程</span>
          </div>
        </div>
        <van-tabs v-model="activeName">
          <van-tab title="系列课程介绍" name="1">
            <div>【课程介绍】</div>
            <div class="desc" v-if="itemData.description" v-html="itemData.description"></div>
            <div class="desc" v-else>暂无课程介绍</div>
          </van-tab>
          <van-tab title="系列课程列表" name="2">
            <div class="course-list-box">
              <div class="course-list-content" v-for="(item, index) in courseListData" :key="item.ID" @click="goto(item)">
                <div class="teacher-logo">
                  <img :src="item.logoFile" />
                </div>
                <div class="course-item">
                  <div style="display: flex; flex-direction: column;">
                    <span style="font-size: 0.32rem; color: #777; width: 5.06rem; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ item.categories }}</span>
                    <span style="font-size: 0.4rem; color: #111; width: 5.06rem; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ item.name }}</span>
                  </div>
                  <span style="font-size: 0.4rem; color: #111;">{{
                    item.isFree === 1 ? '免费' : Number(item.isForbid) ? '会员专享' : '￥' + (hasEnterprise ? item.memberPrice : item.sellPrice)
                  }}</span>
                  <span class="van-icon van-icon-bar-chart-o" v-if="!isShowPlayBtn && index === 0" style="color: #1989fa"></span>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
        <div class="buynow" v-if="!isPay">
          <div class="buy-btn-group" v-if="!isMember">
            <van-button class="buy-btn--highlighted" round @click="getMemberPage('buy')">企业会员价&nbsp;{{ formatAmountToFixed2(itemData.memberPrice) }}元</van-button>
            <van-button class="buy-btn" round @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}&type=CoursePackage`)"
              >立即购买&nbsp;{{ formatAmountToFixed2(itemData.sellPrice) }}元</van-button
            >
          </div>
          <div class="buy-btn-group" v-else>
            <van-button class="buy-btn" style="background: #4087ef" round @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}&type=CoursePackage`)"
              >个人自付{{ itemData.memberPrice }}元 立即学习</van-button
            >
          </div>
          <!-- <van-button round block type="info" @click="gotoPage(`/allCourses/order/${itemData.ID}?type=CoursePackage&couponId=${$route.query.couponId || ''}`)">{{
            hasEnterprise ? `个人自付${itemData.memberPrice}元 立即学习` : `支付${itemData.sellPrice}元 立即学习`
          }}</van-button> -->
        </div>
      </van-col>
    </van-row>
    <van-dialog v-model="shareDialog" title="长按图片分享" confirmButtonText="关闭" @confirm="shareDialog = false" width="100%">
      <img :src="photoSrc + '?' + new Date().getTime()" style="max-width: 100%; height: auto" />
    </van-dialog>
  </div>
</template>
<script>
import 'video.js/dist/video-js.css'
export default {
  name: 'curriculumDetails',
  computed: {
    marginBottom() {
      if (!this.isPay) {
        return '1.61rem'
      }
      return 0
    },
    isShowPlayBtn() {
      return !this.isPlay
    },
  },
  watch: {
    isPay(val) {
      if (val) {
        this.activeName = '2'
      }
    },
  },
  data() {
    return {
      itemData: {},
      shareDialog: false,
      photoSrc: '',
      id: '',
      activeName: '1',
      isPay: false,
      hasEnterprise: false,
      courseListData: [],
      videoHtml: '',
      isPlay: false,
      lastWatchVideoID: '',
      timer: null,
      timer2: null,
      uploadMap: {},
      myPlayer: null,
      isBack: '',
      sourceType: '',
      isFastForward: false, // 是否允许拖进度条
      isSpeedPlayback: false, // 是否允许倍速播放
      speed: 1,
      isMember: false,
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getCourseData()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      if (Number(info.isVip) && (info.enterpriseName || info.enterpriseShorthand)) {
        this.hasEnterprise = true
      }
    }
    // 禁用物理返回键（勿删）
    this.isBack = this.$route.query.isBack
    if (this.isBack === 'Y') {
      // alert("人脸核身课程返回的")
      let temp = localStorage.course_pack_source
      if (temp && temp.indexOf('isBack=') <= 0) {
        if (temp.indexOf('?') <= 0) {
          temp = temp + '?isBack=Y'
        } else {
          temp = temp + '&isBack=Y'
        }
        localStorage.course_pack_source = temp
      }
      this.sourceType = 'coursePackDetails'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  async mounted() {
    await this.loadJS(location.protocol + '//' + location.host + '/js/aliplayer-min.js')
    await this.loadJS(location.protocol + '//' + location.host + '/js/CommentCoreLibrary.js')
    await this.loadJS(location.protocol + '//' + location.host + '/js/aliplayercomponents-1.0.6.min.js')
    await this.loadJS(location.protocol + '//' + location.host + '/js/aliplayer-hls-min.js')
    this.isMember = this.$eventHub.userInfo.isVip
  },
  methods: {
    getMemberPage(type) {
      // 跳转企业会员相关文章页
      this.$axios({
        url: '/json/ktqyhy/index.json',
        methods: 'GET',
        params: {},
      }).then(({ data }) => {
        if (Number(data.status) !== 1) return
        if (type === 'buy') this.handleGotoMemberOrder(data.data[0].artUrl)
        else if (type === 'permission') this.handleGotoMemberOrder(data.data[1].artUrl)
      })
    },
    handleGotoMemberOrder(url) {
      const artUrl = url.substring(url.search(/json/))
      this.$router.push({
        name: 'memberInfo',
        params: {
          url: artUrl,
        },
      })
    },
    formatAmountToFixed2(amount) {
      return Number(amount).toFixed(2)
    },
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack && this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        // this.$Toast.fail('请点击左上角的返回键进行返回')
        let temp = localStorage.course_pack_source
        if (temp) {
          this.$router.push({ path: temp })
        } else {
          if (window.history.length >= 2 && window.location.href.indexOf('isLoged') === -1) {
            if (window.location.href.indexOf('isShare') === -1) {
              this.$router.go(-1)
            } else {
              this.$router.push({
                path: '/',
              })
            }
          } else {
            this.$router.push({
              path: '/',
            })
          }
        }
      }
    },
    async loadJS(url, data) {
      return new Promise(function(resolve, reject) {
        let s = document.createElement('script')
        s.setAttribute('src', url)
        if (data) {
          s.setAttribute('data', data)
        }
        let arr = document.head.children
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].localName === 'script') {
            if (arr[i].src === url) {
              document.head.removeChild(arr[i])
            }
          }
        }
        document.head.appendChild(s)
        s.onload = resolve
        s.onerror = reject
      })
    },
    goto(item) {
      let path = `/allCourses/details/${item.ID}/${'curriculum'}`
      if (item.type === 1) {
        path = `/allCourses/courseHandout/${item.ID}`
      } else {
        path = path + '?backUrl=' + encodeURIComponent(this.$router.history.current.fullPath)
      }
      // let temp = this.$router.history.current.fullPath
      // localStorage.back_url = temp
      this.gotoPage(path)
    },
    posterSharer() {
      if (this.photoSrc) {
        return (this.shareDialog = true)
      }
      this.$Toast.loading({
        message: '正在生成海报中...',
        forbidClick: true,
        duration: 0,
      })
      this.$axios
        .get(`/course/front/permit/poster/${this.$route.params.id}?type=CoursePackage`)
        .then((res) => {
          this.$Toast.clear()
          if (res.data.status === 1) {
            this.shareDialog = true
            this.photoSrc = res.data.data.url
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    // 自定义播放按钮点击触发
    async playBtnClick() {
      if (this.courseListData.length === 0) {
        return this.$Toast.fail('第一个课程没有视频可以播放')
      }
      let itemData = this.courseListData[0]
      if (itemData.isFree !== 1) {
        return this.$Toast.fail(`第一个课程：'${itemData.name}'，非免费课程，不能播放`)
      }
      let html = '<div id="player-con"><div class="center"></div></div>'
      this.videoHtml = html
      this.$nextTick(() => {
        this.activeName = '2'
        this.lastWatchVideoID = itemData.firstVideoInfo.ID
        this.getVideo(itemData.firstVideoInfo, true)
        this.isPlay = true
      })
    },
    getVideo(videoInfo, autoPlay) {
      let flag = false
      if (!this.videoHtml) {
        let html = '<div id="player-con"><div class="center"></div></div>'
        this.videoHtml = html
      }
      const that = this
      const assertID = videoInfo.assertID
      const lastPosition = videoInfo.lastPosition
      if (videoInfo.length <= 0) {
        return this.$Toast.fail('视频转码中。。。')
      }
      // 请求接口获取播放地址参数为courseID-课程ID，assertID-媒资ID
      this.$axios.get(`/course/front/permit/course/assertplayurl?courseID=${this.courseListData[0].ID}&assertID=${assertID}`).then((res) => {
        if (res.data.status === 1) {
          let components = []
          if (this.isSpeedPlayback) {
            components.push({
              name: 'RateComponent',
              type: window.AliPlayerComponent.RateComponent,
            })
          }
          this.$nextTick(() => {
            new window.Aliplayer(
              {
                id: 'player-con',
                source: res.data.message,
                width: '100%',
                height: '5.65rem',
                autoplay: true,
                isLive: false,
                controlBarVisibility: 'click',
                showBarTime: '10000',
                components: components,
              },
              function(player) {
                that.isPlay = true
                that.myPlayer = player
                if (autoPlay) {
                  that.$nextTick(() => {
                    let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
                    if (isiOS) {
                      that.wxReadyByIosVideoAutoPlay(player)
                    } else {
                      player.play()
                    }
                  })
                }
                var seeked = false
                player.on('canplaythrough', function() {
                  if (!seeked) {
                    seeked = true
                    player.seek(lastPosition === videoInfo.length ? 0 : lastPosition)
                  }
                })
                player.on('play', () => {
                  if (!flag) {
                    that.$axios.get(`/course/front/course/${that.courseListData[0].ID}/studytimes`).then((res) => {
                      if (res.data.status === 1) {
                        that.itemData.hasStudyTimes = res.data.data
                      } else {
                        that.isNotMaxCount = false
                        that.$Toast.fail(res.data.message)
                      }
                    })
                    const totalTime = parseInt(player.getDuration())
                    let spaceTime = 30
                    if (totalTime < 30) {
                      spaceTime = totalTime % 30
                    }
                    that.uploadMap[that.lastWatchVideoID] = that.uploadMap[that.lastWatchVideoID] ? that.uploadMap[that.lastWatchVideoID] : {}
                    that.uploadMap[that.lastWatchVideoID].prePostion = 0
                    that.uploadMap[that.lastWatchVideoID].spaceTime = 1
                    that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID)
                    that.timer2 = setInterval(() => {
                      that.uploadMap[that.lastWatchVideoID] && (that.uploadMap[that.lastWatchVideoID].spaceTime += 1)
                      console.log(that.uploadMap[that.lastWatchVideoID].spaceTime)
                    }, 1000)
                    flag = true
                  } else {
                    if (!that.timer2) {
                      let spaceTime = 30
                      const totalTime = parseInt(player.getDuration())
                      if (totalTime < 30) {
                        spaceTime = totalTime % 30
                      }
                      that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID)
                      that.timer2 = setInterval(() => {
                        that.uploadMap[that.lastWatchVideoID].spaceTime += 1
                      }, 1000)
                    }
                  }
                })
                player.on('ended', () => {
                  flag = false
                  clearInterval(that.timer2)
                  that.timer2 = null
                  clearInterval(that.timer)
                  that.timer = null
                  that.postVideoschedule(that.lastWatchVideoID)
                })
                player.on('pause', () => {
                  clearInterval(that.timer2)
                  that.timer2 = null
                  clearInterval(that.timer)
                  that.timer = null
                  if (Math.ceil(player.getCurrentTime()) !== Math.ceil(player.getDuration())) {
                    that.postVideoschedule(that.lastWatchVideoID)
                  }
                })
                player.on('error', function() {
                  that.playError(assertID)
                })
                player.on('timeupdate', function() {
                  if (this.getCurrentTime() > videoInfo.maxPosition) {
                    videoInfo.maxPosition = parseInt(this.getCurrentTime())
                  }
                })
                that.initVideoSpeed(player)
                that.initProgressMove(player, videoInfo)
              }
            )
          })
        } else {
          this.$Toast.fail(res.data.message)
        }
      })
    },
    // 倍速播放初始化
    initVideoSpeed(context) {
      let that = this
      const playerSetSpeed = context.setSpeed
      context.setSpeed = function() {
        let arg = arguments
        console.log('重写的倍速播放，下面可以做一些事情-----', ...arguments)
        that.postVideoschedule(that.lastWatchVideoID, 0, function() {
          that.speed = Number(arg[0])
        })
        playerSetSpeed.apply(context, arguments)
      }
    },
    // 快进快退初始化
    initProgressMove(context, videoInfo) {
      const that = this
      let time = 0
      const playerGetSeconds = context.UI.progress.prototype._getSeconds
      context.UI.progress.prototype._onMouseClick = function() {}
      const playerProgressMove = context.UI.progress.prototype._progressMove
      context.UI.progress.prototype._getSeconds = function() {
        time = playerGetSeconds.apply(context._children[12]._children[0], arguments)
        // 快进时拖拽不能超过看课最大位置
        if (parseInt(time) > videoInfo.maxPosition && !that.isFastForward) {
          time = videoInfo.maxPosition
        }
        return time
      }
      context.UI.progress.prototype._progressMove = function() {
        playerProgressMove.apply(context._children[12]._children[0], arguments)
        let progressDown = this._progressDown
        Object.defineProperty(this, '_progressDown', {
          get: function() {
            return progressDown
          },
          set: function(value) {
            progressDown = value
            if (value === false) {
              that.sendPosition(parseInt(time))
            }
          },
        })
      }
    },
    sendPosition(time) {
      console.log('拖拽后请求进度接口--------', time)
      this.postVideoschedule(this.lastWatchVideoID, 1)
    },
    playError(assertID) {
      // 去除一些不需要展现的错误信息
      if (document.getElementById('tipsP')) {
        document.getElementsByClassName('errorCode')[0] && (document.getElementsByClassName('errorCode')[0].style.display = 'none')
        document.getElementsByClassName('uuid')[0] && (document.getElementsByClassName('uuid')[0].style.display = 'none')
        document.getElementsByClassName('requestId')[0] && (document.getElementsByClassName('requestId')[0].style.display = 'none')
        return
      }
      const content = document.getElementsByClassName('prism-detect-info')[0]
      const tipsP = document.createElement('p')
      const tipsUser = document.createElement('span')
      const tipsUserName = document.createElement('span')

      const tipsCourseID = document.createElement('p')
      const tipsCourse = document.createElement('span')
      const tipsCourseIDValue = document.createElement('span')

      const tipsAssertID = document.createElement('p')
      const tipsAssert = document.createElement('span')
      const tipsAssertIDValue = document.createElement('span')

      // 用户名
      tipsP.id = 'tipsP'
      tipsUser.textContent = '用户名：'
      tipsUserName.textContent = localStorage.userInfo ? JSON.parse(localStorage.userInfo).userName : '没有用户信息'
      tipsUser.className = 'info-label'
      tipsUserName.className = 'info-content'
      tipsP.appendChild(tipsUser)
      tipsP.appendChild(tipsUserName)

      // 课程ID
      tipsCourse.textContent = 'courseID：'
      tipsCourseIDValue.textContent = this.id
      tipsCourse.className = 'info-label'
      tipsCourseIDValue.className = 'info-content'
      tipsCourseID.appendChild(tipsCourse)
      tipsCourseID.appendChild(tipsCourseIDValue)

      // 媒资ID
      tipsAssert.textContent = 'assertID：'
      tipsAssertIDValue.textContent = assertID
      tipsAssert.className = 'info-label'
      tipsAssertIDValue.className = 'info-content'
      tipsAssertID.appendChild(tipsAssert)
      tipsAssertID.appendChild(tipsAssertIDValue)

      content.appendChild(tipsP)
      content.appendChild(tipsCourseID)
      content.appendChild(tipsAssertID)
    },
    setUploadvideoschedule(spaceTime, videoID) {
      if (this.timer) {
        clearInterval(this.timer)
      }
      if (spaceTime > 0) {
        this.timer = setInterval(() => {
          this.postVideoschedule(videoID)
        }, 1000 * spaceTime)
      }
    },
    postVideoschedule(videoID, isDrag, cb) {
      if (!this.myPlayer || !this.uploadMap[videoID]) {
        return
      }
      if (this.myPlayer.paused()) {
        if (this.uploadMap[videoID].prePostion === Math.ceil(this.myPlayer.getCurrentTime())) {
          return
        }
      }
      let params = new FormData()
      let position = Math.ceil(this.myPlayer.getCurrentTime())
      let _spaceTime = this.uploadMap[videoID].spaceTime > 30 ? 30 : this.uploadMap[videoID].spaceTime
      params.append('videoID', videoID)
      params.append('position', position)
      params.append('videoType', 'hd')
      params.append('spaceTime', _spaceTime)
      params.append('speed', this.speed)
      params.append('isDrag', isDrag || 0)
      params.append('platform', 'h5')
      this.uploadMap[videoID].prePostion = position
      this.uploadMap[videoID].spaceTime = 0
      this.$axios.post(`/course/front/course/${this.courseListData[0].ID}/uploadvideoschedule`, params).then((res) => {
        if (typeof cb === 'function') {
          cb()
        }
        if (res.data.status === 1) {
          this.courseListData[0].firstVideoInfo.lastPosition = position
        }
      })
    },
    // 课程详情
    getCourseData() {
      this.$axios
        .get(`/course/front/permit/coursepackage/${this.id}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.itemData = res.data.data
            if (!this.itemData.courses) this.itemData.courses = []
            this.courseListData = res.data.data.courses.map((item) => {
              item.categories = item.categories.split('<br>')[0]
              return item
            })
            this.isPay = res.data.data.hasPermission === 'Y'
            this.isFastForward = res.data.data.isFastForward === 'Y' // 是否允许拖进度条
            this.isSpeedPlayback = res.data.data.isSpeedPlayback === 'Y' // 是否允许倍速播放
            this.setShare()
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.itemData.name || '课程详情'}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
  },
  destroyed() {
    if (this.myPlayer) {
      this.postVideoschedule(this.lastWatchVideoID)
      this.myPlayer.dispose()
    }
    clearInterval(this.timer)
    clearInterval(this.timer2)
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>

<style scoped>
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.buy-btn-group {
  display: flex;
}
.buy-btn {
  background: linear-gradient(to right, #63a1ed, #97d0f5);
  color: #fff;
}
.buy-btn--highlighted {
  background: linear-gradient(to right, #532c10, #7f583b);
  color: #ffebb6;
}
.curriculum-details.curriculum-pack >>> .prism-fullscreen-btn {
  display: none !important;
}
.curriculum-details >>> .van-nav-bar__title {
  max-width: 50%;
}
.curriculum-details >>> .van-dialog {
  margin-top: 0.93rem;
}
.curriculum-details >>> .van-dialog__content {
  max-height: 80vh;
  overflow-y: auto;
}
.poster-sharer {
  display: flex;
  align-items: center;
}
.poster-sharer span.share {
  font-size: 0.58rem;
  color: #1989fa;
  margin-left: 0.26rem;
}
.curriculum-details {
  background-color: #eee;
  margin-bottom: 1.61rem;
}
.curriculum-details >>> #videoPreview {
  width: 100%;
  height: 100%;
}
.curriculum-details >>> .van-nav-bar .van-icon {
  font-size: 0.48rem;
  color: #333;
}
.curriculum-details >>> .van-tab {
  line-height: 0.53rem;
}
.curriculum-details >>> .van-tabs__line {
  position: absolute;
  bottom: 0.4rem;
  left: 0;
  z-index: 1;
  width: 2.56rem;
  height: 0.08rem;
  background-color: #1578f2;
  border-radius: 0.08rem;
}
.curriculum-details >>> .van-tag {
  font-size: 0.32rem;
  line-height: 0.42rem;
  border-radius: 2px;
  white-space: nowrap;
}
.curriculum-details >>> .van-tabs--line .van-tabs__wrap {
  height: 1.17rem;
}
.curriculum-details >>> .van-tab__text.van-tab__text--ellipsis {
  font-size: 0.37rem;
}
.curriculum-details >>> .van-tab--active .van-tab__text.van-tab__text--ellipsis {
  color: #222;
  font-weight: bold;
}
.curriculum-details >>> .van-tabs__content {
  background-color: #fff;
  padding: 0.26rem 0.53rem;
  font-size: 0.37rem;
  margin-top: 0.13rem;
}
.logo {
  height: 5.65rem;
  width: 100%;
}
.details {
  padding: 0.57rem 0.51rem 0.16rem 0.57rem;
  background-color: #fff;
  margin-top: -0.13rem;
  height: 1rem;
}
.details-item {
  height: 0.44rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.tag {
  background-color: #fbc047;
  border-radius: 0.08rem;
  font-size: 0.32rem;
  line-height: 0.42rem;
  border: 1px solid #fbc047;
  border-radius: 0.08rem;
  padding: 0 1px;
  color: #fff;
}
.title {
  font-size: 0.45rem;
  color: #111111;
  line-height: 0.48rem;
  padding-left: 0.13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5.18rem;
}
.contain {
  color: #1578f2;
  border: 0.01rem solid #1578f2;
  font-size: 0.32rem;
  margin-left: 0.32rem;
}
.desc {
  font-size: 0.35rem;
  color: #474747;
  line-height: 0.56rem;
  margin-top: 0.27rem;
  margin-bottom: 0.4rem;
  padding-left: 0.13rem;
  text-align: justify;
}
.desc >>> img {
  max-width: 100%;
}
.buynow {
  height: 1.61rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.curriculum-details >>> .van-button--round {
  margin: 0.19rem 0.28rem;
  width: calc(100% - 0.56rem);
}
.curriculum-details >>> .van-button--normal {
  padding: 0 0.4rem;
  font-size: 0.37rem;
}
.curriculum-details >>> .van-button {
  height: 1.17rem;
}
.share {
  line-height: 1.55rem;
  font-size: 0.69rem;
}
.has-enterprise {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 40px;
  margin-top: 0.2rem;
}
.course-list-content {
  display: flex;
  border-bottom: 0.03rem solid #e5e4e4;
  padding-bottom: 0.16rem;
  margin-top: 0.23rem;
}
.course-list-content:first-child {
  display: flex;
  border-bottom: 0.03rem solid #e5e4e4;
  padding-bottom: 0.16rem;
  margin-top: 0;
}
.teacher-logo {
  margin-right: 0.32rem;
  margin-bottom: 0.14rem;
}
.teacher-logo img {
  border-radius: 0.11rem;
  object-fit: cover;
  width: 1.07rem;
  height: 1.07rem;
  object-fit: cover;
}
.course-item {
  display: flex;
  width: 7.3rem;
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.ali-danmuku-control {
  display: none !important;
}
.prism-setting-btn {
  display: none !important;
}
.prism-cc-btn {
  display: none !important;
}
.prism-volume {
  display: none !important;
}
.video-js {
  width: 100%;
  height: 5.65rem;
}
.my-video-dimensions {
  width: 100%;
  height: 5.65rem;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-js .vjs-tech {
  position: relative !important;
}
.video-js .vjs-time-control {
  display: block;
}
.video-js .vjs-remaining-time {
  display: none;
}
</style>
