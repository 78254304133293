export class MediaManager {
  constraints = {
    audio: false,
    video: false,
  }

  constructor(type) {
    if (type === 'audio' || type === true) {
      this.constraints.audio = true
    }
    if (type === 'video' || type === 'true') {
      this.constraints.video = true
    }
  }

  initUserMedia() {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {}
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function(constraints) {
        let getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia

        if (!getUserMedia) {
          // return Promise.reject(new Error('无法获取浏览器录音功能，请升级系统版本至ios14.3以上!'))
          return Promise.reject('无法获取录音功能，请升级系统版本至ios14.3以上!')
        }

        return new Promise(function(resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    }
  }

  async getMediaPermissions() {
    await this.initUserMedia()

    const { constraints } = this
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          resolve(stream)
        })
        .catch((error) => reject(error))
    })
    // return new Promise((resolve, reject) => {
    //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //     navigator.mediaDevices
    //       .getUserMedia(constraints)
    //       .then(resolve)
    //       .catch(reject)
    //   } else if (getUserMedia()) {
    //     getUserMedia()(constraints, resolve, reject)
    //   } else {
    //     let message
    //     if (navigator.userAgent.toLowerCase().match(/chrome/) && location.origin.indexOf('https://') < 0) {
    //       message = 'chrome下获取浏览器录音功能，因为安全性问题，' + '需要在 ``localhost 或 `127.0.0.1` 或 `https` 下才能获取权限'
    //     } else {
    //       message = '无法获取浏览器录音功能，请升级浏览器或使用chrome'
    //     }
    //     reject(new Error(message))
    //   }
    // })
  }
}
