<template>
  <div class="user">
    <van-icon name="chat-o" :dot="mesCount.messageCount > 0 || mesCount.questionCount > 0" size="0.7rem" @click="gotoNotice" />
    <van-row class="user-top">
      <van-col span="24" class="user-box user-info">
        <div class="info-img" @click="gotoPage('/user/userinfo/info')">
          <van-image round width="100%" height="100%" :src="userinfo.avatar" />
        </div>
        <div class="info-conter" @click="gotoPage('/user/userinfo/info')">
          <div class="info-name">
            <span>{{ userinfo.realName }}</span>
            <van-icon name="arrow" class="user-arrow" />
          </div>
          <div class="info-members" v-if="Number(userinfo.isVip)">
            <van-icon class="members-icon" name="wap-home-o" />
            <span>企业会员</span>
          </div>
          <div class="info-enterprise" v-if="userinfo.enterpriseName">
            <van-icon class="members-icon" name="wap-home-o" />
            <span> {{ userinfo.enterpriseName }}</span>
          </div>
        </div>
      </van-col>
      <van-col span="24" class="user-box user-order">
        <van-col span="24" class="order-title">
          <div>我的订单</div>
        </van-col>
        <van-col span="24" class="order-info">
          <van-col span="8" @click="gotoPage('/user/payment')">
            <div class="order-img">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon01.png')" />
            </div>
            <div class="order-text">已支付</div>
          </van-col>
          <van-col span="8" @click="gotoPage('user/notPayment')">
            <!-- 因为图片大小不一样 所以要进行微调 -->
            <div class="order-img" style="width: 1.26rem;margin-bottom: 0.29rem;">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon02.png')" />
            </div>
            <div class="order-text">未支付</div>
          </van-col>
          <van-col span="8" @click="gotoPage('/user/couponsmodule')">
            <div class="order-img" style="width: 1.26rem;margin-bottom: 0.29rem;">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon03.png')" />
            </div>
            <div class="order-text">优惠券</div>
          </van-col>
        </van-col>
      </van-col>
      <van-col span="24" class="user-box user-order">
        <van-col span="24" class="order-title">
          <div>我的课程</div>
        </van-col>
        <van-col span="24" class="order-info">
          <van-col span="8" @click="gotoPage('/allCourses/CoursesList')">
            <div class="order-img">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon04.png')" />
            </div>
            <div class="order-text">免费课</div>
          </van-col>
          <van-col span="8" @click="gotoPage('/user/userbuy/buy')">
            <div class="order-img">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon08.png')" />
            </div>
            <div class="order-text">我的课</div>
          </van-col>
          <van-col span="8" @click="gotoPage('/user/userbuy/lrecord')">
            <div class="order-img">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon06.png')" />
            </div>
            <div class="order-text">学习记录</div>
          </van-col>
          <van-col span="8" @click="gotoPage('/user/myexam')" style="margin-top: 0.53rem;">
            <div class="order-img">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon14.png')" />
            </div>
            <div class="order-text">我的考试</div>
          </van-col>
          <van-col span="8" @click="gotoPage('/allCourses/allErrorQuestion')" style="margin-top: 0.53rem;">
            <div class="order-img">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon13.png')" />
            </div>
            <div class="order-text">错题练习</div>
          </van-col>
          <van-col span="8" @click="gotoPageTo('/study', 0)" style="margin-top: 0.53rem;">
            <div class="order-img">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon12.png')" />
            </div>
            <div class="order-text">我的班级</div>
          </van-col>
          <!-- <van-col span="8" @click="gotoPage('/index/aliVideo/3')" style="margin-top: 0.53rem;">
            <div class="order-img">
              <van-image width="100%" height="100%" :src="require('../../../public/images/user/icon10.png')" />
            </div>
            <div class="order-text">测试视频</div>
          </van-col> -->
        </van-col>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: 'user',
  data() {
    return {
      mesCount: {},
      userinfo: {
        realName: '',
        mobile: '',
        idCard: '',
        enterpriseName: '',
        avatar: '',
      },
    }
  },
  created() {
    this.userinfo = this.$eventHub.userInfo
    this.userinfo.mobile = this.userinfo.mobile.replace('+86 ', '')
    this.setShare()
    this.getMessagehandle()
    console.log('userInfo', this.$eventHub.userInfo)
  },
  methods: {
    gotoNotice() {
      let path = '/user/notice'
      if (this.mesCount.questionCount >= this.mesCount.messageCount) {
        path = '/user/notice?activeIndex=0'
      } else if (this.mesCount.questionCount < this.mesCount.messageCount) {
        path = '/user/notice?activeIndex=1'
      }
      this.$router.push({
        path: path,
      })
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    notOPen() {
      this.$Toast.fail('暂未开放')
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    gotoPageTo(path, active) {
      this.$router.push({ path: path, query: { active: active } })
    },
    // 用户未读消息总数
    getMessagehandle() {
      this.$axios
        .get('/course/front/messagehandle/unread')
        .then((res) => {
          if (res.data.status === 1) {
            this.mesCount = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
  },
}
</script>
<style scoped>
.user >>> .van-icon.van-icon-chat-o {
  position: absolute;
  right: 0.66rem;
  top: 0.93rem;
}
.user >>> .van-image__img {
  border-radius: 40%;
}
</style>
<style lang="less" scoped>
.user {
  background-color: #f8f8f8;
  // min-height: 100vh;
  // padding-bottom: 1.32rem;
  height: calc(100vh - 1.33rem);
  overflow-y: auto;
  position: relative;
  .user-top {
    margin: 0 0.33rem;
    padding-top: 1.31rem;
    .user-box {
      background-color: #fff;
      border-radius: 0.27rem;
      display: flex;
      align-items: center;
      .info-img {
        width: 1.87rem;
        height: 1.87rem;
      }
      .info-conter {
        display: flex;
        flex-direction: column;
        margin-left: 0.36rem;
        .info-name {
          font-family: PingFang-SC-Heavy;
          font-size: 0.48rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #000000;
          .user-arrow {
            color: #888888;
            font-size: 0.28rem;
          }
        }
        .info-members {
          font-size: 0.37rem;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #fcbf07;
          font-family: PingFang-SC-Regular;
          border-radius: 0.27rem;
          border: solid 0.03rem #f39700;
          align-self: baseline;
          padding: 0 0.2rem;
          margin: 0.2rem 0;
        }
        .info-enterprise {
          align-self: baseline;
          font-family: PingFang-SC-Medium;
          font-size: 0.35rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #333333;
          background-color: #d3d3d3;
          border-radius: 0.27rem;
          padding: 0 0.2rem;
        }
        .members-icon {
          font-size: 0.37rem;
          vertical-align: middle;
          margin-top: -0.04rem;
        }
      }
    }
    .user-info {
      background-color: #f8f8f8;
    }
    .user-order {
      flex-wrap: wrap;
      padding: 0.56rem 0.44rem;
      margin: 0.31rem 0;
      .order-title {
        font-family: PingFang-SC-Heavy;
        font-size: 0.48rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #1d1d1d;
      }
      .order-info {
        font-family: PingFang-SC-Medium;
        font-size: 0.35rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #0d0d0d;
        margin-top: 0.64rem;
        text-align: center;
        .order-img {
          width: 1rem;
          margin: 0 auto;
          margin-bottom: 0.43rem;
        }
        .order-text {
          font-family: PingFang-SC-Medium;
          font-size: 0.35rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #0d0d0d;
        }
      }
    }
  }
}
</style>
