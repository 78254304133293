<template>
  <div class="bottom-navigation">
    <van-tabbar v-model="active" :safe-area-inset-bottom="true">
      <van-tabbar-item v-if="isFrontPage" name="index" @click="gotoPage('/')">
        <span>首页</span>
        <template #icon="">
          <div :class="{ active: $route.name === 'index' }">
            <i class="nav" :style="{ 'margin-left': isShow ? '0.21rem' : '0.26rem' }"></i>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item v-if="isFrontPage" name="allCourses" @click="gotoPage('/allCourses/index')">
        <span>麓辰课程</span>
        <template #icon="">
          <div :class="{ active: $route.name === 'allCourses' }">
            <i class="nav all-courses-nav"></i>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item v-if="isShow" name="nzzone" @click="gotoPage('/nzzone')">
        <span>{{ enterpriseShorthand }}</span>
        <template #icon="">
          <div :class="{ active: $route.name === 'nzzone' || active === 'nzzone' }">
            <i class="special-nav nav"></i>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="study" @click="gotoPage('/study')">
        <span>学习</span>
        <template #icon="">
          <div :class="{ active: $route.name === 'study' }">
            <i class="study-nav nav" style="margin-left: 0.16rem"></i>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="user" @click="gotoPage('/user')">
        <span>我的</span>
        <template #icon="">
          <div :class="{ active: $route.name === 'user' }">
            <i class="user-nav nav" :style="{ 'margin-left': isShow ? '-0.21rem' : '-0.26rem' }"></i>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <!-- <ul class="bottom-nav">
      <li :class="{'active': $route.name === 'index'}" @click="gotoPage('/')" :style="{width: isShow ? '20%': '25%'}">
        <i class="nav" :style="{'margin-left': isShow ? '0.6875rem': '0.96rem'}"></i>
        <div>首页</div>
      </li>
      <li :class="{'active': $route.name === 'allCourses'}" @click="gotoPage('/allCourses/index')" :style="{width: isShow ? '20%': '25%'}">
        <i class="nav all-courses-nav" :style="{'margin-left': isShow ? '0.6875rem': '0.96rem'}"></i>
        <div>麓辰课程</div>
      </li>
      <li :class="{'active': $route.name === 'nzzone'}" @click="gotoPage('/nzzone')" v-if="isShow" :style="{width: isShow ? '20%': '25%'}">
        <i class="special-nav nav" :style="{'margin-left': isShow ? '0.6875rem': '0.96rem'}"></i>
        <div>{{enterpriseShorthand}}</div>
      </li>
      <li :class="{'active': $route.name === 'study'}" @click="gotoPage('/study')" :style="{width: isShow ? '20%': '25%'}">
        <i class="study-nav nav" :style="{'margin-left': isShow ? '0.6875rem': '0.96rem'}"></i>
        <div>学习</div>
      </li>
      <li :class="{'active': $route.name === 'user'}" @click="gotoPage('/user')" :style="{width: isShow ? '20%': '25%'}">
        <i class="user-nav nav" :style="{'margin-left': isShow ? '0.47rem': '0.74rem'}"></i>
        <div>我的</div>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: 'BottomNavigation',
  data() {
    return {
      active: '',
      activePath: '',
      isShow: false,
      enterpriseShorthand: '',
      isFrontPage: 1,
    }
  },
  watch: {
    $route() {
      if (this.$route.name === 'allCourses' && this.isFrontPage === 0 && this.$route.query && this.$route.query.isContinue === '1') {
        this.active = 'nzzone'
      } else {
        this.active = this.$route.name
      }
    },
  },
  created() {
    // 这里单独处理是为了防止直接刷新页面，不触发watch状态中的$route
    if (this.$route.name === 'allCourses' && JSON.parse(localStorage.userInfo).isFrontPage === 0 && this.$route.query && this.$route.query.isContinue === '1') {
      this.active = 'nzzone'
    } else {
      this.active = this.$route.name
    }
    this.$eventHub.$on('getUserInfo', this.getUserInfo)
    if (localStorage.userInfo) {
      this.getUserInfo(JSON.parse(localStorage.userInfo))
    }
  },
  methods: {
    gotoPage(path) {
      if (this.$route.path === path) {
        return
      }
      this.$router.push({ path: path })
    },
    getUserInfo(info) {
      if (info.isVip && (info.enterpriseName || info.enterpriseShorthand)) {
        this.isShow = true
      }
      if (info.enterpriseShorthand) {
        this.enterpriseShorthand = this.$eventHub.userInfo.enterpriseShorthand.substr(0, 5)
      } else if (info.enterpriseName) {
        this.enterpriseShorthand = this.$eventHub.userInfo.enterpriseName.substr(0, 5)
      }
      this.isFrontPage = info.isFrontPage
    },
  },
  destroyed() {
    this.$eventHub.$off('getUserInfo', this.getUserInfo)
  },
}
</script>

<style scoped>
.bottom-navigation {
  /* position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  height: 1.32rem; */
}
.bottom-navigation ul {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  border-top: 1px solid #f5f5f5;
}
.bottom-navigation ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  height: 1.5625rem;
  font-size: 0.32rem;
  padding: 0.13rem 0 0 0;
  text-align: center;
  box-sizing: border-box;
}
.bottom-navigation .nav {
  background-image: url(/images/common/tab-icons.png);
  width: 0.84375rem;
  height: 0.625rem;
  background-size: 500%;
  display: block;
}
.all-courses-nav {
  background-position-x: 3.41rem;
}
.special-nav {
  background-position-x: -1.71875rem;
}
.study-nav {
  background-position-x: -2.72rem;
}
.bottom-navigation i.user-nav {
  background-position-x: -3.375rem;
}
.active .nav {
  background-position-y: 0.625rem;
}
li.active div {
  color: #3fa1fd;
}
.bottom-navigation div {
  margin-top: 0.02rem;
}
.bottom-navigation >>> .van-tabbar {
  height: 1.33rem;
}
.bottom-navigation >>> .van-tabbar-item {
  font-size: 0.32rem;
}
</style>
