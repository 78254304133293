<template>
  <div class="classlist clearfix">
    <zv-nav-bar :title="pagename" :click-left="clickLeft"></zv-nav-bar>
    <van-col span="24">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="class-box" :class="{active: activeID === item.ID}" v-for="item in classlist" :id="item.ID" :key="item.ID" @click="activeID = item.ID">
            <div class="class-top">
              <div class="class-l">
                <van-image round width="100%" height="100%" :src="item.logoSrc" />
              </div>
              <div class="class-r">
                <div class="title-box">
                  <span class="title-name">{{item.name}}</span>
                  <span class="title-num">共{{item.numberOfCourses}}节课</span>
                </div>
                <div class="title-time">有效期:{{item.startDate}}-{{item.endDate}}</div>
              </div>
            </div>
            <div class="info-enterprise" v-html="item.description">
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="btn-box">
        <van-button class="info-btn" round hairline size="large" type="info" @click="changeClass">切换班级</van-button>
      </div>
    </van-col>
  </div>
</template>

<script>
export default {
  name: "classlist",
  data() {
    return {
      refreshing: false,
      activeID: -1,
      pagename: "班级列表",
      classlist: [],
      loading: false,
      finished: false,
      pageIndex: 0
    };
  },
  methods: {
    clickLeft() {
      this.$emit('classChange', this.activeID)
    },
    changeClass() {
      if (this.activeID === -1) {
        return this.$Toast.fail('请选择一个班级')
      }
      this.$emit('classChange', this.activeID)
    },
    onRefresh() {
      this.pageIndex = 0
      this.classlist = []
      this.activeID = -1
      this.$nextTick(() => {
        this.finished = false
        this.loading = true
        this.onLoad()
      })
    },
    onLoad() {
      this.$axios.get(`/course/front/class?pageIndex=${this.pageIndex}&pageSize=10`).then(res => {
        if (res.data.status === 1) {
          this.pageIndex = this.pageIndex + 1
          this.classlist = this.classlist.concat(res.data.data.data)
          this.loading = false
          if (this.classlist.length >= res.data.data.total) {
            this.finished = true
          }
          this.refreshing = false
        } else {
          this.$Toast.fail(res.data.message)
          this.loading = false
          this.finished = true
          this.refreshing = false
        }
      }).catch(e => {
        this.$Toast.fail('服务器开小差了，稍后重试')
        return e
      })
    }
  },
};
</script>

<style lang="less" scoped>
.classlist {
  padding-bottom: 1.6rem;
  .class-box {
    border-radius: 0.13rem;
    margin: 0 0.36rem;
    padding: 0.19rem 0.53rem 0.44rem 0.28rem;
    .class-top {
      display: flex;
      .class-l {
        width: 1.45rem;
        height: 1.45rem;
        margin: 0.15rem;
      }
      .class-r {
        font-size: 0.35rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.17rem;
        flex: 1;
        .title-box {
          display: flex;
          justify-content: space-between;
          .title-name {
            font-family: PingFang-SC-Medium;
            font-size: 0.4rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #1b1b1b;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 5rem;
          }
          .title-num {
            font-family: PingFang-SC-Regular;
            font-size: 0.32rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #999999;
          }
        }
        .title-time {
          font-family: PingFang-SC-Regular;
          font-size: 0.35rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #999999;
        }
      }
    }
    .info-enterprise {
      align-self: baseline;
      font-family: PingFang-SC-Medium;
      font-size: 0.32rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.53rem;
      letter-spacing: 0rem;
      color: #888888;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin: 0;
        margin-top: 0;
      }
    }
  }
  .active {
    background-color: #cae1fd;
  }
  .btn-box {
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    .info-btn {
      width: 80%;
      height: 1.17rem;
      margin-bottom: 0.4rem;
      .van-button__text {
        font-size: 0.4rem;
      }
    }
  }
}
</style>
