<template>
  <div class="page-box">
    <div v-show="!isShow">
      <zv-nav-bar :title="'全部错题'">
        <span class="count">共{{ allCount }}题</span>
      </zv-nav-bar>
      <div class="search-box">
        <van-field v-model="search" class="search-input" placeholder="输入关键字可搜索题目并查看题目解析" />
        <van-button :loading="searchLoading" round type="info" @click="searchClick">搜索</van-button>
      </div>
      <div class="list-box">
        <div class="list-hearder-box">
          <div class="hearder">
            <span>序号</span>
            <span>题目</span>
            <span>答错次数</span>
          </div>
        </div>
        <van-list v-model="listLoading" @load="onLoad" :finished="finished">
          <div v-for="(item, index) in errList" :key="item.ID" class="list-content" @click="itemClick(item)">
            <div class="list-item">
              <span class="number">{{ index + 1 }}</span>
              <span class="title">{{ item.title | delHtmlTagChar }}</span>
            </div>
            <span class="err-count">{{ item.wrongCounts }}</span>
          </div>
        </van-list>
      </div>
      <div class="bottom-btn-box">
        <div @click="gotoPage('/allCourses/wrongExeRecord')">
          <span>练习记录</span>
        </div>
        <div @click="$router.push({ path: '/allCourses/WrongClassExe', query: { allCount: allCount } })">
          <span>错题分类练习</span>
        </div>
      </div>
    </div>
    <single-wrong-topic v-if="isShow" :question="questionList" @back="back" :count="wrongCounts"></single-wrong-topic>
  </div>
</template>
<script>
import SingleWrongTopic from './SingleWrongTopic.vue'
export default {
  name: 'allerrorquestion',
  components: {
    'single-wrong-topic': SingleWrongTopic,
  },
  data() {
    return {
      search: '',
      searchLoading: false,
      listLoading: false,
      finished: false,
      pageIndex: 0,
      errList: [],
      allCount: 0,
      questionList: [],
      isShow: false,
      scrollTop: 0,
      wrongCounts: 0,
    }
  },
  filters: {
    delHtmlTagChar(str) {
      return str
        .replace(/<[^>]+>/g, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .trim()
    },
  },
  created() {
    this.setShare()
    this.getAllErrorCount()
  },
  methods: {
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    searchClick() {
      this.finished = false
      this.pageIndex = 0
      this.errList = []
    },
    onLoad() {
      this.$axios
        .get(`/tk/front/member/errorquestion?title=${this.search}&pageSize=15&pageIndex=${this.pageIndex}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.pageIndex = this.pageIndex + 1
            this.errList = this.errList.concat(res.data.data.data)
            this.listLoading = false
            if (this.errList.length >= res.data.data.total) {
              this.finished = true
            }
          } else {
            this.$Toast.fail(res.data.message)
            this.listLoading = false
            this.finished = true
          }
        })
        .catch((e) => {
          this.listLoading = false
          this.finished = true
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    getAllErrorCount() {
      this.$axios
        .get('/tk/front/member/errorquestion/choosenumber')
        .then((res) => {
          if (res.data.status === 1) {
            this.allCount = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    itemClick(item) {
      this.wrongCounts = item.wrongCounts
      if (document.documentElement && document.documentElement.scrollTop) {
        this.scrollTop = document.documentElement.scrollTop
      }
      this.$Toast.loading({
        message: '加载中...',
        duration: 0,
      })
      this.$axios
        .get(`/tk/front/member/errorquestion/${item.ID}`)
        .then((res) => {
          this.$Toast.clear()
          if (res.data.status === 1) {
            this.questionList = [res.data.data]
            this.isShow = true
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    back() {
      this.isShow = false
      this.$nextTick(() => {
        this.scrollTop && (document.documentElement.scrollTop = this.scrollTop)
      })
    },
    gotoPage(path) {
      this.$router.push({
        path: path,
      })
    },
  },
}
</script>
<style scoped>
.page-box {
  height: 100vh;
  background-color: #f8f8f8;
}
.page-box >>> .van-list {
  padding-bottom: 1.6rem;
}
.count {
  font-size: 0.32rem;
  color: #1578f2;
}
.search-box {
  display: flex;
  padding: 0.17rem 0.21rem 0.17rem 0.36rem;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.search-box >>> .van-button.van-button--info {
  width: 1.47rem;
  height: 0.75rem;
  font-size: 0.35rem;
  padding: 0;
}
.search-input {
  width: 7.71rem;
  height: 0.85rem;
  line-height: 0.85rem;
  background-color: #f1f2f7;
  border-radius: 0.43rem;
}
.search-input.van-cell {
  padding: 0 0.42rem;
  font-size: 0.32rem;
}
.hearder {
  margin: 0.56rem 0.45rem 0 0.27rem;
  border-bottom: 0.03rem solid #e4e1e1;
  padding-bottom: 0.32rem;
  display: flex;
  justify-content: space-between;
}
.hearder span {
  display: inline-block;
  font-size: 0.32rem;
  color: #444444;
}
.list-content {
  font-size: 0.32rem;
  color: #444444;
  display: flex;
  align-items: center;
  height: 0.8rem;
}
.list-content:nth-child(2n) {
  background-color: #dcf0fd;
}
.list-item {
  width: 8.28rem;
  display: flex;
  align-items: center;
}
.number {
  min-width: 1.49rem;
  text-align: center;
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.err-count {
  width: 1.33rem;
  text-align: center;
  color: #fb0202;
}
.bottom-btn-box {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  height: 1.56rem;
  border: 1px solid #eee;
}
.bottom-btn-box div {
  font-size: 0.4rem;
  color: #777;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  border-radius: 0.07rem;
  background-color: #fff;
}
.bottom-btn-box div:nth-child(1) {
  color: #fff;
  background-color: #1578f2;
}
</style>
