<template>
  <div class="curriculum-details" :style="{ 'margin-bottom': marginBottom }">
    <zv-nav-bar :title="'课程详情'" ref="mychild" :sourceType="sourceType">
      <div class="poster-sharer" @click="posterSharer" v-if="itemData.enterpriseExclusive != 1">
        <span style="font-size: 0.32rem; color: #1989fa">海报分享</span>
        <span class="share fa fa-share-square-o"></span>
      </div>
    </zv-nav-bar>
    <van-row>
      <van-col span="24" class="zv_insert">
        <div class="video-js">
          <div v-html="videoHtml" v-if="isNotMaxCount"></div>
          <img class="logo" :src="itemData.logoFile" v-show="!videoHtml" />
          <button class="vjs-big-play-button" @click="playBtnClick" v-if="isShowPlayBtn">
            <span class="vjs-icon-placeholder"></span>
          </button>
        </div>
        <div class="details" :style="{ 'padding-bottom': itemData.isPhoto === 'Y' ? '0.16rem' : '0.26rem' }">
          <div class="details-item">
            <div class="title">{{ itemData.name }}</div>
          </div>
          <div class="teacher">
            <template v-if="itemData.speaker">
              <span>讲师：</span>
              <span class="teacher-arr">{{ itemData.speaker }}</span>
            </template>
            <span class="teacher--empty" v-else>暂无讲师</span>
            <span class="right-data">
              <span class="contain" style="padding: 0.05rem" v-if="itemData.hasPermission === 'Y'">{{
                itemData.maxLearningTimes !== 0 ? itemData.hasStudyTimes + '/' + itemData.maxLearningTimes : '不限次数'
              }}</span>
            </span>
            <div v-if="itemData.isPhoto === 'Y'" style="color: rgb(244, 42, 9); padding: 0.26rem; margin: 0.1rem 0 0 0; background-color: #fbf5f5; text-align: center">
              观看课程需图像采集，请确保面部在摄像可视范围
            </div>
          </div>
          <div class="price-item" v-if="!isPay && itemData.enterpriseExclusive !== 1 && Number(itemData.editStatus === 2)">
            <template v-if="itemData.isForbid === 0">
              <span class="price">￥{{ hasEnterprise ? itemData.memberPrice : itemData.sellPrice }}</span>
              <span class="separator"></span>
              <span class="old-price" v-if="hasEnterprise">￥{{ itemData.sellPrice }}</span>
              <span class="separator"></span>
              <span class="pay" v-if="hasEnterprise">企业已经为你支付{{ (itemData.sellPrice - itemData.memberPrice).toFixed(2) }}元</span>
              <van-tag plain type="primary">{{ itemData.viewCount }}次</van-tag>
            </template>
          </div>
        </div>
        <van-tabs v-model="activeName" @change="tabChange">
          <van-tab title="课程介绍" name="1">
            <div>【课程介绍】</div>
            <template v-if="itemData.description">
              <div class="desc" v-html="itemData.description"></div>
            </template>
            <div v-else class="not-data">暂无数据</div>
            <div>【主讲老师】</div>
            <template v-if="itemData.speakers && itemData.speakers.length > 0">
              <div class="teacher-item-box">
                <div class="teacher-item" v-for="(item, index) in itemData.speakers" :key="index" @click="toTeacherDetail(item)">
                  <div class="teacher-logo">
                    <img :src="item.logoFile" />
                  </div>
                  <div class="teacher-content">
                    <div class="teacher-name">{{ item.name }}</div>
                    <div class="teacher-desc" v-html="item.description || '暂无描述'"></div>
                  </div>
                </div>
              </div>
            </template>
            <div class="not-data" v-else>暂无数据</div>
          </van-tab>
          <van-tab title="视频" name="2">
            <div class="video-content">
              <div class="video-count">{{ studyContent }}</div>
              <template v-if="videoListData.length > 0">
                <div class="video-list-content" v-for="(item, index) in videoListData" :key="index">
                  <div :class="{ 'video-list-item': true, active: item.ID === lastWatchVideoID }" @click="getVideo(item.ID)">
                    <div class="video-list-item-title">{{ index + 1 }}.{{ item.name }}</div>
                    <div class="video-list-item-time">{{ item.lengthHMS }}</div>
                    <div
                      :class="{ 'video-list-item-icon': true, 'van-icon': true, 'van-icon-play-circle-o': item.ID !== lastWatchVideoID, 'van-icon-bar-chart-o': item.ID === lastWatchVideoID }"
                    ></div>
                    <div style="width: 1.25rem" v-if="itemData.hasPermission === 'Y'">{{ item.percent }}</div>
                  </div>
                </div>
              </template>
              <div v-else class="not-data">暂无数据</div>
            </div>
          </van-tab>
          <van-tab title="课件" name="3">
            <template v-if="handouts.length > 0">
              <template v-for="(item, index) in handouts">
                <div :class="{ 'handouts-content': true, active: item.isShow }" :key="item.ID" :style="{ 'margin-bottom': item.isShow ? '0' : '0.13rem' }" @click="getHandoutsDetails(item, index)">
                  <div class="handouts">
                    <span class="van-icon van-icon-paid"></span>
                    <span class="handouts-title">{{ index + 1 + '.' + item.name }}</span>
                    <span :class="{ 'van-icon': true, btn: true, 'van-icon-arrow': !item.isShow, 'van-icon-arrow-down': item.isShow }"></span>
                  </div>
                  <div :style="{ display: item.isShow ? 'block' : 'none' }" class="handouts-details">
                    <template v-if="handoutsDetails.length > 0">
                      <div v-for="(src, _index) in item.handoutsDetails" :key="_index" @click.stop="preventBubbling($event, _index)">
                        <img :src="src" style="pointer-events: none" />
                      </div>
                    </template>
                    <div v-else class="not-data">暂无数据</div>
                  </div>
                </div>
              </template>
            </template>
            <div v-else class="not-data">没有更多</div>
          </van-tab>
        </van-tabs>
        <div class="buynow" v-if="!isPay && itemData.enterpriseExclusive !== 1">
          <template v-if="!Number(itemData.editStatus === 2)">
            <van-button class="buy-btn--disabled" round @click="$Toast.fail('商品已下架')">已下架</van-button>
          </template>
          <template v-else>
            <template v-if="itemData.isForbid === 0">
              <!-- 非企业会员 显示企业会员价 -->
              <div class="buy-btn-group" v-if="!isMember">
                <van-button class="buy-btn--highlighted" round @click="getMemberPage('buy')">企业会员价&nbsp;{{ formatAmountToFixed2(itemData.memberPrice) }}元</van-button>
                <van-button class="buy-btn" round @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}&type=Course`)"
                  >立即购买&nbsp;{{ formatAmountToFixed2(itemData.sellPrice) }}元</van-button
                >
              </div>
              <div class="buy-btn-group" v-else>
                <van-button class="buy-btn" style="background: #4087ef" round @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}&type=Course`)"
                  >立即购买</van-button
                >
              </div>
            </template>
            <template v-else>
              <van-button round block type="info" @click="getMemberPage('permission')">会员专享</van-button>
            </template>
          </template>
        </div>
        <div :class="itemData.practices == '0' ? 'practise disable' : 'practise'" v-if="isPay">
          <van-button round block type="info" @click="goAnswer" :disabled="itemData.practices == '0' || !itemData.practices">课堂练习</van-button>
          <div class="share van-icon van-icon-apps-o" @click="showShare = true"></div>
        </div>
      </van-col>
    </van-row>
    <div class="qs-icon" v-if="itemData.hasPermission === 'Y'" @click="gotoPage(`/user/question/${$route.params.id}?title=${itemData.name}`)">
      <div class="qs-box">
        <span class="fa fa-question" style="font-size: 0.58rem"></span>
      </div>
      <div style="font-size: 0.28rem; text-align: center; margin-top: 0.13rem">提问</div>
    </div>
    <van-image-preview v-model="imgPreviewShow" :images="handoutsDetails" :startPosition="startPosition" @change="imgPrevieChange" :closeable="true">
      <template v-slot:index>第{{ imgPrevieIndex }}张</template>
    </van-image-preview>
    <van-share-sheet v-model="showShare" :options="options" @select="onSelect" />
    <video id="v" style="object-fit: fill; display: none"></video>
    <canvas id="canvas" width="320" height="380" style="display: none"></canvas>
    <van-dialog v-model="videoPreviewShow" title="看课抓拍图片预览效果" width="80%" @confirm="confirm">
      <video id="videoPreview" style="object-fit: fill"></video>
    </van-dialog>
    <van-dialog v-model="shareDialog" title="长按图片分享" confirmButtonText="关闭" @confirm="shareDialog = false" width="100%">
      <img :src="photoSrc + '?' + new Date().getTime()" style="max-width: 100%; height: auto" />
    </van-dialog>
    <van-popup v-model="popupShow" :close-on-click-overlay="false">
      <div class="popup-box">
        <div class="popup-logo">
          <img src="/images/allCourse/examples/exe.png" />
        </div>
        <div class="popup-text-box">
          <span>你还有练习未完成</span>
          <span style="margin-top: 0.28rem">是否开始练习？</span>
        </div>
        <div class="popup-btn-box">
          <div class="goto-exe" @click="goAnswer">进入练习</div>
          <div class="exe-cancel" @click="popupShow = false">取消</div>
        </div>
      </div>
      <div class="popup-close">
        <div class="popup-close-box" @click="popupShow = false">
          <i class="fa fa-close"></i>
        </div>
      </div>
    </van-popup>
    <!--    <van-button type="info" @click="test">跳转按钮</van-button>-->
    <van-overlay :show="overlayShow">
      <div class="wrapper">
        <!--        <iframe :src="authUrl" frameborder="0" ref="helpNestedFrame" id="helpNestedFrame" width="100%" height="100%"></iframe>-->
        <div class="block" id="iframe-div"></div>
        <van-button type="info" @click="showIframeInfo">信息按钮</van-button>
      </div>
    </van-overlay>
  </div>
</template>
<script>
// import $ from 'jquery'
import { Dialog } from 'vant'

export default {
  name: 'curriculumDetails',
  computed: {
    marginBottom() {
      // 一期先注释这里的逻辑
      if (this.activeName === '2' || this.activeName === '3') {
        return '1.61rem'
      }
      if (!this.isPay) {
        return '1.61rem'
      }
      let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        return '0.53rem'
      }
      return 0
    },
    isShowPlayBtn() {
      return !this.isPlay
    },
  },
  data() {
    return {
      popupShow: false,
      shareDialog: false,
      photoSrc: '',
      videoPreviewShow: false,
      isPlay: false,
      isNotMaxCount: true,
      imgPreviewShow: false,
      imgPrevieIndex: 1,
      startPosition: 0,
      id: '',
      showShare: false,
      options: [
        {
          name: '班级',
          typeName: 'class',
          icon: 'https://img01.yzcdn.cn/vant/custom-icon-fire.png',
        },
        {
          name: '查看练习',
          typeName: 'exerc',
          icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png',
        },
      ],
      activeName: '1',
      isPay: false,
      itemData: {},
      videoListData: [],
      handouts: [],
      studyContent: '',
      handoutsDetails: [],
      myPlayer: null,
      videoHtml: '',
      allNotPlayflag: true,
      lastWatchVideoID: '',
      timer: null,
      timer2: null,
      danmukuListTimer: null,
      uploadMap: {},
      imgData: '',
      playVideoOk: true,
      isPlayVideo: false,
      paperID: 0,
      hasEnterprise: false,
      isChangeVideo: false,
      isRequestedHandouts: false,
      isRequestedVideo: false,
      danmukuList: [
        {
          mode: 1,
          text: '',
          size: 22,
          color: 0x1989fa,
        },
      ],
      isLoadVideo: false,
      windowInterval: null,
      overlayShow: false,
      authUrl: '',
      isBack: '', // 是否重定向回来的页面 ==='Y'表示重定向过来的
      backUrl: '',
      sourceType: '',
      speed: 1,
      isFastForward: false, // 是否允许拖进度条
      isSpeedPlayback: false, // 是否允许倍速播放
      photoTimer: '',
      isUploadPhoto: false,
      intervalTimer: null,
      photoIntervalTime: 0,
      photoPreIntervalTime: 0,
      sendDanmuku: false,
      fromTime: 0,
      isInit: false,
      videoInfo: {},
      isMember: false,
    }
  },
  async created() {
    this.id = this.$route.params.id
    await this.getCourseData()
    // lastWatchVideoID路由上包含了视频ID取路由上的否则取接口返回的
    this.lastWatchVideoID = this.$route.query.videoId ? parseInt(this.$route.query.videoId) : this.lastWatchVideoID
    if (this.isPay || this.itemData.freeLength !== 0) {
      this.activeName = '2'
      this.getVideosData()
    }
    this.backUrl = this.$route.query.backUrl
    this.isBack = this.$route.query.isBack
    if (this.isBack && this.isBack === 'Y') {
      let temp = this.backUrl
      if (!temp || temp == '') {
        temp = '/'
      }
      if (temp && temp.indexOf('isBack=') < 0) {
        if (temp.indexOf('?') < 0) {
          temp = temp + '?isBack=Y'
        } else {
          temp = temp + '&isBack=Y'
        }
        this.backUrl = temp
        localStorage.back_url = temp
      }
    }
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      if (Number(info.isVip) && (info.enterpriseName || info.enterpriseShorthand)) {
        this.hasEnterprise = true
      }
      this.danmukuList[0].text = `${info.realName} ${info.mobile} ${info.enterpriseName || ''}`
    }

    // 禁用物理返回键（勿删）
    if (this.isBack && this.isBack === 'Y') {
      this.sourceType = 'curriculumDetails'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  async mounted() {
    await this.loadJS(location.protocol + '//' + location.host + '/js/aliplayer-min.js')
    await this.loadJS(location.protocol + '//' + location.host + '/js/CommentCoreLibrary.js')
    await this.loadJS(location.protocol + '//' + location.host + '/js/aliplayercomponents-1.0.6.min.js')
    await this.loadJS(location.protocol + '//' + location.host + '/js/aliplayer-hls-min.js?20211012')
    this.isMember = this.$eventHub.userInfo.isVip
  },
  methods: {
    test() {
      Dialog.confirm({
        title: '温馨提示',
        message: '测试open跳转',
      }).then(() => {
        this.$axios
          .post(`/course/front/permit/test/test3`)
          .then((res) => {
            if (res.data.status !== 1) {
              this.$Toast.fail(res.data.message)
            }
          })
          .catch((e) => {
            this.$Toast.fail('服务器开小差了，稍后重试' + e)
          })
      })
    },
    getMemberPage(type) {
      // 跳转企业会员相关文章页
      this.$axios({
        url: '/json/ktqyhy/index.json',
        methods: 'GET',
        params: {},
      }).then(({ data }) => {
        if (Number(data.status) !== 1) return
        if (type === 'buy') this.handleGotoMemberOrder(data.data[0].artUrl)
        else if (type === 'permission') this.handleGotoMemberOrder(data.data[1].artUrl)
      })
    },
    handleGotoMemberOrder(url) {
      const artUrl = url.substring(url.search(/json/))
      this.$router.push({
        name: 'memberInfo',
        params: {
          url: artUrl,
        },
      })
    },
    pushHistory() {
      if (this.isBack && this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        // this.$Toast.fail('请点击左上角的返回键进行返回')
        let path = localStorage.back_url
        if (path) {
          localStorage.back_url = ''
          this.$router.push({ path: path })
        } else {
          if (window.history.length >= 2 && window.location.href.indexOf('isLoged') === -1) {
            if (window.location.href.indexOf('isShare') === -1) {
              this.$router.go(-1)
            } else {
              this.$router.push({
                path: '/',
              })
            }
          } else {
            this.$router.push({
              path: '/',
            })
          }
        }
      }
    },
    showIframeInfo() {
      let iframeInfo = document.getElementById('acty-frame')
      let message = iframeInfo.contentWindow.document.body.innerHTML
      Dialog.confirm({
        title: '温馨提示',
        message: message,
      }).then(() => {})
    },
    createIframe(dom, src, onload) {
      //在document中创建iframe
      let iframe = document.createElement('iframe')
      iframe.id = 'acty-frame'
      //设置iframe的样式
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      iframe.style.margin = '0'
      iframe.style.padding = '0'
      iframe.style.overflow = 'hidden'
      iframe.style.border = 'none'
      // 绑定iframe的onload事件,处理事件的兼容问题
      if (onload && Object.prototype.toString.call(onload) === '[object Function]') {
        if (iframe.attachEvent) {
          iframe.attachEvent('onload', onload)
        } else if (iframe.addEventListener) {
          iframe.addEventListener('load', onload)
        } else {
          iframe.onload = onload
        }
      }
      iframe.src = src
      //把iframe载入到dom以下
      dom.appendChild(iframe)
      return iframe
    },
    async loadJS(url, data) {
      return new Promise(function(resolve, reject) {
        let s = document.createElement('script')
        s.setAttribute('src', url)
        if (data) {
          s.setAttribute('data', data)
        }
        // let arr = document.head.children
        // for(let i = 0; i < arr.length; i++) {
        //   if (arr[i].localName === 'script') {
        //     if (arr[i].src === url) {
        //       document.head.removeChild(arr[i])
        //     }
        //   }
        // }
        document.head.appendChild(s)
        s.onload = resolve
        s.onerror = reject
      })
    },
    posterSharer() {
      if (this.photoSrc) {
        return (this.shareDialog = true)
      }
      this.$Toast.loading({
        message: '正在生成海报中...',
        forbidClick: true,
        duration: 0,
      })
      this.$axios
        .get(`/course/front/permit/poster/${this.$route.params.id}?type=Course`)
        .then((res) => {
          this.$Toast.clear()
          if (res.data.status === 1) {
            this.shareDialog = true
            this.photoSrc = res.data.data.url
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    goAnswer() {
      const item = this.itemData
      if (item.practices === '0' || !item.practices) {
        return this.$Toast.fail('暂无课程练习！')
      }
      this.$axios
        .get(`/tk/front/paper/${item.practices}/checkandgeneratekey?courseID=${this.id}`)
        .then((res) => {
          if (res.data.status === 1) {
            // 前往答题页面
            this.$router.push({
              path: `/allCourses/examinationpaper?id=${item.ID}&isPhoto=${item.isPhoto}&key_success=${res.data.data.key_success}`,
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    confirm() {
      this.isPlayVideo = true
    },
    initPhoto() {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          // 首先，如果有getUserMedia的话，就获得它
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
          }
          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      const constraints = {
        video: true,
        audio: false,
      }
      const that = this
      let v = document.getElementById('v')
      let promise = navigator.mediaDevices.getUserMedia(constraints)
      return new Promise((resolve) => {
        promise
          .then((stream) => {
            let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            // 旧的浏览器可能没有srcObject
            if ('srcObject' in v) {
              v.srcObject = stream
            } else {
              // 防止再新的浏览器里使用它，应为它已经不再支持了
              v.src = window.URL.createObjectURL(stream)
            }
            v.onloadedmetadata = function() {
              that.playVideoOk = true
              v.play()
              return resolve({
                status: 1,
                message: 'metadata success',
              })
            }
            if (isiOS) {
              const time = setTimeout(() => {
                clearTimeout(time)
                return resolve({
                  status: that.playVideoOk ? 1 : 0,
                  message: that.playVideoOk ? 'metadata success' : '此设备不支持通过分享的方式观看视频，请通过进入公众号的方式观看视频',
                })
              }, 600)
            } else {
              that.videoPreviewShow = true
              this.$nextTick(() => {
                let videoPreview = document.getElementById('videoPreview')
                if ('srcObject' in videoPreview) {
                  videoPreview.srcObject = stream
                } else {
                  // 防止再新的浏览器里使用它，应为它已经不再支持了
                  videoPreview.src = window.URL.createObjectURL(stream)
                }
                videoPreview.onloadedmetadata = function() {
                  that.playVideoOk = true
                  videoPreview.play()
                  return resolve(true)
                }
              })
            }
          })
          .catch((err) => {
            if (String(err).indexOf('getUserMedia is not implemented') !== -1) {
              that.playVideoOk = false
              return resolve({
                status: 0,
                message: '手机系统版本过低，无法正常使用拍照功能',
              })
            } else {
              that.playVideoOk = false
              return resolve({
                status: 0,
                message: '未开启摄像头，无法观看视频',
              })
            }
          })
      })
    },
    uploadImg() {
      if (this.itemData.isPhoto !== 'Y') {
        return
      }
      if (!this.playVideoOk) {
        return
      }
      if (this.myPlayer.paused()) {
        return
      }
      if (this.getIsUploadPhoto()) {
        return
      }
      const videoInfo = this.videoListData.find((i) => i.ID === this.lastWatchVideoID)
      if (videoInfo.remainingPhotoCount <= 0) {
        this.photoTimer = ''
        return
      }
      this.isUploadPhoto = true
      let v = document.getElementById('v')
      let canvas = document.getElementById('canvas')
      canvas.getContext('2d').drawImage(v, 0, 0, 320, 380)
      this.imgData = canvas.toDataURL('image/png')
      let params = new FormData()
      let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      params.append('courseID', this.id)
      params.append('videoID', this.lastWatchVideoID)
      params.append('imgData', this.imgData)
      this.$axios
        .post('/course/front/course/videophoto/upload', params, { headers: headers })
        .then((res) => {
          if (res.data.status === 1) {
            this.videoListData = this.videoListData.map((item) => {
              if (item.ID === this.lastWatchVideoID) {
                item.remainingPhotoCount = item.remainingPhotoCount - 1
              }
              return item
            })
            this.photoPreIntervalTime = this.photoIntervalTime
            if (!this.intervalTimer) {
              this.intervalTimer = setInterval(() => {
                this.photoIntervalTime = this.photoIntervalTime + 1
              }, 1000)
            }
            this.isUploadPhoto = false
            if (videoInfo.remainingPhotoCount > 0) {
              // 拍照计时器
              let remainTime = parseInt(this.myPlayer.getDuration()) - this.myPlayer.getCurrentTime()
              let num = parseInt(remainTime / videoInfo.remainingPhotoCount)
              let randomNum = parseInt(Number(Math.random().toFixed(1)) * num) < 5 ? 5 : parseInt(Number(Math.random().toFixed(1)) * num)
              this.photoTimer = parseInt(this.myPlayer.getCurrentTime()) + randomNum
            }
          } else {
            this.isUploadPhoto = false
            this.photoIntervalTime = 0
            this.photoPreIntervalTime = 0
          }
        })
        .catch((e) => {
          this.isUploadPhoto = false
          this.photoIntervalTime = 0
          this.photoPreIntervalTime = 0
          return e
        })
    },
    getIsUploadPhoto() {
      if (this.photoIntervalTime === 0 && !this.isUploadPhoto) {
        console.log('正在上传照片')
        return false
      }
      if (!this.isUploadPhoto && this.photoIntervalTime - this.photoPreIntervalTime >= 5) {
        console.log('间隔时间小于5秒')
        return false
      }
      return true
    },
    async tabChange(activeName) {
      if (activeName === '2' && !this.isRequestedVideo) {
        this.getVideosData()
      }
      if (activeName === '3') {
        if (!this.isRequestedHandouts) {
          await this.getHandoutsData()
        }
        if (this.isPlay) {
          const videoInfo = this.videoListData.find((item) => item.ID === this.lastWatchVideoID)
          const handoutID = videoInfo.handoutID
          const item = this.handouts.find((item) => item.ID === handoutID)
          const index = this.handouts.findIndex((item) => item.ID === handoutID)
          item && this.getHandoutsDetails(item, index)
        }
      }
    },
    // 人脸认证播放
    faceAuthRequest(isPlayerOn) {
      console.log(isPlayerOn)
      // window.location.href = 'https://test.lcjykg.com/course/front/permit/test/test3'
      // 获取用户结果
      let requestPath = `/course/front/member/face/auth?type=Course&id=${this.id}&videoID=${this.lastWatchVideoID}&backUrl=${encodeURIComponent(this.backUrl)}`
      if (window.location.href.indexOf('isShare') > -1) {
        requestPath = requestPath + '&isShare=Y'
      }
      this.$axios
        .get(requestPath)
        .then((res) => {
          if (res.data.status === 1) {
            if (!res.data.data.bizToken && res.data.data.isSuccess === 1) {
              this.$Toast.success('已认证成功')
              return
              // if (!isPlayerOn) {
              //   this.playVideoClick()
              // }
            } else {
              window.location.href = res.data.data.url
            }
          } else {
            this.$Toast.fail(res.data.message)
            return
          }
        })
        .catch(() => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return
        })
    },
    faceAuthIsSuccess(openWindow) {
      // 获取用户结果
      this.$axios
        .get(`/course/front/member/face/auth/issuccess?type=Course&id=${this.id}&videoID=${this.lastWatchVideoID}`)
        .then((res) => {
          if (res.data.status === 1) {
            if (res.data.data.isSuccess !== 0) {
              openWindow.close()
              clearInterval(this.windowInterval)
              this.windowInterval = null
            }
          } else {
            this.$Toast.fail(res.data.message)
            return
          }
        })
        .catch(() => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return
        })
    },
    async playBtnClickFaceAuth(isPlayerOn) {
      if (this.itemData.hasPermission !== 'Y' && this.itemData.freeLength === 0) {
        return this.$Toast.fail('没有课程权限')
      }
      if (this.videoListData.length === 0) {
        return this.$Toast.fail('没有视频资源')
      }
      for (let i = 0; i < this.videoListData.length; i++) {
        if (this.videoListData[i].assertID) {
          this.allNotPlayflag = false
          break
        }
      }
      if (this.itemData.hasPermission === 'Y') {
        if (this.itemData.maxLearningTimes !== 0 && this.itemData.hasStudyTimes >= this.itemData.maxLearningTimes) {
          return this.$Toast.fail('超过学习次数，无法观看视频')
        }
        if (this.allNotPlayflag) {
          return this.$Toast.fail('视频正在推送')
        }
        if (this.itemData.isPhoto === 'Y' && this.itemData.hasPermission === 'Y') {
          let res = await this.initPhoto()
          if (res.status === 0) {
            return this.$Toast.fail(res.message)
          }
        }
        if (!this.playVideoOk) {
          return
        }
        if (this.itemData.isIdCardAuth === 1 && this.itemData.isFaceAuth === 0) {
          let info = JSON.parse(localStorage.userInfo)
          if (info.isChecked !== 1) {
            Dialog.confirm({
              title: '身份认证提示',
              message: '未进行身份认证，请先去身份认证',
            })
              .then(() => {
                this.$router.push({ path: `/user/userinfo/info` })
              })
              .catch(() => {
                return
              })
          } else {
            if (!isPlayerOn) {
              await this.playVideoClick()
            }
          }
        } else if (this.itemData.isIdCardAuth === 1 && this.itemData.isFaceAuth === 1) {
          let info = JSON.parse(localStorage.userInfo)
          if (info.isChecked !== 1) {
            Dialog.confirm({
              title: '身份认证提示',
              message: '未进行身份认证，请先去身份认证',
            })
              .then(() => {
                this.$router.push({ path: `/user/userinfo/info` })
              })
              .catch(() => {
                return
              })
          } else {
            this.$axios
              .get(`/course/front/member/face/auth/issuccess?type=Course&id=${this.id}&videoID=${this.lastWatchVideoID}`)
              .then((res) => {
                if (res.data.status === 1) {
                  if (res.data.data.isSuccess !== 1) {
                    Dialog.confirm({
                      title: '温馨提示',
                      message: '即将进入人脸认证，是否继续？',
                    })
                      .then(() => {
                        this.faceAuthRequest(isPlayerOn)
                      })
                      .catch(() => {
                        return
                      })
                  } else {
                    if (!isPlayerOn) {
                      this.playVideoClick()
                    }
                  }
                } else {
                  this.$Toast.fail(res.data.message)
                  return
                }
              })
              .catch(() => {
                this.$Toast.fail('服务器开小差了，稍后重试')
                return
              })
          }
        }
      } else if (this.itemData.freeLength !== 0) {
        // 试看
        let html = '<div id="player-con"><div class="center"></div></div>'
        this.videoHtml = html
        this.$nextTick(() => {
          this.getVideo(null, true, true)
          this.isPlay = true
        })
      } else {
        this.$Toast.fail('没有课程权限')
      }
    },
    async playBtnClick() {
      if ((this.itemData.isIdCardAuth === 1 && this.itemData.isFaceAuth === 1) || (this.itemData.isIdCardAuth === 1 && this.itemData.isFaceAuth === 0)) {
        this.playBtnClickFaceAuth(false)
      } else {
        this.playVideoClick()
      }
    },
    // 自定义播放按钮点击触发
    async playVideoClick() {
      if (this.itemData.hasPermission !== 'Y' && this.itemData.freeLength === 0) {
        return this.$Toast.fail('没有课程权限')
      }
      // if (this.itemData.hasPermission !== 'Y' && ![1, 2].includes(Number(this.itemData.editStatus))) {
      //   return this.$Toast.fail('没有课程权限')
      // }
      if (this.videoListData.length === 0) {
        return this.$Toast.fail('没有视频资源')
      }
      for (let i = 0; i < this.videoListData.length; i++) {
        if (this.videoListData[i].assertID) {
          this.allNotPlayflag = false
          break
        }
      }
      if (this.itemData.hasPermission === 'Y') {
        if (this.itemData.maxLearningTimes !== 0 && this.itemData.hasStudyTimes >= this.itemData.maxLearningTimes) {
          return this.$Toast.fail('超过学习次数，无法观看视频')
        }
        if (this.allNotPlayflag) {
          return this.$Toast.fail('视频正在推送')
        }
        if (this.itemData.isPhoto === 'Y' && this.itemData.hasPermission === 'Y') {
          let res = await this.initPhoto()
          if (res.status === 0) {
            return this.$Toast.fail(res.message)
          }
        }
        if (!this.playVideoOk) {
          return
        }
        // 点完预览弹窗确定按钮后才播放视频
        let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isiOS) {
          let html = '<div id="player-con"><div class="center"></div></div>'
          this.videoHtml = html
          this.$nextTick(() => {
            this.getVideo(null, true)
            this.isPlay = true
          })
        } else {
          const timer = setInterval(() => {
            if (this.isPlayVideo) {
              let html = '<div id="player-con"><div class="center"></div></div>'
              this.videoHtml = html
              this.$nextTick(() => {
                this.getVideo(null, true)
                this.isPlay = true
              })
              clearInterval(timer)
            }
          }, 100)
        }
      } else if (this.itemData.freeLength !== 0) {
        // 试看
        let html = '<div id="player-con"><div class="center"></div></div>'
        this.videoHtml = html
        this.$nextTick(() => {
          this.getVideo(null, true, true)
          this.isPlay = true
        })
      } else {
        this.$Toast.fail('没有课程权限')
      }
    },
    async getVideo(id, autoPlay) {
      if (this.isLoadVideo) {
        return this.$Toast.fail('视频正在加载中，请勿频繁操作...')
      }
      const that = this
      this.isChangeVideo = id ? true : false
      let flag = false
      if (id !== this.lastWatchVideoID) {
        clearInterval(this.timer2)
      } else {
        return
      }
      clearInterval(this.danmukuListTimer)
      this.danmukuListTimer = null
      clearInterval(this.intervalTimer)
      this.intervalTimer = null
      this.photoIntervalTime = 0
      this.photoPreIntervalTime = 0
      if (this.uploadMap[this.lastWatchVideoID]) {
        this.$Toast.loading({
          message: '进度上传中...',
          forbidClick: true,
          duration: 0,
        })
        await this.postVideoschedule(this.lastWatchVideoID, true, null)
        that.myPlayer && that.myPlayer.dispose()
        that.myPlayer = null
        that.$nextTick(() => {
          that.$Toast.clear()
        })
      } else {
        this.myPlayer && this.myPlayer.dispose()
        that.myPlayer = null
      }
      this.lastWatchVideoID = id ? id : this.lastWatchVideoID
      if (this.itemData.hasPermission === 'Y') {
        if (this.videoListData.length === 0) {
          return this.$Toast.fail('该课程没有视频')
        }
        let currentVideo = this.videoListData.find((i) => i.ID === this.lastWatchVideoID)
        if (!currentVideo) {
          this.lastWatchVideoID = this.videoListData[0].ID
        } else if (!currentVideo.assertID) {
          return this.$Toast.fail('视频正在推送')
        }
        if (!this.playVideoOk) {
          if (id) {
            return
          }
          return this.$Toast.fail('未开启摄像头，无法观看视频')
        }
        if (!this.videoHtml) {
          let html = '<div id="player-con"><div class="center"></div></div>'
          this.videoHtml = html
        }
        if (this.itemData.maxLearningTimes !== 0 && this.itemData.hasStudyTimes >= this.itemData.maxLearningTimes) {
          this.videoHtml = ''
          this.$Toast.fail('超过学习次数，无法观看视频')
          return
        }
        that.isLoadVideo = true
        this.lastWatchVideoID = this.lastWatchVideoID === 0 ? this.videoListData[0].ID : this.lastWatchVideoID
        const videoInfo = this.videoListData.find((item) => item.ID === this.lastWatchVideoID) // TODO
        this.videoInfo = videoInfo
        const cover = videoInfo.cover
        const assertID = videoInfo.assertID
        const lastPosition = videoInfo.lastPosition
        const maxPosition = videoInfo.maxPosition
        const fastForward = maxPosition !== 0 && maxPosition === videoInfo.length
        if (videoInfo.length <= 0) {
          return this.$Toast.fail('视频转码中。。。')
        }
        let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        // 请求接口获取播放地址参数为courseID-课程ID，assertID-媒资ID
        this.$axios
          .get(`/course/front/permit/course/assertplayurl?courseID=${this.id}&assertID=${assertID}`)
          .then((res) => {
            if (res.data.status === 1) {
              that.danmukuList[0].stime = lastPosition * 1000 + 1000
              let components = [
                {
                  name: 'AliplayerDanmuComponent',
                  type: window.AliPlayerComponent.AliplayerDanmuComponent,
                  args: [[]],
                },
              ]
              if (!fastForward) {
                if (this.isSpeedPlayback) {
                  components.push({
                    name: 'RateComponent',
                    type: window.AliPlayerComponent.RateComponent,
                  })
                }
              } else {
                components.push({
                  name: 'RateComponent',
                  type: window.AliPlayerComponent.RateComponent,
                })
              }
              this.$nextTick(() => {
                new window.Aliplayer(
                  {
                    id: 'player-con',
                    source: res.data.message,
                    width: '100%',
                    height: '5.65rem',
                    autoplay: false,
                    isLive: false,
                    controlBarVisibility: 'click',
                    showBarTime: '10000',
                    vodRetry: 0, // 视频播放错误重试次数
                    cover: cover || that.itemData.logoFile,
                    components: components,
                    x5_type: '',
                  },
                  function(player) {
                    that.isLoadVideo = false
                    that.isPlay = true
                    that.myPlayer = player
                    if (autoPlay) {
                      that.$nextTick(() => {
                        that.wxReadyByIosOrAndroidVideoAutoPlayByFaceAuth(player, isiOS)
                      })
                    }
                    document.getElementsByClassName('ali-danmuku-control')[0] && (document.getElementsByClassName('ali-danmuku-control')[0].style.display = 'none')
                    document.getElementsByClassName('prism-setting-btn')[0] && (document.getElementsByClassName('prism-setting-btn')[0].style.display = 'none')
                    document.getElementsByClassName('prism-cc-btn')[0] && (document.getElementsByClassName('prism-cc-btn')[0].style.display = 'none')
                    document.getElementsByClassName('prism-volume')[0] && (document.getElementsByClassName('prism-volume')[0].style.display = 'none')
                    if (!fastForward) {
                      if (isiOS && that.itemData.isFastForward === 'N') {
                        document.getElementsByClassName('prism-fullscreen-btn')[0] && (document.getElementsByClassName('prism-fullscreen-btn')[0].style.display = 'none')
                      }
                    }
                    const AliplayerDanmuComponent = player._lifeCycleManager.getComponent('AliplayerDanmuComponent')
                    var seeked = false
                    player.on('canplaythrough', function() {
                      if (!seeked) {
                        seeked = true
                        player.seek(lastPosition === videoInfo.length ? 0 : lastPosition)
                      }
                    })
                    player.on('play', () => {
                      if (that.itemData.isIdCardAuth === 1 && that.itemData.isFaceAuth === 0) {
                        let info = JSON.parse(localStorage.userInfo)
                        if (info.isChecked !== 1) {
                          player.pause()
                          Dialog.confirm({
                            title: '身份认证提示',
                            message: '未进行身份认证，请先去身份认证',
                          })
                            .then(() => {
                              that.$router.push({ path: `/user/userinfo/info` })
                            })
                            .catch(() => {
                              return
                            })
                        } else {
                          that.isPlay = true
                          document.getElementsByClassName('prism-progress prism-progress-hover')[0] &&
                            (document.getElementsByClassName('prism-progress prism-progress-hover')[0].style.pointerEvents = 'auto')
                          if (!that.danmukuListTimer) {
                            that.danmukuListTimer = setInterval(() => {
                              that.removeAliplayerDanmuComponent(player)
                              that.danmukuList[0] && AliplayerDanmuComponent.CM.send(that.danmukuList[0])
                              // 弹幕位置靠下一点
                              // AliplayerDanmuComponent.CM.runline[0].y = 40
                            }, 1 * 60 * 1000)
                          }
                          if (!flag) {
                            const formData = new FormData()
                            formData.append('courseID', that.id)
                            formData.append('videoID', that.lastWatchVideoID)
                            that.$axios.post('/course/front/course/studytimes', formData).then((res) => {
                              if (res.data.status === 1) {
                                that.itemData.hasStudyTimes = res.data.data
                              } else {
                                that.isNotMaxCount = false
                                that.$Toast.fail(res.data.message)
                              }
                            })
                            const totalTime = parseInt(player.getDuration() || videoInfo.length)
                            // 拍照计时器
                            let remainTime = totalTime - (player.getCurrentTime() || (videoInfo.length === videoInfo.lastPosition ? 0 : videoInfo.lastPosition))
                            let num = parseInt(remainTime / videoInfo.remainingPhotoCount)
                            let randomNum = parseInt(Number(Math.random().toFixed(1)) * num) < 5 ? 5 : parseInt(Number(Math.random().toFixed(1)) * num)
                            if (that.itemData.isPhoto === 'Y') {
                              that.photoTimer = parseInt(player.getCurrentTime() || (videoInfo.length === videoInfo.lastPosition ? 0 : videoInfo.lastPosition)) + randomNum
                            }
                            let spaceTime = 30
                            if (totalTime < 30) {
                              spaceTime = totalTime % 30
                            }
                            that.uploadMap[that.lastWatchVideoID] = that.uploadMap[that.lastWatchVideoID] ? that.uploadMap[that.lastWatchVideoID] : {}
                            that.uploadMap[that.lastWatchVideoID].prePostion = 0
                            that.uploadMap[that.lastWatchVideoID].spaceTime = 1
                            that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID)
                            that.timer2 = setInterval(() => {
                              that.uploadMap[that.lastWatchVideoID] && (that.uploadMap[that.lastWatchVideoID].spaceTime += 1)
                            }, 1000)
                            flag = true
                          } else {
                            if (!that.timer2) {
                              let spaceTime = 30
                              const totalTime = parseInt(player.getDuration())
                              if (totalTime < 30) {
                                spaceTime = totalTime % 30
                              }
                              that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID)
                              that.timer2 = setInterval(() => {
                                that.uploadMap[that.lastWatchVideoID].spaceTime += 1
                              }, 1000)
                            }
                          }
                          if (!that.intervalTimer) {
                            that.intervalTimer = setInterval(() => {
                              that.photoIntervalTime = that.photoIntervalTime + 1
                            }, 1000)
                          }
                        }
                      } else if (that.itemData.isIdCardAuth === 1 && that.itemData.isFaceAuth === 1) {
                        let info = JSON.parse(localStorage.userInfo)
                        if (info.isChecked !== 1) {
                          player.pause()
                          Dialog.confirm({
                            title: '身份认证提示',
                            message: '未进行身份认证，请先去身份认证',
                          })
                            .then(() => {
                              that.$router.push({ path: `/user/userinfo/info` })
                            })
                            .catch(() => {
                              return
                            })
                        } else {
                          that.$axios
                            .get(`/course/front/member/face/auth/issuccess?type=Course&id=${that.id}&videoID=${that.lastWatchVideoID}`)
                            .then((res) => {
                              if (res.data.status === 1) {
                                if (res.data.data.isSuccess !== 1) {
                                  player.pause()
                                  Dialog.confirm({
                                    title: '温馨提示',
                                    message: '即将进入人脸认证，是否继续？',
                                  })
                                    .then(() => {
                                      that.faceAuthRequest(true)
                                    })
                                    .catch(() => {
                                      return
                                    })
                                } else {
                                  that.isPlay = true
                                  document.getElementsByClassName('prism-progress prism-progress-hover')[0] &&
                                    (document.getElementsByClassName('prism-progress prism-progress-hover')[0].style.pointerEvents = 'auto')
                                  if (!that.danmukuListTimer) {
                                    that.danmukuListTimer = setInterval(() => {
                                      that.removeAliplayerDanmuComponent(player)
                                      that.danmukuList[0] && AliplayerDanmuComponent.CM.send(that.danmukuList[0])
                                      // 弹幕位置靠下一点
                                      // AliplayerDanmuComponent.CM.runline[0].y = 40
                                    }, 1 * 60 * 1000)
                                  }
                                  if (!flag) {
                                    const formData = new FormData()
                                    formData.append('courseID', that.id)
                                    formData.append('videoID', that.lastWatchVideoID)
                                    that.$axios.post('/course/front/course/studytimes', formData).then((res) => {
                                      if (res.data.status === 1) {
                                        that.itemData.hasStudyTimes = res.data.data
                                      } else {
                                        that.isNotMaxCount = false
                                        that.$Toast.fail(res.data.message)
                                      }
                                    })
                                    const totalTime = parseInt(player.getDuration() || videoInfo.length)
                                    // 拍照计时器
                                    let remainTime = totalTime - (player.getCurrentTime() || (videoInfo.length === videoInfo.lastPosition ? 0 : videoInfo.lastPosition))
                                    let num = parseInt(remainTime / videoInfo.remainingPhotoCount)
                                    let randomNum = parseInt(Number(Math.random().toFixed(1)) * num) < 5 ? 5 : parseInt(Number(Math.random().toFixed(1)) * num)
                                    if (that.itemData.isPhoto === 'Y') {
                                      that.photoTimer = parseInt(player.getCurrentTime() || (videoInfo.length === videoInfo.lastPosition ? 0 : videoInfo.lastPosition)) + randomNum
                                    }
                                    let spaceTime = 30
                                    if (totalTime < 30) {
                                      spaceTime = totalTime % 30
                                    }
                                    that.uploadMap[that.lastWatchVideoID] = that.uploadMap[that.lastWatchVideoID] ? that.uploadMap[that.lastWatchVideoID] : {}
                                    that.uploadMap[that.lastWatchVideoID].prePostion = 0
                                    that.uploadMap[that.lastWatchVideoID].spaceTime = 1
                                    that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID)
                                    that.timer2 = setInterval(() => {
                                      that.uploadMap[that.lastWatchVideoID] && (that.uploadMap[that.lastWatchVideoID].spaceTime += 1)
                                    }, 1000)
                                    flag = true
                                  } else {
                                    if (!that.timer2) {
                                      let spaceTime = 30
                                      const totalTime = parseInt(player.getDuration())
                                      if (totalTime < 30) {
                                        spaceTime = totalTime % 30
                                      }
                                      that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID)
                                      that.timer2 = setInterval(() => {
                                        that.uploadMap[that.lastWatchVideoID].spaceTime += 1
                                      }, 1000)
                                    }
                                  }
                                  if (!that.intervalTimer) {
                                    that.intervalTimer = setInterval(() => {
                                      that.photoIntervalTime = that.photoIntervalTime + 1
                                    }, 1000)
                                  }
                                }
                              } else {
                                player.pause()
                                that.$Toast.fail(res.data.message)
                                return
                              }
                            })
                            .catch(() => {
                              player.pause()
                              that.$Toast.fail('服务器开小差了，稍后重试')
                              return
                            })
                        }
                      } else {
                        that.isPlay = true
                        document.getElementsByClassName('prism-progress prism-progress-hover')[0] &&
                          (document.getElementsByClassName('prism-progress prism-progress-hover')[0].style.pointerEvents = 'auto')
                        if (!that.danmukuListTimer) {
                          that.danmukuListTimer = setInterval(() => {
                            that.removeAliplayerDanmuComponent(player)
                            that.danmukuList[0] && AliplayerDanmuComponent.CM.send(that.danmukuList[0])
                            // 弹幕位置靠下一点
                            // AliplayerDanmuComponent.CM.runline[0].y = 40
                          }, 1 * 60 * 1000)
                        }
                        if (!flag) {
                          const formData = new FormData()
                          formData.append('courseID', that.id)
                          formData.append('videoID', that.lastWatchVideoID)
                          that.$axios.post('/course/front/course/studytimes', formData).then((res) => {
                            if (res.data.status === 1) {
                              that.itemData.hasStudyTimes = res.data.data
                            } else {
                              that.isNotMaxCount = false
                              that.$Toast.fail(res.data.message)
                            }
                          })
                          const totalTime = parseInt(player.getDuration() || videoInfo.length)
                          // 拍照计时器
                          let remainTime = totalTime - (player.getCurrentTime() || (videoInfo.length === videoInfo.lastPosition ? 0 : videoInfo.lastPosition))
                          let num = parseInt(remainTime / videoInfo.remainingPhotoCount)
                          let randomNum = parseInt(Number(Math.random().toFixed(1)) * num) < 5 ? 5 : parseInt(Number(Math.random().toFixed(1)) * num)
                          if (that.itemData.isPhoto === 'Y') {
                            that.photoTimer = parseInt(player.getCurrentTime() || (videoInfo.length === videoInfo.lastPosition ? 0 : videoInfo.lastPosition)) + randomNum
                          }
                          let spaceTime = 30
                          if (totalTime < 30) {
                            spaceTime = totalTime % 30
                          }
                          that.uploadMap[that.lastWatchVideoID] = that.uploadMap[that.lastWatchVideoID] ? that.uploadMap[that.lastWatchVideoID] : {}
                          that.uploadMap[that.lastWatchVideoID].prePostion = 0
                          that.uploadMap[that.lastWatchVideoID].spaceTime = 1
                          that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID)
                          that.timer2 = setInterval(() => {
                            that.uploadMap[that.lastWatchVideoID] && (that.uploadMap[that.lastWatchVideoID].spaceTime += 1)
                          }, 1000)
                          flag = true
                        } else {
                          if (!that.timer2) {
                            let spaceTime = 30
                            const totalTime = parseInt(player.getDuration())
                            if (totalTime < 30) {
                              spaceTime = totalTime % 30
                            }
                            that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID)
                            that.timer2 = setInterval(() => {
                              that.uploadMap[that.lastWatchVideoID].spaceTime += 1
                            }, 1000)
                          }
                        }
                        if (!that.intervalTimer) {
                          that.intervalTimer = setInterval(() => {
                            that.photoIntervalTime = that.photoIntervalTime + 1
                          }, 1000)
                        }
                      }
                      // 这个暂停播放页会触发，处理下暂停1分钟播放会发2条弹幕的情况
                      if (!that.sendDanmuku) {
                        setTimeout(() => {
                          that.sendDanmuku = true
                          AliplayerDanmuComponent.CM.send(that.danmukuList[0])
                        }, 1000)
                      }
                    })
                    player.on('ended', async () => {
                      try {
                        AliplayerDanmuComponent.CM.clear()
                        clearInterval(that.danmukuListTimer)
                        that.danmukuListTimer = null
                        flag = false
                        clearInterval(that.timer2)
                        that.timer2 = null
                        clearInterval(that.timer)
                        that.timer = null
                        await that.postVideoschedule(that.lastWatchVideoID)
                        let exeFlag = true
                        for (let i = 0; i < that.videoListData.length; i++) {
                          if (that.videoListData[i].percent !== '100%' && that.lastWatchVideoID !== that.videoListData[i].ID) {
                            exeFlag = false
                          }
                        }
                        if (that.itemData.practices !== '0' && that.itemData.isDoneExerc === 'N' && exeFlag) {
                          that.popupShow = true
                        }
                      } catch (e) {
                        alert('错误：' + e)
                      }
                    })
                    player.on('pause', () => {
                      document.getElementsByClassName('prism-progress prism-progress-hover')[0] &&
                        (document.getElementsByClassName('prism-progress prism-progress-hover')[0].style.pointerEvents = 'none')
                      clearInterval(that.timer2)
                      that.timer2 = null
                      clearInterval(that.timer)
                      that.timer = null
                      clearInterval(that.intervalTimer)
                      that.intervalTimer = null
                      if (Math.ceil(player.getCurrentTime()) !== Math.ceil(player.getDuration())) {
                        that.postVideoschedule(that.lastWatchVideoID)
                      }
                    })
                    player.on('error', function() {
                      that.playError(assertID)
                    })
                    player.on('timeupdate', function() {
                      if (this.getCurrentTime() > that.videoInfo.maxPosition) {
                        that.videoInfo.maxPosition = parseInt(this.getCurrentTime())
                      }
                      if (that.photoTimer && this.getCurrentTime() >= that.photoTimer) {
                        that.uploadImg()
                      }
                    })
                    if (!that.isInit) {
                      that.initVideoSpeed()
                      that.initProgressMove()
                      that.isInit = true
                    }
                  }
                )
              })
            } else {
              that.isLoadVideo = false
              this.$Toast.fail(res.data.message)
            }
          })
          .catch((e) => {
            that.isLoadVideo = false
            this.$Toast.fail('服务器开小差了，稍后重试' + e)
          })
      } else if (this.itemData.freeLength !== 0) {
        if (this.videoListData.length === 0) {
          return this.$Toast.fail('该课程没有视频')
        }
        let currentVideo = this.videoListData.find((i) => i.ID === this.lastWatchVideoID)
        if (!currentVideo) {
          this.lastWatchVideoID = this.videoListData[0].ID
        } else if (!currentVideo.assertID) {
          return this.$Toast.fail('视频正在推送')
        }
        if (!this.videoHtml) {
          let html = '<div id="player-con"><div class="center"></div></div>'
          this.videoHtml = html
        }
        that.isLoadVideo = true
        this.lastWatchVideoID = this.lastWatchVideoID === 0 ? this.videoListData[0].ID : this.lastWatchVideoID
        const videoInfo = this.videoListData.find((item) => item.ID === this.lastWatchVideoID)
        const assertID = videoInfo.assertID
        const lastPosition = videoInfo.lastPosition
        const isForbid = this.itemData.isForbid // 禁止购买
        // 请求接口获取播放地址参数为courseID-课程ID，assertID-媒资ID
        this.$axios
          .get(`/course/front/permit/course/assertplayurl?courseID=${this.id}&assertID=${assertID}`)
          .then((res) => {
            if (res.data.status === 1) {
              let components = [
                {
                  name: 'AliplayerDanmuComponent',
                  type: window.AliPlayerComponent.AliplayerDanmuComponent,
                  args: [[]],
                },
                {
                  name: 'PreviewVodComponent',
                  type: window.AliPlayerComponent.PreviewVodComponent,
                  args: [that.itemData.freeLength, null, Number(isForbid) ? `联系客服授权观看完整视频` : `<a class="vip-join">购买</a> 后观看完整视频`],
                },
              ]
              if (this.isSpeedPlayback) {
                components.push({
                  name: 'RateComponent',
                  type: window.AliPlayerComponent.RateComponent,
                })
              }
              this.$nextTick(() => {
                new window.Aliplayer(
                  {
                    id: 'player-con',
                    source: res.data.message,
                    width: '100%',
                    height: '5.65rem',
                    autoplay: false,
                    isLive: false,
                    controlBarVisibility: 'click',
                    showBarTime: '10000',
                    components: components,
                    x5_type: '',
                  },
                  function(player) {
                    that.isLoadVideo = false
                    that.isPlay = true
                    const goBuy = document.getElementsByClassName('vip-join')[0]
                    goBuy.removeEventListener('click', that.goBuy)
                    goBuy.addEventListener('click', that.goBuy)
                    that.myPlayer = player
                    if (autoPlay) {
                      that.$nextTick(() => {
                        if (isiOS) {
                          that.wxReadyByIosVideoAutoPlay(player)
                        } else {
                          player.play()
                        }
                      })
                    }
                    document.getElementsByClassName('ali-danmuku-control')[0] && (document.getElementsByClassName('ali-danmuku-control')[0].style.display = 'none')
                    document.getElementsByClassName('prism-setting-btn')[0] && (document.getElementsByClassName('prism-setting-btn')[0].style.display = 'none')
                    document.getElementsByClassName('prism-cc-btn')[0] && (document.getElementsByClassName('prism-cc-btn')[0].style.display = 'none')
                    document.getElementsByClassName('prism-volume')[0] && (document.getElementsByClassName('prism-volume')[0].style.pointerEvents = 'none')
                    const AliplayerDanmuComponent = player._lifeCycleManager.getComponent('AliplayerDanmuComponent')
                    var seeked = false
                    let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
                    // ios&&不允许拖拽进度条，隐藏全屏按钮
                    if (isiOS && that.itemData.isFastForward === 'N') {
                      document.getElementsByClassName('prism-fullscreen-btn')[0] && (document.getElementsByClassName('prism-fullscreen-btn')[0].style.display = 'none')
                    }
                    player.on('canplaythrough', function() {
                      if (!seeked) {
                        seeked = true
                        player.seek(lastPosition >= that.itemData.freeLength || lastPosition >= videoInfo.length ? 0 : lastPosition)
                      }
                    })
                    player.on('play', () => {
                      that.isPlay = true
                      document.getElementsByClassName('prism-progress prism-progress-hover')[0] &&
                        (document.getElementsByClassName('prism-progress prism-progress-hover')[0].style.pointerEvents = 'auto')
                      if (!that.danmukuListTimer) {
                        that.danmukuListTimer = setInterval(() => {
                          that.danmukuList[0] && AliplayerDanmuComponent.CM.send(that.danmukuList[0])
                          if (AliplayerDanmuComponent.CM.runline.length > 1) {
                            AliplayerDanmuComponent.CM.runline.splice(1, AliplayerDanmuComponent.CM.runline.length)
                          }
                        }, 1 * 60 * 1000)
                      }
                      if (!flag) {
                        const totalTime = parseInt(that.itemData.freeLength)
                        let spaceTime = 30
                        if (totalTime < 30) {
                          spaceTime = totalTime % 30
                        }
                        that.uploadMap[that.lastWatchVideoID] = that.uploadMap[that.lastWatchVideoID] ? that.uploadMap[that.lastWatchVideoID] : {}
                        that.uploadMap[that.lastWatchVideoID].prePostion = 0
                        that.uploadMap[that.lastWatchVideoID].spaceTime = 1
                        that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID, true)
                        that.timer2 = setInterval(() => {
                          that.uploadMap[that.lastWatchVideoID] && (that.uploadMap[that.lastWatchVideoID].spaceTime += 1)
                        }, 1000)
                        flag = true
                      } else {
                        if (!that.timer2) {
                          let spaceTime = 30
                          const totalTime = parseInt(that.itemData.freeLength)
                          if (totalTime < 30) {
                            spaceTime = totalTime % 30
                          }
                          that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID, true)
                          that.timer2 = setInterval(() => {
                            that.uploadMap[that.lastWatchVideoID].spaceTime += 1
                          }, 1000)
                        }
                      }
                      // 这个暂停播放页会触发，处理下暂停1分钟播放会发2条弹幕的情况
                      if (!that.sendDanmuku) {
                        setTimeout(() => {
                          that.sendDanmuku = true
                          AliplayerDanmuComponent.CM.send(that.danmukuList[0])
                        }, 1000)
                      }
                    })
                    player.on('ended', () => {
                      AliplayerDanmuComponent.CM.clear()
                      clearInterval(that.danmukuListTimer)
                      flag = false
                      clearInterval(that.timer2)
                      that.timer2 = null
                      clearInterval(that.timer)
                      that.timer = null
                      // player.pause()
                      that.postVideoschedule(that.lastWatchVideoID, null, true)
                    })
                    player.on('pause', () => {
                      clearInterval(that.timer2)
                      that.timer2 = null
                      clearInterval(that.timer)
                      that.timer = null
                      if (Math.ceil(player.getCurrentTime()) !== Math.ceil(player.getDuration())) {
                        that.postVideoschedule(that.lastWatchVideoID, null, true)
                      }
                    })
                    player.on('error', function() {
                      that.playError(assertID)
                    })
                    let videoData = {
                      maxPosition: videoInfo.maxPosition > that.itemData.freeLength ? that.itemData.freeLength : videoInfo.maxPosition,
                    }
                    that.videoInfo = videoData
                    player.on('timeupdate', function() {
                      if (this.getCurrentTime() > videoData.maxPosition) {
                        videoData.maxPosition = parseInt(this.getCurrentTime())
                      }
                    })
                    if (!that.isInit) {
                      that.initVideoSpeed()
                      that.initProgressMove()
                      that.isInit = true
                    }
                  }
                )
              })
            } else {
              that.isLoadVideo = false
              this.$Toast.fail(res.data.message)
            }
          })
          .catch((e) => {
            that.isLoadVideo = false
            this.$Toast.fail('服务器开小差了，稍后重试' + e)
          })
      } else {
        that.isLoadVideo = false
        this.$Toast.fail('没有课程权限')
      }
    },
    // 倍速播放初始化
    initVideoSpeed() {
      let that = this
      const playerSetSpeed = that.myPlayer.setSpeed
      that.myPlayer.setSpeed = function() {
        let arg = arguments
        that.postVideoschedule(that.lastWatchVideoID, null, that.itemData.freeLength !== 0 && that.itemData.hasPermission !== 'Y', function() {
          that.speed = Number(arg[0])
        })
        playerSetSpeed.apply(that.myPlayer, arguments)
      }
    },
    // 快进快退初始化
    initProgressMove() {
      const that = this
      let time = 0
      that.fromTime = 0
      const playerGetSeconds = that.myPlayer.UI.progress.prototype._getSeconds
      that.myPlayer.UI.progress.prototype._onMouseClick = function() {}
      const playerProgressMove = that.myPlayer.UI.progress.prototype._progressMove
      that.myPlayer.UI.progress.prototype._getSeconds = function() {
        time = playerGetSeconds.apply(that.myPlayer._children[12]._children[0], arguments)
        that.fromTime = time
        // 快进时拖拽不能超过看课最大位置
        if (parseInt(time) > that.videoInfo.maxPosition && !that.isFastForward) {
          time = that.videoInfo.maxPosition
        }
        return time
      }
      that.myPlayer.UI.progress.prototype._progressMove = function() {
        playerProgressMove.apply(that.myPlayer._children[12]._children[0], arguments)
        let progressDown = this._progressDown
        Object.defineProperty(this, '_progressDown', {
          get: function() {
            return progressDown
          },
          set: function(value) {
            progressDown = value
            if (value === false) {
              that.sendPosition()
            }
          },
        })
      }
    },
    sendPosition() {
      let that = this
      if (that.fromTime > that.videoInfo.maxPosition && !that.isFastForward) {
        const AliplayerDanmuComponent = that.myPlayer._lifeCycleManager.getComponent('AliplayerDanmuComponent')
        this.removeDragAliplayerDanmuComponent(that.myPlayer)
        AliplayerDanmuComponent.CM.send({
          mode: 4,
          text: `只允许拖拽到${that.formatSeconds(that.videoInfo.maxPosition)}`,
          stime: 1 * 1000,
          size: 16,
          color: 0x1989fa,
        })
        setTimeout(() => {
          let index = AliplayerDanmuComponent.CM.runline.findIndex((item) => item.text.indexOf('只允许拖拽到') > -1)
          if (index > -1) {
            AliplayerDanmuComponent.CM.runline[index].y = 50
          }
        }, 5)
      }
      this.postVideoschedule(this.lastWatchVideoID, null, this.itemData.freeLength !== 0 && this.itemData.hasPermission !== 'Y', null, 1)
    },
    removeAliplayerDanmuComponent(player) {
      const danmu = player._lifeCycleManager.getComponent('AliplayerDanmuComponent')
      let count = 0
      for (let i = 0; i < danmu.CM.runline.length; i++) {
        if (danmu.CM.runline[i].text.indexOf('只允许拖拽到') === -1) {
          count += 1
        }
      }
      if (count < 1) {
        return
      }
      let flag = true
      while (flag) {
        let index = danmu.CM.runline.findIndex((item) => item.text.indexOf('只允许拖拽到') === -1)
        if (index === -1) {
          flag = false
        } else {
          danmu.CM.runline[index].dom.remove()
          danmu.CM.runline.splice(index, 1)
        }
      }
    },
    removeDragAliplayerDanmuComponent(player) {
      const danmu = player._lifeCycleManager.getComponent('AliplayerDanmuComponent')
      let flag = true
      while (flag) {
        let index = danmu.CM.runline.findIndex((item) => item.text.indexOf('只允许拖拽到') > -1)
        if (index === -1) {
          flag = false
        } else {
          danmu.CM.runline[index].dom.remove()
          danmu.CM.runline.splice(index, 1)
        }
      }
    },
    formatSeconds(value) {
      let result = parseInt(value) || 0
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      let m = Math.floor((result / 60) % 60) < 10 ? '0' + Math.floor((result / 60) % 60) : Math.floor((result / 60) % 60)
      let s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60)
      let res = `${s}秒`
      if (result >= 3600) {
        return `${h}时${m}分${s}秒`
      } else if (result >= 60) {
        return `${m}分${s}秒`
      }
      return res
    },
    goBuy() {
      this.gotoPage(`/allCourses/order/${this.itemData.ID}?couponId=${this.$route.query.couponId || ''}`)
    },
    playError(assertID) {
      // 去除一些不需要展现的错误信息
      if (document.getElementById('tipsP')) {
        document.getElementsByClassName('errorCode')[0] && (document.getElementsByClassName('errorCode')[0].style.display = 'none')
        document.getElementsByClassName('uuid')[0] && (document.getElementsByClassName('uuid')[0].style.display = 'none')
        document.getElementsByClassName('requestId')[0] && (document.getElementsByClassName('requestId')[0].style.display = 'none')
        return
      }
      const content = document.getElementsByClassName('prism-detect-info')[0]
      const tipsP = document.createElement('p')
      const tipsUser = document.createElement('span')
      const tipsUserName = document.createElement('span')

      const tipsCourseID = document.createElement('p')
      const tipsCourse = document.createElement('span')
      const tipsCourseIDValue = document.createElement('span')

      const tipsAssertID = document.createElement('p')
      const tipsAssert = document.createElement('span')
      const tipsAssertIDValue = document.createElement('span')

      // 用户名
      tipsP.id = 'tipsP'
      tipsUser.textContent = '用户名：'
      tipsUserName.textContent = localStorage.userInfo ? JSON.parse(localStorage.userInfo).userName : '没有用户信息'
      tipsUser.className = 'info-label'
      tipsUserName.className = 'info-content'
      tipsP.appendChild(tipsUser)
      tipsP.appendChild(tipsUserName)

      // 课程ID
      tipsCourse.textContent = 'courseID：'
      tipsCourseIDValue.textContent = this.id
      tipsCourse.className = 'info-label'
      tipsCourseIDValue.className = 'info-content'
      tipsCourseID.appendChild(tipsCourse)
      tipsCourseID.appendChild(tipsCourseIDValue)

      // 媒资ID
      tipsAssert.textContent = 'assertID：'
      tipsAssertIDValue.textContent = assertID
      tipsAssert.className = 'info-label'
      tipsAssertIDValue.className = 'info-content'
      tipsAssertID.appendChild(tipsAssert)
      tipsAssertID.appendChild(tipsAssertIDValue)

      content.appendChild(tipsP)
      content.appendChild(tipsCourseID)
      content.appendChild(tipsAssertID)
    },
    setUploadvideoschedule(spaceTime, videoID, flag) {
      if (this.timer) {
        clearInterval(this.timer)
      }
      if (spaceTime > 0) {
        this.timer = setInterval(() => {
          this.postVideoschedule(videoID, null, flag)
        }, 1000 * spaceTime)
      }
    },
    async postVideoschedule(videoID, refreshVideos, flag, cb, isDrag) {
      if (!this.myPlayer || !this.uploadMap[videoID]) {
        this.$nextTick(() => {
          this.$Toast.clear()
        })
        return
      }
      if (this.myPlayer.paused()) {
        if (this.uploadMap[videoID].prePostion === Math.ceil(this.myPlayer.getCurrentTime())) {
          this.$nextTick(() => {
            this.$Toast.clear()
          })
          return
        }
      }
      let params = new FormData()
      let position = Math.ceil(this.myPlayer.getCurrentTime())
      let _spaceTime = this.uploadMap[videoID].spaceTime > 30 ? 30 : this.uploadMap[videoID].spaceTime
      params.append('videoID', videoID)
      params.append('position', position)
      params.append('videoType', 'hd')
      params.append('spaceTime', _spaceTime)
      params.append('platform', 'h5')
      params.append('speed', this.speed)
      params.append('isDrag', isDrag || 0)
      if (flag) {
        params.append('isTrialWatch', 'Y')
      }
      this.uploadMap[videoID].prePostion = position
      this.uploadMap[videoID].spaceTime = 0
      const that = this
      this.$axios.post(`/course/front/course/${this.id}/uploadvideoschedule`, params).then((res) => {
        console.log('回传')
        if (typeof cb === 'function') {
          cb()
        }
        if (res.data.status === 1) {
          if (refreshVideos) {
            this.getVideosData()
          }
          that.videoListData = that.videoListData.map((item) => {
            if (item.ID === videoID) {
              item.lastPosition = position
            }
            return item
          })
        }
      })
    },
    formatTime(time) {
      let str = time
      let temp = str.split(':')
      let Seconds = 3600 * Number(temp[0]) + 60 * Number(temp[1]) + Number(temp[2])
      if (temp.length === 2) {
        Seconds = 60 * Number(temp[0]) + Number(temp[1])
      }
      return Seconds
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.itemData.name || '课程详情'}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    // 课程详情
    async getCourseData() {
      function backToIndex() {
        this.$Toast.fail('没有该课程权限，稍后将自动跳转回首页')
        const timer = setTimeout(() => {
          clearTimeout(timer)
          this.$router.replace({
            path: '/',
          })
        }, 2000)
        return
      }
      await this.$axios
        .get(`/course/front/permit/course/${this.id}`)
        .then((res) => {
          if (res.data.status === 1) {
            // const isFromStudyCenter = this.$route.query.fromStudyCenter
            // 从学习中心点进来 则
            // if (isFromStudyCenter) {
            if (res.data.data.isHidden === 'Y') {
              backToIndex.bind(this)()
            }
            // } else {
            //   if (res.data.data.isHidden === 'Y') {
            //     backToIndex.bind(this)()
            //   }
            // }
            this.itemData = res.data.data
            this.lastWatchVideoID = res.data.data.lastWatchVideoID
            this.playVideoOk = res.data.data.isPhoto === 'N'
            this.isPlayVideo = res.data.data.isPhoto === 'N'
            this.isPay = res.data.data.hasPermission === 'Y'
            this.isFastForward = res.data.data.isFastForward === 'Y' // 是否允许拖进度条
            this.isSpeedPlayback = res.data.data.isSpeedPlayback === 'Y' // 是否允许倍速播放
            this.paperID = res.data.data.practices
            if (res.data.data.isDoneExerc !== 'Y') {
              this.options.splice(1, 1)
            }
            if (res.data.data.workID !== 0) {
              this.options.push({
                name: '作业',
                typeName: 'work',
                icon: 'https://img01.yzcdn.cn/vant/custom-icon-water.png',
                ID: res.data.data.workID,
              })
            }
            this.setShare()
          } else {
            this.$Toast.fail(res.data.message)
            setTimeout(() => {
              this.$router.push('/')
            }, 2000)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 课程主页视频列表
    getVideosData() {
      this.isRequestedVideo = true
      this.$axios
        .get(`/course/front/permit/course/${this.id}/videos`)
        .then((res) => {
          if (res.data.status === 1) {
            this.videoListData = res.data.data.videos
            this.studyContent = res.data.data.studyContent
            let flag = true
            for (let i = 0; i < this.videoListData.length; i++) {
              if (this.videoListData[i].percent !== '100%') {
                flag = false
              }
            }
            if (this.itemData.practices !== '0' && this.itemData.isDoneExerc === 'N' && flag) {
              this.popupShow = true
            }
            // 第一次看这个课没有最后观看视频的ID，默认取第一个视频的ID
            if ((!this.lastWatchVideoID || this.lastWatchVideoID <= 0) && this.videoListData.length > 0) {
              this.lastWatchVideoID = this.videoListData[0].ID
            }
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 课程主页讲义列表
    async getHandoutsData() {
      this.isRequestedHandouts = true
      await this.$axios
        .get(`/course/front/permit/course/${this.id}/handouts`)
        .then((res) => {
          if (res.data.status === 1) {
            this.handouts = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 获取讲义详情
    getHandoutsDetails(item, index) {
      if (this.itemData.hasPermission !== 'Y') {
        return this.$Toast.fail('没有课程权限，无法查看详情信息')
      }
      if (item.isShow) {
        item.isShow = false
        this.$forceUpdate()
        return
      }
      this.$Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.$axios
        .get(`/course/front/course/${this.id}/handout?handoutID=${item.ID}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.handoutsDetails = res.data.data.url
            this.handouts[index].handoutsDetails = this.handoutsDetails
            item.isShow = !item.isShow
          } else {
            this.$Toast.fail(res.data.message)
          }
          this.$Toast.clear()
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
      this.$forceUpdate()
    },
    preventBubbling(e, index) {
      this.startPosition = index
      this.imgPreviewShow = true
      return false
    },
    imgPrevieChange(index) {
      this.imgPrevieIndex = index + 1
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    onSelect(item) {
      if (item.typeName === 'exerc') {
        // 练习详情页
        this.$router.push({
          path: `/study/studyreport/${this.paperID}/${this.$route.params.id}`,
        })
      } else if (item.typeName === 'work') {
        // 作业详情页
        this.$router.push({
          path: `/nzzone/workDetails/${item.ID}?isHiddenTime=${this.itemData.isFree === 1 && this.itemData.enterpriseExclusive === 0}`,
        })
      } else {
        this.$router.push({
          path: `/allCourses/classByCourse/${this.$route.params.id}`,
        })
      }
    },
    toTeacherDetail(item) {
      this.$router.push({
        name: 'teacherDetail',
        params: {
          id: item.ID,
        },
      })
    },
    formatAmountToFixed2(amount) {
      return Number(amount).toFixed(2)
    },
  },
  destroyed() {
    if (this.myPlayer) {
      this.postVideoschedule(this.lastWatchVideoID, null, this.itemData.freeLength !== 0 && this.itemData.hasPermission !== 'Y')
      this.myPlayer.dispose()
    }
    clearInterval(this.timer)
    clearInterval(this.timer2)
    clearInterval(this.danmukuListTimer)
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>

<style scoped>
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.practise.disable >>> .van-button.van-button--info {
  color: #fff;
  background-color: #585f67;
  border: 1px solid #585f67;
}

.curriculum-details >>> .van-popup.van-popup--center {
  background-color: unset;
}

.popup-box {
  width: 6.53rem;
  height: 7.87rem;
  background-color: #ffffff;
  border: 1px solid #fff;
  border-radius: 0.64rem;
}

.popup-logo img {
  max-width: 100%;
  height: auto;
}

.popup-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  opacity: 0.8;
  font-size: 0.32rem;
  margin-top: 0.32rem;
}

.popup-btn-box {
  font-size: 0.34rem;
  display: flex;
  justify-content: space-around;
  margin-top: 0.57rem;
  color: #fff;
}

.goto-exe {
  width: 2.44rem;
  height: 0.91rem;
  background-image: linear-gradient(0deg, #2182fb 0%, #60a7ff 100%);
  box-shadow: 0.06rem 0.07rem 0.24rem 0rem rgba(42, 135, 252, 0.4);
  border-radius: 0.45rem;
  line-height: 0.91rem;
  text-align: center;
}

.exe-cancel {
  width: 2.45rem;
  height: 0.91rem;
  background-color: #9ca5ae;
  border-radius: 0.45rem;
  line-height: 0.91rem;
  text-align: center;
}

.popup-close {
  display: flex;
  justify-content: space-around;
  margin-top: 0.41rem;
}

.popup-close-box {
  width: 0.87rem;
  height: 0.87rem;
  background-color: #d0d0d0;
  opacity: 0.8;
  line-height: 0.87rem;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 0.53rem;
}

.curriculum-details >>> .van-dialog {
  margin-top: 0.93rem;
}

.curriculum-details >>> .van-dialog__content {
  max-height: 80vh;
  overflow-y: auto;
}

.poster-sharer {
  display: flex;
  align-items: center;
}

.poster-sharer span.share {
  font-size: 0.58rem;
  color: #1989fa;
  margin-left: 0.26rem;
}

.curriculum-details {
  background-color: #eee;
  margin-bottom: 1.61rem;
}

.curriculum-details >>> .van-tag {
  font-size: 0.32rem;
  line-height: 0.42rem;
  border-radius: 2px;
  white-space: nowrap;
}

.curriculum-details >>> #videoPreview {
  width: 100%;
  height: 100%;
}

.curriculum-details >>> .van-nav-bar .van-icon {
  font-size: 0.48rem;
  color: #333;
}

.curriculum-details >>> .van-tabs.van-tabs--line {
  margin-top: 0.13rem;
}

.curriculum-details >>> .van-tab {
  line-height: 0.53rem;
}

.curriculum-details >>> .van-tabs__line {
  position: absolute;
  bottom: 0.4rem;
  left: 0;
  z-index: 1;
  width: 1.06rem;
  height: 0.08rem;
  background-color: #1578f2;
  border-radius: 0.08rem;
}

.curriculum-details >>> .van-tabs--line .van-tabs__wrap {
  height: 1.17rem;
}

.curriculum-details >>> .van-tab__text.van-tab__text--ellipsis {
  font-size: 0.37rem;
}

.curriculum-details >>> .van-tab--active .van-tab__text.van-tab__text--ellipsis {
  color: #222;
  font-weight: bold;
}

.curriculum-details >>> .van-tabs__content {
  background-color: #fff;
  padding: 0.26rem 0.53rem;
  font-size: 0.37rem;
}

.logo {
  height: 5.65rem;
  width: 100%;
}

.details {
  padding: 0.57rem 0.51rem 0.16rem 0.57rem;
  background-color: #fff;
  margin-top: -0.13rem;
}

.details-item {
  height: 0.44rem;
  display: flex;
}

.tag {
  font-size: 0.32rem;
  display: inline-block;
  border: 1px solid #fc7d55;
  border-radius: 0.11rem;
  padding: 0 1px;
  color: #fc7d55;
}

.title {
  font-size: 0.45rem;
  color: #111111;
  line-height: 0.48rem;
  padding-left: 0.13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8.74rem;
}

.teacher {
  font-size: 0.35rem;
  margin-top: 0.33rem;
  color: #111111;
  position: relative;
}

.teacher-arr {
  display: inline-block;
  width: 6.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: text-bottom;
}

.teacher--empty {
  margin-left: 5px;
  color: #999999;
}

.separator {
  margin: 0 0.13rem;
}

.right-data {
  position: absolute;
  right: 0;
}

.count {
  color: #777777;
}

.contain {
  color: #1578f2;
  border: 0.01rem solid #1578f2;
  font-size: 0.32rem;
  margin-left: 0.32rem;
}

.price-item {
  font-size: 0.32rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.13rem;
}

.price {
  font-size: 0.53rem;
  color: #111;
}

.old-price {
  font-size: 0.32rem;
  color: #999999;
  text-decoration: line-through;
}

.pay {
  color: #777777;
}

.player-count {
  top: 0.21rem;
  width: 1.2rem;
  height: 0.45rem;
  background-color: #ffffff;
  border-radius: 0.05rem;
  border: solid 0.01rem #1578f2;
  text-align: center;
  line-height: 0.45rem;
  right: 0;
  color: #1578f2;
}

.desc {
  font-size: 0.35rem;
  color: #474747;
  line-height: 0.56rem;
  margin-top: 0.27rem;
  margin-bottom: 0.4rem;
  padding-left: 0.13rem;
  text-align: justify;
}

.desc >>> img {
  max-width: 100%;
}

.teacher-item {
  display: flex;
  margin-top: 0.4rem;
}

.teacher-logo {
  width: 1.33rem;
  height: 1.33rem;
  margin-left: 0.21rem;
}

.teacher-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.teacher-content {
  width: 7.09rem;
  padding-left: 0.31rem;
}

.teacher-name {
  font-size: 0.37rem;
}

.teacher-desc {
  font-size: 0.32rem;
  padding-top: 0.29rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  width: 7.06rem;
}

.teacher-desc >>> p {
  margin: 0;
}

.buynow {
  height: 1.61rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.buy-btn-group {
  display: flex;
}
.buy-btn {
  background: linear-gradient(to right, #63a1ed, #97d0f5);
  color: #fff;
}
.buy-btn--highlighted {
  background: linear-gradient(to right, #532c10, #7f583b);
  color: #ffebb6;
}
.buy-btn--disabled {
  background: #acafb3;
  color: #fff;
}

.curriculum-details >>> .van-button--round {
  margin: 0.19rem 0.28rem;
  width: calc(100% - 0.56rem);
}

.curriculum-details >>> .van-button--normal {
  padding: 0 0.4rem;
  font-size: 0.37rem;
}

.curriculum-details >>> .van-button {
  height: 1.17rem;
}

.curriculum-details .practise >>> .van-button {
  width: 8.29rem;
}

.video-content {
  margin-left: 0.26rem;
}

.video-count {
  font-size: 0.32rem;
  color: #777;
}

.video-list-content {
  width: 8.26rem;
}

.video-list-item {
  display: flex;
  justify-content: space-between;
  font-size: 0.37rem;
  color: #777;
  height: 1.26rem;
  line-height: 1.26rem;
  border-bottom: 1px solid #eee;
}

.video-list-item.active {
  color: #1578f2;
}

.video-list-item-title {
  width: 4.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video-list-item-time {
  font-size: 0.27rem;
  line-height: 1.26rem;
}

.video-list-item-icon {
  line-height: 1.26rem;
  font-size: 0.58rem;
}

.html-content {
  margin: 0 -0.53rem;
}

.html-content >>> img {
  max-width: 100%;
}

.practise {
  height: 1.61rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
}

.share {
  line-height: 1.55rem;
  font-size: 0.69rem;
}

.handouts-content {
  color: #999;
  width: 100vw;
  margin-left: -0.53rem;
  background-color: #eee;
  line-height: 0.93rem;
  margin-bottom: 0.13rem;
}

.handouts-content.active {
  color: #1578f2;
}

.handouts {
  width: 8.93rem;
  display: flex;
  padding: 0 0.53rem;
}

.handouts .handouts-title {
  display: inline-block;
  width: 7.78rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.handouts .van-icon.van-icon-paid {
  font-size: 0.53rem;
  margin-right: 0.26rem;
  line-height: 0.93rem;
}

.handouts-content .btn {
  line-height: 0.93rem;
}

.handouts-content .handouts-details {
  display: block;
  padding-top: 0.13rem;
  background-color: rgb(255, 255, 255);
  line-height: normal;
  text-align: center;
}

.handouts-content img {
  max-width: 100%;
  height: auto;
}

.curriculum-details >>> .van-image-preview__image img {
  pointer-events: none;
}

.qs-icon {
  position: fixed;
  bottom: 2.17rem;
  right: 0.71rem;
}

.qs-box {
  text-align: center;
  background-color: #3889ff;
  color: #fff;
  height: 0.92rem;
  width: 0.92rem;
  line-height: 0.92rem;
  border-radius: 0.21rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.qs-box::after {
  content: '▲';
  position: absolute;
  color: #3889ff;
  transform: rotate(180deg);
  font-size: 0.37rem;
  top: 0.52rem;
  left: 0.29rem;
}

.teacher-item-box {
  padding-bottom: 1.61rem;
}
</style>
<style>
.prism-big-play-btn {
  left: 50% !important;
  bottom: 50% !important;
  transform: translate(-50%, 50%);
}
.ali-danmuku-control {
  display: none !important;
}

.prism-setting-btn {
  display: none !important;
}

.prism-cc-btn {
  display: none !important;
}

.prism-volume {
  display: none !important;
}

.video-js {
  width: 100%;
  height: 5.65rem;
}

.my-video-dimensions {
  width: 100%;
  height: 5.65rem;
}

.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-js .vjs-tech {
  position: relative !important;
}

.video-js .vjs-time-control {
  display: block;
}

.video-js .vjs-remaining-time {
  display: none;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>
