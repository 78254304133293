// 学习中心
import UserStudy from '@/page/study/Index'
import TestResults from '@/page/study/TestResults'
import StudyReport from '@/page/study/StudyReport'
export default [
  {
    path: '/study',
    name: 'study',
    component: UserStudy,
    meta: {
      title: '学习中心',
      keepAlive: true
    }
  },
  {
    path:'/study/testresults/:paperID/:examID',
    name: 'testresults',
    component: TestResults
  },
  {
    path:'/study/studyreport/:paperID/:courseID',
    name: 'studyreport',
    component: StudyReport
  }
]
