<template>
  <div>
    <zv-nav-bar :title="'热门课程'"></zv-nav-bar>
    <div class="course-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div
            class="course-item"
            v-for="item in coursesData"
            :key="item.ID"
            @click="gotoPage(item.type !== 0 ? `/allCourses/courseHandout/${item.ID}` : `/allCourses/details/${item.ID}/${'curriculum'}`)"
          >
            <div class="img-box">
              <img :src="item.logoFile" />
            </div>
            <ul>
              <li>
                <h5 class="line-ellipsis">
                  <span>{{ item.type === 0 ? '视频' : '讲义' }}</span>
                  {{ item.name }}
                </h5>
                <h6 class="line-ellipsis-2" v-html="item.description || '暂无描述'"></h6>
              </li>
              <li>
                <span>{{ item.speaker }}</span>
              </li>
              <!--              <li>
                <div class="progress">
                  <div :style="{ width: item.studyPercent * 100 + '%' }"></div>
                </div>
                <span>进度：{{ parseInt(item.studyPercent * 100) }}%</span>
              </li>-->
            </ul>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
export default {
  name: 'enterpriseList',
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      coursesData: [],
      pageIndex: 0,
    }
  },
  created() {
    this.setShare()
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: `企业课程列表`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    onRefresh() {
      this.pageIndex = 0
      this.coursesData = []
      this.$nextTick(() => {
        this.finished = false
        this.loading = true
        this.onLoad()
      })
    },
    onLoad() {
      let enterpriseID = this.$route.query.id
      let enterpriseInnercode = this.$route.query.code
      this.$axios
        .get(`/course/front/member/enterprisecenter/recommend/courses?enterpriseID=${enterpriseID}&enterpriseInnercode=${enterpriseInnercode}&pageSize=10&pageIndex=${this.pageIndex}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.pageIndex = this.pageIndex + 1
            this.coursesData = this.coursesData.concat(res.data.data.data)
            this.loading = false
            if (this.coursesData.length >= res.data.data.total) {
              this.finished = true
            }
            this.refreshing = false
          } else {
            this.loading = false
            this.finished = true
            this.refreshing = false
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          // 加载状态结束
          this.loading = false
          this.finished = true
          this.refreshing = false
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
      this.$forceUpdate()
    },
    // 页面跳转
    gotoPage(path) {
      this.$router.push({ path: path })
    },
  },
}
</script>
<style scoped>
.course-list {
  background: #fff;
  border-radius: 0.13rem;
  padding: 0.28rem 0.44rem;
}
.course-list .course-item {
  display: flex;
  margin-bottom: 0.4rem;
}
.course-list .course-item .img-box {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.13rem;
  overflow: hidden;
  margin-right: 0.53rem;
}
.course-list .course-item .img-box img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.course-list .course-item > ul {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.course-list .course-item > ul li:nth-child(1) span {
  color: #fff;
  font-size: 0.27rem;
  border-radius: 0.08rem;
  border: 1px solid #70dd96;
  background-color: #70dd96;
  padding: 0 0.07rem;
  white-space: nowrap;
  vertical-align: middle;
}
.course-list .course-item > ul li:nth-child(1) h5 {
  color: #1b1b1b;
  font-size: 0.37rem;
  margin: 0;
  margin-left: 0.2rem;
}

.line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.course-list .course-item > ul li:nth-child(2) span {
  color: #484a49;
  font-size: 0.32rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.course-list .course-item > ul li:nth-child(2) p {
  color: #999999;
  font-size: 0.32rem;
  margin: 0.1rem 0;
}
.course-list .course-item > ul li:nth-child(3) {
  display: flex;
  align-items: center;
}
.course-list .course-item > ul li:nth-child(3) .progress {
  height: 0.27rem;
  background-color: #eeeeee;
  border-radius: 0.13rem;
  flex: 1;
  margin-right: 0.3rem;
}
.course-list .course-item > ul li:nth-child(3) .progress div {
  background: #d5af82;
  height: 100%;
  border-radius: 1rem;
}
.course-list .course-item > ul li:nth-child(3) span {
  color: #828282;
  font-size: 0.32rem;
  width: 2rem;
  white-space: nowrap;
}

.course-list .course-item > ul li h6 {
  color: #878787;
  font-size: 0.32rem;
  font-weight: normal;
  margin: 0.1rem 0;
}
</style>
