<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <bottom-navigation v-if="isShow" />
  </div>
</template>

<script>
import BottomNavigation from "@/components/BottomNavigation.vue";
export default {
  name: "App",
  components: {
    "bottom-navigation": BottomNavigation,
  },
  data() {
    return {
      routers: ["index", "allCourses", "nzzone", "study", "user"],
    };
  },
  created () {
    if(location.href.includes('shareUrl')) {
      let shareUrl = this.getParamFromUrl('shareUrl')
      let result = decodeURIComponent(shareUrl)
      if (location.href.includes('isShare=true') && !result.includes('isShare=true')) {
        shareUrl = shareUrl.indexOf('?') > -1 ? (shareUrl + '&isShare=true') : (shareUrl + '?isShare=true')
      }
      location.href = location.origin + '/#' + shareUrl
      return
    }
  },
  methods: {
    getParamFromUrl(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
      const r = decodeURI(window.location.search).substr(1).match(reg)
      if (r != null) {
        // 资讯的不解码，否则访问不了
        if (r[2] && r[2].indexOf('/newsDetail') >= 0) {
          return r[2]
        } else {
          return unescape(r[2])
        }
      }
      return null
    }
  },
  computed: {
    isShow() {
      return this.routers.indexOf(this.$route.name) > -1;
    },
  },
};
</script>

<style>
#app .van-nav-bar__arrow {
  font-size: 0.42rem;
}
#app .van-nav-bar__title {
  font-size: 0.42rem;
}
#app .van-nav-bar__content {
  height: 1.22rem;
}
#app .van-nav-bar {
  line-height: 0.58rem;
}
#app .van-ellipsis {
  overflow: hidden;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix {
  *zoom: 1;
}
.van-toast {
  width: 50% !important;
  min-height: 2.34rem !important;
  font-size: 0.37rem !important;
  line-height: 0.53rem !important;
  border-radius: 0.21rem !important;
}
</style>
