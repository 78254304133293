<template>
  <div class="curriculum-details" :style="{ 'margin-bottom': marginBottom }">
    <zv-nav-bar :title="'课程详情'">
      <div class="poster-sharer" @click="posterSharer" v-if="itemData.enterpriseExclusive != 1">
        <span style="font-size: 0.32rem; color: #1989fa">海报分享</span>
        <span class="share fa fa-share-square-o"></span>
      </div>
    </zv-nav-bar>
    <van-row>
      <van-col span="24" class="zv_insert">
        <div class="video-js">
          <div v-html="videoHtml" v-if="isNotMaxCount"></div>
          <img class="logo" :src="itemData.logoFile" v-show="!videoHtml" />
          <button class="vjs-big-play-button" @click="playBtnClick" v-if="isShowPlayBtn">
            <span class="vjs-icon-placeholder"></span>
          </button>
        </div>
        <div class="details" :style="{ 'padding-bottom': itemData.isPhoto === 'Y' ? '0.16rem' : '0.26rem' }">
          <div class="details-item">
            <div class="title">{{ itemData.name }}</div>
          </div>
          <div class="teacher">
            <span>讲师：</span>
            <span class="teacher-arr">{{ itemData.speaker }}</span>
            <span class="right-data">
              <!-- <span class="count" v-if="itemData.hasPermission === 'N'">{{itemData.viewCount}}次</span> -->
              <span class="contain" style="padding: 0.05rem" v-if="itemData.hasPermission === 'Y'">{{
                itemData.maxLearningTimes !== 0 ? itemData.hasStudyTimes + '/' + itemData.maxLearningTimes : '不限次数'
              }}</span>
              <span class="contain" v-if="isSeriesOfCourses">包含{{ itemData.contain }}门课程</span>
            </span>
            <div v-if="itemData.isPhoto === 'Y'" style="color: rgb(244, 42, 9); padding: 0.26rem; margin: 0.1rem 0 0 0; background-color: #fbf5f5; text-align: center">
              观看课程需图像采集，请确保面部在摄像可视范围
            </div>
          </div>
          <div class="price-item" v-if="!isPay && itemData.enterpriseExclusive !== 1 && Number(itemData.editStatus === 2)">
            <span class="price">￥{{ hasEnterprise ? itemData.memberPrice : itemData.sellPrice }}</span>
            <span class="separator"></span>
            <span class="old-price" v-if="hasEnterprise">￥{{ itemData.sellPrice }}</span>
            <span class="separator"></span>
            <span class="pay" v-if="hasEnterprise">企业已经为你支付{{ (itemData.sellPrice - itemData.memberPrice).toFixed(2) }}元</span>
            <!-- <span class="player-count">{{itemData.maxLearningTimes}}次</span> -->
            <van-tag plain type="primary">{{ itemData.viewCount }}次</van-tag>
          </div>
        </div>
        <van-tabs v-model="activeName" @change="tabChange">
          <van-tab title="课程介绍" name="1">
            <div>【课程介绍】</div>
            <template v-if="itemData.description">
              <div class="desc" v-html="itemData.description"></div>
            </template>
            <div v-else class="not-data">暂无数据</div>
            <div>【主讲老师】</div>
            <template v-if="itemData.speakers && itemData.speakers.length > 0">
              <div class="teacher-item" v-for="(item, index) in itemData.speakers" :key="index" @click="toTeacherDetail(item)">
                <div class="teacher-logo">
                  <img :src="item.logoFile" />
                </div>
                <div class="teacher-content">
                  <div class="teacher-name">{{ item.name }}</div>
                  <div class="teacher-desc" v-html="item.description || '暂无描述'"></div>
                </div>
              </div>
            </template>
            <div class="not-data" v-else>暂无数据</div>
          </van-tab>
          <van-tab title="视频" name="2" v-if="!isSeriesOfCourses">
            <div class="video-content">
              <div class="video-count">{{ studyContent }}</div>
              <template v-if="videoListData.length > 0">
                <div class="video-list-content" v-for="(item, index) in videoListData" :key="index">
                  <div :class="{ 'video-list-item': true, active: item.ID === lastWatchVideoID }" @click="getVideo(item.ID)">
                    <div class="video-list-item-title">{{ index + 1 }}.{{ item.name }}</div>
                    <div class="video-list-item-time">{{ item.lengthHMS }}</div>
                    <div
                      :class="{ 'video-list-item-icon': true, 'van-icon': true, 'van-icon-play-circle-o': item.ID !== lastWatchVideoID, 'van-icon-bar-chart-o': item.ID === lastWatchVideoID }"
                    ></div>
                    <div style="width: 1.25rem">{{ item.percent }}</div>
                  </div>
                </div>
              </template>
              <div v-else class="not-data">没有更多</div>
            </div>
          </van-tab>
          <van-tab title="课件" name="3" v-if="!isSeriesOfCourses">
            <template v-if="handouts.length > 0">
              <template v-for="(item, index) in handouts">
                <div :class="{ 'handouts-content': true, active: item.isShow }" :key="item.ID" :style="{ 'margin-bottom': item.isShow ? '0' : '0.13rem' }" @click="getHandoutsDetails(item, index)">
                  <div class="handouts">
                    <span class="van-icon van-icon-paid"></span>
                    <span class="handouts-title">{{ index + 1 + '.' + item.name }}</span>
                    <span :class="{ 'van-icon': true, btn: true, 'van-icon-arrow': !item.isShow, 'van-icon-arrow-down': item.isShow }"></span>
                  </div>
                  <div :style="{ display: item.isShow ? 'block' : 'none' }" class="handouts-details">
                    <template v-if="handoutsDetails.length > 0">
                      <div v-for="(src, _index) in item.handoutsDetails" :key="_index" @click.stop="preventBubbling($event, _index)">
                        <img :src="src" style="pointer-events: none" />
                      </div>
                    </template>
                    <div v-else class="not-data">暂无数据</div>
                  </div>
                </div>
              </template>
            </template>
            <div v-else class="not-data">没有更多</div>
          </van-tab>
          <van-tab title="课程列表" name="4" v-if="isSeriesOfCourses">
            <div class="course-list" v-for="(item, index) in courseListData" :key="index">
              <img :src="item.logo" />
              <div class="course-list-item">
                <div class="course-list-item-title">{{ item.title }}</div>
                <div class="course-list-item-tacher-name">
                  <span>讲师：</span>
                  <span>{{ item.teacher[0] }}</span>
                  <span class="separator">|</span>
                  <span>{{ item.teacher[1] }}</span>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
        <div class="buynow" v-if="!isPay && itemData.enterpriseExclusive !== 1">
          <template v-if="!Number(itemData.editStatus === 2)">
            <van-button class="buy-btn--disabled" round @click="$Toast.fail('商品已下架')">已下架</van-button>
          </template>
          <template v-else>
            <div class="buy-btn-group" v-if="!isMember">
              <van-button class="buy-btn--highlighted" round @click="getMemberPage('buy')">企业会员价&nbsp;{{ formatAmountToFixed2(itemData.memberPrice) }}元</van-button>
              <van-button class="buy-btn" round @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}&type=Course`)"
                >立即购买&nbsp;{{ formatAmountToFixed2(itemData.sellPrice) }}元</van-button
              >
            </div>
            <div class="buy-btn-group" v-else>
              <van-button class="buy-btn" style="background: #4087ef" round @click="gotoPage(`/allCourses/order/${itemData.ID}?couponId=${$route.query.couponId || ''}&type=Course`)"
                >立即购买</van-button
              >
            </div>
          </template>
        </div>
        <div :class="itemData.practices == '0' ? 'practise disable' : 'practise'" v-if="(activeName === '2' || activeName === '3') && isPay">
          <van-button round block type="info" @click="goAnswer" :disabled="itemData.practices == '0'">课堂练习</van-button>
          <div class="share van-icon van-icon-apps-o" @click="showShare = true"></div>
        </div>
      </van-col>
    </van-row>
    <div class="qs-icon" v-if="itemData.hasPermission === 'Y'" @click="gotoPage(`/user/question/${$route.params.id}?title=${itemData.name}`)">
      <div class="qs-box">
        <span class="fa fa-question" style="font-size: 0.58rem"></span>
      </div>
      <div style="font-size: 0.28rem; text-align: center; margin-top: 0.13rem">提问</div>
    </div>
    <van-image-preview v-model="imgPreviewShow" :images="handoutsDetails" :startPosition="startPosition" @change="imgPrevieChange" :closeable="true">
      <template v-slot:index>第{{ imgPrevieIndex }}张</template>
    </van-image-preview>
    <van-share-sheet v-model="showShare" :options="options" @select="onSelect" />
    <video id="v" style="object-fit: fill; display: none"></video>
    <canvas id="canvas" width="320" height="380" style="display: none"></canvas>
    <van-dialog v-model="videoPreviewShow" title="看课抓拍图片预览效果" width="80%" @confirm="confirm">
      <video id="videoPreview" style="object-fit: fill"></video>
    </van-dialog>
    <van-dialog v-model="shareDialog" title="长按图片分享" confirmButtonText="关闭" @confirm="shareDialog = false" width="100%">
      <img :src="photoSrc + '?' + new Date().getTime()" style="max-width: 100%; height: auto" />
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: 'courseDetails',
  computed: {
    marginBottom() {
      // 一期先注释这里的逻辑
      if (this.activeName === '2' || this.activeName === '3') {
        return '1.61rem'
      }
      if (!this.isPay) {
        return '1.61rem'
      }
      let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        return '0.53rem'
      }
      return 0
    },
    isShowPlayBtn() {
      return !this.isPlay
    },
  },
  data() {
    return {
      shareDialog: false,
      photoSrc: '',
      videoPreviewShow: false,
      isPlay: false,
      isNotMaxCount: true,
      imgPreviewShow: false,
      imgPrevieIndex: 1,
      startPosition: 0,
      id: '',
      showShare: false,
      options: [
        {
          name: '班级',
          typeName: 'class',
          icon: 'https://img01.yzcdn.cn/vant/custom-icon-fire.png',
        },
        {
          name: '查看练习',
          typeName: 'exerc',
          icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png',
        },
      ],
      activeName: '1',
      isSeriesOfCourses: false,
      isPay: false,
      itemData: {},
      courseListData: [],
      videoListData: [],
      handouts: [],
      studyContent: '',
      handoutsDetails: [],
      myPlayer: null,
      videoHtml: '',
      allNotPlayflag: true,
      lastWatchVideoID: '',
      timer: null,
      timer2: null,
      uploadMap: {},
      imgData: '',
      playVideoOk: true,
      isPlayVideo: false,
      paperID: 0,
      hasEnterprise: false,
      isChangeVideo: false,
      isRequestedHandouts: false,
      isRequestedVideo: false,
      isMember: false,
    }
  },
  async created() {
    this.id = this.$route.params.id
    // 是系列课程（栏目）还是单课程
    this.isSeriesOfCourses = this.$route.params.type !== 'curriculum'
    await this.getCourseData()
    if (this.isPay) {
      this.activeName = '2'
      this.getVideosData()
    }
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      if (Number(info.isVip) && (info.enterpriseName || info.enterpriseShorthand)) {
        this.hasEnterprise = true
      }
    }
  },
  mounted() {
    this.isMember = this.$eventHub.userInfo.isVip
  },
  methods: {
    getMemberPage(type) {
      // 跳转企业会员相关文章页
      this.$axios({
        url: '/json/ktqyhy/index.json',
        methods: 'GET',
        params: {},
      }).then(({ data }) => {
        if (Number(data.status) !== 1) return
        if (type === 'buy') this.handleGotoMemberOrder(data.data[0].artUrl)
        else if (type === 'permission') this.handleGotoMemberOrder(data.data[1].artUrl)
      })
    },
    handleGotoMemberOrder(url) {
      const artUrl = url.substring(url.search(/json/))
      this.$router.push({
        name: 'memberInfo',
        params: {
          url: artUrl,
        },
      })
    },
    posterSharer() {
      if (this.photoSrc) {
        return (this.shareDialog = true)
      }
      this.$Toast.loading({
        message: '正在生成海报中...',
        forbidClick: true,
        duration: 0,
      })
      this.$axios
        .get(`/course/front/permit/poster/${this.$route.params.id}?type=Course`)
        .then((res) => {
          this.$Toast.clear()
          if (res.data.status === 1) {
            this.shareDialog = true
            this.photoSrc = res.data.data.url
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试' + e)
        })
    },
    goAnswer() {
      const item = this.itemData

      if (item.practices === '0' || !item.practices) {
        return this.$Toast.fail('暂无课程练习！')
      }
      this.$axios
        .get(`/tk/front/paper/${item.practices}/checkandgeneratekey`)
        .then((res) => {
          if (res.data.status === 1) {
            // 前往答题页面
            this.$router.push({
              path: `/allCourses/examinationpaper?id=${item.ID}&isPhoto=${item.isPhoto}&key_success=${res.data.data.key_success}`,
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    confirm() {
      this.isPlayVideo = true
    },
    initPhoto() {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          // 首先，如果有getUserMedia的话，就获得它
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
          }
          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      const constraints = {
        video: true,
        audio: false,
      }
      const that = this
      let v = document.getElementById('v')
      let promise = navigator.mediaDevices.getUserMedia(constraints)
      return new Promise((resolve) => {
        promise
          .then((stream) => {
            let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            // 旧的浏览器可能没有srcObject
            if ('srcObject' in v) {
              v.srcObject = stream
            } else {
              // 防止再新的浏览器里使用它，应为它已经不再支持了
              v.src = window.URL.createObjectURL(stream)
            }
            v.onloadedmetadata = function() {
              that.playVideoOk = true
              v.play()
              return resolve(true)
            }
            if (isiOS) {
              // that.playVideoOk = true
              // v.play()
              // return resolve(true)
              return
            }
            that.videoPreviewShow = true
            this.$nextTick(() => {
              let videoPreview = document.getElementById('videoPreview')
              if ('srcObject' in videoPreview) {
                videoPreview.srcObject = stream
              } else {
                // 防止再新的浏览器里使用它，应为它已经不再支持了
                videoPreview.src = window.URL.createObjectURL(stream)
              }
              videoPreview.onloadedmetadata = function() {
                that.playVideoOk = true
                videoPreview.play()
                return resolve(true)
              }
            })
          })
          .catch((err) => {
            if (String(err).indexOf('getUserMedia is not implemented') !== -1) {
              that.playVideoOk = true
              this.$Toast.fail('手机系统版本过低，无法正常使用拍照功能')
            } else {
              that.playVideoOk = false
              this.$Toast.fail('未开启摄像头，无法观看视频')
            }
            resolve(true)
            return err
          })
      })
    },
    uploadImg() {
      if (this.itemData.isPhoto !== 'Y') {
        return
      }
      if (!this.playVideoOk) {
        return
      }
      if (this.myPlayer.paused()) {
        return
      }
      const videoInfo = this.videoListData.find((i) => i.ID === this.lastWatchVideoID)
      if (videoInfo.remainingPhotoCount <= 0) {
        return
      }
      let v = document.getElementById('v')
      let canvas = document.getElementById('canvas')
      canvas.getContext('2d').drawImage(v, 0, 0, 320, 380)
      this.imgData = canvas.toDataURL('image/png')
      let params = new FormData()
      let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      params.append('courseID', this.id)
      params.append('videoID', this.lastWatchVideoID)
      params.append('imgData', this.imgData)
      this.$axios.post('/course/front/course/videophoto/upload', params, { headers: headers }).then((res) => {
        if (res.data.status === 1) {
          this.videoListData = this.videoListData.map((item) => {
            if (item.ID === this.lastWatchVideoID) {
              item.remainingPhotoCount = item.remainingPhotoCount - 1
            }
            return item
          })
          // 拍照计时器
          let remainTime = parseInt(this.myPlayer.getDuration()) - this.myPlayer.getCurrentTime()
          let num = parseInt(remainTime / videoInfo.remainingPhotoCount)
          let randomNum = parseInt(Number(Math.random().toFixed(1)) * num) < 5 ? 5 : parseInt(Number(Math.random().toFixed(1)) * num)
          const timer = setTimeout(() => {
            this.uploadImg()
            clearTimeout(timer)
          }, randomNum * 1000)
        }
      })
    },
    async tabChange(activeName) {
      if (activeName === '2' && !this.isRequestedVideo) {
        this.getVideosData()
      }
      if (activeName === '3') {
        if (!this.isRequestedHandouts) {
          await this.getHandoutsData()
        }
        if (this.isPlay) {
          const videoInfo = this.videoListData.find((item) => item.ID === this.lastWatchVideoID)
          const handoutID = videoInfo.handoutID
          const item = this.handouts.find((item) => item.ID === handoutID)
          const index = this.handouts.findIndex((item) => item.ID === handoutID)
          item && this.getHandoutsDetails(item, index)
        }
      }
    },
    // 自定义播放按钮点击触发
    async playBtnClick() {
      if (this.videoListData.length === 0) {
        return this.$Toast.fail('没有视频资源')
      }
      for (let i = 0; i < this.videoListData.length; i++) {
        if (this.videoListData[i].assertID) {
          this.allNotPlayflag = false
          break
        }
      }
      if (this.itemData.hasPermission === 'Y') {
        if (this.itemData.maxLearningTimes !== 0 && this.itemData.hasStudyTimes >= this.itemData.maxLearningTimes) {
          return this.$Toast.fail('超过学习次数，无法观看视频')
        }
        if (this.allNotPlayflag) {
          return this.$Toast.fail('视频正在推送')
        }
        if (this.itemData.isPhoto === 'Y' && this.itemData.hasPermission === 'Y') {
          await this.initPhoto()
        }
        if (!this.playVideoOk) {
          return this.$Toast.fail('未开启摄像头，无法观看视频')
        }
        // 点完预览弹窗确定按钮后才播放视频
        let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isiOS) {
          let html = '<div class="video-js vjs-default-skin" id="my-video"></div>'
          this.videoHtml = html
          this.$nextTick(() => {
            this.getVideo(null, true)
            this.isPlay = true
          })
        } else {
          const timer = setInterval(() => {
            if (this.isPlayVideo) {
              let html = '<div class="video-js vjs-default-skin" id="my-video"></div>'
              this.videoHtml = html
              this.$nextTick(() => {
                this.getVideo(null, true)
                this.isPlay = true
              })
              clearInterval(timer)
            }
          }, 100)
        }
      } else {
        this.$Toast.fail('没有课程权限')
      }
    },
    getVideo(id, autoPlay) {
      this.isChangeVideo = id ? true : false
      if (document.getElementsByClassName('mask-content').length > 0) {
        document.getElementsByClassName('mask-zv')[0] && document.getElementsByClassName('mask-zv')[0].remove()
      }
      let flag = false
      if (id !== this.lastWatchVideoID) {
        clearInterval(this.timer2)
      } else {
        return
      }
      this.uploadMap[this.lastWatchVideoID] && this.postVideoschedule(this.lastWatchVideoID, true)
      this.lastWatchVideoID = id ? id : this.lastWatchVideoID
      if (this.itemData.hasPermission === 'Y') {
        if (this.videoListData.length === 0) {
          return this.$Toast.fail('该课程没有视频')
        }
        let currentVideo = this.videoListData.find((i) => i.ID === this.lastWatchVideoID)
        if (!currentVideo) {
          this.lastWatchVideoID = this.videoListData[0].ID
        } else if (!currentVideo.assertID) {
          return this.$Toast.fail('视频正在推送')
        }
        if (!this.playVideoOk) {
          if (id) {
            return
          }
          return this.$Toast.fail('未开启摄像头，无法观看视频')
        }
        if (!this.videoHtml) {
          let html = '<div class="video-js vjs-default-skin" id="my-video"></div>'
          this.videoHtml = html
        }
        if (this.itemData.maxLearningTimes !== 0 && this.itemData.hasStudyTimes >= this.itemData.maxLearningTimes) {
          this.videoHtml = ''
          this.$Toast.fail('超过学习次数，无法观看视频')
          return
        }
        const that = this
        this.lastWatchVideoID = this.lastWatchVideoID === 0 ? this.videoListData[0].ID : this.lastWatchVideoID
        const videoInfo = this.videoListData.find((item) => item.ID === this.lastWatchVideoID)
        const assertID = videoInfo.assertID
        const lastPosition = videoInfo.lastPosition
        const maxPosition = videoInfo.maxPosition
        const fastForward = maxPosition !== 0 && maxPosition === videoInfo.length
        if (videoInfo.length <= 0) {
          return this.$Toast.fail('视频转码中。。。')
        }
        // 请求接口获取播放地址参数为courseID-课程ID，assertID-媒资ID
        this.$axios.get(`/course/front/permit/course/assertplayurl?courseID=${this.id}&assertID=${assertID}`).then((res) => {
          if (res.data.status === 1) {
            if (this.myPlayer) {
              this.myPlayer.dispose()
            }
            this.myPlayer = new window.Aliplayer(
              {
                id: 'my-video',
                source: res.data.message,
                width: '100%',
                height: '5.65rem',
                autoplay: true,
                rePlay: false,
                playsinline: true,
                preload: true,
                useh5Prism: true,
                controlBarVisibility: 'click',
              },
              function(myPlayer) {
                let seeked = false
                myPlayer.on('canplaythrough', function() {
                  if (!seeked) {
                    seeked = true
                    myPlayer.seek(lastPosition === videoInfo.length ? 0 : lastPosition)
                  }
                  if (autoPlay) {
                    myPlayer.play()
                  }
                })
                const controlbar = document.getElementsByClassName('prism-controlbar')[0]
                if (controlbar) {
                  controlbar.style.display = 'none'
                }
                // 监听视频播放
                myPlayer.on('playing', () => {
                  controlbar.style.display = 'block'
                  let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
                  if (document.getElementsByClassName('mask-content').length === 0 && !fastForward) {
                    const parent = document.getElementsByClassName('prism-controlbar')[0]
                    const maskContent = document.createElement('div')
                    const maskHtml = document.createElement('div')
                    const insertDom = document.getElementsByClassName('prism-progress')[0]
                    parent.insertBefore(maskContent, insertDom)
                    maskHtml.className = 'mask-zv'
                    maskHtml.style.position = 'absolute'
                    maskHtml.style.zIndex = '100'
                    maskHtml.style.width = '100%'
                    maskHtml.style.height = insertDom.getBoundingClientRect().height + 'px'
                    maskContent.className = 'mask-content'
                    maskContent.style.display = 'flex'
                    maskContent.style.flex = 'auto'
                    maskContent.style.pointerEvents = 'none'
                    maskContent.appendChild(maskHtml)
                    maskContent.appendChild(insertDom)
                  }
                  if (isiOS && this.itemData.isFastForward === 'N') {
                    if (!fastForward) {
                      const fullscreenHtml = document.getElementsByClassName('prism-fullscreen-btn')[0]
                      fullscreenHtml.style.display = 'none'
                    }
                  }
                  const cc_btn = document.getElementsByClassName('prism-cc-btn')[0]
                  const setting_btn = document.getElementsByClassName('prism-setting-btn')[0]
                  if (cc_btn) {
                    cc_btn.style.display = 'none'
                  }
                  if (setting_btn) {
                    setting_btn.style.display = 'none'
                  }
                  if (!flag) {
                    that.$axios.get(`/course/front/course/${that.id}/studytimes`).then((res) => {
                      if (res.data.status === 1) {
                        that.itemData.hasStudyTimes = res.data.data
                      } else {
                        that.isNotMaxCount = false
                        that.$Toast.fail(res.data.message)
                      }
                    })
                    const totalTime = parseInt(that.myPlayer.getDuration())
                    // 拍照计时器
                    let remainTime = totalTime - that.myPlayer.getCurrentTime()
                    let num = parseInt(remainTime / videoInfo.remainingPhotoCount)
                    let randomNum = parseInt(Number(Math.random().toFixed(1)) * num) < 5 ? 5 : parseInt(Number(Math.random().toFixed(1)) * num)
                    if (that.itemData.isPhoto === 'Y') {
                      const timer = setTimeout(() => {
                        that.uploadImg()
                        clearTimeout(timer)
                      }, randomNum * 1000)
                    }
                    let spaceTime = 30
                    if (totalTime < 30) {
                      spaceTime = totalTime % 30
                    }
                    that.uploadMap[that.lastWatchVideoID] = that.uploadMap[this.lastWatchVideoID] ? that.uploadMap[this.lastWatchVideoID] : {}
                    that.uploadMap[that.lastWatchVideoID].prePostion = 0
                    that.uploadMap[that.lastWatchVideoID].spaceTime = 1
                    that.setUploadvideoschedule(spaceTime, that.lastWatchVideoID)
                    that.timer2 = setInterval(() => {
                      that.uploadMap[that.lastWatchVideoID] && (that.uploadMap[that.lastWatchVideoID].spaceTime += 1)
                      console.log(that.uploadMap[that.lastWatchVideoID].spaceTime)
                    }, 1000)
                    flag = true
                  } else {
                    if (!that.timer2) {
                      let spaceTime = 30
                      const totalTime = parseInt(that.myPlayer.getDuration())
                      if (totalTime < 30) {
                        spaceTime = totalTime % 30
                      }
                      that.setUploadvideoschedule(spaceTime, this.lastWatchVideoID)
                      that.timer2 = setInterval(() => {
                        that.uploadMap[that.lastWatchVideoID].spaceTime += 1
                      }, 1000)
                    }
                  }
                })
                // 播放结束
                myPlayer.on('ended', () => {
                  flag = false
                  clearInterval(that.timer2)
                  that.timer2 = null
                  clearInterval(that.timer)
                  that.timer = null
                  that.postVideoschedule(that.lastWatchVideoID)
                })
                myPlayer.on('pause', function() {
                  clearInterval(that.timer2)
                  that.timer2 = null
                  clearInterval(that.timer)
                  that.timer = null
                  if (Math.ceil(myPlayer.getCurrentTime()) !== Math.ceil(myPlayer.getDuration())) {
                    that.postVideoschedule(that.lastWatchVideoID)
                  }
                })
                myPlayer.on('seeked', function() {
                  that.uploadImg()
                })
              }
            )
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
      } else {
        this.$Toast.fail('没有课程权限')
      }
    },
    setUploadvideoschedule(spaceTime, videoID) {
      if (this.timer) {
        clearInterval(this.timer)
      }
      if (spaceTime > 0) {
        this.timer = setInterval(() => {
          this.postVideoschedule(videoID)
        }, 1000 * spaceTime)
      }
    },
    postVideoschedule(videoID, refreshVideos) {
      if (!this.myPlayer || !this.uploadMap[videoID]) {
        return
      }
      if (this.myPlayer.paused()) {
        if (this.uploadMap[videoID].prePostion === Math.ceil(this.myPlayer.getCurrentTime())) {
          return
        }
      }
      let params = new FormData()
      let position = Math.ceil(this.myPlayer.getCurrentTime())
      let _spaceTime = this.uploadMap[videoID].spaceTime > 30 ? 30 : this.uploadMap[videoID].spaceTime
      params.append('videoID', videoID)
      params.append('position', position)
      params.append('videoType', 'hd')
      params.append('spaceTime', _spaceTime)
      params.append('speed', 1)
      params.append('platform', 'h5')
      this.uploadMap[videoID].prePostion = position
      this.uploadMap[videoID].spaceTime = 0
      const that = this
      this.$axios.post(`/course/front/course/${this.id}/uploadvideoschedule`, params).then((res) => {
        if (res.data.status === 1) {
          if (refreshVideos) {
            this.getVideosData()
          }
          that.videoListData = that.videoListData.map((item) => {
            if (item.ID === videoID) {
              item.lastPosition = position
            }
            return item
          })
        }
      })
    },
    formatTime(time) {
      let str = time
      let temp = str.split(':')
      let Seconds = 3600 * Number(temp[0]) + 60 * Number(temp[1]) + Number(temp[2])
      if (temp.length === 2) {
        Seconds = 60 * Number(temp[0]) + Number(temp[1])
      }
      return Seconds
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.itemData.name || '课程详情'}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    // 课程详情
    async getCourseData() {
      function backToIndex() {
        this.$Toast.fail('没有该课程权限，稍后将自动跳转回首页')
        const timer = setTimeout(() => {
          clearTimeout(timer)
          this.$router.replace({
            path: '/',
          })
        }, 2000)
        return
      }
      await this.$axios
        .get(`/course/front/permit/course/${this.id}`)
        .then((res) => {
          if (res.data.status === 1) {
            // const isFromStudyCenter = this.$route.query.fromStudyCenter
            // if (isFromStudyCenter) {
            if (res.data.data.isHidden === 'Y') {
              backToIndex.bind(this)()
            }
            // } else {
            //   if (res.data.data.isHidden === 'Y') {
            //     backToIndex.bind(this)()
            //   }
            // }

            this.itemData = res.data.data
            this.lastWatchVideoID = res.data.data.lastWatchVideoID
            this.playVideoOk = res.data.data.isPhoto === 'N'
            this.isPlayVideo = res.data.data.isPhoto === 'N'
            this.isPay = res.data.data.hasPermission === 'Y'
            this.paperID = res.data.data.practices
            if (res.data.data.isDoneExerc !== 'Y') {
              this.options.splice(1, 1)
            }
            if (res.data.data.workID !== 0) {
              this.options.push({
                name: '作业',
                typeName: 'work',
                icon: 'https://img01.yzcdn.cn/vant/custom-icon-water.png',
                ID: res.data.data.workID,
              })
            }
            this.setShare()
          } else {
            this.$Toast.fail(res.data.message)
            setTimeout(() => {
              this.$router.push('/')
            }, 2000)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 课程主页视频列表
    getVideosData() {
      this.isRequestedVideo = true
      this.$axios
        .get(`/course/front/permit/course/${this.id}/videos`)
        .then((res) => {
          if (res.data.status === 1) {
            this.videoListData = res.data.data.videos
            this.studyContent = res.data.data.studyContent
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 课程主页讲义列表
    async getHandoutsData() {
      this.isRequestedHandouts = true
      await this.$axios
        .get(`/course/front/permit/course/${this.id}/handouts`)
        .then((res) => {
          if (res.data.status === 1) {
            this.handouts = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 获取讲义详情
    getHandoutsDetails(item, index) {
      if (this.itemData.hasPermission !== 'Y') {
        return this.$Toast.fail('没有课程权限，无法查看详情信息')
      }
      if (item.isShow) {
        item.isShow = false
        this.$forceUpdate()
        return
      }
      this.$Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.$axios
        .get(`/course/front/course/${this.id}/handout?handoutID=${item.ID}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.handoutsDetails = res.data.data.url
            this.handouts[index].handoutsDetails = this.handoutsDetails
            item.isShow = !item.isShow
          } else {
            this.$Toast.fail(res.data.message)
          }
          this.$Toast.clear()
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
      this.$forceUpdate()
    },
    preventBubbling(e, index) {
      this.startPosition = index
      this.imgPreviewShow = true
      return false
    },
    imgPrevieChange(index) {
      this.imgPrevieIndex = index + 1
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    onSelect(item) {
      if (item.typeName === 'exerc') {
        // 练习详情页
        this.$router.push({
          path: `/study/studyreport/${this.paperID}/${this.$route.params.id}`,
        })
      } else if (item.typeName === 'work') {
        // 作业详情页
        this.$router.push({
          path: `/nzzone/workDetails/${item.ID}?isHiddenTime=${this.itemData.isFree === 1 && this.itemData.enterpriseExclusive === 0}`,
        })
      } else {
        this.$router.push({
          path: `/allCourses/classByCourse/${this.$route.params.id}`,
        })
      }
    },
    toTeacherDetail(item) {
      this.$router.push({
        name: 'teacherDetail',
        params: {
          id: item.ID,
        },
      })
    },
  },
  destroyed() {
    if (this.myPlayer) {
      this.postVideoschedule(this.lastWatchVideoID)
      this.myPlayer.dispose()
    }
    clearInterval(this.timer)
    clearInterval(this.timer2)
  },
}
</script>

<style scoped>
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.practise.disable >>> .van-button.van-button--info {
  color: #fff;
  background-color: #585f67;
  border: 1px solid #585f67;
}
.curriculum-details >>> .van-dialog {
  margin-top: 0.93rem;
}
.curriculum-details >>> .van-dialog__content {
  max-height: 80vh;
  overflow-y: auto;
}
.poster-sharer {
  display: flex;
  align-items: center;
}
.poster-sharer span.share {
  font-size: 0.58rem;
  color: #1989fa;
  margin-left: 0.26rem;
}
.curriculum-details {
  background-color: #eee;
  margin-bottom: 1.61rem;
}
.curriculum-details >>> .van-tag {
  font-size: 0.32rem;
  line-height: 0.42rem;
  border-radius: 2px;
  white-space: nowrap;
}
.curriculum-details >>> #videoPreview {
  width: 100%;
  height: 100%;
}
.curriculum-details >>> .van-nav-bar .van-icon {
  font-size: 0.48rem;
  color: #333;
}
.curriculum-details >>> .van-tabs.van-tabs--line {
  margin-top: 0.13rem;
}
.curriculum-details >>> .van-tab {
  line-height: 0.53rem;
}
.curriculum-details >>> .van-tabs__line {
  position: absolute;
  bottom: 0.4rem;
  left: 0;
  z-index: 1;
  width: 1.06rem;
  height: 0.08rem;
  background-color: #1578f2;
  border-radius: 0.08rem;
}
.curriculum-details >>> .van-tabs--line .van-tabs__wrap {
  height: 1.17rem;
}
.curriculum-details >>> .van-tab__text.van-tab__text--ellipsis {
  font-size: 0.37rem;
  /* color: #ccc; */
}
.curriculum-details >>> .van-tab--active .van-tab__text.van-tab__text--ellipsis {
  color: #222;
  font-weight: bold;
  /* text-shadow: 1px 0px 0px #000; */
}
.curriculum-details >>> .van-tabs__content {
  background-color: #fff;
  /* padding: 0.47rem 0.53rem; */
  padding: 0.26rem 0.53rem;
  font-size: 0.37rem;
}
.buy-btn {
  background: linear-gradient(to right, #63a1ed, #97d0f5);
  color: #fff;
}
.buy-btn--highlighted {
  background: linear-gradient(to right, #532c10, #7f583b);
  color: #ffebb6;
}
.buy-btn--disabled {
  background: #acafb3;
  color: #fff;
}
.logo {
  height: 5.65rem;
  width: 100%;
}
.details {
  padding: 0.57rem 0.51rem 0.16rem 0.57rem;
  background-color: #fff;
  margin-top: -0.13rem;
}
.details-item {
  height: 0.44rem;
  display: flex;
}
.tag {
  font-size: 0.32rem;
  display: inline-block;
  border: 1px solid #fc7d55;
  border-radius: 0.11rem;
  padding: 0 1px;
  color: #fc7d55;
}
.title {
  font-size: 0.45rem;
  color: #111111;
  line-height: 0.48rem;
  padding-left: 0.13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8.74rem;
}
.teacher {
  font-size: 0.35rem;
  margin-top: 0.33rem;
  color: #111111;
  position: relative;
}
.teacher-arr {
  display: inline-block;
  width: 6.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: text-bottom;
}
.separator {
  margin: 0 0.13rem;
}
.right-data {
  position: absolute;
  right: 0;
}
.count {
  color: #777777;
}
.contain {
  color: #1578f2;
  border: 0.01rem solid #1578f2;
  font-size: 0.32rem;
  margin-left: 0.32rem;
}
.price-item {
  font-size: 0.32rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.13rem;
}
.price {
  font-size: 0.53rem;
  color: #111;
}
.old-price {
  font-size: 0.32rem;
  color: #999999;
  text-decoration: line-through;
}
.pay {
  color: #777777;
}
.player-count {
  top: 0.21rem;
  width: 1.2rem;
  height: 0.45rem;
  background-color: #ffffff;
  border-radius: 0.05rem;
  border: solid 0.01rem #1578f2;
  text-align: center;
  line-height: 0.45rem;
  right: 0;
  color: #1578f2;
}
.desc {
  font-size: 0.35rem;
  color: #474747;
  line-height: 0.56rem;
  margin-top: 0.27rem;
  margin-bottom: 0.4rem;
  padding-left: 0.13rem;
  text-align: justify;
}
.desc >>> img {
  max-width: 100%;
}
.teacher-item {
  display: flex;
  margin-top: 0.4rem;
}
.teacher-logo {
  width: 1.33rem;
  height: 1.33rem;
  margin-left: 0.21rem;
}
.teacher-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.teacher-content {
  width: 7.09rem;
  padding-left: 0.31rem;
}
.teacher-name {
  font-size: 0.37rem;
}
.teacher-desc {
  font-size: 0.32rem;
  /* text-align: justify; */
  padding-top: 0.29rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  width: 7.06rem;
}
.teacher-desc >>> p {
  margin: 0;
}
.buynow {
  height: 1.61rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.curriculum-details >>> .van-button--round {
  margin: 0.19rem 0.28rem;
  width: calc(100% - 0.56rem);
}
.curriculum-details >>> .van-button--normal {
  padding: 0 0.4rem;
  font-size: 0.37rem;
}
.curriculum-details >>> .van-button {
  height: 1.17rem;
}
.curriculum-details .practise >>> .van-button {
  width: 8.29rem;
  /* width: 100%; */
}
.course-list {
  height: 2.13rem;
  display: flex;
}
.course-list img {
  width: 2.93rem;
  height: 1.95rem;
}
.course-list-item {
  margin-left: 0.21rem;
}
.course-list-item-title {
  font-size: 0.4rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.course-list-item-tacher-name {
  font-size: 0.35rem;
  color: #777777;
  margin-top: 0.33rem;
}
.video-content {
  margin-left: 0.26rem;
}
.video-count {
  font-size: 0.32rem;
  color: #777;
}
.video-list-content {
  width: 8.26rem;
}
.video-list-item {
  display: flex;
  justify-content: space-between;
  font-size: 0.37rem;
  color: #777;
  height: 1.26rem;
  line-height: 1.26rem;
  border-bottom: 1px solid #eee;
}
.video-list-item.active {
  color: #1578f2;
}
.video-list-item-title {
  width: 4.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.video-list-item-time {
  font-size: 0.27rem;
  line-height: 1.26rem;
}
.video-list-item-icon {
  line-height: 1.26rem;
  font-size: 0.58rem;
}
.html-content {
  margin: 0 -0.53rem;
}
.html-content >>> img {
  max-width: 100%;
}
.practise {
  height: 1.61rem;
  background-color: #ffffff;
  border: solid 0.01rem #eeeeee;
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
}
.share {
  line-height: 1.55rem;
  font-size: 0.69rem;
}
.handouts-content {
  color: #999;
  width: 100vw;
  margin-left: -0.53rem;
  background-color: #eee;
  line-height: 0.93rem;
  margin-bottom: 0.13rem;
}
.handouts-content.active {
  color: #1578f2;
}
.handouts {
  width: 8.93rem;
  display: flex;
  padding: 0 0.53rem;
}
.handouts .handouts-title {
  display: inline-block;
  width: 7.78rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.handouts .van-icon.van-icon-paid {
  font-size: 0.53rem;
  margin-right: 0.26rem;
  line-height: 0.93rem;
}
.handouts-content .btn {
  line-height: 0.93rem;
}
.handouts-content .handouts-details {
  display: block;
  padding-top: 0.13rem;
  background-color: rgb(255, 255, 255);
  line-height: normal;
  text-align: center;
}
.handouts-content img {
  max-width: 100%;
  height: auto;
}
.curriculum-details >>> .van-image-preview__image img {
  pointer-events: none;
}
.qs-icon {
  position: fixed;
  bottom: 2.17rem;
  right: 0.71rem;
}
.qs-box {
  text-align: center;
  background-color: #3889ff;
  color: #fff;
  height: 0.92rem;
  width: 0.92rem;
  line-height: 0.92rem;
  border-radius: 0.21rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.qs-box::after {
  content: '▲';
  position: absolute;
  color: #3889ff;
  transform: rotate(180deg);
  font-size: 0.37rem;
  top: 0.52rem;
  left: 0.29rem;
}
</style>
<style>
.video-js {
  width: 100%;
  height: 5.65rem;
}
.my-video-dimensions {
  width: 100%;
  height: 5.65rem;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-js .vjs-tech {
  position: relative !important;
}
.video-js .vjs-time-control {
  display: block;
}
.video-js .vjs-remaining-time {
  display: none;
}
</style>
