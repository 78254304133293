<template>
  <div class="user-buy">
    <zv-nav-bar :title="pagename" :sourceType="sourceType"></zv-nav-bar>
    <van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <van-col span="24" v-for="item in buylist" :id="item.ID" :key="item.ID">
            <van-row class="buy-box" @click="gotoPage(item)">
              <van-row class="box-left">
                <div class="left-img">
                  <van-image width="100%" height="100%" :src="item.logoFile" />
                </div>
              </van-row>
              <van-row class="box-centre">
                <van-row class="centre-title">
                  <div class="title-top">
                    <div :class="{'title-tag': item.enterpriseExclusive !== 1, 'enterprise-tag': item.enterpriseExclusive === 1}">{{ item.type !== 0 ? '讲义' : item.resourceTypeName}}</div>
                    <div class="title-name">
                      <span>{{item.name}}</span>
                    </div>
                  </div>
                  <div class="title-center">{{item.timeRange}}</div>
                  <div class="title-bottom">
                    <div class="bottom-l">时长{{item.totalLength}}</div>
                    <div class="bottom-r">
                      <div class="progress" :style="{width: (item.studyPercent * 100) + '%'}"></div>
                    </div>
                  </div>
                </van-row>
              </van-row>
              <van-row class="box-right">
                <div class="r-top" :class="{'overdue': item.studyStatus === 0, 'numnom': item.maxLearningTimes !== 0 && item.hasStudyTimes >= item.maxLearningTimes, 'soldout': item.editStatus === 3, '': item.studyStatus === 1}">
                  {{item.warningContent}}
                </div>
                <div :class="{'r-conter': true}">{{item.maxLearningTimes ? item.hasStudyTimes + "/" + item.maxLearningTimes :  "不限次数"}}</div>
                <div class="r-bottom">{{item.studyPercent !== 0 ? item.studyPercent === 1 ? "已完成" : parseInt(item.studyPercent * 100) + "%" : "未学习"}}</div>
              </van-row>
            </van-row>
          </van-col>
        </van-list>
      </van-pull-refresh>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "userbuy",
  data() {
    return {
      refreshing: false,
      pagename: "我的课程",
      loading: false,
      finished: false,
      buylist: [],
      isBack: '',
      sourceType: ''
    };
  },
  created() {
    this.setShare()
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      this.sourceType = 'userBuy'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    onRefresh() {
      this.buylist = []
      this.$nextTick(() => {
        this.finished = false
        this.loading = true
        this.onLoad()
      })
    },
    onLoad() {
      this.$axios.get('/course/front/member/personalcenter/permissionresources').then(res => {
        if (res.data.status === 1) {
          this.buylist = res.data.data
          this.loading = false
          this.finished = true
          this.refreshing = false
        } else {
          this.loading = false
          this.finished = true
          this.refreshing = false
          this.$Toast.fail(res.data.message)
        }
      })
    },
    gotoPage(item) {
      let path = item.type !== 0 ? `/allCourses/courseHandout/${item.ID}` : `/allCourses/details/${item.ID}/curriculum`
      if (item.type === 0) {
        path = path + "?backUrl=" + this.$router.history.current.fullPath
        // let temp = this.$router.history.current.fullPath
        // localStorage.back_url = temp
      }
      this.$router.push({path: path})
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  }
};
</script>

<style lang="less" scoped>
.user-buy {
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-bottom: 0.27rem;
  .buy-box {
    font-size: 0.32rem;
    height: 2.45rem;
    margin: 0.27rem 0.33rem 0 0.33rem;
    background-color: #ffffff;
    border-radius: 0.13rem;
    display: flex;
    img {
      width: 100%;
    }
    .box-left {
      width: 1.52rem;
      display: flex;
      justify-content: center;
      margin-top: 0.35rem;
      .left-img {
        width: 1.04rem;
        height: 1.04rem;
        background-color: #d2d2d2;
        border-radius: 0.11rem;
      }
    }
    .box-centre {
      flex: 1;
      .centre-title {
        margin-top: 0.39rem;
        .title-top {
          display: flex;
          .title-name {
            font-family: PingFang-SC-Medium;
            font-size: 0.4rem;
            font-weight: bold;
            font-stretch: normal;
            margin-left: 0.13rem;
            letter-spacing: 0rem;
            color: #444444;
            width: 5.33rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .title-tag {
            width: 0.7rem;
            height: 0.45rem;
            background-color: #ffffff;
            border-radius: 0.08rem;
            border: solid 0.01rem #fbc047;
            background-color: #fbc047;
            font-family: PingFang-SC-Medium;
            font-size: 0.27rem;
            font-weight: normal;
            font-stretch: normal;
            line-height: 0.45rem;
            letter-spacing: 0rem;
            color: #fff;
            margin-top: 0.06rem;
            text-align: center;
          }
          .enterprise-tag {
            width: 0.7rem;
            height: 0.45rem;
            background-color: #ffffff;
            border-radius: 0.08rem;
            border: solid 0.01rem #70dd96;
            background-color: #70dd96;
            font-family: PingFang-SC-Medium;
            font-size: 0.27rem;
            font-weight: normal;
            font-stretch: normal;
            line-height: 0.45rem;
            letter-spacing: 0rem;
            color: #fff;
            margin-top: 0.06rem;
            text-align: center;
          }
        }
        .title-center {
          font-family: PingFang-SC-Regular;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #999999;
          margin-top: 0.04rem;
        }
        .title-bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 0.24rem;
          .bottom-l {
            width: 2.66rem;
            height: 0.48rem;
            background-color: #f6f7f9;
            border-radius: 0.05rem;
            font-family: PingFang-SC-Regular;
            font-size: 0.27rem;
            line-height: 0.48rem;
            color: #b1b1b1;
            text-align: center;
          }
          .bottom-r {
            width: 3.06rem;
            height: 0.27rem;
            background-color: #eeeeee;
            border-radius: 0.13rem;
            margin-top: 0.1rem;
            .progress {
              height: 0.27rem;
              background-color: #d5af82;
              border-radius: 0.1rem;
            }
          }
        }
      }
    }
    .box-right {
      width: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .r-top {
        width: 1.4rem;
        height: 0.53rem;
        background-image: linear-gradient(-90deg, #1578f2 0%, #4c9cff 100%);
        border-radius: 0rem 0.13rem 0rem 0.13rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.29rem;
        color: #ffffff;
        line-height: 0.53rem;
        text-align: center;
      }
      .r-conter {
        width: 1.34rem;
        background-color: #ffffff;
        border-radius: 0.05rem;
        border: 1px solid #1578f2;
        text-align: center;
        font-size: 0.32rem;
        letter-spacing: 0rem;
        color: #1578f2;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Regular;
      }
      .r-conter.no-border {
        border: none;
      }
      .r-bottom {
        font-family: PingFang-SC-Regular;
        font-size: 0.32rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #999999;
        align-self: flex-start;
        margin-left: 0.2rem;
      }

      .overdue {
        background-image: initial;
        background-color: #d0d0d0;
      }
      .numnom {
        background-image: linear-gradient(-90deg, #ff5134 0%, #ff8380 100%);
      }
      .soldout {
        background-image: initial;
        background-color: #d5af82;
      }
    }
  }
}
</style>
